import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { DaxHoverProvider } from './contexts/DaxHoverContext';
import axios from 'axios';
import { API_URL } from './contexts/AuthContext';

// Layout Components
import Navbar from './components/NavBar';
import AdminNavBar from './components/AdminNavBar';
import BrokerNavBar from './components/BrokerNavBar';

// Page Components
import Dashboard from './components/Dashboard';
import Add from './components/AddPage';
import Basket from './components/BasketPage';
import Saved from './components/SavedPage';
import Profile from './components/ProfilePage';
import Login from './components/Login';
import Register from './components/Register';
import { AdminLogin, AdminRegister } from './components/AdminAuth';
import { BrokerLogin, BrokerRegister } from './components/BrokerAuth';
import AdminDashboard from './components/AdminDashboard';
import BrokerDashboard from './components/BrokerDashboard';
import AdminCreateDesign from './components/AdminCreateDesign';
import AdminSavedDesigns from './components/AdminSavedDesigns';
import Inventory from './components/Inventory';
import TakeOut from './components/TakeOut';
import ItemAdd from './components/ItemAdd';
import Orders from './components/Orders';
import Messenger from './components/Messenger';
import Notifications from './components/Notifications';
import AddStore from './components/AddStore';
import Reports from './components/Reports';
import DaxAI from './components/DaxAI';
import LandingPage from './components/LandingPage';
import Finances from './components/Finances';
import Accounting from './components/Accounting';
import Checkout from './components/CheckOut';
import StoreSecPlan from './components/StoreSecPlan';
import ItemRegister from './components/ItemRegister';
import { BrokerReferralRegister } from './components/BrokerReferralRegister';
import BrokerClients from './components/BrokerClients';

// Regular user route protection
const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const [isVerifying, setIsVerifying] = useState(true);
  const location = useLocation();
  
  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    const userData = localStorage.getItem('user_data');
    
    if (token && userData && !user) {
      const timer = setTimeout(() => {
        setIsVerifying(false);
      }, 2000);
      
      return () => clearTimeout(timer);
    } else {
      setIsVerifying(false);
    }
  }, [user]);

  if (loading || isVerifying) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  const token = localStorage.getItem('auth_token');
  const userData = localStorage.getItem('user_data');

  if (!token || (!user && !userData)) {
    return <Navigate to="/login" replace />;
  }

  const effectiveUser = user || (userData ? JSON.parse(userData) : null);

  if (effectiveUser?.role === 'admin') {
    return <Navigate to="/admin/dashboard" replace />;
  }

  if (effectiveUser?.role === 'broker') {
    return <Navigate to="/broker/dashboard" replace />;
  }

  // For finance routes, return children directly (no UserLayout wrapper)
  if (location.pathname.startsWith('/inventory/finances')) {
    return children;
  }

  // For all other routes, wrap with UserLayout
  return <UserLayout>{children}</UserLayout>;
};

// Admin route protection
const AdminRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/admin/login" replace />;
  }

  if (user.role !== 'admin') {
    return <Navigate to="/store/dashboard" replace />;
  }

  return children;
};

// Broker route protection
const BrokerRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/broker/login" replace />;
  }

  if (user.role !== 'broker') {
    return <Navigate to="/store/dashboard" replace />;
  }

  return children;
};

// Public route protection
const PublicRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!user) {
    return children;
  }

  if (user.role === 'admin') {
    return <Navigate to="/admin/dashboard" replace />;
  }

  if (user.role === 'broker') {
    return <Navigate to="/broker/dashboard" replace />;
  }

  return <Navigate to="/store/dashboard" replace />;
};

// Layout wrapper for user pages
const UserLayout = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const fetchCounts = async () => {
      if (!user) return;

      try {
        
        const [notificationRes, messageRes] = await Promise.all([
          axios.get(`${API_URL}/pages/notifications/count`),
          axios.get(`${API_URL}/pages/messages/unread`)
        ]);
        
      
        setNotificationCount(notificationRes.data.count || 0);
        setUnreadMessageCount(messageRes.data.count || 0);
      } catch (error) {
        console.error('Error fetching counts in UserLayout:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
      }
    };

    if (user) {
      fetchCounts();
      const interval = setInterval(fetchCounts, 30000);
      return () => clearInterval(interval);
    }
  }, [user]);

  // Don't render navbar for finance routes
  if (location.pathname.startsWith('/inventory/finances')) {
    return children;
  }

  return (
    <>
      <Navbar 
        notificationCount={notificationCount}
        unreadMessageCount={unreadMessageCount}
      />
      {children}
    </>
  );
};

const AdminLayout = ({ children }) => (
  <>
    <AdminNavBar />
    {children}
  </>
);

const BrokerLayout = ({ children }) => {
  const { user } = useAuth();

   

  return (
    <>
      <BrokerNavBar 
      />
      {children}
    </>
  );
};

const AppContent = () => {
  const { user, loading } = useAuth();
  const location = useLocation();

  return (
    <Routes>
      {/* Landing Page Route */}
      <Route 
        path="/" 
        element={
          loading ? (
            <div className="loading">Loading...</div>
          ) : user ? (
            user.role === 'admin' ? (
              <Navigate to="/admin/dashboard" replace />
            ) : user.role === 'broker' ? (
              <Navigate to="/broker/dashboard" replace />
            ) : (
              <Navigate to="/store/dashboard" replace />
            )
          ) : (
            <LandingPage />
          )
        } 
      />

      {/* Public Routes */}
      <Route path="/login" element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      } />
      <Route path="/register" element={
        <PublicRoute>
          <Register />
        </PublicRoute>
      } />
      <Route path="/register/:brokerId" element={
        <PublicRoute>
          <BrokerReferralRegister />
        </PublicRoute>
      } />
      <Route path="/admin/login" element={
        <PublicRoute>
          <AdminLogin />
        </PublicRoute>
      } />
      <Route path="/admin/register" element={
        <PublicRoute>
          <AdminRegister />
        </PublicRoute>
      } />
      <Route path="/broker/login" element={
        <PublicRoute>
          <BrokerLogin />
        </PublicRoute>
      } />
      <Route path="/broker/register" element={
        <PublicRoute>
          <BrokerRegister />
        </PublicRoute>
      } />

    <Route path="/broker/clients" element={
      <BrokerRoute>
        <BrokerLayout>
          <BrokerClients />
        </BrokerLayout>
      </BrokerRoute>
    } />

      {/* Finance Routes - All under /inventory/finances */}
      <Route path="/store/finances/*" element={
        <PrivateRoute>
          <Finances />
        </PrivateRoute>
      } />

      {/* Regular User Routes */}
      <Route path="/store/dashboard" element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      } />
      <Route path="/store/add-item" element={
        <PrivateRoute>
          <ItemAdd />
        </PrivateRoute>
      } />

      <Route path="/store/register-item" element={
        <PrivateRoute>
          <ItemRegister />
        </PrivateRoute>
      } />
      <Route path="/store/inventory" element={
        <PrivateRoute>
          <Inventory />
        </PrivateRoute>
      } />
      <Route path="/store/takeout" element={
        <PrivateRoute>
          <TakeOut />
        </PrivateRoute>
      } />

      <Route path="/store/checkout" element={
        <PrivateRoute>
          <Checkout />
        </PrivateRoute>
      } />
      <Route path="/store/orders" element={
        <PrivateRoute>
          <Orders />
        </PrivateRoute>
      } />
      <Route path="/store/orders/new" element={
        <PrivateRoute>
          <Orders />
        </PrivateRoute>
      } />
      <Route path="/store/orders/:orderNumber" element={
        <PrivateRoute>
          <Orders />
        </PrivateRoute>
      } />
      <Route path="/store/notifications" element={
        <PrivateRoute>
          <Notifications />
        </PrivateRoute>
      } />
      <Route path="/store/add-store" element={
        <PrivateRoute>
          <AddStore />
        </PrivateRoute>
      } />
      <Route path="/store/reports" element={
        <PrivateRoute>
          <Reports />
        </PrivateRoute>
      } />
      <Route path="/store/dax" element={
        <PrivateRoute>
          <DaxAI />
        </PrivateRoute>
      } />
      <Route path="/store/dax/new" element={
        <PrivateRoute>
          <DaxAI />
        </PrivateRoute>
      } />
      <Route path="/store/dax/:chatId" element={
        <PrivateRoute>
          <DaxAI />
        </PrivateRoute>
      } />
      <Route path="/store/profile" element={
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      } />

      <Route path="/store/secplan" element={
            <PrivateRoute>
              <StoreSecPlan />
            </PrivateRoute>
      } />
      <Route path="/store/messenger" element={
        <PrivateRoute>
          <Messenger />
        </PrivateRoute>
      } />
      <Route path="/store/messenger/:chatId" element={
        <PrivateRoute>
          <Messenger />
        </PrivateRoute>
      } />

      {/* Admin Routes */}
      <Route path="/admin/dashboard" element={
        <AdminRoute>
          <AdminLayout>
            <AdminDashboard />
          </AdminLayout>
        </AdminRoute>
      } />
      <Route path="/admin/create" element={
        <AdminRoute>
          <AdminLayout>
            <AdminCreateDesign />
          </AdminLayout>
        </AdminRoute>
      } />
      <Route path="/admin/create/:id" element={
        <AdminRoute>
          <AdminLayout>
            <AdminCreateDesign />
          </AdminLayout>
        </AdminRoute>
      } />
      <Route path="/admin/saved-designs" element={
        <AdminRoute>
          <AdminLayout>
            <AdminSavedDesigns />
          </AdminLayout>
        </AdminRoute>
      } />

      {/* Broker Routes */}
      <Route path="/broker/dashboard" element={
        <BrokerRoute>
          <BrokerLayout>
            <BrokerDashboard />
          </BrokerLayout>
        </BrokerRoute>
      } />
      
     


      {/* Catch-all redirect */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <DaxHoverProvider>
          <div className="app">
            <AppContent />
          </div>
        </DaxHoverProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;