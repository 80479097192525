import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { 
  UserRound, MoreHorizontal, Share, Pencil, Archive, Trash2, 
  ArrowLeftToLine, ArrowRightToLine, ArrowUp, Box, FileText, 
  ShoppingBag, Paperclip 
} from 'lucide-react';
import './DaxAI.css';
import DocumentPreview from './DocumentPreview';
import StyledMessage from './StyledMessage';
import { translations } from './Data/data'; // Import translations

const DaxAI = () => {
  // State declarations
  const [chats, setChats] = useState([]);
  const [starredChats, setStarredChats] = useState([]);
  const [recentChats, setRecentChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [generatingText, setGeneratingText] = useState('');
  const [activeOptionsMenu, setActiveOptionsMenu] = useState(null);
  const [hoveredChat, setHoveredChat] = useState(null);
  const [renamingChat, setRenamingChat] = useState(null);
  const [newChatName, setNewChatName] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSidebarPinned, setIsSidebarPinned] = useState(true);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isThinking, setIsThinking] = useState(false);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [messageDocumentPreviews, setMessageDocumentPreviews] = useState({});
  const [isInventoryBtnHovered, setIsInventoryBtnHovered] = useState(false);
  const [isOrdersBtnHovered, setIsOrdersBtnHovered] = useState(false);
  const [isReportsBtnHovered, setIsReportsBtnHovered] = useState(false);
  const [generatingMessage, setGeneratingMessage] = useState(null);
  const [thinkingState, setThinkingState] = useState('');
  const [language, setLanguage] = useState('en');

  const location = useLocation();
  const navigate = useNavigate();
  const { chatId } = useParams();
  const { user } = useAuth();

  const chatMainRef = useRef(null);
  const inputRef = useRef(null);
  const optionsMenuRef = useRef(null);
  const sidebarRef = useRef(null);

  // Translation helper function
const translate = (key) => {
  return translations[language]?.[key] || translations['en'][key];
};

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    
    if (location.state?.initialMessage) {
      const messageData = location.state.initialMessage;
      setDocumentPreview(processDocumentContent(messageData));
      // Clear the navigation state
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [user, location.state, navigate]);

  const getThinkingState = (documentPreview) => {
    if (!documentPreview) return translate('thinking_deeply');
    
    switch (documentPreview.title) {
      case translate('inventory_summary'):
        return translate('analyzing_inventory_data');
      case translate('inventory_analytics_overview'):
        return translate('processing_analytics');
      default:
        return translate('analyzing_document');
    }
  };

  const getDocumentTitle = (content) => {
    if (content.toLowerCase().includes('inventory summary')) {
      return translate('inventory_summary');
    } else if (content.toLowerCase().includes('analytics') || 
               content.toLowerCase().includes('analysis')) {
      return translate('inventory_analytics_overview');
    }
    return translate('document_preview');
  };

  const processDocumentContent = (messageData) => {
    const documentTitle = getDocumentTitle(messageData.content);
    return {
      title: documentTitle,
      type: "Document",
      content: messageData.content
    };
  };


  // Initialize chat
  useEffect(() => {
    if (modifiedBrandName && !isInitialized) {
      initializeChat();
    }
  }, [modifiedBrandName, isInitialized, location.pathname]);

  useEffect(() => {
    fetchStoreInfo();
  }, []);

  const fetchStoreInfo = async () => {
    try {
      const response = await axios.get('/dax/store-info');
      setModifiedBrandName(response.data.modifiedBrandName);
      setUserEmail(response.data.email);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError(translate('error_user_data'));
    }
  };

  const initializeChat = async () => {
    if (location.pathname === '/store/dax') {
      navigate('/store/dax/new');
      return;
    }

    await loadChats();

    if (chatId === 'new') {
      setCurrentChat(null);
      setMessages([]);
      setMessageDocumentPreviews({});
    } else if (chatId) {
      try {
        const response = await axios.get(`/dax/chat/${modifiedBrandName}/${chatId}`);
        const chatData = response.data;
        setCurrentChat(chatData);
        
        const messages = chatData.messages || [];
        const previews = {};
        messages.forEach((message, index) => {
          if (message.documentPreview) {
            previews[index] = message.documentPreview;
          }
        });
        
        setMessages(messages);
        setMessageDocumentPreviews(previews);
      } catch (error) {
        console.error('Error fetching chat:', error);
        navigate('/store/dax/new');
      }
    } else {
      navigate('/store/dax/new');
    }

    setIsInitialized(true);
  };

  const loadChats = async () => {
    try {
      const response = await axios.get(`/dax/chats/${modifiedBrandName}`);
      const loadedChats = response.data;
      
      const starred = loadedChats.filter(chat => chat.status === 'Saved');
      const recents = loadedChats.filter(chat => chat.status !== 'Saved');
      
      setStarredChats(starred);
      setRecentChats(recents);
      setChats(loadedChats);
    } catch (error) {
      console.error('Error loading chats:', error);
      setError(translate('error_load_chat'));
    }
  };

  // Handle message submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const userInputContent = inputText.trim();
    if (!userInputContent && !documentPreview) return;
  
    // For the UI: Create visible content WITHOUT the instruction
    const visibleContent = documentPreview 
      ? `${translate('document_content')}:\n${documentPreview.content}\n\n${translate('user_question')}: ${userInputContent}`
      : userInputContent;
    
    // For the API: Create message content WITH the hidden instruction
    // Add instruction at the end where it's less likely to be displayed
    const apiContent = documentPreview 
      ? `${translate('document_content')}:\n${documentPreview.content}\n\n${translate('user_question')}: ${userInputContent} `
      : `${userInputContent}`;
    
    // Create message object with separate properties for UI and API
    const newMessage = {
      role: 'user',
      content: visibleContent,
      timestamp: new Date().toISOString(),
      originalContent: apiContent // This is for the API only
    };
  
    setInputText('');
    setDocumentPreview(null);
    setMessages(prev => [...prev, newMessage]);
    setIsLoading(true);
    setIsThinking(true);
    setThinkingState(getThinkingState(documentPreview));
    setError(null);
  
    try {
      let chatId = currentChat?.id;
      if (!chatId) {
        const newChat = await createNewChat(newMessage);
        if (!newChat) throw new Error('Failed to create new chat');
        chatId = newChat.id;
        setCurrentChat(newChat);
      }
  
      // When sending to API, use originalContent property that contains the instruction
      const response = await axios.post('/dax/chat/message', {
        modifiedBrandName,
        chatId,
        messages: [...messages.map(m => m.originalContent ? 
          { ...m, content: m.originalContent } : m), 
          { ...newMessage, content: apiContent }]
      });
  
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to get response');
      }
  
      const { response: aiResponse } = response.data;
      
      setIsGenerating(true);
      setGeneratingMessage({
        role: 'assistant',
        content: '',
        timestamp: new Date().toISOString(),
        isGenerating: true
      });
  
      let text = '';
      const fullText = aiResponse;
      const chunkSize = 3;
      
      for (let i = 0; i <= fullText.length; i += chunkSize) {
        text = fullText.slice(0, i);
        setGeneratingMessage(prev => ({
          ...prev,
          content: text
        }));
        await new Promise(resolve => setTimeout(resolve, 1));
      }
  
      const assistantMessage = {
        role: 'assistant',
        content: fullText,
        timestamp: new Date().toISOString()
      };
  
      setMessages(prev => [...prev.slice(0, prev.length - 1), newMessage, assistantMessage]);
      setGeneratingMessage(null);
  
      const chatTitle = fullText.split('.')[0];
      const truncatedTitle = chatTitle.length > 50 
        ? chatTitle.substring(0, 47) + '...' 
        : chatTitle;
  
      const updatedChat = {
        ...currentChat,
        id: chatId,
        name: truncatedTitle,
        messages: [...messages, newMessage, assistantMessage]
      };
  
      setCurrentChat(updatedChat);
      updateChatsLists(updatedChat);
  
      await updateChatInFirestore(chatId, truncatedTitle, [...messages, newMessage, assistantMessage]);
      
      navigate(`/store/dax/${chatId}`);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
      setIsThinking(false);
      setIsGenerating(false);
    }
  };

  const createNewChat = async (userMessage) => {
    try {
      const response = await axios.post('/dax/chat/new', {
        modifiedBrandName,
        userMessage
      });
      
      const createdChat = response.data;
      setCurrentChat(createdChat);
      setMessages(createdChat.messages);
      setRecentChats(prev => [createdChat, ...prev]);
      setChats(prev => [createdChat, ...prev]);
      return createdChat;
    } catch (error) {
      console.error('Error creating chat:', error);
      setError(translate('error_chat_creation'));
      return null;
    }
  };

  const updateChatsLists = (updatedChat) => {
    const updateList = list => list.map(chat => 
      chat.id === updatedChat.id ? updatedChat : chat
    );

    setChats(updateList);
    setRecentChats(updateList);
  };

  const updateChatInFirestore = async (chatId, title, messages) => {
    try {
      await axios.put(`/dax/chat/${modifiedBrandName}/${chatId}`, {
        name: title,
        messages,
        lastUpdated: new Date()
      });
    } catch (error) {
      console.error('Error updating chat:', error);
    }
  };

  const handleError = (error) => {
    console.error('Error:', error);
    const errorMessage = translate('error_generic');
    setError(errorMessage);
    setMessages(prev => [...prev, { 
      role: 'assistant',
      content: errorMessage,
      timestamp: new Date().toISOString()
    }]);
  };

  // Chat management functions
  const handleNewChat = () => {
    setCurrentChat(null);
    setMessages([]);
    setDocumentPreview(null);
    navigate('/store/dax/new');
  };

  const handleChatRename = async (chatId, newName) => {
    try {
      await axios.put(`/dax/chat/${modifiedBrandName}/${chatId}/rename`, {
        newName
      });
      
      updateChatsLists({
        ...currentChat,
        name: newName
      });
    } catch (error) {
      console.error('Error renaming chat:', error);
      setError(translate('error_chat_rename'));
    }
    setRenamingChat(null);
  };
  const handleChatLeave = () => {
    setHoveredChat(null);
 };

 const truncateChatName = (name) => {
  return name && name.length > 20 ? `${name.substring(0, 20)}...` : (name || 'New Chat');
};


const handleChatHover = (chatId) => {
  setHoveredChat(chatId);
};



  const handleSaveChat = async (chatId) => {
    try {
      await axios.put(`/dax/chat/${modifiedBrandName}/${chatId}`, {
        status: 'Saved'
      });
      
      const updatedChat = {
        ...currentChat,
        status: 'Saved'
      };
      
      setStarredChats(prev => [updatedChat, ...prev]);
      setRecentChats(prev => prev.filter(chat => chat.id !== chatId));
      updateChatsLists(updatedChat);
    } catch (error) {
      console.error('Error saving chat:', error);
      setError(translate('error_save_chat'));
    }
    setActiveOptionsMenu(null);
  };

  const handleDeleteChat = async (chatId) => {
    try {
      await axios.delete(`/dax/chat/${modifiedBrandName}/${chatId}`);
      
      setChats(prev => prev.filter(chat => chat.id !== chatId));
      setStarredChats(prev => prev.filter(chat => chat.id !== chatId));
      setRecentChats(prev => prev.filter(chat => chat.id !== chatId));
      
      if (currentChat?.id === chatId) {
        setCurrentChat(null);
        setMessages([]);
        navigate('/store/dax/new');
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
      setError(translate('error_chat_delete'));
    }
    setActiveOptionsMenu(null);
  };

  // UI handlers
  const handleChatItemClick = (chat) => {
    if (currentChat?.id === chat.id) return;
    
    setCurrentChat(chat);
    setMessages(chat.messages || []);
    navigate(`/store/dax/${chat.id}`);
  };

  const handleChatItemDoubleClick = (chat) => {
    setRenamingChat(chat.id);
    setNewChatName(truncateChatName(chat.name) || translate('new_chat'));
  };


  const handleRenameSubmit = async (e, chatId) => {
    e.preventDefault();
    
    if (newChatName.trim()) {
      try {
        await axios.put(`/dax/chat/${modifiedBrandName}/${chatId}/rename`, {
          newName: newChatName
        });
        
        setChats(prevChats => prevChats.map(chat => 
          chat.id === chatId ? { ...chat, name: newChatName } : chat
        ));
        setRecentChats(prevChats => prevChats.map(chat => 
          chat.id === chatId ? { ...chat, name: newChatName } : chat
        ));
        setStarredChats(prevChats => prevChats.map(chat => 
          chat.id === chatId ? { ...chat, name: newChatName } : chat
        ));
      } catch (error) {
        console.error('Error renaming chat:', error);
        setError(translate('error_rename'));
      }
    }
    setRenamingChat(null);
  };

  const handleOptionsClick = (e, chatId) => {
    e.stopPropagation();
    
    // Toggle the active menu
    if (activeOptionsMenu === chatId) {
      setActiveOptionsMenu(null);
      return;
    }
    
    // First, determine the position without showing the menu
    try {
      // Get the target chat item
      const chatItem = e.currentTarget.closest('.dax-chat-item');
      if (!chatItem) {
        setActiveOptionsMenu(chatId); // Fallback to default behavior
        return;
      }
      
      // Get the chat item position
      const chatItemRect = chatItem.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      
      // Estimate menu height (typical height of the menu with 4 options)
      const estimatedMenuHeight = 160; 
      
      // Check if we need to position above
      const spaceBelow = viewportHeight - chatItemRect.bottom;
      const shouldPositionAbove = spaceBelow < estimatedMenuHeight + 10;
      
      // Set the position mode in a data attribute before showing the menu
      chatItem.setAttribute('data-menu-position', shouldPositionAbove ? 'above' : 'below');
    } catch (error) {
      console.error('Error pre-positioning menu:', error);
      // Continue with default behavior
    }
    
    // Now set the active menu to show it
    setActiveOptionsMenu(chatId);
  
   // Fine-tune the position after the menu is rendered
   setTimeout(() => {
    try {
      // Find the menu element using document query
      const optionsMenu = document.querySelector(`.dax-chat-item .options-menu[data-chat-id="${chatId}"]`);
      if (!optionsMenu) return;
      
      // Get the parent chat item
      const chatItem = optionsMenu.closest('.dax-chat-item');
      if (!chatItem) return;
      
      // Check the position mode we determined earlier
      const positionMode = chatItem.getAttribute('data-menu-position');
      
      if (positionMode === 'above') {
        // Position above
        optionsMenu.style.opacity = '0'; // Hide during positioning
        optionsMenu.style.bottom = '100%';
        optionsMenu.style.top = 'auto';
        optionsMenu.style.transform = 'translateY(0)';
        optionsMenu.style.marginTop = '0';
        optionsMenu.style.marginBottom = '5px';
        
        // Show after positioning is complete
        setTimeout(() => {
          optionsMenu.style.opacity = '1';
        }, 5);
      } else {
        // Position below
        optionsMenu.style.top = 'auto';
        optionsMenu.style.bottom = '0';
        optionsMenu.style.transform = 'translateY(100%)';
        optionsMenu.style.marginTop = '5px';
        optionsMenu.style.marginBottom = '0';
      }
    } catch (error) {
      console.error('Error positioning menu:', error);
    }
  }, 10);
};

  const handleRenameClick = (e, chatId, currentName) => {
    e.stopPropagation();
    setRenamingChat(chatId);
    setNewChatName(truncateChatName(chat.name) || translate('new_chat'));
    setActiveOptionsMenu(null);
  };

  const toggleSidebarPin = () => {
    setIsSidebarPinned(!isSidebarPinned);
    setIsSidebarVisible(true);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isSidebarPinned) {
        if (e.clientX <= 60) {
          setIsSidebarVisible(true);
        } else if (!sidebarRef.current?.contains(e.target)) {
          setIsSidebarVisible(false);
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [isSidebarPinned]);

  useEffect(() => {
    if (currentChat) {
      setTimeout(() => {
        scrollToBottom();
        chatMainRef.current.style.opacity = '1';
      }, 0);
    }
  }, [currentChat, messages]);

  const scrollToBottom = () => {
    if (chatMainRef.current) {
      chatMainRef.current.scrollTop = chatMainRef.current.scrollHeight;
    }
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!activeOptionsMenu) return;
      
      try {
        // Check if the click was inside the menu or button
        const isInsideMenu = Boolean(event.target.closest('.options-menu'));
        const isInsideButton = Boolean(event.target.closest('.options-btn'));
        
        if (!isInsideMenu && !isInsideButton) {
          setActiveOptionsMenu(null);
        }
      } catch (error) {
        console.error('Error in click outside handler:', error);
        // Fallback - close the menu anyway to prevent it getting stuck
        setActiveOptionsMenu(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeOptionsMenu]);
  
  // Close menu when scrolling chat list
  useEffect(() => {
    const chatList = document.querySelector('.dax-chat-list');
    if (!chatList) return;
    
    const handleScroll = () => {
      if (activeOptionsMenu) {
        setActiveOptionsMenu(null);
      }
    };
    
    chatList.addEventListener('scroll', handleScroll);
    return () => chatList.removeEventListener('scroll', handleScroll);
  }, [activeOptionsMenu]);

  const renderOptionsMenu = (chatId) => {
    // Get the chat item element
    const chatItem = document.querySelector(`.dax-chat-item[data-chat-id="${chatId}"]`);
    const positionMode = chatItem?.getAttribute('data-menu-position') || 'below';
    
    // Use the style based on the determined position
    const initialStyle = positionMode === 'above' ? 
      { opacity: 0, bottom: '100%', top: 'auto', transform: 'translateY(0)', marginBottom: '5px' } :
      {};
      
    return (
      <div 
        className="options-menu" 
        data-chat-id={chatId} 
        ref={optionsMenuRef}
        style={initialStyle}
      >
        <button onClick={() => handleShareChat(chatId)}>
          <Share size={16} /> {translate('share')}
        </button>
        <button onClick={(e) => handleRenameClick(e, chatId)}>
          <Pencil size={16} /> {translate('rename')}
        </button>
        <button onClick={() => handleSaveChat(chatId)}>
          <Archive size={16} /> {translate('save')}
        </button>
        <button onClick={() => handleDeleteChat(chatId)} className="delete-btn">
          <Trash2 size={16} /> {translate('delete')}
        </button>
      </div>
    );
  };
  
  

const renderMessage = (message, index) => {
  let userInput = null;
  let documentContent = null;
  let documentTitle = null;

  if (message.role === 'user' && message.content) {
    // Process document content if present
    if (message.content.includes(translate('document_content')) && message.content.includes(translate('user_question'))) {
      const [docPart, userPart] = message.content.split(`${translate('user_question')}:`);
      documentContent = docPart.replace(`${translate('document_content')}:`, "").trim();
      
      // Remove all variations of the instruction text
      userInput = userPart
        .replace(translate('explain_document_armenian'), '')
        .replace('Please write in Armenian', '')
        .replace('write in Armenian', '')
        .trim();
        
      documentTitle = getDocumentTitle(documentContent);
    } else {
      // For regular messages, also strip out all variations of the instruction
      userInput = message.content
        .replace(translate('explain_document_armenian'), '')
        .replace('Please answer in Armenian', '')
        .replace('write in Armenian', '')
        .trim();
    }
  }

  return (
    <div 
      key={`${message.timestamp}-${index}`} 
      className={`message ${message.role}`}
    >
      {message.role === 'assistant' && (
        <div className="message-avatar assistant-avatar">DAX</div>
      )}
      <div className="message-content">
        {message.role === 'user' && (
          <>
            {documentContent && (
              <DocumentPreview
                title={documentTitle}
                type="Document"
                content={documentContent}
              />
            )}
            {/* Only display user input if there's content after filtering */}
            {userInput && userInput.trim() !== '' && (
              <div className="message-text">{userInput}</div>
            )}
          </>
        )}
        {message.role === 'assistant' && (
          <div className="markdown-content">
            <StyledMessage content={message.content} />
          </div>
        )}
      </div>
      {message.role === 'user' && (
        <div className="message-avatar user-avatar">
          <UserRound color='#00005f' size={24} />
        </div>
      )}
    </div>
  );
};

  return (
<div className={`dax-ai-container ${isSidebarPinned ? 'sidebar-pinned' : 'sidebar-unpinned'}`}>
      <div 
        ref={sidebarRef} 
        className={`dax-chat-sidebar ${isSidebarPinned ? 'pinned' : 'unpinned'} ${isSidebarVisible ? 'visible' : ''}`}
      >
        <div className="sidebar-header">
          <h1 className="sidebar-header-h1">  {translate('recent_chats')}
          </h1>
          <button className="toggle-sidebar" onClick={toggleSidebarPin}>
            {isSidebarPinned ? <ArrowLeftToLine size={18} /> : <ArrowRightToLine size={18} />}
          </button>
        </div>

        <button onClick={handleNewChat} className="new-dax-chat-btn">
        {translate('start_new_chat')}

        </button>

        <div className="dax-chat-list">
          {chats.map((chat) => (
       <div 
       key={chat.id}
       data-chat-id={chat.id}
       className={`dax-chat-item ${currentChat && chat.id === currentChat.id ? 'active' : ''} ${activeOptionsMenu === chat.id ? 'options-active' : ''}`}
       onClick={() => handleChatItemClick(chat)}
       onDoubleClick={() => handleChatItemDoubleClick(chat)}
       onMouseEnter={() => handleChatHover(chat.id)}
       onMouseLeave={handleChatLeave}
     >
       <div className="dax-chat-item-content">
         {renamingChat === chat.id ? (
           <form onSubmit={(e) => handleRenameSubmit(e, chat.id)}>
             <input
               type="text"
               value={newChatName}
               onChange={(e) => setNewChatName(e.target.value)}
               onBlur={() => handleRenameSubmit({ preventDefault: () => {} }, chat.id)}
               autoFocus
             />
           </form>
         ) : (
           truncateChatName(chat.name)
         )}
       </div>

          {(hoveredChat === chat.id || activeOptionsMenu === chat.id) && !renamingChat && (
        <button 
          className="options-btn" 
          onClick={(e) => handleOptionsClick(e, chat.id)}
          data-chat-id={chat.id}
        >
          <MoreHorizontal size={16} />
        </button>
      )}

      {activeOptionsMenu === chat.id && renderOptionsMenu(chat.id)}
    </div>
          ))}
        </div>

        <div className="dax-user-email">{userEmail}</div>
      </div>

      <div className="dax-chat-main-wrapper">
        <div className="dax-chat-main" ref={chatMainRef}>
          <div className="dax-chat-messages">
            {messages.length === 0 && <div className="empty-chat"></div>}
            {messages.map((message, index) => renderMessage(message, index))}
            
            {generatingMessage && (
              <div className="message assistant generating">
                <div className="message-avatar assistant-avatar">DAX</div>
                <div className="message-content">
                  <div className="markdown-content">
                    <StyledMessage content={generatingMessage.content} />
                  </div>
                </div>
              </div>
            )}

            {isThinking && !isGenerating && (
              <div className="message assistant">
                <div className="message-avatar assistant-avatar">DAX</div>
                <div className="message-content">{thinkingState}</div>
              </div>
            )}
          </div>

          <div className="dax-chat-input-wrapper">
            {documentPreview && (
              <DocumentPreview
                title={documentPreview.title}
                type="Document"
                content={documentPreview.content}
                onClose={() => setDocumentPreview(null)}
              />
            )}

            <form onSubmit={handleSubmit} className="dax-chat-input-form">
              <div className="dax-chat-input-container">
              <input
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder={translate('ask_anything')}
                className="dax-chat-input"
                ref={inputRef}
              />
                <button 
                  type="submit" 
                  className="dax-send-btn" 
                  disabled={isLoading || isGenerating}
                >
                  <ArrowUp className="dax-send-icon"/>
                </button>

                <div className="dax-input-info-connect-menu">
                  <div className="dax-input-info-left-bottom-icons">
                    <Paperclip size={18}/>
                  </div>

                  <div 
                    className="dax-info-connect-buttons-wrapper"
                    onMouseEnter={() => setIsInventoryBtnHovered(true)}
                    onMouseLeave={() => setIsInventoryBtnHovered(false)}
                  >
                    <div className="dax-info-connect-buttons">
                      <Box size={18} />
                      <p className="dax-info-connect-p">
                      {isInventoryBtnHovered ? translate('add_data') : translate('inventory')}
                      </p>
                    </div>
                  </div>

                  <div
                    onMouseEnter={() => setIsOrdersBtnHovered(true)}
                    onMouseLeave={() => setIsOrdersBtnHovered(false)}
                  >
                    <div className="dax-info-connect-buttons">
                      <ShoppingBag size={18} />
                      <p className="dax-info-connect-p">
                      {isOrdersBtnHovered ? translate('add_data') : translate('orders')}
                      </p>
                    </div>
                  </div>

                  <div
                    onMouseEnter={() => setIsReportsBtnHovered(true)}
                    onMouseLeave={() => setIsReportsBtnHovered(false)}
                  >
                    <div className="dax-info-connect-buttons">
                      <FileText size={18} />
                      <p className="dax-info-connect-p">
                      {isReportsBtnHovered ? translate('add_data') : translate('reports')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <p className="dax-chat-input-p">
            {translate('verification_notice')}            
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DaxAI;
