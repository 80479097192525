import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { MoreVertical, Trash2, Edit, Download, Image } from 'lucide-react';
import './AdminSavedDesigns.css';

function AdminSavedDesigns() {
  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeMenu, setActiveMenu] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDesigns();
  }, []);

  const fetchDesigns = async () => {
    try {
      setLoading(true);
      const response = await api.get('/designs');
      
      // Sort designs by last modified date
      const sortedDesigns = response.data.sort((a, b) => {
        const dateA = new Date(a.updatedAt || a.createdAt);
        const dateB = new Date(b.updatedAt || b.createdAt);
        return dateB - dateA;
      });

      setDesigns(sortedDesigns);
      setError('');
    } catch (err) {
      console.error('Error fetching designs:', err);
      setError('Failed to load designs');
    } finally {
      setLoading(false);
    }
  };

  const handleDesignClick = (designId) => {
    navigate(`/admin/create/${designId}`);
  };

  const handleDelete = async (e, designId) => {
    e.stopPropagation();
    if (!window.confirm('Are you sure you want to delete this design?')) return;

    try {
      await api.delete(`/designs/${designId}`);
      setDesigns(designs.filter(design => design.id !== designId));
    } catch (err) {
      console.error('Error deleting design:', err);
      setError('Failed to delete design');
    }
  };

  const toggleMenu = (e, designId) => {
    e.stopPropagation();
    setActiveMenu(activeMenu === designId ? null : designId);
  };

  const getDesignPreview = (design) => {
    return (
      <div className="preview-container">
        {design.previewUrl ? (
          <img 
            src={design.previewUrl}
            alt={design.title || 'Design preview'}
            className="design-preview-image"
            crossOrigin="anonymous"
            loading="lazy"
            onError={(e) => {
              console.error('Failed to load preview:', design.previewUrl);
              e.target.parentElement.innerHTML = `
                <div class="preview-placeholder">
                  <svg width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
                    <circle cx="8.5" cy="8.5" r="1.5"/>
                    <polyline points="21 15 16 10 5 21"/>
                  </svg>
                </div>
              `;
            }}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              backgroundColor: 'white'
            }}
          />
        ) : (
          <div className="preview-placeholder">
            <Image size={40} color="#9ca3af" />
          </div>
        )}
      </div>
    );
  };


  const formatDate = (dateString) => {
    if (!dateString) return 'Invalid Date';
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="saved-designs">
      <div className="saved-designs-header">
        <h1>Recent designs</h1>
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="designs-grid">
        {designs.map(design => (
          <div
            key={design.id}
            className="design-card"
            onClick={() => handleDesignClick(design.id)}
          >
            <div className="design-preview">
              {getDesignPreview(design)}
            </div>

            <div className="design-info">
              <h3>{design.title || 'Untitled Design'}</h3>
              <p className="design-dimensions">
                {design.canvasData?.width || '800'} × {design.canvasData?.height || '600'} px
              </p>
              <p className="design-date">
                Last modified: {formatDate(design.updatedAt || design.createdAt)}
              </p>
            </div>

            <div className="design-actions">
              <button
                className="menu-button"
                onClick={(e) => toggleMenu(e, design.id)}
              >
                <MoreVertical size={20} />
              </button>

              {activeMenu === design.id && (
                <div className="actions-menu">
                  <button onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/admin/create/${design.id}`);
                  }}>
                    <Edit size={16} />
                    Edit
                  </button>
                  <button onClick={(e) => handleDelete(e, design.id)}>
                    <Trash2 size={16} />
                    Delete
                  </button>
                  {design.previewUrl && (
                    <button onClick={(e) => {
                      e.stopPropagation();
                      window.open(design.previewUrl, '_blank');
                    }}>
                      <Download size={16} />
                      Download
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminSavedDesigns;