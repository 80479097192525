import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import ConfirmationDialog from './ConfirmationDialog';

const TakeOut = () => {
  const [brandName, setBrandName] = useState('');
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [takeOutAmount, setTakeOutAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const takeOutInputRef = useRef(null);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    fetchStoreInfo();
  }, [user, navigate]);

  useEffect(() => {
    if (location.state?.modifiedBrandName) {
      setModifiedBrandName(location.state.modifiedBrandName);
      setSelectedStore(location.state.selectedStore);
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if (brandName && selectedStore) {
      fetchItems();
    }
  }, [brandName, selectedStore]);

  useEffect(() => {
    if (selectedItem) {
      const item = items.find(i => i.id === selectedItem);
      if (item) {
        setMaxAmount(item.quantity);
      }
    }
  }, [selectedItem, items]);

  const fetchStoreInfo = async () => {
    try {
      const response = await axios.get('/takeout/store-info');
      const { brandName, modifiedBrandName, stores } = response.data;
      setBrandName(brandName);
      setModifiedBrandName(modifiedBrandName);
      setStores(stores);
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch store information');
      setLoading(false);
    }
  };

  const fetchItems = async () => {
    if (!modifiedBrandName || !selectedStore) return;

    try {
      const response = await axios.get('/takeout/items', {
        params: { modifiedBrandName, selectedStore }
      });
      setItems(response.data);
    } catch (error) {
      setError('Failed to fetch items');
    }
  };

  const handleConfirm = async () => {
    try {
      await axios.post('/takeout/take-out', {
        modifiedBrandName,
        selectedStore,
        selectedItem,
        takeOutAmount
      });

      setShowConfirmation(false);
      setSelectedItem('');
      setTakeOutAmount('');
      fetchItems();
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to take out item');
      setShowConfirmation(false);
    }
  };

  const handleTakeOut = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const isFormValid = selectedStore && selectedItem && takeOutAmount;

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="add-item-container">
      <div className="itemadd-container">
        <h2 className="itemadd-title">Take Out Items</h2>
        <form onSubmit={handleTakeOut} className="itemadd-form">
          <div className="itemadd-form-group">
            <select 
              className="itemadd-form-group-input-i"
              value={selectedStore} 
              onChange={(e) => setSelectedStore(e.target.value)}
            >
              <option value="">Select a Store</option>
              {stores.map(store => (
                <option key={store} value={store}>{store}</option>
              ))}
            </select>
          </div>
          <div className="itemadd-form-group">
            <select 
              className="itemadd-form-group-input-i"
              value={selectedItem} 
              onChange={(e) => setSelectedItem(e.target.value)}
            >
              <option value="">Select an item</option>
              {items.map(item => (
                <option key={item.id} value={item.id}>
                  {item.barcode || "No barcode"} - Current quantity: {item.quantity}
                </option>
              ))}
            </select>
          </div>
          <div className="itemadd-form-group">
            <input
              ref={takeOutInputRef}
              className="itemadd-form-group-input-i"
              type="number"
              value={takeOutAmount}
              onChange={(e) => setTakeOutAmount(e.target.value)}
              placeholder="Amount to take out"
              min="1"
              max={maxAmount}
            />
          </div>
          
          <button
            type="submit"
            className="itemadd-submit-btn"
            disabled={!isFormValid}
          >
            Take Out
          </button>
        </form>

        {showConfirmation && (
            <ConfirmationDialog
            message="Are you sure you want to proceed?"
            onConfirm={handleConfirm}
            onCancel={() => setShowConfirmation(false)}
            confirmText="Take Out"
            cancelText="Cancel"
          />
        )}
      </div>
    </div>
  );
};

export default TakeOut;