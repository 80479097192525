import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './Accounting.css';
import EmployeeDetailsForm from './EmployeeDetailsForm';
import { getPreciseCalculation } from './PreciseDateCalculations.js';

const INACTIVITY_TIMEOUT = 5 * 60 * 1000;

const Accounting = () => {
  // State declarations (keeping all original state declarations)
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    surname: '',
    type: '',
    country: '',
    state: '',
    fiscalYearEnd: '',
    currency: '',
  });
  const [financialData, setFinancialData] = useState({
    revenue: 0,
    costOfGoodsSold: 0,
    operatingExpenses: {
      rent: 0,
      utilities: 0,
      marketing: 0,
      other: 0,
    },
    otherIncome: 0,
    otherExpenses: 0,
  });
  const [taxRates, setTaxRates] = useState({
    incomeTax: 0,
    salesTax: 0,
    payrollTax: 0,
  });
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const inactivityTimerRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      fetchStoreData();
    } else {
      setLoading(false);
      setError("Please log in to access accounting data.");
    }

    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('keypress', resetInactivityTimer);

    return () => {
      document.removeEventListener('mousemove', resetInactivityTimer);
      document.removeEventListener('keypress', resetInactivityTimer);
      if (inactivityTimerRef.current) clearTimeout(inactivityTimerRef.current);
    };
  }, []);

  // Fetch store data
  const fetchStoreData = async () => {
    try {
      const response = await axios.get('/accounting/store-data', {
        headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` }
      });
      setModifiedBrandName(response.data.modifiedBrandName);
      fetchAccountingData(response.data.modifiedBrandName);
    } catch (error) {
      console.error('Error fetching store data:', error);
      setError('Failed to fetch store data');
      setLoading(false);
    }
  };

  // Fetch accounting data
  const fetchAccountingData = async (brandName) => {
    try {
      const response = await axios.get(`/accounting/accounting-data/${brandName}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` }
      });
      const data = response.data;
      setCompanyInfo(data.companyInfo || {});
      setFinancialData(data.financialData || {});
      setTaxRates(data.taxRates || {});
      await fetchEmployees(brandName);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching accounting data:', error);
      setError('Failed to fetch accounting data');
      setLoading(false);
    }
  };

  // Save company info
  const saveCompanyInfo = async () => {
    try {
      await axios.put(
        `/accounting/company-info/${modifiedBrandName}`,
        { companyInfo },
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` } }
      );
    } catch (error) {
      console.error('Error saving company info:', error);
      setError('Failed to save company information');
    }
  };

  // Save financial data
  const saveFinancialData = async () => {
    try {
      await axios.put(
        `/accounting/financial-data/${modifiedBrandName}`,
        { financialData },
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` } }
      );
    } catch (error) {
      console.error('Error saving financial data:', error);
      setError('Failed to save financial data');
    }
  };

  // Save tax rates
  const saveTaxRates = async () => {
    try {
      await axios.put(
        `/accounting/tax-rates/${modifiedBrandName}`,
        { taxRates },
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` } }
      );
    } catch (error) {
      console.error('Error saving tax rates:', error);
      setError('Failed to save tax rates');
    }
  };

  const saveAccountingData = async () => {
    if (!modifiedBrandName) return;
  
    setLoading(true);
    setError(null);
    try {
      await axios.post(
        `/accounting/save-all/${modifiedBrandName}`,
        {
          companyInfo,
          financialData,
          taxRates
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` }
        }
      );
    } catch (err) {
      console.error("Error saving accounting data:", err);
      setError("Failed to save accounting data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Employee management functions
  const fetchEmployees = async (brandName) => {
    try {
      const response = await axios.get(
        `/accounting/employees/${brandName}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` } }
      );
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setError('Failed to fetch employees');
    }
  };

  const addEmployee = async (employeeData) => {
    try {
      const response = await axios.post(
        `/accounting/employees/${modifiedBrandName}`,
        employeeData,
        {
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.data.success) {
        await fetchEmployees(modifiedBrandName);
        return true;
      } else {
        setError('Failed to add employee');
        return false;
      }
    } catch (error) {
      console.error('Error adding employee:', error);
      setError('Failed to add employee. Please try again.');
      return false;
    }
  };

  const updateEmployee = async (employeeId, employeeData) => {
    try {
      await axios.put(
        `/accounting/employees/${modifiedBrandName}/${employeeId}`,
        employeeData,
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` } }
      );
      await fetchEmployees(modifiedBrandName);
    } catch (error) {
      console.error('Error updating employee:', error);
      setError('Failed to update employee');
    }
  };

  const deleteEmployee = async (employeeId) => {
    try {
      await axios.delete(
        `/accounting/employees/${modifiedBrandName}/${employeeId}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` } }
      );
      await fetchEmployees(modifiedBrandName);
    } catch (error) {
      console.error('Error deleting employee:', error);
      setError('Failed to delete employee');
    }
  };

  const resetInactivityTimer = () => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    inactivityTimerRef.current = setTimeout(() => {
      // Handle inactivity timeout
    }, INACTIVITY_TIMEOUT);
  };

  if (loading) return <div className="loading">Loading accounting data...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="accounting-container">
      <h1 className='h1-finances'>Accounting Dashboard</h1>
      <Tabs>
        <TabList>
          <Tab>Company Info</Tab>
          <Tab>Financial Data</Tab>
          <Tab>Employee Management</Tab>
          <Tab>Tax Rates</Tab>
          <Tab>Reports</Tab>
        </TabList>

        <TabPanel>
          <CompanyInfoForm 
            companyInfo={companyInfo} 
            handleChange={(e) => setCompanyInfo({...companyInfo, [e.target.name]: e.target.value})}
            handleSubmit={saveAccountingData}
          />
        </TabPanel>

        <TabPanel>
          <FinancialDataForm 
            financialData={financialData}
            handleChange={(e) => {
              const { name, value } = e.target;
              if (name.includes('.')) {
                const [category, subCategory] = name.split('.');
                setFinancialData({
                  ...financialData,
                  [category]: {
                    ...financialData[category],
                    [subCategory]: parseFloat(value) || 0,
                  },
                });
              } else {
                setFinancialData({ ...financialData, [name]: parseFloat(value) || 0 });
              }
            }}
            handleSubmit={saveAccountingData}
          />
        </TabPanel>

        <TabPanel>
          <EmployeeManagement 
            employees={employees}
            addEmployee={addEmployee}
            updateEmployee={updateEmployee}
            deleteEmployee={deleteEmployee}
            modifiedBrandName={modifiedBrandName}
          />
        </TabPanel>

        <TabPanel>
          <TaxRateForm 
            taxRates={taxRates}
            handleChange={(e) => setTaxRates({...taxRates, [e.target.name]: parseFloat(e.target.value) || 0})}
            handleSubmit={saveAccountingData}
          />
        </TabPanel>

        <TabPanel>
          <ReportGenerator modifiedBrandName={modifiedBrandName} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

// Component definitions
const CompanyInfoForm = ({ companyInfo, handleChange, handleSubmit }) => (
  <form onSubmit={(e) => {
    e.preventDefault();
    handleSubmit();
  }} className="company-info-form">    
    <h2 className='h2-finances'>Company Information</h2>
    <div className="form-group-a">
      <label htmlFor="name">Company Name:</label>
      <input
        className="form-group-input-a"
        id="name"
        name="name"
        value={companyInfo.name}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="type">Company Type:</label>
      <select
        className="form-group-input-a"
        id="type"
        name="type"
        value={companyInfo.type}
        onChange={handleChange}
        required
      >
        <option value="">Select Company Type</option>
        <option value="corporation">Corporation</option>
        <option value="llc">LLC</option>
        <option value="partnership">Partnership</option>
        <option value="sole-proprietorship">Sole Proprietorship</option>
      </select>
    </div>
    <div className="form-group-a">
      <label htmlFor="country">Country:</label>
      <input
        className="form-group-input-a"
        id="country"
        name="country"
        value={companyInfo.country}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="state">State/Province:</label>
      <input
        className="form-group-input-a"
        id="state"
        name="state"
        value={companyInfo.state}
        onChange={handleChange}
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="fiscalYearEnd">Fiscal Year End:</label>
      <input
        className="form-group-input-a"
        id="fiscalYearEnd"
        name="fiscalYearEnd"
        type="date"
        value={companyInfo.fiscalYearEnd}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="currency">Currency:</label>
      <input
        className="form-group-input-a"
        id="currency"
        name="currency"
        value={companyInfo.currency}
        onChange={handleChange}
        required
      />
    </div>
    <button className='button-a' type="submit">Save Company Info</button>
  </form>
);

const FinancialDataForm = ({ financialData, handleChange, handleSubmit }) => {
  const operatingExpenses = financialData?.operatingExpenses || {
    rent: 0,
    utilities: 0,
    marketing: 0,
    other: 0,
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      handleSubmit();
    }} className="financial-data-form">
      <h2 className='h2-finances'>Financial Data</h2>
      <div className="form-group-a">
        <label htmlFor="revenue">Revenue:</label>
        <input
          className="form-group-input-a"
          id="revenue"
          name="revenue"
          type="number"
          value={financialData?.revenue || 0}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group-a">
        <label htmlFor="costOfGoodsSold">Cost of Goods Sold:</label>
        <input
          className="form-group-input-a"
          id="costOfGoodsSold"
          name="costOfGoodsSold"
          type="number"
          value={financialData?.costOfGoodsSold || 0}
          onChange={handleChange}
          required
        />
      </div>
      <h3 className='h3-finances'>Operating Expenses</h3>
      <div className="form-group-a">
        <label htmlFor="rent">Rent:</label>
        <input
          className="form-group-input-a"
          id="rent"
          name="operatingExpenses.rent"
          type="number"
          value={operatingExpenses.rent}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group-a">
        <label htmlFor="utilities">Utilities:</label>
        <input
          className="form-group-input-a"
          id="utilities"
          name="operatingExpenses.utilities"
          type="number"
          value={operatingExpenses.utilities}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group-a">
        <label htmlFor="marketing">Marketing:</label>
        <input
          className="form-group-input-a"
          id="marketing"
          name="operatingExpenses.marketing"
          type="number"
          value={operatingExpenses.marketing}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group-a">
        <label htmlFor="other">Other Operating Expenses:</label>
        <input
          className="form-group-input-a"
          id="other"
          name="operatingExpenses.other"
          type="number"
          value={operatingExpenses.other}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group-a">
        <label htmlFor="otherIncome">Other Income:</label>
        <input
          className="form-group-input-a"
          id="otherIncome"
          name="otherIncome"
          type="number"
          value={financialData?.otherIncome || 0}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group-a">
        <label htmlFor="otherExpenses">Other Expenses:</label>
        <input
          className="form-group-input-a"
          id="otherExpenses"
          name="otherExpenses"
          type="number"
          value={financialData?.otherExpenses || 0}
          onChange={handleChange}
          required
        />
      </div>
      <button className='button-a' type="submit">Save Financial Data</button>
    </form>
  );
};

const TaxRateForm = ({ taxRates, handleChange, handleSubmit }) => (
  <form onSubmit={(e) => {
    e.preventDefault();
    handleSubmit();
  }} className="tax-rate-form">
    <h2 className='h2-finances'>Tax Rates</h2>
    <div className="form-group-a">
      <label htmlFor="incomeTax">Income Tax Rate (%):</label>
      <input
        className="form-group-input-a"
        id="incomeTax"
        name="incomeTax"
        type="number"
        step="0.01"
        value={taxRates.incomeTax}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="salesTax">Sales Tax Rate (%):</label>
      <input
        className="form-group-input-a"
        id="salesTax"
        name="salesTax"
        type="number"
        step="0.01"
        value={taxRates.salesTax}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group-a">
      <label htmlFor="payrollTax">Payroll Tax Rate (%):</label>
      <input
        className="form-group-input-a"
        id="payrollTax"
        name="payrollTax"
        type="number"
        step="0.01"
        value={taxRates.payrollTax}
        onChange={handleChange}
        required
      />
    </div>
    <button className='button-a' type="submit">Save Tax Rates</button>
  </form>
);

const ReportGenerator = ({ modifiedBrandName }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);

  const handleGenerateReport = async (e) => {
    e.preventDefault();
    if (!modifiedBrandName) {
      setError('Store information not available');
      return;
    }

    try {
      const response = await axios.get(
        `/accounting/financial-reports/${modifiedBrandName}`,
        {
          params: { startDate, endDate },
          headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` }
        }
      );

      const { totalRevenue, totalExpenses, netProfit, totalSalaries } = response.data;

      setReport(
        <div className="financial-reports">
          <h3 className='h3-finances'>Financial Reports</h3>
          <div className="report">
            <h4 className='h4-finances'>Income Statement</h4>
            <p>Total Revenue: ${totalRevenue.toFixed(2)}</p>
            <p>Total Expenses: ${totalExpenses.toFixed(2)}</p>
            <p>Net Profit: ${netProfit.toFixed(2)}</p>
          </div>
          <div className="report">
            <h4 className='h4-finances'>Employee Expenses</h4>
            <p>Total Salaries: ${totalSalaries.toFixed(2)}</p>
          </div>
        </div>
      );
      setError(null);
    } catch (err) {
      console.error("Error generating reports:", err);
      setError('Failed to generate financial reports. Please try again.');
      setReport(null);
    }
  };

  return (
    <div className="report-generator">
      <h2 className='h2-finances'>Generate Financial Report</h2>
      <form onSubmit={handleGenerateReport}>
        <div className="form-group-a">
          <label htmlFor="startDate">Start Date:</label>
          <input
            className="form-group-input-a"
            id="startDate"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group-a">
          <label htmlFor="endDate">End Date:</label>
          <input
            className="form-group-input-a"
            id="endDate"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </div>
        <button className='button-a' type="submit">Generate Report</button>
      </form>
      {error && (
        <div className="error-message">
          <h3 className='h3-finances'>Error Generating Reports</h3>
          <p>{error}</p>
        </div>
      )}
      {report && (
        <div className="generated-report">
          {report}
        </div>
      )}
    </div>
  );
};

const EmployeeManagement = ({ employees, addEmployee, updateEmployee, deleteEmployee, modifiedBrandName }) => {
  const [newEmployee, setNewEmployee] = useState({
    name: '',
    surname: '',
    position: '',
    department: '',
    employmentType: '',
    paymentType: '',
    salary: '',
    hourlyRate: '',
    hoursPerDay: '',
    daysPerWeek: '',
    commissionRate: '',
    pieceRate: '',
    stipendAmount: '',
    retainerAmount: '',
    projectFee: '',
    performanceMetrics: '',
    startDate: '',
    endDate: '',
    endDateNA: false,
    maritalStatus: '',
    payrollSchedule: '',
    projectEndDate: '',
    projectEndDateNA: false,
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [hoveredEmployeeId, setHoveredEmployeeId] = useState(null);

  const handleRowClick = (employee) => {
    setSelectedEmployee(employee);
  };

  const handleCloseEmployeeDetails = () => {
    setSelectedEmployee(null);
  };

  const handleUpdateEmployee = (updatedEmployee) => {
    updateEmployee(updatedEmployee.id, updatedEmployee);
    setSelectedEmployee(null);
  };

  const handleDeleteEmployee = (employeeId) => {
    deleteEmployee(employeeId);
    setSelectedEmployee(null);
  };

  const handleNewEmployeeChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedEmployee = { ...newEmployee };

    if (type === 'checkbox') {
      updatedEmployee[name] = checked;
    } else {
      updatedEmployee[name] = value;
    }

    if (name === 'paymentType') {
      if (value === 'pieceRate') {
        updatedEmployee.payrollSchedule = 'pieceRate';
      } else if (value === 'projectBased') {
        updatedEmployee.payrollSchedule = 'projectBased';
      } else {
        updatedEmployee.payrollSchedule = '';
      }
    }

    setNewEmployee(updatedEmployee);
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();
    const employeeData = { ...newEmployee };

    // Calculate monthly salary equivalent for all payment types
    let monthlySalary = 0;
    switch (employeeData.paymentType) {
      case 'hourly':
        monthlySalary = parseFloat(employeeData.hourlyRate) * 
                      parseFloat(employeeData.hoursPerDay) * 
                      parseFloat(employeeData.daysPerWeek) * 4.33;
        break;
      case 'salary':
        monthlySalary = parseFloat(employeeData.salary);
        break;
      case 'commission':
        monthlySalary = parseFloat(employeeData.salary) * 
                      (1 + parseFloat(employeeData.commissionRate) / 100);
        break;
      case 'pieceRate':
        monthlySalary = parseFloat(employeeData.pieceRate) * 1000;
        break;
      case 'projectBased':
        const startDate = new Date(employeeData.startDate);
        const endDate = new Date(employeeData.projectEndDate);
        const monthDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
                       (endDate.getMonth() - startDate.getMonth());
        monthlySalary = parseFloat(employeeData.projectFee) / (monthDiff || 1);
        break;
      default:
        monthlySalary = 0;
    }

    employeeData.calculatedMonthlySalary = monthlySalary.toFixed(2);

    const success = await addEmployee(employeeData);
    if (success) {
      setNewEmployee({
        name: '',
        surname: '',
        position: '',
        department: '',
        employmentType: '',
        paymentType: '',
        salary: '',
        hourlyRate: '',
        hoursPerDay: '',
        daysPerWeek: '',
        commissionRate: '',
        pieceRate: '',
        stipendAmount: '',
        retainerAmount: '',
        projectFee: '',
        performanceMetrics: '',
        startDate: '',
        endDate: '',
        endDateNA: false,
        maritalStatus: '',
        payrollSchedule: '',
        projectEndDate: '',
        projectEndDateNA: false,
      });
      setShowAddForm(false);
    }
  };

  const handleMouseEnter = (employeeId) => {
    setHoveredEmployeeId(employeeId);
  };

  const handleMouseLeave = () => {
    setHoveredEmployeeId(null);
  };

  const filteredEmployees = employees.filter(employee => {
    const fullName = `${employee.name} ${employee.surname}`.toLowerCase();
    const searchTerms = searchTerm.toLowerCase().split(' ');
    return searchTerms.every(term => 
      fullName.includes(term) ||
      employee.position.toLowerCase().includes(term) ||
      employee.department.toLowerCase().includes(term)
    );
  });

  const calculateMonthlyEquivalent = (employee) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    
    const { daysInMonth, weekdays, weeks } = getPreciseCalculation(currentYear, currentMonth);

    switch (employee.paymentType) {
      case 'hourly':
        const hoursPerDay = parseFloat(employee.hoursPerDay) || 8;
        const daysPerWeek = parseFloat(employee.daysPerWeek) || 5;
        const actualWorkDays = Math.min(weekdays, daysPerWeek * weeks);
        return parseFloat(employee.hourlyRate) * hoursPerDay * actualWorkDays;
      case 'salary':
      case 'commission':
        return parseFloat(employee.salary);
      case 'pieceRate':
        return parseFloat(employee.pieceRate);
      case 'projectBased':
        return parseFloat(employee.projectFee);
      default:
        return 0;
    }
  };

  const formatSalary = (employee) => {
    const monthlyEquivalent = calculateMonthlyEquivalent(employee);
    let amount;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const { weeks } = getPreciseCalculation(currentYear, currentMonth);

    switch (employee.payrollSchedule) {
      case 'weekly':
        amount = (monthlyEquivalent / weeks).toFixed(2);
        break;
      case 'biweekly':
        amount = (monthlyEquivalent / (weeks / 2)).toFixed(2);
        break;
      case 'semimonthly':
        amount = (monthlyEquivalent / 2).toFixed(2);
        break;
      case 'monthly':
        amount = monthlyEquivalent.toFixed(2);
        break;
      default:
        amount = monthlyEquivalent.toFixed(2);
    }

    switch (employee.paymentType) {
      case 'hourly':
        return `${amount}`;
      case 'salary':
        return `${amount}`;
      case 'commission':
        return `${amount} + ${employee.commissionRate}%`;
      case 'pieceRate':
        return `${employee.pieceRate}`;
      case 'projectBased':
        return `${employee.projectFee}`;
      case 'unpaid':
        return 'Unpaid';
      default:
        return 'N/A';
    }
  };

  const formatPayrollSchedule = (employee) => {
    if (employee.paymentType === 'pieceRate') {
      return 'Per Piece';
    } else if (employee.paymentType === 'projectBased') {
      return 'Per Project';
    } else {
      return employee.payrollSchedule;
    }
  };

  if (selectedEmployee) {
    return (
      <EmployeeDetailsForm
        employee={selectedEmployee}
        modifiedBrandName={modifiedBrandName}
        onClose={handleCloseEmployeeDetails}
        onUpdate={handleUpdateEmployee}
        onDelete={handleDeleteEmployee}
      />
    );
  }

  return (
    <div className="employee-management">
      {!showAddForm ? (
        <>
          <h2 className='h2-finances'>Current Employees</h2>
          <div className="employee-management-header">
            <div className="search-bar">
              <input
                className="form-group-input-a"
                type="text"
                placeholder="Search employees by name, position, or department..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button className='button-c' onClick={() => setShowAddForm(true)}>
              Add New Employee
            </button>
          </div>
          <div className="table-responsive">
            <table className="employee-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Department</th>
                  <th>Employment Type</th>
                  <th>Payroll Schedule</th>
                  <th>Compensation</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredEmployees.map((employee) => (
                  <tr
                    key={employee.id}
                    onClick={() => handleRowClick(employee)}
                    onMouseEnter={() => handleMouseEnter(employee.id)}
                    onMouseLeave={handleMouseLeave}
                    style={{ position: 'relative' }}
                  >
                    <td data-label="Name">{`${employee.name} ${employee.surname}`}</td>
                    <td data-label="Position">{employee.position}</td>
                    <td data-label="Department">{employee.department}</td>
                    <td data-label="Employment Type">{employee.employmentType}</td>
                    <td data-label="Payroll Schedule">{formatPayrollSchedule(employee)}</td>
                    <td data-label="Compensation">{formatSalary(employee)}</td>
                    <td data-label="Start Date">{employee.startDate}</td>
                    <td data-label="End Date">{employee.endDate || 'N/A'}</td>
                    {hoveredEmployeeId === employee.id && (
                      <td
                        className="hover-message"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: '#012c59',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 1,
                        }}
                      >
                        Click to view profile information
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="add-employee-section">
          <h2 className='h2-finances'>Add New Employee</h2>
          <form onSubmit={handleAddEmployee} className="add-employee-form">
            {/* Basic Information */}
            <div className="form-group-a">
              <label htmlFor="name">Name:</label>
              <input
                className="form-group-input-a"
                id="name"
                name="name"
                value={newEmployee.name}
                onChange={handleNewEmployeeChange}
                required
              />
            </div>
            <div className="form-group-a">
              <label htmlFor="surname">Surname:</label>
              <input
                className="form-group-input-a"
                id="surname"
                name="surname"
                value={newEmployee.surname}
                onChange={handleNewEmployeeChange}
                required
              />
            </div>
            <div className="form-group-a">
              <label htmlFor="maritalStatus">Marital Status:</label>
              <select
                className="form-group-input-a"
                id="maritalStatus"
                name="maritalStatus"
                value={newEmployee.maritalStatus}
                onChange={handleNewEmployeeChange}
                required
              >
                <option value="">Select Marital Status</option>
                <option value="single">Single</option>
                <option value="marriedJoint">Married Filing Jointly</option>
                <option value="marriedSeparate">Married Filing Separately</option>
                <option value="headOfHousehold">Head of Household</option>
                <option value="widower">Qualifying Widow(er) with Dependent Child</option>
                <option value="n/a">N/A</option>
              </select>
            </div>
            <div className="form-group-a">
              <label htmlFor="position">Position:</label>
              <input
                className="form-group-input-a"
                id="position"
                name="position"
                value={newEmployee.position}
                onChange={handleNewEmployeeChange}
                required
              />
            </div>
            <div className="form-group-a">
              <label htmlFor="department">Department:</label>
              <input
                className="form-group-input-a"
                id="department"
                name="department"
                value={newEmployee.department}
                onChange={handleNewEmployeeChange}
                required
              />
            </div>
            <div className="form-group-a">
              <label htmlFor="employmentType">Employment Type:</label>
              <select
                className="form-group-input-a"
                id="employmentType"
                name="employmentType"
                value={newEmployee.employmentType}
                onChange={handleNewEmployeeChange}
                required
              >
                <option value="">Select Employment Type</option>
                <option value="fullTime">Full-time</option>
                <option value="partTime">Part-time</option>
                <option value="contract">Contract</option>
                <option value="freelance">Freelance</option>
                <option value="temporary">Temporary</option>
                <option value="internship">Internship</option>
                <option value="seasonal">Seasonal</option>
              </select>
            </div>
            <div className="form-group-a">
              <label htmlFor="paymentType">Payment Type:</label>
              <select
                className="form-group-input-a"
                id="paymentType"
                name="paymentType"
                value={newEmployee.paymentType}
                onChange={handleNewEmployeeChange}
                required
              >
                <option value="">Select Payment Type</option>
                <option value="hourly">Hourly</option>
                <option value="salary">Salary</option>
                <option value="commission">Commission</option>
                <option value="pieceRate">Piece Rate</option>
                <option value="projectBased">Project-based</option>
              </select>
            </div>

            {/* Conditional fields based on payment type */}
            {newEmployee.paymentType === 'hourly' && (
              <>
                <div className="form-group-a">
                  <label htmlFor="hourlyRate">Hourly Rate:</label>
                  <input
                    className="form-group-input-a"
                    id="hourlyRate"
                    name="hourlyRate"
                    type="number"
                    value={newEmployee.hourlyRate}
                    onChange={handleNewEmployeeChange}
                    required
                  />
                </div>
                <div className="form-group-a">
                  <label htmlFor="hoursPerDay">Hours per Day:</label>
                  <input
                    className="form-group-input-a"
                    id="hoursPerDay"
                    name="hoursPerDay"
                    type="number"
                    value={newEmployee.hoursPerDay}
                    onChange={handleNewEmployeeChange}
                    required
                  />
                </div>
                <div className="form-group-a">
                  <label htmlFor="daysPerWeek">Days per Week:</label>
                  <input
                    className="form-group-input-a"
                    id="daysPerWeek"
                    name="daysPerWeek"
                    type="number"
                    value={newEmployee.daysPerWeek}
                    onChange={handleNewEmployeeChange}
                    required
                  />
                </div>
              </>
            )}
      {newEmployee.paymentType === 'salary' && (
           <div className="form-group-a">
           <label htmlFor="salary">Monthly Salary:</label>
           <input
             className="form-group-input-a"
             id="salary"
             name="salary"
             type="number"
             value={newEmployee.salary}
             onChange={handleNewEmployeeChange}
             required
           />
         </div>
       )}

       {newEmployee.paymentType === 'commission' && (
         <>
           <div className="form-group-a">
             <label htmlFor="salary">Base Salary:</label>
             <input
               className="form-group-input-a"
               id="salary"
               name="salary"
               type="number"
               value={newEmployee.salary}
               onChange={handleNewEmployeeChange}
               required
             />
           </div>
           <div className="form-group-a">
             <label htmlFor="commissionRate">Commission Rate (%):</label>
             <input
               className="form-group-input-a"
               id="commissionRate"
               name="commissionRate"
               type="number"
               value={newEmployee.commissionRate}
               onChange={handleNewEmployeeChange}
               required
             />
           </div>
         </>
       )}

       {newEmployee.paymentType === 'pieceRate' && (
         <div className="form-group-a">
           <label htmlFor="pieceRate">Rate per Piece:</label>
           <input
             className="form-group-input-a"
             id="pieceRate"
             name="pieceRate"
             type="number"
             value={newEmployee.pieceRate}
             onChange={handleNewEmployeeChange}
             required
           />
         </div>
       )}

       {newEmployee.paymentType === 'projectBased' && (
         <>
           <div className="form-group-a">
             <label htmlFor="projectFee">Project Fee:</label>
             <input
               className="form-group-input-a"
               id="projectFee"
               name="projectFee"
               type="number"
               value={newEmployee.projectFee}
               onChange={handleNewEmployeeChange}
               required
             />
           </div>
           <div className="form-group-a">
             <label htmlFor="projectEndDate">Project End Date:</label>
             <input
               className="form-group-input-a"
               id="projectEndDate"
               name="projectEndDate"
               type="date"
               value={newEmployee.projectEndDate}
               onChange={handleNewEmployeeChange}
               required
             />
           </div>
         </>
       )}

       <div className="form-group-a">
         <label htmlFor="payrollSchedule">Payroll Schedule:</label>
         <select
           className="form-group-input-a"
           id="payrollSchedule"
           name="payrollSchedule"
           value={newEmployee.payrollSchedule}
           onChange={handleNewEmployeeChange}
           required
         >
           <option value="">Select Payroll Schedule</option>
           <option value="weekly">Weekly</option>
           <option value="biweekly">Bi-weekly</option>
           <option value="semimonthly">Semi-monthly</option>
           <option value="monthly">Monthly</option>
           {newEmployee.paymentType === 'projectBased' && (
             <option value="onCompletion">On Project Completion</option>
           )}
         </select>
       </div>

       <div className="form-group-a">
         <label htmlFor="startDate">Start Date:</label>
         <input
           className="form-group-input-a"
           id="startDate"
           name="startDate"
           type="date"
           value={newEmployee.startDate}
           onChange={handleNewEmployeeChange}
           required
         />
       </div>

       <div className="form-group-a">
         <label htmlFor="endDate">End Date:</label>
         <input
           className="form-group-input-a"
           id="endDate"
           name="endDate"
           type="date"
           value={newEmployee.endDate}
           onChange={handleNewEmployeeChange}
           disabled={newEmployee.endDateNA}
           required={!newEmployee.endDateNA}
         />
         <label>
           <input
             type="checkbox"
             name="endDateNA"
             checked={newEmployee.endDateNA}
             onChange={handleNewEmployeeChange}
           /> N/A
         </label>
       </div>

       <div className="form-actions">
         <button className='button-a' type="submit">Add Employee</button>
         <button className='button-b' type="button" onClick={() => setShowAddForm(false)}>Cancel</button>
       </div>
     </form>
   </div>
 )}
</div>
);
};

export default Accounting;