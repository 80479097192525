import React, { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './LoadingAnimation.css';

const LoadingAnimation = ({ onLoadComplete }) => {
  const [progress, setProgress] = useState(0);
  const [animationIndex, setAnimationIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          const dashboardContainer = document.querySelector('.dashboard-container');
          if (dashboardContainer) {
            dashboardContainer.classList.add('loaded');
          }
          setTimeout(() => {
            setIsVisible(false);
            if (typeof onLoadComplete === 'function') {
              onLoadComplete();
            }
          }, 300);
          return 100;
        }
        return prevProgress + 2;
      });
    }, 30);

    return () => clearInterval(interval);
  }, [onLoadComplete]);

  useEffect(() => {
    setAnimationIndex(Math.floor(Math.random() * 4));
  }, []);

  const animations = [
    { id: 'animation1', title: 'Optimizing' },
    { id: 'animation2', title: 'Configuring' },
    { id: 'animation3', title: 'Processing' },
    { id: 'animation4', title: 'Finalizing' },
  ];

  const currentAnimation = animations[animationIndex];

  const svgVariants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.4,
        ease: "easeIn",
      },
    },
  };

  const circleVariants = {
    animate: {
      scale: [1, 1.2, 1],
      transition: {
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  if (!isVisible) return null;

  return (
    <div className="loading-container" ref={containerRef}>
      <div className="loading-animation">
        <AnimatePresence mode="wait">
          <motion.svg
            key={currentAnimation.id}
            width="150"
            height="150"
            viewBox="0 0 200 200"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={svgVariants}
          >
            <motion.circle
              cx="100"
              cy="100"
              r="90"
              fill="none"
              stroke="#e5e7eb"
              strokeWidth="6"
            />
            <motion.circle
              cx="100"
              cy="100"
              r="70"
              fill="none"
              stroke="#3b82f6"
              strokeWidth="8"
              strokeLinecap="round"
              strokeDasharray="439.82 439.82"
              strokeDashoffset="439.82"
              animate={{
                strokeDashoffset: 0,
                transition: {
                  duration: 2,
                  ease: "easeInOut",
                  repeat: Infinity,
                },
              }}
            />
            <motion.circle
              cx="100"
              cy="100"
              r="20"
              fill="#3b82f6"
              variants={circleVariants}
            />
          </motion.svg>
        </AnimatePresence>
      </div>
      <motion.div
        className="loading-bar"
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{ duration: 0.3 }}
      />
      <motion.p
        className="loading-text"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        {currentAnimation.title}
      </motion.p>
      <motion.p
        className="loading-percentage"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        {`${progress}%`}
      </motion.p>
    </div>
  );
};

const SimpleLoad = ({ onLoadComplete }) => {
  const [loading, setLoading] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        const reportsContainer = document.querySelector('.reports-container-main');
        if (reportsContainer) {
          reportsContainer.classList.add('loaded');
        }
        setLoading(false);
        if (typeof onLoadComplete === 'function') {
          onLoadComplete();
        }
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [loading, onLoadComplete]);

  const dotChildVariants = {
    animate: {
      scale: [1, 1.5, 1],
      opacity: [0.3, 1, 0.3],
      transition: {
        duration: 0.8,
        repeat: Infinity,
        repeatType: 'loop',
        ease: "easeInOut",
      },
    },
  };

  if (!loading) return null;

  return (
    <div className="simple-load-container" ref={containerRef}>
      <motion.div
        className="dot"
        variants={dotChildVariants}
        initial="initial"
        animate="animate"
      />
      <motion.div
        className="dot"
        variants={dotChildVariants}
        initial="initial"
        animate="animate"
        style={{ animationDelay: '0.2s' }}
      />
      <motion.div
        className="dot"
        variants={dotChildVariants}
        initial="initial"
        animate="animate"
        style={{ animationDelay: '0.4s' }}
      />
    </div>
  );
};

export default LoadingAnimation;
export { SimpleLoad };