import React from 'react';

function Saved() {
  return (
    <div className="page-container">
      <h1>Saved Page</h1>
    </div>
  );
}

export default Saved;
