import React from 'react';

function Reports() {
  return (
    <div className="page-container">
      <h1>Reports Page</h1>
    </div>
  );
}

export default Reports;
