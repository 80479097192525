import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { ArrowUpDown, ChevronLeft, ChevronRight } from 'lucide-react';
import './InventoryManagement.css';
import { translations } from './Data/data';

// Convert categories to keys for translations
const categoryKeys = [
  'all_categories', 'raw_materials', 'groceries', 'beverages', 
  'household_products', 'personal_care_items', 'snacks', 'frozen_foods', 
  'health_and_wellness_products', 'electronics', 'pet_supplies', 'office_supplies'
];

// Helper function to create a mapping between translated category names and keys
const createCategoryMappings = (language) => {
  const categoryToKeyMap = {};
  const keyToCategoryMap = {};
  
  categoryKeys.forEach(key => {
    const translatedCategory = translations[language]?.[key] || translations['en'][key];
    categoryToKeyMap[translatedCategory] = key;
    keyToCategoryMap[key] = translatedCategory;
  });
  
  return {
    categoryToKeyMap,   // Maps from translated name to key
    keyToCategoryMap    // Maps from key to translated name
  };
};

// Function to get a category key from a category name in any language
const getCategoryKeyFromName = (categoryName) => {
  // Get mappings for all supported languages
  const allMappings = Object.keys(translations).map(lang => 
    createCategoryMappings(lang).categoryToKeyMap
  );
  
  // Try to find the category key in any language
  for (const mapping of allMappings) {
    if (mapping[categoryName]) {
      return mapping[categoryName];
    }
  }
  
  // Fallback for category names that don't match any translation
  return null;
};

const Inventory = () => {
  const [language, setLanguage] = useState('en');
  
  const [inventory, setInventory] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all_categories');
  const [filterBrand, setFilterBrand] = useState('all_brands');
  const [filterStore, setFilterStore] = useState('all_stores');
  const [filterLocation, setFilterLocation] = useState('all_locations');
  const [inStockOnly, setInStockOnly] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [stores, setStores] = useState([]);
  const [brands, setBrands] = useState(['all_brands']);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [sortBy, setSortBy] = useState('expiry_date');
  const [sortOrder, setSortOrder] = useState('ascending');
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [categoryMappings, setCategoryMappings] = useState({
    categoryToKeyMap: {},
    keyToCategoryMap: {}
  });
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const navigate = useNavigate();
  const { user } = useAuth();

  // Translation helper function
  const translate = (key) => {
    return translations[language]?.[key] || translations['en'][key];
  };

  // Update category mappings when language changes
  useEffect(() => {
    setCategoryMappings(createCategoryMappings(language));
  }, [language]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    fetchStoreInfo();
  }, [user, navigate]);

  useEffect(() => {
    if (modifiedBrandName) {
      fetchInventory();
    }
  }, [modifiedBrandName]);

  useEffect(() => {
    filterAndSortInventory();
  }, [inventory, searchTerm, filterCategory, filterBrand, filterLocation, filterStore, inStockOnly, sortBy, sortOrder]);

  useEffect(() => {
    // Reset to first page when filters change
    setCurrentPage(1);
  }, [searchTerm, filterCategory, filterBrand, filterLocation, filterStore, inStockOnly]);

  const fetchStoreInfo = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await axios.get('/inventory/store-info');
      
      if (response.data) {
        // Handle different possible response formats
        const brandName = response.data.brandName || (response.data.data && response.data.data.brandName);
        const modifiedBrandName = response.data.modifiedBrandName || (response.data.data && response.data.data.modifiedBrandName);
        const stores = response.data.stores || (response.data.data && response.data.data.stores) || [];
        
        setBrandName(brandName);
        setModifiedBrandName(modifiedBrandName);
        setStores(stores);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching store info:', error);
      setError('Failed to fetch store information');
    } finally {
      setLoading(false);
    }
  };

  const fetchInventory = async () => {
    try {
      const response = await axios.get('/inventory/inventory', {
        params: { modifiedBrandName }
      });

      if (response.data) {
        // Process the inventory items and add category keys
        const processedItems = response.data.map(item => {
          // Try to find the category key for this item
          const categoryKey = getCategoryKeyFromName(item.category);
          
          return {
            ...item,
            dateAdded: new Date(item.dateAdded),
            expiryDateTo: item.expiryDateTo ? new Date(item.expiryDateTo) : null,
            // Store the category key for language-independent filtering
            categoryKey: categoryKey
          };
        });
        
        setInventory(processedItems);
        setFilteredInventory(processedItems);
        updateBrands(processedItems);
      }
    } catch (error) {
      console.error('Error fetching inventory:', error);
      setError('Failed to fetch inventory');
    }
  };

  const updateBrands = (items) => {
    const uniqueBrands = ['all_brands', ...new Set(items.map(item => item.brand).filter(Boolean))];
    setBrands(uniqueBrands);
  };

  const sendNotification = async (item, status) => {
    try {
      await axios.post('/inventory/notification', {
        modifiedBrandName,
        item,
        status
      });
    } catch (error) {
      console.error('Failed to send notification');
    }
  };

  const filterAndSortInventory = () => {
    if (!inventory.length) return;
    
    let filtered = inventory.filter(item => {
      // Using categoryKeys for more reliable filtering
      const categoryMatch = filterCategory === 'all_categories' || 
        (item.categoryKey && item.categoryKey === filterCategory);
      
      return item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        categoryMatch &&
        (filterBrand === 'all_brands' || item.brand === filterBrand) &&
        (filterStore === 'all_stores' || item.store === filterStore) &&
        (filterLocation === 'all_locations' || item.location === filterLocation) &&
        (!inStockOnly || item.quantity > 0);
    });

    filtered.sort((a, b) => {
      let comparison = 0;

      const compareDates = (dateA, dateB) => {
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;
        return new Date(dateA) - new Date(dateB);
      };

      switch (sortBy) {
        case 'alphabetical':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'expiry_date':
          comparison = compareDates(a.expiryDateTo, b.expiryDateTo);
          break;
        case 'date_added':
          comparison = compareDates(a.dateAdded, b.dateAdded);
          break;
        case 'quantity':
          comparison = b.quantity - a.quantity;
          break;
        case 'price':
          comparison = b.price - a.price;
          break;
        default:
          comparison = 0;
      }
      return sortOrder === 'ascending' ? comparison : -comparison;
    });

    setFilteredInventory(filtered);
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'ascending' ? 'descending' : 'ascending');
  };

  const handleEdit = (item) => {
    if (modifiedBrandName) {
      navigate('/store/add-item', { 
        state: { 
          selectedStore: item.store, 
          selectedItem: item,
          editMode: true,
          modifiedBrandName: modifiedBrandName 
        } 
      });
    }
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInventory.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredInventory.length / itemsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  if (error) {
    return <div className="checkout-error">{error}</div>;
  }

  return (
    <div className="inventory-page-container">
      <div className="inventory-page-layout">
        <div className="inventory-page-main">
          <div className="inventory-page-header-section">
            <div className="inventory-page-search-filters">
              <input
                type="text"
                className="inventory-page-search-input"
                placeholder={translate('search_inventory')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              
              <select 
                value={filterCategory} 
                onChange={(e) => setFilterCategory(e.target.value)}
                className="filter-dropdown"
              >
                {categoryKeys.map(category => (
                  <option key={category} value={category}>{translate(category)}</option>
                ))}
              </select>
            </div>
            
            <div className="inventory-page-filters-sort-container">
              <div className="inventory-page-filters-section">
                <div className="filter-group">
                  <select 
                    value={filterStore} 
                    onChange={(e) => setFilterStore(e.target.value)}
                    className="filter-dropdown"
                  >
                    <option value="all_stores">{translate('all_stores')}</option>
                    {stores.map(store => (
                      <option key={store} value={store}>{store}</option>
                    ))}
                  </select>
                </div>
                
                <div className="filter-group">
                  <select 
                    value={filterBrand} 
                    onChange={(e) => setFilterBrand(e.target.value)}
                    className="filter-dropdown"
                  >
                    {brands.map(brand => (
                      <option key={brand} value={brand}>
                        {brand === 'all_brands' ? translate('all_brands') : brand}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              
              <div className="inventory-page-sort-section">
                <div className="sort-controls">
                  <select 
                    value={sortBy} 
                    onChange={(e) => setSortBy(e.target.value)}
                    className="sort-dropdown"
                  >
                    <option value="alphabetical">{translate('alphabetical')}</option>
                    <option value="date_added">{translate('date_added')}</option>
                    <option value="expiry_date">{translate('expiry_date')}</option>
                    <option value="quantity">{translate('quantity')}</option>
                    <option value="price">{translate('price')}</option>
                  </select>
                  
                  <button 
                    className="inventory-page-sort-order-button" 
                    onClick={toggleSortOrder}
                  >
                    <ArrowUpDown size={20} />
                    {translate(sortOrder)}
                  </button>
                </div>
              </div>
              
              <div className="inventory-page-filter-toggle">
                <label className="toggle-switch">
                  <p>{translate('only_items_in_stock')}</p> 
                  <input
                    type="checkbox"
                    checked={inStockOnly}
                    onChange={(e) => setInStockOnly(e.target.checked)}
                  />
                  <span className="slider round"> </span>
                  <span className="toggle-label">{translate('only_in_stock')}</span>
                </label>
              </div>
            </div>
          </div>

          <div className="inventory-page-table-container">
            <table className="inventory-page-table">
              <thead>
                <tr>
                  <th>{translate('product')}</th>
                  <th>{translate('brand')}</th>
                  <th>{translate('category')}</th>
                  <th>{translate('price')}</th>
                  <th>{translate('quantity')}</th>
                  <th>{translate('expiry_date')}</th>
                  <th>{translate('store')}</th>
                  <th>{translate('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="no-items">{translate('no_items_found')}</td>
                  </tr>
                ) : (
                  currentItems.map((item) => (
                    <tr 
                      key={item.id} 
                      className={`inventory-row ${hoveredItem === item.id ? 'hovered' : ''}`}
                      onMouseEnter={() => setHoveredItem(item.id)}
                      onMouseLeave={() => setHoveredItem(null)}
                    >
                      <td>
                        <div className="product-info">
                          <div className="product-name">{item.name}</div>
                          <div className="product-details">
                            {item.weight && item.unitOfMeasureForWeight !== "N/A" && 
                              <span>{item.weight} {item.unitOfMeasureForWeight}</span>
                            }
                            {item.volume && item.unitOfMeasureForVolume !== "N/A" && 
                              <span>{item.volume} {item.unitOfMeasureForVolume}</span>
                            }
                          </div>
                        </div>
                      </td>
                      <td>{item.brand}</td>
                      <td>{item.category}</td>
                      <td className="price-cell">
                        <span className="price-display">
                        <span className="currency-symbol">{translate('currency_symbol')}</span>
                        {parseFloat(item.price).toFixed(2)}
                        </span>
                      </td>
                      <td className="inventory-quantity-cell">
                        <span className={`quantity-badge ${
                          item.quantity < item.lowStockThreshold && item.quantity !== 0 
                            ? 'low-stock' 
                            : item.quantity === 0 
                              ? 'out-of-stock' 
                              : 'in-stock'
                        }`}>
                          {item.quantity}
                        </span>
                      </td>
                      <td>
                        {item.noExpiryDate 
                          ? translate('no_expiry') 
                          : (item.expiryDateTo 
                            ? new Date(item.expiryDateTo).toLocaleDateString() 
                            : translate('no_expiry'))}
                      </td>
                      <td>{item.store}</td>
                      <td className="inventory-quantity-cell">
                        <button
                          className="edit-button"
                          onClick={() => handleEdit(item)}
                          disabled={item.quantity <= 0}
                        >
                          {translate('edit')}
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            {/* Pagination Component */}
            {filteredInventory.length > 0 && (
              <div className="inventory-pagination">
                <div className="pagination-info">
                  {translate('showing')} {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, filteredInventory.length)} {translate('of')} {filteredInventory.length} {translate('items')}
                </div>
                <div className="pagination-controls">
                  <button 
                    className="pagination-button"
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    aria-label="Previous page"
                  >
                    <ChevronLeft size={18} />
                  </button>
                  
                  <div className="pagination-pages">
                    {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                      let pageNum;
                      if (totalPages <= 5) {
                        // If we have 5 or fewer pages, show all pages
                        pageNum = i + 1;
                      } else if (currentPage <= 3) {
                        // If we're near the start, show pages 1-5
                        pageNum = i + 1;
                      } else if (currentPage >= totalPages - 2) {
                        // If we're near the end, show the last 5 pages
                        pageNum = totalPages - 4 + i;
                      } else {
                        // Otherwise show 2 pages before and 2 after the current page
                        pageNum = currentPage - 2 + i;
                      }
                      
                      return (
                        <button
                          key={pageNum}
                          className={`pagination-page-button ${currentPage === pageNum ? 'active' : ''}`}
                          onClick={() => paginate(pageNum)}
                          aria-label={`Page ${pageNum}`}
                          aria-current={currentPage === pageNum ? "page" : undefined}
                        >
                          {pageNum}
                        </button>
                      );
                    })}
                  </div>
                  
                  <button 
                    className="pagination-button"
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    aria-label="Next page"
                  >
                    <ChevronRight size={18} />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inventory;