import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, Eye, EyeOff } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import './Finances.css';
import MobileMenuFinances from './MobileMenuFinances';
import Accounting from './Accounting';
import logoFinances from '../assets/Daxtop-Finance-logo.svg';


const INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 5 minutes

const Finances = () => {
  // State declarations
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isPinSet, setIsPinSet] = useState(false);
  const [pinCode, setPinCode] = useState('');
  const [isLocked, setIsLocked] = useState(true);
  const [showPinModal, setShowPinModal] = useState(true);
  const [enteredPin, setEnteredPin] = useState('');
  const [pinError, setPinError] = useState('');
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [loading, setLoading] = useState(true);
  const [showPin, setShowPin] = useState(false);
  const [isPinValid, setIsPinValid] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const inactivityTimerRef = useRef(null);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    initializeFinances();
  }, [user, navigate]);

  const initializeFinances = async () => {
    const prefetchData = sessionStorage.getItem('financesPrefetchData');
    if (prefetchData) {
      const { modifiedBrandName, timestamp } = JSON.parse(prefetchData);
      if (Date.now() - timestamp < 300000) { // 5 minutes
        setModifiedBrandName(modifiedBrandName);
        await fetchPinCode(modifiedBrandName);
        return;
      }
    }

    setLoading(true);
    try {
      const response = await axios.get('/finances/store-info');
      setModifiedBrandName(response.data.modifiedBrandName);
      await fetchPinCode(response.data.modifiedBrandName);
    } catch (error) {
      console.error('Error initializing finances:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPinCode = async (brandName) => {
    if (!brandName) return;

    try {
      const response = await axios.get(`/finances/pin/${brandName}`);
      setIsPinSet(response.data.isPinSet);
      setPinCode(response.data.pinCode || '');
    } catch (error) {
      console.error('Error fetching PIN:', error);
    }
  };

  const handlePinChange = (e) => {
    const newPin = e.target.value;
    setEnteredPin(newPin);
    setIsPinValid(newPin.length >= 4);

    if (newPin === pinCode) {
      unlockPage();
    }
  };

  const handlePinSubmit = async (e) => {
    e.preventDefault();
    if (!isPinValid) return;

    try {
      if (isPinSet) {
        const response = await axios.post('/finances/pin/verify', {
          modifiedBrandName,
          pin: enteredPin
        });

        if (response.data.isValid) {
          unlockPage();
        } else {
          setPinError('Incorrect PIN. Please try again.');
        }
      } else {
        await axios.post('/finances/pin/set', {
          modifiedBrandName,
          pin: enteredPin
        });

        setIsPinSet(true);
        setPinCode(enteredPin);
        unlockPage();
      }
    } catch (error) {
      console.error('Error handling PIN:', error);
      setPinError(error.response?.data?.error || 'Failed to process PIN. Please try again.');
    }
  };

  const unlockPage = () => {
    setIsLocked(false);
    setShowPinModal(false);
    setEnteredPin('');
    setPinError('');
    localStorage.setItem('financesUnlocked', 'true');
    startInactivityTimer();
  };

  const startInactivityTimer = () => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    inactivityTimerRef.current = setTimeout(lockPage, INACTIVITY_TIMEOUT);
  };

  const lockPage = () => {
    setIsLocked(true);
    setShowPinModal(true);
    localStorage.removeItem('financesUnlocked');
  };

  useEffect(() => {
    const handleActivity = () => {
      if (!isLocked) {
        startInactivityTimer();
      }
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [isLocked]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 1200;
      setIsMobileView(isMobile);
      if (!isMobile) {
        setIsMobileMenuOpen(false);
      }
    };
    
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleInventoryClick = () => {
    navigate('/store/dashboard');
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prev => !prev);
  };

  if (loading) {
    return (
      <div className="finances-loading">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="finances-page">
      <nav className="finances-navbar">
        <div className="navbar-container">
          <div className="navbar-left">
            <div className="navbar-logo">
              <Link to="/store/finances/accounting">
                <img src={logoFinances} alt="DAXTOP FINANCES" />
              </Link>
            </div>
            {!isMobileView && (
              <ul className="navbar-links">
                <li>
                  <Link 
                    to="/store/finances/accounting" 
                    className={isActive('/store/finances/accounting')}
                  >
                    Accounting
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/store/finances/budgeting" 
                    className={isActive('/store/finances/budgeting')}
                  >
                    Budgeting
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/store/finances/credit-management" 
                    className={isActive('/store/finances/credit-management')}
                  >
                    Credit Management
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/store/finances/payments" 
                    className={isActive('/store/finances/payments')}
                  >
                    Payments
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/store/finances/reports" 
                    className={isActive('/store/finances/reports')}
                  >
                    Financial Reports
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <div className="navbar-actions">
            {isMobileView ? (
              <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                <Menu size={24} />
              </button>
            ) : (
              <button className="inventory-btn" onClick={handleInventoryClick}>
                Go to Inventory
              </button>
            )}
          </div>
        </div>
      </nav>

      {isMobileView && (
        <MobileMenuFinances 
          isOpen={isMobileMenuOpen} 
          onClose={() => setIsMobileMenuOpen(false)} 
        />
      )}

      <div 
        className="finances-content" 
        style={{ 
          filter: isLocked ? 'blur(5px)' : 'none', 
          pointerEvents: isLocked ? 'none' : 'auto' 
        }}
      >
        {!isLocked && (
          <Routes>
            <Route 
              path="/accounting" 
              element={<Accounting modifiedBrandName={modifiedBrandName} />} 
            />
            <Route 
              path="/budgeting" 
              element={<h1 className="h1-finances">Budgeting (Coming Soon)</h1>} 
            />
            <Route 
              path="/credit-management" 
              element={<h1 className="h1-finances">Credit Management (Coming Soon)</h1>} 
            />
            <Route 
              path="/payments" 
              element={<h1 className="h1-finances">Payments (Coming Soon)</h1>} 
            />
            <Route 
              path="/reports" 
              element={<h1 className="h1-finances">Financial Reports (Coming Soon)</h1>} 
            />
          </Routes>
        )}
      </div>

      {showPinModal && (
        <div className="pin-modal-overlay">
          <div className="pin-modal">
            <h2 className="h2-finances">Enter PIN for Access</h2>
            <form onSubmit={handlePinSubmit}>
              <div className="pin-input-container">
                <label htmlFor="pin">PIN:</label>
                <div className="pin-input-wrapper">
                  <input
                    id="pin"
                    type={showPin ? "text" : "password"}
                    value={enteredPin}
                    onChange={handlePinChange}
                    placeholder="Enter PIN"
                    required
                  />
                  <button
                    type="button"
                    className="toggle-pin-visibility"
                    onClick={() => setShowPin(!showPin)}
                  >
                    {showPin ? <EyeOff size={18} /> : <Eye size={18} />}
                  </button>
                </div>
              </div>
              {pinError && <p className="pin-error">{pinError}</p>}
              <button 
                type="submit" 
                className={`unlock-button ${!isPinValid ? 'disabled' : ''}`}
                disabled={!isPinValid}
              >
                Unlock
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Finances;
