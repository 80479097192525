import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import logoBroker from '../assets/Daxtop-Broker-logo.svg';
import './BrokerNavBar.css';

function BrokerNavBar() {
  const { user, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showUserDropdown, setShowUserDropdown] = useState(false);

  useEffect(() => {
    // Fetch actual notification count from API
    const fetchNotifications = async () => {
      try {
        // Replace with actual API call
        setNotificationCount(3); // Placeholder
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };

    fetchNotifications();
    
    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (showUserDropdown && !event.target.closest('.user-profile-dropdown')) {
        setShowUserDropdown(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showUserDropdown]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/broker/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active-nav-link' : '';
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="broker-navbar">
      <div className="broker-navbar-container">
        <div className="navbar-logo">
          <Link to="/broker/dashboard">
            <img src={logoBroker} alt="Daxtop Broker Portal" />
          </Link>
        </div>
        
        <button 
          className="mobile-menu-button"
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label="Toggle menu"
        >
          <span className="menu-icon">{menuOpen ? '✕' : '☰'}</span>
        </button>
        
        <ul className={`navbar-menu ${menuOpen ? 'open' : ''}`}>
          <li className={isActive('/broker/dashboard')}>
            <Link to="/broker/dashboard" onClick={closeMenu}>
              <i className="nav-icon dashboard-icon"></i>
              Dashboard
            </Link>
          </li>
     
          <li className={isActive('/broker/clients')}>
            <Link to="/broker/clients" onClick={closeMenu}>
              <i className="nav-icon partners-icon"></i>
              Clients
            </Link>
          </li>
          <li className={isActive('/broker/finances')}>
            <Link to="/broker/finances" onClick={closeMenu}>
              <i className="nav-icon finances-icon"></i>
              Finances
            </Link>
          </li>
          <li className={isActive('/broker/finances')}>
            <Link to="/broker/finances" onClick={closeMenu}>
              <i className="nav-icon deals-icon"></i>
              Deals
            </Link>
          </li>
          <li className={`notification-item ${isActive('/broker/notifications')}`}>
            <Link to="/broker/notifications" onClick={closeMenu}>
              <i className="nav-icon notifications-icon"></i>
              Notifications
              {notificationCount > 0 && (
                <span className="notification-badge">{notificationCount}</span>
              )}
            </Link>
          </li>
        </ul>

        <div className="user-section">
          <div 
            className="user-profile-dropdown"
            onClick={() => setShowUserDropdown(!showUserDropdown)}
          >
            <div className="user-avatar">
              {user?.fullName ? user.fullName.charAt(0).toUpperCase() : 'B'}
            </div>
            <div className="user-info">
              <span className="broker-user-name">{user?.fullName || 'Broker'}</span>
              <span className="broker-user-role">Broker Partner</span>
            </div>
            <i className={`dropdown-arrow ${showUserDropdown ? 'up' : 'down'}`}></i>
            
            {showUserDropdown && (
              <div className="user-dropdown-menu">
                <div className="dropdown-header">
                  <div className="dropdown-user-info">
                    <span className="dropdown-broker-user-name">{user?.fullName || 'Broker'}</span>
                    <span className="dropdown-user-email">{user?.email || 'broker@example.com'}</span>
                  </div>
                </div>
                <Link to="/broker/profile" onClick={() => setShowUserDropdown(false)}>
                  <i className="profile-icon"></i> My Profile
                </Link>
                <Link to="/broker/settings" onClick={() => setShowUserDropdown(false)}>
                  <i className="settings-icon"></i> Settings
                </Link>
                <Link to="/broker/help" onClick={() => setShowUserDropdown(false)}>
                  <i className="help-icon"></i> Help Center
                </Link>
                <div className="dropdown-divider"></div>
                <button onClick={handleLogout} className="logout-button">
                  <i className="broker-logout-icon"></i> Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default BrokerNavBar;