import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  ShoppingBag,
  Users,
  Palette,
  TrendingUp,
  Clock,
  CheckCircle,
  AlertCircle,
  Image,
  Star
} from 'lucide-react';
import './AdminDashboard.css';

function AdminDashboard() {
  const { user } = useAuth();

  // Sample data - replace with real data from your backend
  const stats = {
    totalOrders: 156,
    activeCustomers: 1289,
    totalDesigns: 45,
    monthlyRevenue: 12567
  };

  const recentOrders = [
    { id: 1, customer: 'Emma Wilson', design: 'Princess Doll', status: 'Pending', date: '2024-01-10' },
    { id: 2, customer: 'James Smith', design: 'Fairy Doll', status: 'Completed', date: '2024-01-09' },
    { id: 3, customer: 'Sophia Chen', design: 'Mermaid Doll', status: 'Processing', date: '2024-01-09' },
  ];

  const popularDesigns = [
    { id: 1, name: 'Princess Doll', orders: 45, rating: 4.8 },
    { id: 2, name: 'Fairy Doll', orders: 38, rating: 4.7 },
    { id: 3, name: 'Mermaid Doll', orders: 32, rating: 4.9 },
  ];

  return (
    <div className="admin-dashboard">
      {/* Stats Cards */}
      <div className="stats-grid">
        <div className="stat-card">
          <div className="stat-icon orders">
            <ShoppingBag size={24} />
          </div>
          <div className="stat-content">
            <h3>Total Orders</h3>
            <p className="stat-value">{stats.totalOrders}</p>
            <span className="stat-change positive">+12% from last month</span>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon customers">
            <Users size={24} />
          </div>
          <div className="stat-content">
            <h3>Active Customers</h3>
            <p className="stat-value">{stats.activeCustomers}</p>
            <span className="stat-change positive">+5% from last month</span>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon designs">
            <Palette size={24} />
          </div>
          <div className="stat-content">
            <h3>Total Designs</h3>
            <p className="stat-value">{stats.totalDesigns}</p>
            <span className="stat-change">+3 new this month</span>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon revenue">
            <TrendingUp size={24} />
          </div>
          <div className="stat-content">
            <h3>Monthly Revenue</h3>
            <p className="stat-value">${stats.monthlyRevenue}</p>
            <span className="stat-change positive">+8% from last month</span>
          </div>
        </div>
      </div>

      {/* Main Content Grid */}
      <div className="dashboard-grid">
        {/* Recent Orders */}
        <div className="dashboard-card orders-card">
          <div className="card-header">
            <h2>Recent Orders</h2>
            <button className="view-all">View All</button>
          </div>
          <div className="orders-list">
            {recentOrders.map(order => (
              <div key={order.id} className="order-item">
                <div className="order-info">
                  <h4>{order.customer}</h4>
                  <p>{order.design}</p>
                  <span className="order-date">
                    <Clock size={14} />
                    {order.date}
                  </span>
                </div>
                <div className={`order-status ${order.status.toLowerCase()}`}>
                  {order.status === 'Completed' ? (
                    <CheckCircle size={16} />
                  ) : order.status === 'Pending' ? (
                    <AlertCircle size={16} />
                  ) : (
                    <Clock size={16} />
                  )}
                  {order.status}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Popular Designs */}
        <div className="dashboard-card designs-card">
          <div className="card-header">
            <h2>Popular Designs</h2>
            <button className="view-all">View All</button>
          </div>
          <div className="designs-list">
            {popularDesigns.map(design => (
              <div key={design.id} className="design-item">
                <div className="design-preview">
                  <Image size={48} />
                </div>
                <div className="design-info">
                  <h4>{design.name}</h4>
                  <p>{design.orders} orders</p>
                  <div className="design-rating">
                    <Star size={14} fill="#FFD700" />
                    <span>{design.rating}</span>
                  </div>
                </div>
                <button className="edit-design">Edit</button>
              </div>
            ))}
          </div>
        </div>

        {/* Quick Actions */}
        <div className="dashboard-card actions-card">
          <div className="card-header">
            <h2>Quick Actions</h2>
          </div>
          <div className="quick-actions">
            <button className="action-button">
              <Palette size={20} />
              Create New Design
            </button>
            <button className="action-button">
              <ShoppingBag size={20} />
              View Orders
            </button>
            <button className="action-button">
              <Users size={20} />
              Customer List
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;