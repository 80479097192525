import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ArrowUpDown } from 'lucide-react';
import axios from 'axios';
import './Orders.css';
import ConfirmationDialog from './ConfirmationDialog';

// OrderCheckIn Component
const OrderCheckIn = ({ order, modifiedBrandName }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [items, setItems] = useState(order.items);
  const [originalItems, setOriginalItems] = useState(order.items);
  const [showScanButton, setShowScanButton] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `/orders/check-in/${order.id}`,
        {
          items,
          modifiedBrandName
        }
      );

      if (response.data.success) {
        setIsEditing(false);
        setOriginalItems(items);
        setError(null);
        setSuccess('Check-in updated successfully!');
        
        // Clear success message after 2 seconds
        setTimeout(() => {
          setSuccess('');
        }, 2000);
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      setError(error.response?.data?.error || 'Failed to save changes');
    }
  };

  const handleConfirmSave = () => {
    setConfirmAction(() => handleSave);
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    setShowConfirmation(false);
    if (confirmAction) {
      await confirmAction();
    }
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    if (!updatedItems[index]) {
      updatedItems[index] = {};
    }
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: field === 'quantity' ? Number(value) : value
    };
    setItems(updatedItems);
  };

  const handleCancel = () => {
    setItems([...originalItems]);
    setIsEditing(false);
    setShowScanButton(false);
  };

  const handleProceed = () => {
    setShowScanButton(true);
  };

  return (
    <div className="check-in-container">
      <div className="orders-header-row">
        <h2 className="orders-section-title">Order Check-In: {order.orderNumber}</h2>
      </div>
      
      {error && <div className="orders-error">{error}</div>}
      {success && <div className="orders-success">{success}</div>}
      
      <div className="check-in-actions">
        {!showScanButton && !isEditing && (
          <>
            <button className="orders-primary-btn" onClick={handleProceed}>
              Proceed
            </button>
            <button 
              className="orders-secondary-btn" 
              onClick={() => {
                setIsEditing(true);
                setOriginalItems([...items]);
              }}
            >
              Edit
            </button>
          </>
        )}
        {isEditing && (
          <>
            <button className="orders-primary-btn" onClick={handleConfirmSave}>
              Save
            </button>
            <button className="orders-secondary-btn" onClick={handleCancel}>
              Cancel
            </button>
          </>
        )}
        {showScanButton && (
          <>
            <button className="orders-primary-btn">Scan</button>
            <button className="orders-secondary-btn" onClick={handleCancel}>
              Cancel
            </button>
          </>
        )}
      </div>
      <div className="table-container">
        <table className="orders-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Volume</th>
              <th>Weight</th>
              <th>Barcode</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={item.name || ''}
                      onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                      className="orders-input"
                    />
                  ) : (
                    item.name || 'N/A'
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="number"
                      value={item.quantity || ''}
                      onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                      className="orders-input"
                    />
                  ) : (
                    item.quantity || 'N/A'
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <div className="measure-input">
                      <input
                        type="number"
                        value={item.volume || ''}
                        onChange={(e) => handleItemChange(index, 'volume', e.target.value)}
                        className="orders-input"
                      />
                      <select
                        value={item.unitOfMeasureForVolume || 'N/A'}
                        onChange={(e) => handleItemChange(index, 'unitOfMeasureForVolume', e.target.value)}
                        className="orders-select"
                      >
                        <option value="N/A">N/A</option>
                        <option value="Liter (L)">Liter (L)</option>
                        <option value="Milliliter (mL)">Milliliter (mL)</option>
                        <option value="Fluid Ounce (fl oz)">Fluid Ounce (fl oz)</option>
                        <option value="Cubic Meter (m³)">Cubic Meter (m³)</option>
                      </select>
                    </div>
                  ) : (
                    item.volume && item.unitOfMeasureForVolume !== 'N/A'
                      ? `${item.volume} ${item.unitOfMeasureForVolume}`
                      : 'N/A'
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <div className="measure-input">
                      <input
                        type="number"
                        value={item.weight || ''}
                        onChange={(e) => handleItemChange(index, 'weight', e.target.value)}
                        className="orders-input"
                      />
                      <select
                        value={item.unitOfMeasureForWeight || 'N/A'}
                        onChange={(e) => handleItemChange(index, 'unitOfMeasureForWeight', e.target.value)}
                        className="orders-select"
                      >
                        <option value="N/A">N/A</option>
                        <option value="Kilogram (kg)">Kilogram (kg)</option>
                        <option value="Gram (g)">Gram (g)</option>
                        <option value="Ounce (oz)">Ounce (oz)</option>
                        <option value="Pound (lb)">Pound (lb)</option>
                      </select>
                    </div>
                  ) : (
                    item.weight && item.unitOfMeasureForWeight !== 'N/A'
                      ? `${item.weight} ${item.unitOfMeasureForWeight}`
                      : 'N/A'
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={item.barcode || ''}
                      onChange={(e) => handleItemChange(index, 'barcode', e.target.value)}
                      className="orders-input"
                    />
                  ) : (
                    item.barcode || 'N/A'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {showConfirmation && (
        <ConfirmationDialog
          message="Are you sure you want to save these changes?"
          onConfirm={handleConfirm}
          onCancel={() => setShowConfirmation(false)}
          confirmText="Confirm"
          cancelText="Cancel"
        />
      )}
    </div>
  );
};

// CreateOrderForm Component
const CreateOrderForm = ({ modifiedBrandName, stores, onSubmit }) => {
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState({
    barcode: '',
    category: '',
    name: '',
    unitOfMeasureForVolume: '',
    volume: '',
    unitOfMeasureForWeight: '',
    weight: '',
    quantity: ''
  });
  const [selectedStore, setSelectedStore] = useState('');
  const [supplier, setSupplier] = useState('');
  const [expectedDelivery, setExpectedDelivery] = useState('');
  const [isDeliveryNA, setIsDeliveryNA] = useState(false);
  const [notifyBeforeValue, setNotifyBeforeValue] = useState('');
  const [notifyBeforeUnit, setNotifyBeforeUnit] = useState('days');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const categories = [
    'Groceries', 'Beverages', 'Household Products', 'Personal Care Items', 
    'Snacks', 'Frozen Foods', 'Health and Wellness Products', 'Electronics', 
    'Pet Supplies', 'Office Supplies', 'Raw Materials'
  ];

  const unitsOfMeasure = {
    weight: ['Kilogram (kg)', 'Gram (g)', 'Ounce (oz)', 'Pound (lb)', 'N/A'],
    volume: ['Liter (L)', 'Milliliter (mL)', 'Fluid Ounce (fl oz)', 'Cubic Meter (m³)', 'N/A']
  };

  const handleAddItem = () => {
    if (currentItem.name && currentItem.quantity) {
      setItems([...items, { ...currentItem, id: Date.now() }]);
      setCurrentItem({
        barcode: '',
        category: '',
        name: '',
        unitOfMeasureForVolume: '',
        volume: '',
        unitOfMeasureForWeight: '',
        weight: '',
        quantity: ''
      });
    }
  };

  const handleDeliveryNAToggle = () => {
    setIsDeliveryNA(!isDeliveryNA);
    if (!isDeliveryNA) {
      setExpectedDelivery('');
      setNotifyBeforeValue('');
      setNotifyBeforeUnit('days');
    }
  };

  const handleConfirmSubmit = () => {
    setShowConfirmation(true);
  };

  const handleSubmit = async () => {
    setShowConfirmation(false);
    if (items.length === 0 || !selectedStore || !supplier) return;
  
    try {
      const orderData = {
        items,
        store: selectedStore,
        supplier,
        expectedDelivery: isDeliveryNA ? 'N/A' : expectedDelivery,
        notifyBefore: expectedDelivery && !isDeliveryNA && notifyBeforeValue ? {
          value: Number(notifyBeforeValue),
          unit: notifyBeforeUnit
        } : null,
        status: 'Pending'
      };
  
      await onSubmit(orderData);
      setSuccess('Order submitted successfully!');
      
      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess('');
        navigate('/store/orders');
      }, 2000);
    } catch (error) {
      console.error('Error submitting order:', error);
      setError('Failed to submit order. Please try again.');
    }
  };

  return (
    <div className="create-order-container">
      <div className="orders-header-row">
        <h2 className="orders-section-title">Create New Order</h2>
      </div>
      
      {error && <div className="orders-error">{error}</div>}
      {success && <div className="orders-success">{success}</div>}
      
      <div className="orders-form-container">
        <div className="orders-form-row">
          <div className="orders-form-group">
            <label>Store</label>
            <select
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
              required
              className="orders-select"
            >
              <option value="">Select Store</option>
              {stores.map(store => (
                <option key={store} value={store}>{store}</option>
              ))}
            </select>
          </div>

          <div className="orders-form-group">
            <label>Supplier</label>
            <input
              type="text"
              value={supplier}
              onChange={(e) => setSupplier(e.target.value)}
              placeholder="Supplier Name"
              required
              className="orders-input"
            />
          </div>
        </div>

        <div className="orders-form-row">
          <div className="orders-form-group">
            <label>Expected Date of Delivery</label>
            <div className="date-input-wrapper">
              <input
                type="date"
                value={expectedDelivery}
                onChange={(e) => setExpectedDelivery(e.target.value)}
                disabled={isDeliveryNA}
                min={new Date().toISOString().split('T')[0]}
                required={!isDeliveryNA}
                className="orders-input"
              />
              <div className="checkbox-container">
                <label>
                  <input
                    type="checkbox"
                    checked={isDeliveryNA}
                    onChange={handleDeliveryNAToggle}
                  />
                  <span>N/A</span>
                </label>
              </div>
            </div>
            {!isDeliveryNA && !expectedDelivery && (
              <p className="helper-text">Please select when you expect this order to arrive</p>
            )}
          </div>

          {expectedDelivery && !isDeliveryNA && (
            <div className="orders-form-group">
              <label>Notification Period</label>
              <div className="notify-inputs-wrapper">
                <input
                  type="number"
                  min="1"
                  value={notifyBeforeValue}
                  onChange={(e) => setNotifyBeforeValue(e.target.value)}
                  placeholder="Enter value"
                  className="orders-input"
                />
                <select
                  value={notifyBeforeUnit}
                  onChange={(e) => setNotifyBeforeUnit(e.target.value)}
                  className="orders-select"
                >
                  <option value="hours">Hours</option>
                  <option value="days">Days</option>
                  <option value="weeks">Weeks</option>
                </select>
              </div>
            </div>
          )}
        </div>
        
        <div className="orders-section-divider"></div>
        
        <h3 className="orders-subsection-title">Add Items</h3>
        
        <div className="orders-form-row">
          <div className="orders-form-group">
            <label>Barcode</label>
            <input
              type="text"
              value={currentItem.barcode}
              onChange={(e) => setCurrentItem({ ...currentItem, barcode: e.target.value })}
              placeholder="Barcode"
              className="orders-input"
            />
          </div>
          
          <div className="orders-form-group">
            <label>Category</label>
            <select
              value={currentItem.category}
              onChange={(e) => setCurrentItem({ ...currentItem, category: e.target.value })}
              className="orders-select"
            >
              <option value="">Select Category</option>
              {categories.map(cat => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="orders-form-row">
          <div className="orders-form-group">
            <label>Product Name</label>
            <input
              type="text"
              value={currentItem.name}
              onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
              placeholder="Product Name"
              required
              className="orders-input"
            />
          </div>
          
          <div className="orders-form-group">
            <label>Quantity</label>
            <input
              type="number"
              value={currentItem.quantity}
              onChange={(e) => setCurrentItem({ ...currentItem, quantity: e.target.value })}
              placeholder="Quantity"
              required
              className="orders-input"
            />
          </div>
        </div>

        <div className="orders-form-row">
          <div className="orders-form-group">
            <label>Volume Unit</label>
            <select
              value={currentItem.unitOfMeasureForVolume}
              onChange={(e) => setCurrentItem({ ...currentItem, unitOfMeasureForVolume: e.target.value })}
              className="orders-select"
            >
              <option value="">Select Volume Unit</option>
              {unitsOfMeasure.volume.map(unit => (
                <option key={unit} value={unit}>{unit}</option>
              ))}
            </select>
          </div>
          
          <div className="orders-form-group">
            <label>Volume</label>
            <input
              type="number"
              value={currentItem.volume}
              onChange={(e) => setCurrentItem({ ...currentItem, volume: e.target.value })}
              placeholder="Volume"
              disabled={currentItem.unitOfMeasureForVolume === "N/A"}
              className="orders-input"
            />
          </div>
        </div>

        <div className="orders-form-row">
          <div className="orders-form-group">
            <label>Weight Unit</label>
            <select
              value={currentItem.unitOfMeasureForWeight}
              onChange={(e) => setCurrentItem({ ...currentItem, unitOfMeasureForWeight: e.target.value })}
              className="orders-select"
            >
              <option value="">Select Weight Unit</option>
              {unitsOfMeasure.weight.map(unit => (
                <option key={unit} value={unit}>{unit}</option>
              ))}
            </select>
          </div>
          
          <div className="orders-form-group">
            <label>Weight</label>
            <input
              type="number"
              value={currentItem.weight}
              onChange={(e) => setCurrentItem({ ...currentItem, weight: e.target.value })}
              placeholder="Weight"
              disabled={currentItem.unitOfMeasureForWeight === "N/A"}
              className="orders-input"
            />
          </div>
        </div>

        <button 
          type="button" 
          onClick={handleAddItem} 
          className="orders-add-btn"
          disabled={!currentItem.name || !currentItem.quantity}
        >
          Add Item
        </button>
        
        {items.length > 0 && (
          <div className="items-list">
            <h3 className="orders-subsection-title">Order Items</h3>
            <div className="order-items-container">
              {items.map((item, index) => (
                <div key={item.id} className="order-item-card">
                  <div className="order-item-details">
                    <span className="order-item-name">{item.name}</span>
                    <span className="order-item-quantity">Qty: {item.quantity}</span>
                    {item.category && <span className="order-item-category">{item.category}</span>}
                  </div>
                  <button
                    onClick={() => setItems(items.filter((_, i) => i !== index))}
                    className="orders-remove-btn"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
        
        <div className="orders-form-actions">
          <button
            onClick={handleConfirmSubmit}
            className="orders-submit-btn"
            disabled={items.length === 0 || !selectedStore || !supplier || (!isDeliveryNA && !expectedDelivery)}
          >
            Submit Order
          </button>
        </div>
      </div>
      
      {showConfirmation && (
        <ConfirmationDialog
          message="Are you sure you want to submit this order?"
          onConfirm={handleSubmit}
          onCancel={() => setShowConfirmation(false)}
          confirmText="Confirm"
          cancelText="Cancel"
        />
      )}
    </div>
  );
};

// Order Details Component
const OrderDetails = ({ order }) => {
  return (
    <div className="order-details-container">
      <div className="orders-header-row">
        <h2 className="orders-section-title">Order Details: {order.orderNumber}</h2>
      </div>
      
      <div className="order-details-card">
        <div className="order-details-info">
          <div className="order-info-item">
            <span className="order-info-label">Supplier:</span>
            <span className="order-info-value">{order.supplier}</span>
          </div>
          <div className="order-info-item">
            <span className="order-info-label">Store:</span>
            <span className="order-info-value">{order.store}</span>
          </div>
          <div className="order-info-item">
            <span className="order-info-label">Status:</span>
            <span className="order-info-value">{order.status}</span>
          </div>
          <div className="order-info-item">
            <span className="order-info-label">Expected Delivery:</span>
            <span className="order-info-value">{order.expectedDelivery}</span>
          </div>
          {order.srStatus && (
            <div className="order-info-item">
              <span className="order-info-label">SR Status:</span>
              <span className={`status-badge status-${order.srStatus.toLowerCase()}`}>
                {order.srStatus}
              </span>
            </div>
          )}
        </div>
      </div>
      
      <div className="orders-section-divider"></div>
      
      <h3 className="orders-subsection-title">Order Items</h3>
      
      <div className="order-items-grid">
        {order.items.map((item, index) => (
          <div key={index} className="order-item-detail-card">
            <h4 className="order-item-title">{item.name}</h4>
            <div className="order-item-properties">
              <div className="order-item-property">
                <span className="property-label">Quantity:</span>
                <span className="property-value">{item.quantity}</span>
              </div>
              {item.category && (
                <div className="order-item-property">
                  <span className="property-label">Category:</span>
                  <span className="property-value">{item.category}</span>
                </div>
              )}
              {item.volume && item.unitOfMeasureForVolume !== 'N/A' && (
                <div className="order-item-property">
                  <span className="property-label">Volume:</span>
                  <span className="property-value">{item.volume} {item.unitOfMeasureForVolume}</span>
                </div>
              )}
              {item.weight && item.unitOfMeasureForWeight !== 'N/A' && (
                <div className="order-item-property">
                  <span className="property-label">Weight:</span>
                  <span className="property-value">{item.weight} {item.unitOfMeasureForWeight}</span>
                </div>
              )}
              {item.barcode && (
                <div className="order-item-property">
                  <span className="property-label">Barcode:</span>
                  <span className="property-value">{item.barcode}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Main Orders Component
const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [stores, setStores] = useState([]);
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [sortBy, setSortBy] = useState('Expected Date');
  const [sortOrder, setSortOrder] = useState('ascending');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { orderNumber } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  const isCreateOrder = location.pathname.includes('/new');
  const isCheckIn = location.pathname.includes('_check_in');

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    fetchData();
  }, [user, navigate]);

  const fetchData = async () => {
    try {
      // Fetch store info
      const { data } = await axios.get('/orders/store-info');

      if (data.modifiedBrandName) {
        setModifiedBrandName(data.modifiedBrandName);
        setStores(data.stores);

        // Fetch orders
        const ordersResponse = await axios.get(`/orders/orders/${data.modifiedBrandName}`);
        setOrders(ordersResponse.data);
        setFilteredOrders(ordersResponse.data);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setError(error.response?.data?.error || 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    filterAndSortOrders();
  }, [orders, sortBy, sortOrder, searchTerm]);

  const filterAndSortOrders = () => {
    let sorted = [...orders];
    
    // Filter by search term
    if (searchTerm) {
      sorted = sorted.filter(order => 
        order.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.supplier.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.store.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Sort orders
    sorted.sort((a, b) => {
      switch (sortBy) {
        case 'Expected Date':
          if (a.expectedDelivery === 'N/A' && b.expectedDelivery === 'N/A') return 0;
          if (a.expectedDelivery === 'N/A') return 1;
          if (b.expectedDelivery === 'N/A') return -1;
          return new Date(a.expectedDelivery) - new Date(b.expectedDelivery);
        
        case 'Date Added':
          return new Date(a.dateCreated) - new Date(b.dateCreated);
        
        case 'Alphabetical':
          return a.supplier.localeCompare(b.supplier);
        
        default:
          return 0;
      }
    });
    
    if (sortOrder === 'descending') {
      sorted = sorted.reverse();
    }
    
    setFilteredOrders(sorted);
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'ascending' ? 'descending' : 'ascending');
  };

  const handleCreateOrder = async (orderData) => {
    try {
      const response = await axios.post('/orders/create', {
        modifiedBrandName,
        orderData: {
          ...orderData,
          dateCreated: new Date(),
          expectedDelivery: orderData.expectedDelivery,
          notifyBefore: orderData.notifyBefore || null
        }
      });
      
      if (response.data) {
        setOrders(prevOrders => [...prevOrders, response.data]);
      }
      return response.data;
    } catch (error) {
      console.error('Error creating order:', error);
      throw error;
    }
  };

  const handleReview = (orderId) => {
    navigate(`/store/orders/${orderId}`);
  };

  const handleCheckIn = (orderId) => {
    navigate(`/store/orders/${orderId}_check_in`);
  };

  if (error) {
    return <div className="orders-error-display">{error}</div>;
  }

  if (isCreateOrder) {
    return (
      <CreateOrderForm
        modifiedBrandName={modifiedBrandName}
        stores={stores}
        onSubmit={handleCreateOrder}
      />
    );
  }

  if (isCheckIn && orderNumber) {
    const orderIdBase = orderNumber.split('_check_in')[0];
    const order = orders.find(o => o.id === orderIdBase);
    if (!order) return <div className="orders-error-display">Order not found</div>;
    return <OrderCheckIn order={order} modifiedBrandName={modifiedBrandName} />;
  }

  if (orderNumber) {
    const order = orders.find(o => o.id === orderNumber);
    if (!order) return <div className="orders-error-display">Order not found</div>;
    return <OrderDetails order={order} />;
  }

  return (
    <div className="orders-container">
      <div className="orders-header-row">
        <h2 className="orders-section-title">Orders Management</h2>
        
        <button
          className="orders-create-btn"
          onClick={() => navigate('/store/orders/new')}
        >
          Create New Order
        </button>
      </div>
      
      <div className="orders-controls">
        <div className="orders-search-filter">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search orders..."
            className="orders-search-input"
          />
        </div>
        
        <div className="orders-sort-controls">
          <select 
            value={sortBy} 
            onChange={(e) => setSortBy(e.target.value)}
            className="orders-select"
          >
            <option value="Expected Date">Expected Date</option>
            <option value="Date Added">Date Added</option>
            <option value="Alphabetical">Alphabetical</option>
          </select>
          
          <button onClick={toggleSortOrder} className="orders-sort-btn">
            <ArrowUpDown size={16} />
            <span>{sortOrder === 'ascending' ? 'Ascending' : 'Descending'}</span>
          </button>
        </div>
      </div>
      
      {filteredOrders.length === 0 ? (
        <div className="orders-empty-state">
          {searchTerm ? 'No orders found matching your search.' : 'No orders available. Create a new order to get started.'}
        </div>
      ) : (
        <div className="orders-list">
          {filteredOrders.map((order) => (
            <div key={order.id} className="order-card">
              <div className="order-card-header">
                <span className="order-number">Order #{order.orderNumber}</span>
                {order.srStatus && (
                  <span className={`status-badge status-${order.srStatus.toLowerCase()}`}>
                    {order.srStatus}
                  </span>
                )}
              </div>
              
              <div className="order-card-body">
                <div className="order-info">
                  <div className="order-info-item">
                    <span className="order-info-label">Supplier:</span>
                    <span className="order-info-value">{order.supplier}</span>
                  </div>
                  <div className="order-info-item">
                    <span className="order-info-label">Store:</span>
                    <span className="order-info-value">{order.store}</span>
                  </div>
                  <div className="order-info-item">
                    <span className="order-info-label">Expected:</span>
                    <span className="order-info-value">{order.expectedDelivery}</span>
                  </div>
                </div>
                
                <div className="order-summary">
                  <span>{order.items.length} {order.items.length === 1 ? 'item' : 'items'}</span>
                </div>
              </div>
              
              <div className="order-card-actions">
                <button
                  className="orders-secondary-btn"
                  onClick={() => handleReview(order.id)}
                >
                  View Details
                </button>
                <button
                  className="orders-primary-btn"
                  onClick={() => handleCheckIn(order.id)}
                >
                  Check In
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Orders;