import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./EmployeeDetailsForm.css";

const PinConfirmation = ({ isOpen, onClose, onConfirm, action }) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const result = await onConfirm(pin, action);
    if (!result.success) {
      setError(result.message);
    } else {
      setPin('');
      onClose();
    }
  };

  const handleCancel = () => {
    setPin('');
    setError('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="pin-confirmation-overlay">
      <div className="pin-confirmation-container">
        <h3>Confirm Action</h3>
        <p>Please enter your PIN to {action === 'save' ? 'save changes' : 'delete employee'}:</p>
        <form onSubmit={handleSubmit}>
          <input
            className="form-group-input-a"
            type="password"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            placeholder="Enter PIN"
            required
          />
          {error && <p className="error-message">{error}</p>}
          <div className="form-actions">
            <button type="submit" className="button-confirm">Confirm</button>
            <button type="button" className="button-cancel" onClick={handleCancel}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const EmployeeDetailsForm = ({ employee, modifiedBrandName, onClose, onUpdate, onDelete }) => {
  const [employeeData, setEmployeeData] = useState(employee);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showPinConfirmation, setShowPinConfirmation] = useState(false);
  const [pinAction, setPinAction] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    setEmployeeData(employee);
  }, [employee]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedEmployee = { ...employeeData };

    if (type === 'checkbox') {
      updatedEmployee[name] = checked;
    } else {
      updatedEmployee[name] = value;
    }

    // Synchronize fields based on employment type and payment type
    if (name === 'employmentType') {
      updatedEmployee.endDateNA = value === 'fullTime' || value === 'partTime';
      updatedEmployee.endDate = updatedEmployee.endDateNA ? 'N/A' : '';
    }

    if (name === 'paymentType') {
      // Reset all payment-related fields
      const paymentFields = ['salary', 'hourlyRate', 'commissionRate', 'pieceRate', 
                           'stipendAmount', 'retainerAmount', 'projectFee', 'performanceMetrics'];
      paymentFields.forEach(field => updatedEmployee[field] = '');
      
      // Set payroll schedule based on payment type
      const scheduleMap = {
        hourly: 'weekly',
        salary: 'monthly',
        commission: 'monthly',
        pieceRate: 'weekly',
        projectBased: 'onCompletion'
      };
      updatedEmployee.payrollSchedule = scheduleMap[value] || '';
    }

    setEmployeeData(updatedEmployee);
  };

  const handlePinConfirm = async (enteredPin, action) => {
    try {
      const verifyResponse = await axios.post(
        `/finances/pin/verify`,
        { 
          modifiedBrandName,
          pin: enteredPin 
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` } }
      );
  
      if (verifyResponse.data.isValid) {
        if (action === 'save') {
          await saveChanges();
          return { success: true };
        } else if (action === 'delete') {
          await deleteEmployee();
          return { success: true };
        }
      }
      return { success: false, message: 'Incorrect PIN' };
    } catch (error) {
      console.error('Error:', error);
      return { 
        success: false, 
        message: error.response?.data?.error || 'Failed to verify PIN'
      };
    }
  };
  
  const saveChanges = async () => {
    try {
      const response = await axios.put(
        `/accounting/employees/${modifiedBrandName}/${employee.id}`,
        employeeData,
        { 
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.data.success) {
        onUpdate(employeeData);
        setIsEditing(false);
      } else {
        throw new Error(response.data.message || 'Failed to update employee data');
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      setError(error.response?.data?.error || 'Failed to update employee data');
      throw error;
    }
  };
  
  const deleteEmployee = async () => {
    try {
      if (!employee.id || !modifiedBrandName) {
        throw new Error('Missing employee information');
      }
  
      const response = await axios.delete(
        `/accounting/employees/${modifiedBrandName}/${employee.id}`,
        { 
          headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` }
        }
      );
  
      if (response.data.success) {
        onDelete(employee.id);
      } else {
        throw new Error(response.data.message || 'Failed to delete employee');
      }
    } catch (error) {
      console.error('Error deleting employee:', error);
      setError(error.response?.data?.error || 'Failed to delete employee');
      throw error;
    }
  };

  const handleSaveClick = () => {
    if (!modifiedBrandName || !employee.id) {
      setError('Required information is missing');
      return;
    }
    setPinAction('save');
    setShowPinConfirmation(true);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    if (!modifiedBrandName || !employee.id) {
      setError('Missing required information');
      return;
    }
    setPinAction('delete');
    setShowPinConfirmation(true);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    if (isEditing) {
      setEmployeeData(employee);
      setIsEditing(false);
    } else {
      onClose();
    }
  };

  if (loading) return <div className="loading">Loading...</div>;

  return (
    <div className="employee-details-form">
      <h2>{isEditing ? "Edit Employee" : "Employee Profile"}</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={(e) => e.preventDefault()}>
        {/* Basic Information */}
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            className="form-group-input-a"
            id="name"
            name="name"
            value={employeeData.name}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="surname">Surname:</label>
          <input
            className="form-group-input-a"
            id="surname"
            name="surname"
            value={employeeData.surname}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="maritalStatus">Marital Status:</label>
          <select
            className="form-group-input-a"
            id="maritalStatus"
            name="maritalStatus"
            value={employeeData.maritalStatus}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          >
            <option value="">Select Marital Status</option>
            <option value="single">Single</option>
            <option value="marriedJoint">Married Filing Jointly</option>
            <option value="marriedSeparate">Married Filing Separately</option>
            <option value="headOfHousehold">Head of Household</option>
            <option value="widower">Qualifying Widow(er) with Dependent Child</option>
            <option value="n/a">N/A</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="position">Position:</label>
          <input
            className="form-group-input-a"
            id="position"
            name="position"
            value={employeeData.position}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="department">Department:</label>
          <input
            className="form-group-input-a"
            id="department"
            name="department"
            value={employeeData.department}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="employmentType">Employment Type:</label>
          <select
            className="form-group-input-a"
            id="employmentType"
            name="employmentType"
            value={employeeData.employmentType}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          >
            <option value="">Select Employment Type</option>
            <option value="fullTime">Full-time</option>
            <option value="partTime">Part-time</option>
            <option value="contract">Contract</option>
            <option value="freelance">Freelance</option>
            <option value="temporary">Temporary</option>
            <option value="internship">Internship</option>
            <option value="seasonal">Seasonal</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="paymentType">Payment Type:</label>
          <select
            className="form-group-input-a"
            id="paymentType"
            name="paymentType"
            value={employeeData.paymentType}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          >
            <option value="">Select Payment Type</option>
            <option value="hourly">Hourly</option>
            <option value="salary">Salary</option>
            <option value="commission">Commission</option>
            <option value="pieceRate">Piece Rate</option>
            <option value="projectBased">Project-based</option>
          </select>
        </div>

        {/* Payment Type Specific Fields */}
        {employeeData.paymentType === 'hourly' && (
          <>
            <div className="form-group">
              <label htmlFor="hourlyRate">Hourly Rate:</label>
              <input
                className="form-group-input-a"
                id="projectFee"
                name="projectFee"
                type="number"
                value={employeeData.projectFee}
                onChange={handleInputChange}
                disabled={!isEditing}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="projectEndDate">Project End Date:</label>
              <input
                className="form-group-input-a"
                id="projectEndDate"
                name="projectEndDate"
                type="date"
                value={employeeData.projectEndDate}
                onChange={handleInputChange}
                disabled={!isEditing}
                required
              />
            </div>
          </>
        )}

        <div className="form-group">
          <label htmlFor="payrollSchedule">Payroll Schedule:</label>
          <select
            className="form-group-input-a"
            id="payrollSchedule"
            name="payrollSchedule"
            value={employeeData.payrollSchedule}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          >
            <option value="">Select Payroll Schedule</option>
            <option value="weekly">Weekly</option>
            <option value="biweekly">Bi-weekly</option>
            <option value="semimonthly">Semi-monthly</option>
            <option value="monthly">Monthly</option>
            {employeeData.paymentType === 'projectBased' && (
              <option value="onCompletion">On Project Completion</option>
            )}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="startDate">Start Date:</label>
          <input
            className="form-group-input-a"
            id="startDate"
            name="startDate"
            type="date"
            value={employeeData.startDate}
            onChange={handleInputChange}
            disabled={!isEditing}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="endDate">End Date:</label>
          <input
            className="form-group-input-a"
            id="endDate"
            name="endDate"
            type="date"
            value={employeeData.endDate}
            onChange={handleInputChange}
            disabled={!isEditing || employeeData.endDateNA}
            required={!employeeData.endDateNA}
          />
          <label>
            <input
              type="checkbox"
              name="endDateNA"
              checked={employeeData.endDateNA}
              onChange={handleInputChange}
              disabled={!isEditing}
            /> N/A
          </label>
        </div>

        <div className="form-actions">
          {isEditing ? (
            <>
              <button type="button" className="button-confirm" onClick={handleSaveClick}>Save Changes</button>
              <button type="button" className="button-cancel" onClick={handleCancelClick}>Cancel</button>
            </>
          ) : (
            <>
              <button type="button" className="button-confirm" onClick={handleEditClick}>Edit</button>
              <button type="button" className="button-cancel" onClick={handleDeleteClick}>Delete</button>
              <button type="button" className="button-cancel" onClick={onClose}>Close</button>
            </>
          )}
        </div>
      </form>

      <PinConfirmation
        isOpen={showPinConfirmation}
        onClose={() => setShowPinConfirmation(false)}
        onConfirm={handlePinConfirm}
        action={pinAction}
      />
    </div>
  );
};

export default EmployeeDetailsForm;
