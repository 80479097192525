import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './AdminNavBar.css';
import { 
    Home, 
    Bell, 
    Settings, 
    LogOut, 
    Image,
    Plus,
    Users,
    ShoppingBag,
    Menu,
    X,
    Save
  } from 'lucide-react';

function AdminNavBar() {
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={`admin-layout ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      {/* Sidebar */}
      <div className="sidebar">
        <div className="sidebar-header">
          <h2 className="logo">DollDesign.ai</h2>
          <button className="toggle-btn" onClick={toggleSidebar}>
            {isSidebarOpen ? <X size={20} /> : <Menu size={20} />}
          </button>
        </div>

        <nav className="sidebar-nav">
          <Link to="/admin/dashboard" className={`nav-item ${isActive('/admin/dashboard') ? 'active' : ''}`}>
            <Home size={20} />
            <span>Dashboard</span>
          </Link>
          <Link to="/admin/saved-designs" className={`nav-item ${isActive('/admin/saved-designs') ? 'active' : ''}`}>
            <Save size={20} />
            <span>Saved Designs</span>
          </Link>

          <Link to="/admin/create" className={`nav-item ${isActive('/admin/create') ? 'active' : ''}`}>
            <Plus size={20} />
            <span>Create Design</span>
          </Link>
          <Link to="/admin/orders" className={`nav-item ${isActive('/admin/orders') ? 'active' : ''}`}>
            <ShoppingBag size={20} />
            <span>Orders</span>
          </Link>
          <Link to="/admin/customers" className={`nav-item ${isActive('/admin/customers') ? 'active' : ''}`}>
            <Users size={20} />
            <span>Customers</span>
          </Link>
        </nav>

        <div className="sidebar-footer">
          <Link to="/admin/settings" className="nav-item">
            <Settings size={20} />
            <span>Settings</span>
          </Link>
          <button className="nav-item logout-btn" onClick={handleLogout}>
            <LogOut size={20} />
            <span>Logout</span>
          </button>
        </div>
      </div>

      {/* Top Header */}
      <div className="top-header">
        <div className="header-content">
          <div className="header-left">
            <button className="mobile-menu-btn" onClick={toggleSidebar}>
              <Menu size={24} />
            </button>
            <h1 className="page-title">Admin Dashboard</h1>
          </div>
          <div className="header-right">
            <button className="notification-btn">
              <Bell size={20} />
              <span className="notification-badge">3</span>
            </button>
            <div className="admin-profile">
              <img 
                src="/api/placeholder/32/32" 
                alt="Admin" 
                className="admin-avatar" 
              />
              <span className="admin-name">{user?.email}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminNavBar;