// WeekSelection.js
import React, { useState } from 'react';
import './WeekSelection.css';

// Helper function to get start and end of current week
const getCurrentWeekRange = () => {
  const now = new Date();
  const dayOfWeek = now.getDay(); // 0 = Sunday, 1 = Monday, etc.
  
  // Calculate the date of Monday (start of week)
  const monday = new Date(now);
  monday.setDate(now.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
  monday.setHours(0, 0, 0, 0);
  
  // Calculate the date of Sunday (end of week)
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  sunday.setHours(23, 59, 59, 999);
  
  return {
    start: monday,
    end: sunday
  };
};

// Helper function to format a date as YYYY-MM-DD for input elements
const formatDateForInput = (date) => {
  return date.toISOString().split('T')[0];
};

// Helper function to get start and end of a week containing the given date
const getWeekRangeForDate = (date) => {
  const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, etc.
  
  // Calculate the date of Monday (start of week)
  const monday = new Date(date);
  monday.setDate(date.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
  monday.setHours(0, 0, 0, 0);
  
  // Calculate the date of Sunday (end of week)
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  sunday.setHours(23, 59, 59, 999);
  
  return {
    start: monday,
    end: sunday
  };
};

const WeekSelection = ({ translate, onWeekChange }) => {
  const [weekType, setWeekType] = useState('average');
  const [customDate, setCustomDate] = useState(formatDateForInput(new Date()));
  
  // When week type changes, notify parent component
  const handleWeekTypeChange = (e) => {
    const newWeekType = e.target.value;
    setWeekType(newWeekType);
    
    let dateRange = null;
    
    if (newWeekType === 'current') {
      dateRange = getCurrentWeekRange();
    } else if (newWeekType === 'custom') {
      const selectedDate = new Date(customDate);
      dateRange = getWeekRangeForDate(selectedDate);
    }
    
    onWeekChange(newWeekType, dateRange);
  };
  
  // When custom date changes, calculate week range and notify parent
  const handleCustomDateChange = (e) => {
    const newDate = e.target.value;
    setCustomDate(newDate);
    
    if (weekType === 'custom') {
      const selectedDate = new Date(newDate);
      const dateRange = getWeekRangeForDate(selectedDate);
      onWeekChange('custom', dateRange);
    }
  };
  
  return (
    <div className="week-selection">
      <div className="week-type-selector">
        <select 
          value={weekType} 
          onChange={handleWeekTypeChange}
          className="week-type-select"
        >
          <option value="average">{translate('average_all_time')}</option>
          <option value="current">{translate('current_week')}</option>
          <option value="custom">{translate('custom_week')}</option>
        </select>
      </div>
      
      {weekType === 'custom' && (
        <div className="custom-week-selector">
          <input
            type="date"
            value={customDate}
            onChange={handleCustomDateChange}
            className="custom-week-input"
          />
          <div className="week-range-display">
            {(() => {
              const selectedDate = new Date(customDate);
              const range = getWeekRangeForDate(selectedDate);
              return `${range.start.toLocaleDateString()} - ${range.end.toLocaleDateString()}`;
            })()}
          </div>
        </div>
      )}
    </div>
  );
};

export default WeekSelection;