import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import ConfirmationDialog from './ConfirmationDialog';
import { translations } from './Data/data'; // Import translations
import './ItemAdd.css';

// Convert arrays to translation keys
const categoryKeys = [
  'groceries', 'beverages', 'household_products', 'personal_care_items', 
  'snacks', 'frozen_foods', 'health_and_wellness_products', 'electronics', 
  'pet_supplies', 'office_supplies', 'raw_materials'
];

const weightUnitKeys = [
  'kilogram', 'gram', 'ounce', 'pound', 'na'
];

const volumeUnitKeys = [
  'liter', 'milliliter', 'fluid_ounce', 'cubic_meter', 'na'
];

const expirationPeriodUnitKeys = [
  'days', 'weeks', 'months', 'years', 'na'
];

// Helper function to create a mapping between translated values and keys
const createKeysMapping = (keys, language) => {
  const toKeyMap = {};
  const toValueMap = {};
  
  keys.forEach(key => {
    const translatedValue = translations[language]?.[key] || translations['en'][key];
    toKeyMap[translatedValue] = key;
    toValueMap[key] = translatedValue;
  });
  
  return {
    toKeyMap,   // Maps from translated value to key
    toValueMap  // Maps from key to translated value
  };
};

// Function to get a key from a value in any language
const getKeyFromValue = (value, keys) => {
  // Get mappings for all supported languages
  const allMappings = Object.keys(translations).map(lang => 
    createKeysMapping(keys, lang).toKeyMap
  );
  
  // Try to find the key in any language
  for (const mapping of allMappings) {
    if (mapping[value]) {
      return mapping[value];
    }
  }
  
  // Return original value as fallback
  return value;
};

const ItemAdd = () => {
  const [language, setLanguage] = useState('en');
  
  // Create mappings for different selectable values
  const [categoryMappings, setCategoryMappings] = useState({
    toKeyMap: {},
    toValueMap: {}
  });
  
  const [weightUnitMappings, setWeightUnitMappings] = useState({
    toKeyMap: {},
    toValueMap: {}
  });
  
  const [volumeUnitMappings, setVolumeUnitMappings] = useState({
    toKeyMap: {},
    toValueMap: {}
  });
  
  const [expirationUnitMappings, setExpirationUnitMappings] = useState({
    toKeyMap: {},
    toValueMap: {}
  });
  
  // Translation helper function
  const translate = (key) => {
    return translations[language]?.[key] || translations['en'][key] || key;
  };
  
  // Replace formatting strings with a helper function 
  const formatMessage = (message, ...args) => {
    return message.replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] !== 'undefined' ? args[number] : match;
    });
  };

  // Update mappings when language changes
  useEffect(() => {
    setCategoryMappings(createKeysMapping(categoryKeys, language));
    setWeightUnitMappings(createKeysMapping(weightUnitKeys, language));
    setVolumeUnitMappings(createKeysMapping(volumeUnitKeys, language));
    setExpirationUnitMappings(createKeysMapping(expirationPeriodUnitKeys, language));
  }, [language]);

  // Refs for input focus management
  const barcodeInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const brandInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const quantityInputRef = useRef(null);
  const priceInputRef = useRef(null);
  const costPriceInputRef = useRef(null);
  const thresholdInputRef = useRef(null);
  const takeOutInputRef = useRef(null);

  // State variables
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState('All');
  const [newItem, setNewItem] = useState({
    name: '',
    brand: '',
    category: '',
    categoryKey: '',
    unitOfMeasureForWeight: 'na',
    weightUnitKey: 'na',
    unitOfMeasureForVolume: 'na',
    volumeUnitKey: 'na',
    volume: '',
    weight: '',
    quantity: '',
    expiryDateFrom: '',
    expiryDateTo: '',
    noExpiryDate: false,
    sku: '',
    barcode: '',
    price: '',
    costPrice: '',
    description: '',
    lowStockThreshold: '5', // Default value for convenience
    expirationPeriod: '',
    expirationUnit: 'days',
    expirationUnitKey: 'days'
  });
  
  // Add take out state
  const [showTakeOutInput, setShowTakeOutInput] = useState(false);
  const [takeOutAmount, setTakeOutAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState(0);
  
  // Barcode input active state
  const [barcodeInputActive, setBarcodeInputActive] = useState(false);
  const [shouldValidateFields, setShouldValidateFields] = useState(false);
  
  // Template integration
  const [templateItems, setTemplateItems] = useState([]);
  const [templateLoaded, setTemplateLoaded] = useState(false);
  const [templateFound, setTemplateFound] = useState(false);
  
  // Recently used values for quick selection
  const [recentBrands, setRecentBrands] = useState([]);
  const [recentCategories, setRecentCategories] = useState([]);
  const [quickAddMode, setQuickAddMode] = useState(false);
  const [savedTemplates, setSavedTemplates] = useState([]);
  const [showTemplates, setShowTemplates] = useState(false);
  
  const [editingId, setEditingId] = useState(null);
  const [brandName, setBrandName] = useState('');
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [modifiedBrandName, setModifiedBrandName] = useState('');

  // Barcode scanning state
  const [isScanning, setIsScanning] = useState(false);
  const [scanBuffer, setScanBuffer] = useState('');
  const [scanTimeout, setScanTimeout] = useState(null);

  // Filter and sort states
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('ascending');
  const [filterCategory, setFilterCategory] = useState('All');
  const [filteredItems, setFilteredItems] = useState([]);
  
  // For numpad
  const [activeInput, setActiveInput] = useState(null);
  
  // For duplicate function
  const [lastAddedItem, setLastAddedItem] = useState(null);
  const [expiryToggleActive, setExpiryToggleActive] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  // Initial data fetching
  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    fetchStoreInfo();
    loadSavedData();
    
    // Check if we have an item passed via navigation state (from take_out button)
    if (location.state && location.state.selectedItem && location.state.editMode) {
      const item = location.state.selectedItem;
      const store = location.state.selectedStore;
      
      // Get keys for translatable fields
      const categoryKey = getKeyFromValue(item.category, categoryKeys);
      const weightUnitKey = getKeyFromValue(item.unitOfMeasureForWeight, weightUnitKeys);
      const volumeUnitKey = getKeyFromValue(item.unitOfMeasureForVolume, volumeUnitKeys);
      const expirationUnitKey = getKeyFromValue(item.expirationUnit || 'days', expirationPeriodUnitKeys);
      
      // Set the selected store
      setSelectedStore(store);
      
      // Populate the form with the item data
      setNewItem({
        name: item.name || '',
        brand: item.brand || '',
        category: item.category || '',
        categoryKey: categoryKey || '',
        unitOfMeasureForWeight: item.unitOfMeasureForWeight || translate('na'),
        weightUnitKey: weightUnitKey || 'na',
        unitOfMeasureForVolume: item.unitOfMeasureForVolume || translate('na'),
        volumeUnitKey: volumeUnitKey || 'na',
        volume: item.volume || '',
        weight: item.weight || '',
        quantity: item.quantity || '',
        expiryDateFrom: item.expiryDateFrom ? new Date(item.expiryDateFrom).toISOString().split('T')[0] : '',
        expiryDateTo: item.expiryDateTo ? new Date(item.expiryDateTo).toISOString().split('T')[0] : '',
        noExpiryDate: item.noExpiryDate || false,
        sku: item.sku || '',
        barcode: item.barcode || '',
        price: item.price || '',
        costPrice: item.costPrice || '',
        description: item.description || '',
        lowStockThreshold: item.lowStockThreshold || '5',
        expirationPeriod: item.expirationPeriod || '',
        expirationUnit: item.expirationUnit || '',
        expirationUnitKey: expirationUnitKey || 'days'
      });
      
      // Set maximum quantity for take out
      setMaxAmount(Number(item.quantity) || 0);
      
      // Set editing mode
      setEditingId(item.id);
      
      // Focus on quantity field since it's usually what changes
      if (quantityInputRef.current) {
        setTimeout(() => {
          quantityInputRef.current.focus();
        }, 300);
      }
    }
  }, [user, navigate, location.state]); // Add location.state as a dependency

  useEffect(() => {
    if (brandName && selectedStore) {
      fetchItems();
      fetchTemplateItems();
    }
  }, [brandName, selectedStore]);

  // Filter and sort items
  useEffect(() => {
    filterAndSortItems();
  }, [items, filter, searchTerm, filterCategory, sortBy, sortOrder]);

  // Auto-focus on barcode input when component mounts and after adding item
  useEffect(() => {
    if (barcodeInputRef.current && !editingId) {
      barcodeInputRef.current.focus();
    }
  }, [editingId, success]);

  // Check template when barcode input loses focus
  useEffect(() => {
    if (!barcodeInputActive && newItem.barcode && templateLoaded) {
      checkTemplateItemByBarcode(newItem.barcode);
      // Activate field validation when barcode input loses focus
      setShouldValidateFields(true);
    }
  }, [barcodeInputActive, newItem.barcode, templateLoaded]);

  // Calculate expiry date when the "from" date changes
  useEffect(() => {
    if (newItem.expiryDateFrom && !newItem.noExpiryDate && templateFound && 
        newItem.expirationPeriod && newItem.expirationUnitKey) {
      calculateExpiryDate();
    }
  }, [newItem.expiryDateFrom, newItem.expirationPeriod, newItem.expirationUnitKey]);

  // Load saved data from local storage
  const loadSavedData = () => {
    try {
      const storedBrands = localStorage.getItem('recentBrands');
      if (storedBrands) {
        setRecentBrands(JSON.parse(storedBrands));
      }

      const storedCategories = localStorage.getItem('recentCategories');
      if (storedCategories) {
        setRecentCategories(JSON.parse(storedCategories));
      }

      const storedTemplates = localStorage.getItem('itemTemplates');
      if (storedTemplates) {
        setSavedTemplates(JSON.parse(storedTemplates));
      }
    } catch (error) {
      console.error('Error loading saved data:', error);
    }
  };

  // Calculate the "to" date based on the "from" date and expiration period
  const calculateExpiryDate = () => {
    try {
      if (!newItem.expiryDateFrom || !newItem.expirationPeriod || !newItem.expirationUnitKey) {
        return;
      }
      
      const fromDate = new Date(newItem.expiryDateFrom);
      let toDate = new Date(fromDate);
      
      const period = parseInt(newItem.expirationPeriod);
      const unitKey = newItem.expirationUnitKey;
      
      switch (unitKey) {
        case 'days':
          toDate.setDate(fromDate.getDate() + period);
          break;
        case 'weeks':
          toDate.setDate(fromDate.getDate() + (period * 7));
          break;
        case 'months':
          toDate.setMonth(fromDate.getMonth() + period);
          break;
        case 'years':
          toDate.setFullYear(fromDate.getFullYear() + period);
          break;
        default:
          return;
      }
      
      // Format the date as YYYY-MM-DD for the input field
      const formattedDate = toDate.toISOString().split('T')[0];
      setNewItem(prev => ({ ...prev, expiryDateTo: formattedDate }));
    } catch (error) {
      console.error('Error calculating expiry date:', error);
    }
  };

  // Save data to local storage
  const saveToLocalStorage = (key, data) => {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.error(`Error saving ${key} to local storage:`, error);
    }
  };

  // Handle barcode scanning
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Only process if we're not in an input field (except barcode field)
      const activeElement = document.activeElement;
      const isInputField = activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || activeElement.tagName === 'SELECT';
      
      if (isInputField && activeElement !== barcodeInputRef.current) {
        return;
      }
  
      // If it's a barcode scanner, it usually sends data very quickly
      if (!isScanning) {
        setIsScanning(true);
        setScanBuffer('');
      }
  
      // Clear existing timeout
      if (scanTimeout) {
        clearTimeout(scanTimeout);
      }
  
      // Check if e.key exists before trying to access its length
      if (e.key && e.key.length === 1) {
        setScanBuffer(prev => prev + e.key);
      } else if (e.key === 'Enter') {
        // Scanner typically ends with Enter key
        if (scanBuffer) {
          setNewItem(prev => ({ ...prev, barcode: scanBuffer }));
          checkTemplateItemByBarcode(scanBuffer);
        }
        setIsScanning(false);
        setScanBuffer('');
      }
  
      // Set timeout to reset scanning state if no more keys are pressed
      const timeout = setTimeout(() => {
        if (isScanning) {
          setIsScanning(false);
          // If buffer has content but no Enter was pressed, still try to use it
          if (scanBuffer && scanBuffer.length > 5) {
            setNewItem(prev => ({ ...prev, barcode: scanBuffer }));
            checkTemplateItemByBarcode(scanBuffer);
          }
          setScanBuffer('');
        }
      }, 100); // Short timeout for scanner speed
  
      setScanTimeout(timeout);
    };
  
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (scanTimeout) {
        clearTimeout(scanTimeout);
      }
    };
  }, [isScanning, scanBuffer, scanTimeout]);

  // Fetch template items
  const fetchTemplateItems = async () => {
    try {
      const response = await axios.get('/items/template-items', {
        params: { 
          modifiedBrandName,
          selectedStore 
        }
      });
      
      // Process the items to add language-independent keys
      const processedItems = response.data.map(item => {
        // Add keys for each translatable field
        const categoryKey = getKeyFromValue(item.category, categoryKeys);
        const weightUnitKey = getKeyFromValue(item.unitOfMeasureForWeight, weightUnitKeys);
        const volumeUnitKey = getKeyFromValue(item.unitOfMeasureForVolume, volumeUnitKeys);
        const expirationUnitKey = getKeyFromValue(item.expirationUnit, expirationPeriodUnitKeys);
        
        return {
          ...item,
          categoryKey,
          weightUnitKey,
          volumeUnitKey,
          expirationUnitKey
        };
      });
      
      setTemplateItems(processedItems);
      setTemplateLoaded(true);
    } catch (error) {
      console.error('Error fetching template items:', error);
      // Don't show error to user, just log it
    }
  };

  // Check if a template item exists for the given barcode
  const checkTemplateItemByBarcode = async (barcode) => {
    if (!barcode || !modifiedBrandName || !selectedStore || !templateLoaded) return;
    
    // First check locally in our loaded template items
    const localTemplate = templateItems.find(item => item.barcode === barcode);
    
    if (localTemplate) {
      applyTemplateToForm(localTemplate);
      return;
    }
    
    // If not found locally, check on the server
    try {
      const response = await axios.get('/items/template-item-by-barcode', {
        params: {
          modifiedBrandName,
          selectedStore,
          barcode
        },
        // Add validateStatus to prevent axios from treating 404 as an error
        validateStatus: function (status) {
          return status >= 200 && status < 500; // Only treat 500+ as errors
        }
      });
      
      if (response.data && response.data.success && response.data.item) {
        // Find keys for all translatable fields
        const item = response.data.item;
        const categoryKey = getKeyFromValue(item.category, categoryKeys);
        const weightUnitKey = getKeyFromValue(item.unitOfMeasureForWeight, weightUnitKeys);
        const volumeUnitKey = getKeyFromValue(item.unitOfMeasureForVolume, volumeUnitKeys);
        const expirationUnitKey = getKeyFromValue(item.expirationUnit, expirationPeriodUnitKeys);
        
        const processedItem = {
          ...item,
          categoryKey,
          weightUnitKey,
          volumeUnitKey,
          expirationUnitKey
        };
        
        applyTemplateToForm(processedItem);
      }
      // If not found, simply don't do anything - let the user continue with manual entry
    } catch (error) {
      // Completely silent handling - no console logs at all
      // This catch block should only execute for network errors or 500+ status codes
    }
  };
  
  // Apply template data to the form
  const applyTemplateToForm = (template) => {
    setNewItem(prev => ({
      ...prev,
      name: template.name || '',
      brand: template.brand || '',
      category: template.category || '',
      categoryKey: template.categoryKey || '',
      unitOfMeasureForWeight: template.unitOfMeasureForWeight || translate('na'),
      weightUnitKey: template.weightUnitKey || 'na',
      unitOfMeasureForVolume: template.unitOfMeasureForVolume || translate('na'),
      volumeUnitKey: template.volumeUnitKey || 'na',
      weight: template.weight || '',
      volume: template.volume || '',
      sku: template.sku || '',
      costPrice: template.costPrice || '',
      noExpiryDate: template.noExpiryDate || false,
      expirationPeriod: template.expirationPeriod || '',
      expirationUnit: template.expirationUnit || '',
      expirationUnitKey: template.expirationUnitKey || 'days'
    }));
    
    setTemplateFound(true);
    setSuccess(translate('template_found'));
    
    // Focus on quantity field since it's usually what changes
    if (quantityInputRef.current) {
      quantityInputRef.current.focus();
    }
  };

  // Fetch item by barcode from inventory
  const fetchItemByBarcode = async (barcode) => {
    if (!barcode || !modifiedBrandName || !selectedStore) return;
  
    try {
      const response = await axios.get('/inventory/item-by-barcode', {
        params: { modifiedBrandName, selectedStore, barcode },
        // Add validateStatus to prevent axios from treating 404 as an error
        validateStatus: function (status) {
          return status >= 200 && status < 500; // Only treat 500+ as errors
        }
      });
  
      if (response.data && response.data.success) {
        if (response.data.isManualEntry) {
          // It's a new item, focus on the name field
          if (nameInputRef.current) {
            nameInputRef.current.focus();
          }
        } else {
          // It's an existing item, fill the form
          const item = response.data.item;
          
          // Get keys for translatable fields
          const categoryKey = getKeyFromValue(item.category, categoryKeys);
          const weightUnitKey = getKeyFromValue(item.unitOfMeasureForWeight, weightUnitKeys);
          const volumeUnitKey = getKeyFromValue(item.unitOfMeasureForVolume, volumeUnitKeys);
          const expirationUnitKey = getKeyFromValue(item.expirationUnit, expirationPeriodUnitKeys);
          
          setNewItem({
            name: item.name || '',
            brand: item.brand || '',
            category: item.category || '',
            categoryKey: categoryKey || '',
            unitOfMeasureForWeight: item.unitOfMeasureForWeight || translate('na'),
            weightUnitKey: weightUnitKey || 'na',
            unitOfMeasureForVolume: item.unitOfMeasureForVolume || translate('na'),
            volumeUnitKey: volumeUnitKey || 'na',
            volume: item.volume || '',
            weight: item.weight || '',
            quantity: item.quantity || '',
            expiryDateFrom: '',
            expiryDateTo: '',
            noExpiryDate: item.noExpiryDate || false,
            sku: item.sku || '',
            barcode: item.barcode || barcode,
            price: item.price || '',
            costPrice: item.costPrice || '',
            description: item.description || '',
            lowStockThreshold: item.lowStockThreshold || '5',
            expirationPeriod: item.expirationPeriod || '',
            expirationUnit: item.expirationUnit || '',
            expirationUnitKey: expirationUnitKey || 'days'
          });
          
          // Set maximum quantity for take out
          setMaxAmount(Number(item.quantity) || 0);
          
          setEditingId(item.id);
          setSuccess(translate('existing_item_found'));
        }
      }
    } catch (error) {
      // Completely silent handling - no console logs at all
      // Focus on the name field for new entry
      if (nameInputRef.current) {
        nameInputRef.current.focus();
      }
    }
  };

  const fetchStoreInfo = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await axios.get('/items/store-info');

      if (response.data.success && response.data.data) {
        const { brandName, modifiedBrandName, stores } = response.data.data;
        setBrandName(brandName);
        setModifiedBrandName(modifiedBrandName);
        setStores(stores);
        if (stores.length > 0) {
          setSelectedStore(stores[0]);
        }
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Store info fetch error:', error);
      setError(error.response?.data?.error || error.message || translate('failed_fetch_store'));
    } finally {
      setLoading(false);
    }
  };

  const fetchItems = async () => {
    try {
      const response = await axios.get('/items/store-items', {
        params: { modifiedBrandName, selectedStore }
      });
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
      setError(translate('failed_fetch_items'));
    }
  };

  const filterAndSortItems = () => {
    if (!items.length) return;

    let filtered = [...items];
    
    // Filter by status
    if (filter !== 'All') {
      filtered = filtered.filter(item => item.status === filter);
    }
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(item => 
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.barcode && item.barcode.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    
    // Filter by category
    if (filterCategory !== 'All') {
      filtered = filtered.filter(item => item.category === filterCategory);
    }
    
    // Sort
    filtered.sort((a, b) => {
      let comparison = 0;
      
      switch (sortBy) {
        case 'name':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'brand':
          comparison = a.brand.localeCompare(b.brand);
          break;
        case 'quantity':
          comparison = a.quantity - b.quantity;
          break;
        case 'price':
          comparison = a.price - b.price;
          break;
        case 'status':
          comparison = a.status.localeCompare(b.status);
          break;
        default:
          comparison = a.name.localeCompare(b.name);
      }
      
      return sortOrder === 'ascending' ? comparison : -comparison;
    });
    
    setFilteredItems(filtered);
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'ascending' ? 'descending' : 'ascending');
  };

  const handleConfirm = async () => {
    setShowConfirmation(false);
    if (confirmAction) {
      await confirmAction();
    }
  };

  const updateRecentValues = (item) => {
    // Update recent brands
    if (item.brand) {
      const updatedBrands = [item.brand, ...recentBrands.filter(b => b !== item.brand)].slice(0, 5);
      setRecentBrands(updatedBrands);
      saveToLocalStorage('recentBrands', updatedBrands);
    }

    // Update recent categories
    if (item.category) {
      const updatedCategories = [item.category, ...recentCategories.filter(c => c !== item.category)].slice(0, 5);
      setRecentCategories(updatedCategories);
      saveToLocalStorage('recentCategories', updatedCategories);
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    
    // Skip confirmation in quick add mode
    if (quickAddMode) {
      await submitItem();
    } else {
      setConfirmAction(() => submitItem);
      setShowConfirmation(true);
    }
  };

  const submitItem = async () => {
    try {
      // Show success message and clear it after 2 seconds
      setSuccess('');
      
      // Prepare data for saving - translate keys to current language values for backend
      const translatedCategoryValue = categoryMappings.toValueMap[newItem.categoryKey] || newItem.category;
      const translatedWeightUnitValue = weightUnitMappings.toValueMap[newItem.weightUnitKey] || newItem.unitOfMeasureForWeight;
      const translatedVolumeUnitValue = volumeUnitMappings.toValueMap[newItem.volumeUnitKey] || newItem.unitOfMeasureForVolume;
      const translatedExpirationUnitValue = expirationUnitMappings.toValueMap[newItem.expirationUnitKey] || newItem.expirationUnit;
      
      const itemData = {
        ...newItem,
        category: translatedCategoryValue,
        unitOfMeasureForWeight: translatedWeightUnitValue,
        unitOfMeasureForVolume: translatedVolumeUnitValue,
        expirationUnit: translatedExpirationUnitValue,
        quantity: Number(newItem.quantity),
        price: Number(newItem.price),
        costPrice: Number(newItem.costPrice),
        lowStockThreshold: Number(newItem.lowStockThreshold),
        volume: newItem.volume || '',
        weight: newItem.weight || '',
        sku: newItem.sku || '',
        barcode: newItem.barcode || '',
        description: newItem.description || '',
        // Include keys for future editing
        categoryKey: newItem.categoryKey,
        weightUnitKey: newItem.weightUnitKey,
        volumeUnitKey: newItem.volumeUnitKey,
        expirationUnitKey: newItem.expirationUnitKey
      };

      let response;
      
      if (editingId) {
        response = await axios.put(`/items/update-item/${editingId}`, {
          modifiedBrandName,
          selectedStore,
          itemData
        });
        setSuccess(formatMessage(translate('item_updated_success'), itemData.name));
      } else {
        response = await axios.post('/items/add-item', {
          modifiedBrandName,
          selectedStore,
          itemData
        });
        setSuccess(formatMessage(translate('item_added_success'), itemData.name));
        
        // Store as last added for duplication
        setLastAddedItem({...itemData});
      }

      // Update recent values
      updateRecentValues(itemData);
      
      // Reset form but maintain store and date settings
      const store = selectedStore;
      const noExpiryDate = newItem.noExpiryDate;
      resetForm();
      setSelectedStore(store);
      setNewItem(prev => ({
        ...prev,
        noExpiryDate: noExpiryDate,
        lowStockThreshold: '5', // Default value
        weightUnitKey: 'na',
        volumeUnitKey: 'na',
        expirationUnitKey: 'days'
      }));
      
      fetchItems();
      
      // Reset validation state
      setShouldValidateFields(false);
      
      // Focus on barcode input after adding
      if (barcodeInputRef.current) {
        barcodeInputRef.current.focus();
      }
      
      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess('');
      }, 2000);
    } catch (error) {
      console.error('Error saving item:', error);
      setError(translate('failed_save_item'));
    }
  };

  // Handle the "Take Out" action
  const handleTakeOut = (e) => {
    if (e) e.preventDefault();
    
    // Show the take out input field
    setShowTakeOutInput(true);
    
    // Focus on the input after rendering
    setTimeout(() => {
      if (takeOutInputRef.current) {
        takeOutInputRef.current.focus();
      }
    }, 100);
  };

  // Handle confirming the take out
  const handleConfirmTakeOut = () => {
    if (!isFormValid() || !takeOutAmount || isNaN(Number(takeOutAmount)) || Number(takeOutAmount) <= 0) {
      setError(translate('invalid_take_out_amount'));
      return;
    }

    // Ensure take out amount doesn't exceed available quantity
    if (Number(takeOutAmount) > Number(newItem.quantity)) {
      setError(translate('not_enough_stock'));
      return;
    }

    setConfirmAction(() => processTakeOut);
    setShowConfirmation(true);
  };

  // Process the take out
  const processTakeOut = async () => {
    try {
      await axios.post('/takeout/take-out', {
        modifiedBrandName,
        selectedStore,
        selectedItem: editingId,
        takeOutAmount
      });

      // Update quantity in the form
      const newQuantity = Number(newItem.quantity) - Number(takeOutAmount);
      setNewItem(prev => ({ ...prev, quantity: newQuantity.toString() }));
      
      // Reset take out input
      setTakeOutAmount('');
      setShowTakeOutInput(false);
      
      // Update max amount
      setMaxAmount(newQuantity);
      
      // Show success message
      setSuccess(translate('item_taken_out_success'));
      
      // Fetch updated items
      fetchItems();
      
      // Clear success after 2 seconds
      setTimeout(() => {
        setSuccess('');
      }, 2000);
    } catch (error) {
      console.error('Error taking out item:', error);
      setError(error.response?.data?.error || translate('failed_take_out_item'));
    }
  };

  const cancelTakeOut = () => {
    setShowTakeOutInput(false);
    setTakeOutAmount('');
  };

  const handleDelete = (item) => {
    setConfirmAction(() => async () => {
      try {
        await axios.delete(`/items/delete-item/${item.id}`, {
          data: {
            modifiedBrandName,
            selectedStore,
            itemData: item
          }
        });
        setSuccess(formatMessage(translate('item_deleted_success'), item.name));
        fetchItems();
        
        // Clear success message after 2 seconds
        setTimeout(() => {
          setSuccess('');
        }, 2000);
      } catch (error) {
        console.error('Error deleting item:', error);
        setError(translate('failed_delete_item'));
      }
    });
    setShowConfirmation(true);
  };

  const resetForm = () => {
    setNewItem({
      name: '',
      brand: '',
      category: '',
      categoryKey: '',
      unitOfMeasureForWeight: translate('na'),
      weightUnitKey: 'na',
      unitOfMeasureForVolume: translate('na'),
      volumeUnitKey: 'na',
      volume: '',
      weight: '',
      quantity: '',
      expiryDateFrom: '',
      expiryDateTo: '',
      noExpiryDate: false,
      sku: '',
      barcode: '',
      price: '',
      costPrice: '',
      description: '',
      lowStockThreshold: '5',
      expirationPeriod: '',
      expirationUnit: translate('days'),
      expirationUnitKey: 'days'
    });
    setEditingId(null);
    setTemplateFound(false);
    setShouldValidateFields(false);

    // Focus on barcode input
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  };
  const duplicateLastItem = () => {
    if (lastAddedItem) {
      // Get a new barcode or clear the existing one
      const newBarcode = prompt(translate('enter_barcode_duplicate'), '');

      setNewItem({
        ...lastAddedItem,
        barcode: newBarcode || '',
        expiryDateFrom: '',
        expiryDateTo: '',
      });

      // Focus on the quantity field since that's likely to change
      if (quantityInputRef.current) {
        quantityInputRef.current.focus();
      }
    } else {
      setError(translate('no_previous_item'));
    }
  };



  const saveAsTemplate = () => {
    const templateName = prompt(translate('enter_template_name'));
    if (!templateName) return;

    const newTemplate = {
      name: templateName,
      template: {
        ...newItem,
        barcode: '', // Don't save barcode in template
        quantity: '', // Reset quantity
        expiryDateFrom: '',
        expiryDateTo: ''
      }
    };

    const updatedTemplates = [...savedTemplates, newTemplate];
    setSavedTemplates(updatedTemplates);
    saveToLocalStorage('itemTemplates', updatedTemplates);

    setSuccess(formatMessage(translate('template_saved_success'), templateName));
    setTimeout(() => setSuccess(''), 2000);
  };

  const loadTemplate = (template) => {
    setNewItem({
      ...template.template,
      barcode: newItem.barcode, // Keep current barcode
      expiryDateFrom: '',
      expiryDateTo: ''
    });

    setShowTemplates(false);

    // Focus on quantity field
    if (quantityInputRef.current) {
      quantityInputRef.current.focus();
    }
  };

  const deleteTemplate = (index) => {
    const updatedTemplates = [...savedTemplates];
    updatedTemplates.splice(index, 1);
    setSavedTemplates(updatedTemplates);
    saveToLocalStorage('itemTemplates', updatedTemplates);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      setNewItem(prevData => ({ ...prevData, [name]: checked }));
      return;
    }
    
    // Handle select dropdowns with keys
    if (name === 'category') {
      setNewItem(prevData => {
        // Get the category key from the selected option
        const categoryKey = value;
        // Get the translated category name
        const translatedValue = categoryMappings.toValueMap[categoryKey] || value;
        
        return { 
          ...prevData, 
          categoryKey: categoryKey,
          category: translatedValue
        };
      });
    } 
    else if (name === 'unitOfMeasureForWeight') {
      setNewItem(prevData => {
        const selectedKey = value;
        const translatedValue = weightUnitMappings.toValueMap[selectedKey] || value;
        
        return { 
          ...prevData, 
          weightUnitKey: selectedKey,
          unitOfMeasureForWeight: translatedValue
        };
      });
    }
    else if (name === 'unitOfMeasureForVolume') {
      setNewItem(prevData => {
        const selectedKey = value;
        const translatedValue = volumeUnitMappings.toValueMap[selectedKey] || value;
        
        return { 
          ...prevData, 
          volumeUnitKey: selectedKey,
          unitOfMeasureForVolume: translatedValue
        };
      });
    }
    else if (name === 'expirationUnit') {
      setNewItem(prevData => {
        const selectedKey = value;
        const translatedValue = expirationUnitMappings.toValueMap[selectedKey] || value;
        
        return { 
          ...prevData, 
          expirationUnitKey: selectedKey,
          expirationUnit: translatedValue
        };
      });
    }
    else {
      // Handle regular inputs
      setNewItem(prevData => ({ ...prevData, [name]: value }));
    }

    // Auto-focus to next field based on certain conditions
    if (name === 'barcode' && value.length >= 8 && !editingId) {
      // If barcode is entered, move to name
      if (nameInputRef.current) nameInputRef.current.focus();
    }
  };

  // Handle key events for quick navigation
  const handleKeyDown = (e, nextFieldRef) => {
    // Make sure nextFieldRef exists before accessing its properties
    if (e.key === 'Enter' && nextFieldRef && nextFieldRef.current) {
      e.preventDefault();
      nextFieldRef.current.focus();
    }
  };

  const isFormValid = () => {
    return selectedStore && 
         newItem.name && 
         newItem.brand && 
         (newItem.category || newItem.categoryKey) && 
         (newItem.unitOfMeasureForWeight || newItem.weightUnitKey) && 
         (newItem.unitOfMeasureForVolume || newItem.volumeUnitKey) &&
         newItem.quantity && 
         newItem.price && 
         newItem.costPrice &&
         newItem.lowStockThreshold &&
        (newItem.noExpiryDate || (newItem.expiryDateFrom && newItem.expiryDateTo));
  };

  // Handle numpad input
  const handleNumpadInput = (value) => {
    if (!activeInput) return;

    if (value === 'clear') {
      // Remove last character (act like backspace)
      setNewItem(prev => ({
        ...prev,
        [activeInput]: prev[activeInput].toString().slice(0, -1)
      }));
    } else if (value === '+1' && (activeInput === 'quantity' || activeInput === 'lowStockThreshold')) {
      // Increment by 1 (primarily for quantity)
      setNewItem(prev => ({
        ...prev,
        [activeInput]: (parseInt(prev[activeInput] || '0') + 1).toString()
      }));
    } else if (value === '-1' && (activeInput === 'quantity' || activeInput === 'lowStockThreshold')) {
      // Decrement by 1 (primarily for quantity)
      const currentVal = parseInt(newItem[activeInput] || '0');
      if (currentVal > 1) {
        setNewItem(prev => ({
          ...prev,
          [activeInput]: (currentVal - 1).toString()
        }));
      }
    } else if (value === '.' && (activeInput === 'price' || activeInput === 'costPrice' || activeInput === 'volume' || activeInput === 'weight')) {
      // Add decimal point (for price, costPrice, volume, weight)
      if (!newItem[activeInput].includes('.')) {
        setNewItem(prev => ({
          ...prev,
          [activeInput]: prev[activeInput] + '.'
        }));
      }
    } else if (value === 'OK') {
      // Move focus to next field if applicable
      document.activeElement.blur();
      setActiveInput(null);
    } else {
      // Add the number to the active input
      setNewItem(prev => ({
        ...prev,
        [activeInput]: prev[activeInput] + value
      }));
    }
  };

  // Set the active input for numpad focus
  const handleInputFocus = (inputName) => {
    setActiveInput(inputName);
  };

  // Get class for input validation
  const getInputClassName = (name) => {
    if (shouldValidateFields && name !== 'barcode' && name !== 'selectedStore') {
      // For checkboxes, we don't want to show validation state
      if (name === 'noExpiryDate') return '';
      
      // For volume and weight, only validate if the corresponding unit is not N/A
      if (name === 'volume' && newItem.weightUnitKey === 'na') return '';
      if (name === 'weight' && newItem.volumeUnitKey === 'na') return '';

      // For expiry dates, only validate if noExpiryDate is false
      if ((name === 'expiryDateFrom' || name === 'expiryDateTo') && newItem.noExpiryDate) return '';

      // For all other fields, check if they're empty
      const isEmpty = !newItem[name];
      return isEmpty ? 'empty-field' : '';
    }
    return '';
  };

  return (
    <div className="add-item-container">
      <div className={`inventory-container ${showConfirmation ? 'confirmation-active' : ''}`}>
        <div className="inventory-layout">
          {/* Main content - form and inventory list */}
          <div className="inventory-main">
            {/* Input section */}
            <div className="inventory-input-section">
              <div className="inventory-header-row">
                <h2 className="inventory-section-title">
                  {editingId ? translate('edit_item') : translate('add_new_item')}
                </h2>
                
                <div className="quick-actions">
                  <Link to="/store/register-item" className="register-item-button">
                    {translate('register_barcode')}
                  </Link>
                  
                  <button 
                    className={`quick-mode-toggle ${quickAddMode ? 'active' : ''}`}
                    onClick={() => setQuickAddMode(!quickAddMode)}
                    title={quickAddMode ? translate('disable_quick_mode_hint') : translate('enable_quick_mode_hint')}
                  >
                    {translate('quick_mode')} 
                  </button>
                </div>
              </div>
              
              {error && <div className="inventory-error">{error}</div>}
              {success && <div className="inventory-success">{success}</div>}
              
              {showTemplates && (
                <div className="templates-panel">
                  <div className="templates-header">
                    <h3>{translate('item_templates')}</h3>
                    <button 
                      className="templates-save-button"
                      onClick={saveAsTemplate}
                      disabled={!newItem.name}
                    >
                      {translate('save_current_as_template')}
                    </button>
                    <button 
                      className="templates-close-button"
                      onClick={() => setShowTemplates(false)}
                    >
                      ×
                    </button>
                  </div>
                  
                  <div className="templates-list">
                    {savedTemplates.length === 0 ? (
                      <p className="no-templates">{translate('no_saved_templates')}</p>
                    ) : (
                      savedTemplates.map((template, index) => (
                        <div key={index} className="template-item">
                          <span className="template-name">{template.name}</span>
                          <div className="template-actions">
                            <button 
                              className="template-load"
                              onClick={() => loadTemplate(template)}
                            >
                              {translate('load')}
                            </button>
                            <button 
                              className="template-delete"
                              onClick={() => deleteTemplate(index)}
                            >
                              {translate('delete')}
                            </button>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
              
              <form className="inventory-form" onSubmit={handleSubmit}>
                <div className="inventory-form-row">
                  <div className="inventory-form-group">
                    <label>{translate('store')}</label>
                    <select
                      name="selectedStore"
                      value={selectedStore}
                      onChange={(e) => setSelectedStore(e.target.value)}
                      className={getInputClassName('selectedStore')}
                      required
                    >
                      <option value="">{translate('select_store')}</option>
                      {stores.map(store => (
                        <option key={store} value={store}>{store}</option>
                      ))}
                    </select>
                  </div>
                  
                  <div className="inventory-form-group">
                    <label>{translate('barcode_upc')}</label>
                    <input
                      ref={barcodeInputRef}
                      type="text"
                      name="barcode"
                      value={newItem.barcode}
                      onChange={handleInputChange}
                      onKeyDown={(e) => {
                        // Only call handleKeyDown if the key is Enter
                        if (e.key === 'Enter') {
                          handleKeyDown(e, nameInputRef);
                        }
                      }}
                      onFocus={() => {
                        handleInputFocus('barcode');
                        setBarcodeInputActive(true);
                      }}
                      onBlur={() => setBarcodeInputActive(false)}
                      className={isScanning ? 'scanning' : ''}
                    />
                  </div>
                </div>
                
                <div className="inventory-form-row">
                  <div className="inventory-form-group">
                    <label>{translate('product_name')}</label>
                    <input
                      ref={nameInputRef}
                      type="text"
                      name="name"
                      value={newItem.name}
                      onChange={handleInputChange}
                      onKeyDown={(e) => handleKeyDown(e, brandInputRef)}
                      className={getInputClassName('name')}
                      required
                      onFocus={() => handleInputFocus('name')}
                    />
                  </div>
                  
                  <div className="inventory-form-group">
                    <label>{translate('brand')}</label>
                    <div className="input-with-suggestions">
                      <input
                        ref={brandInputRef}
                        type="text"
                        name="brand"
                        value={newItem.brand}
                        onChange={handleInputChange}
                        onKeyDown={(e) => handleKeyDown(e, categoryInputRef)}
                        className={getInputClassName('brand')}
                        required
                        onFocus={() => handleInputFocus('brand')}
                        list="brand-suggestions"
                      />
                      <datalist id="brand-suggestions">
                        {recentBrands.map((brand, idx) => (
                          <option key={`brand-${idx}`} value={brand} />
                        ))}
                      </datalist>
                      
                     
                    </div>
                  </div>
                </div>
                
                <div className="inventory-form-row">
                  <div className="inventory-form-group">
                    <label>{translate('category')}</label>
                    <div className="input-with-suggestions">
                      <select
                        ref={categoryInputRef}
                        name="category"
                        value={newItem.categoryKey}
                        onChange={handleInputChange}
                        onKeyDown={(e) => handleKeyDown(e, quantityInputRef)}
                        className={getInputClassName('category')}
                        required
                      >
                        <option value="">{translate('select_category')}</option>
                        {categoryKeys.map(category => (
                          <option key={category} value={category}>
                            {translate(category)}
                          </option>
                        ))}
                      </select>
                      
                    
                    </div>
                  </div>
                  
                  <div className="inventory-form-group">
                    <label>{translate('quantity')}</label>
                    <input
                      ref={quantityInputRef}
                      type="number"
                      name="quantity"
                      value={newItem.quantity}
                      onChange={handleInputChange}
                      onKeyDown={(e) => handleKeyDown(e, priceInputRef)}
                      className={getInputClassName('quantity')}
                      required
                      onFocus={() => handleInputFocus('quantity')}
                    />
             
                  </div>
                </div>
                
                <div className="inventory-form-row">
                  <div className="inventory-form-group">
                    <label>{translate('selling_price')}</label>
                    <input
                      ref={priceInputRef}
                      type="number"
                      name="price"
                      value={newItem.price}
                      onChange={handleInputChange}
                      onKeyDown={(e) => handleKeyDown(e, costPriceInputRef)}
                      className={getInputClassName('price')}
                      required
                      onFocus={() => handleInputFocus('price')}
                      step="0.01"
                    />
                  </div>
                  
                  <div className="inventory-form-group">
                    <label>{translate('cost_price')}</label>
                    <input
                      ref={costPriceInputRef}
                      type="number"
                      name="costPrice"
                      value={newItem.costPrice}
                      onChange={handleInputChange}
                      onKeyDown={(e) => handleKeyDown(e, thresholdInputRef)}
                      className={getInputClassName('costPrice')}
                      required
                      onFocus={() => handleInputFocus('costPrice')}
                      step="0.01"
                    />
                  </div>
                </div>
                
                <div className="inventory-form-row">
                  <div className="inventory-form-group">
                    <label>{translate('low_stock_threshold')}</label>
                    <input
                      ref={thresholdInputRef}
                      type="number"
                      name="lowStockThreshold"
                      value={newItem.lowStockThreshold}
                      onChange={handleInputChange}
                      className={getInputClassName('lowStockThreshold')}
                      required
                      onFocus={() => handleInputFocus('lowStockThreshold')}
                    />
                  </div>
                  
                  <div className="inventory-form-group">
                    <label>{translate('sku')}</label>
                    <input
                      type="text"
                      name="sku"
                      value={newItem.sku}
                      onChange={handleInputChange}
                      onFocus={() => handleInputFocus('sku')}
                    />
                  </div>
                </div>
                
                <div className="inventory-form-row">
                  <div className="inventory-form-group">
                    <label>{translate('weight_unit')}</label>
                    <select
                      name="unitOfMeasureForWeight"
                      value={newItem.weightUnitKey}
                      onChange={handleInputChange}
                      className={getInputClassName('unitOfMeasureForWeight')}
                      required
                    >
                      <option value="">{translate('select_weight_unit')}</option>
                      {weightUnitKeys.map(unitKey => (
                        <option key={unitKey} value={unitKey}>
                          {translate(unitKey)}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <div className="inventory-form-group">
                    <label>{translate('weight')}</label>
                    <input
                      type="text"
                      name="weight"
                      value={newItem.weight}
                      onChange={handleInputChange}
                      placeholder={
                        newItem.weightUnitKey === 'na'
                          ? translate('weight_not_applicable')
                          : formatMessage(translate('weight_of_unit'), translate(newItem.weightUnitKey))
                      }
                      className={getInputClassName('weight')}
                      disabled={newItem.weightUnitKey === 'na'}
                      onFocus={() => handleInputFocus('weight')}
                    />
                  </div>
                </div>
                
                <div className="inventory-form-row">
                  <div className="inventory-form-group">
                    <label>{translate('volume_unit')}</label>
                    <select
                      name="unitOfMeasureForVolume"
                      value={newItem.volumeUnitKey}
                      onChange={handleInputChange}
                      className={getInputClassName('unitOfMeasureForVolume')}
                      required
                    >
                      <option value="">{translate('select_volume_unit')}</option>
                      {volumeUnitKeys.map(unitKey => (
                        <option key={unitKey} value={unitKey}>
                          {translate(unitKey)}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <div className="inventory-form-group">
                    <label>{translate('volume')}</label>
                    <input
                      type="text"
                      name="volume"
                      value={newItem.volume}
                      onChange={handleInputChange}
                      placeholder={
                        newItem.volumeUnitKey === 'na'
                          ? translate('volume_not_applicable')
                          : formatMessage(translate('volume_of_unit'), translate(newItem.volumeUnitKey))
                      }
                      className={getInputClassName('volume')}
                      disabled={newItem.volumeUnitKey === 'na'}
                      onFocus={() => handleInputFocus('volume')}
                    />
                  </div>
                </div>
                
                <div className="inventory-form-row expiry-date-row">
                  <div className="inventory-form-group">
                    <label>{translate('expiry_date_from')}</label>
                    <input
                      type="date"
                      name="expiryDateFrom"
                      value={newItem.expiryDateFrom}
                      onChange={handleInputChange}
                      disabled={newItem.noExpiryDate}
                      required={!newItem.noExpiryDate}
                      className={getInputClassName('expiryDateFrom')}
                    />
                  </div>
                  
                  <div className="inventory-form-group">
                    <label>{translate('expiry_date_to')}</label>
                    <input
                      type="date"
                      name="expiryDateTo"
                      value={newItem.expiryDateTo}
                      onChange={handleInputChange}
                      disabled={newItem.noExpiryDate}
                      required={!newItem.noExpiryDate}
                      className={getInputClassName('expiryDateTo')}
                    />
                  </div>
                  
                  <div className="inventory-form-group checkbox-container">
                    <label>
                      <input
                        type="checkbox"
                        name="noExpiryDate"
                        checked={newItem.noExpiryDate}
                        onChange={handleInputChange}
                      />
                      {translate('no_expiry_date')}
                    </label>
                  </div>
                </div>
                
                <div className="inventory-form-row">
                  <div className="inventory-form-group full-width">
                    <label>{translate('description')}</label>
                    <textarea
                      name="description"
                      value={newItem.description}
                      onChange={handleInputChange}
                      onFocus={() => handleInputFocus('description')}
                    />
                  </div>
                </div>
                
                {showTakeOutInput ? (
                  <div className="inventory-form-row take-out-input-row">
                    <div className="inventory-form-group">
                      <input
                        ref={takeOutInputRef}
                        type="number"
                        value={takeOutAmount}
                        onChange={(e) => setTakeOutAmount(e.target.value)}
                        placeholder={translate('quantity_to_take_out')}
                        min="1"
                        max={maxAmount}
                        className={getInputClassName('takeOutAmount')}
                        onFocus={() => handleInputFocus('takeOutAmount')}
                      />
                    </div>
                    <div className="take-out-actions">
                      <button 
                        type="button" 
                        className="inventory-take-out-btn" 
                        onClick={handleConfirmTakeOut}
                        disabled={!takeOutAmount || Number(takeOutAmount) <= 0 || Number(takeOutAmount) > maxAmount}
                      >
                        {translate('take_out')}
                      </button>
                      <button 
                        type="button" 
                        className="inventory-cancel-btn" 
                        onClick={cancelTakeOut}
                      >
                        {translate('cancel')}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="inventory-form-actions">
                    <button 
                      type="submit" 
                      className="inventory-submit-btn" 
                      disabled={!isFormValid()}
                    >
                      {editingId ? translate('update_item') : translate('add_item')}
                    </button>
                    
                    {editingId && (
                      <>
                        <button 
                          type="button" 
                          className="inventory-take-out-btn" 
                          onClick={handleTakeOut}
                        >
                          {translate('take_out')}
                        </button>
                        <button 
                          type="button" 
                          className="inventory-cancel-btn" 
                          onClick={resetForm}
                        >
                          {translate('cancel_edit')}
                        </button>
                      </>
                    )}
                  </div>
                )}
              </form>
            </div>
          </div>
          
          {/* Sidebar - numpad and additional controls */}
          <div className="inventory-sidebar">
            <div className="inventory-sidebar-header">
              <h1 className="inventory-title">{translate('speed_inventory')}</h1>
            </div>
            
            <div className="sidebar-content">
              <div className="sidebar-actions">
                <div className="sidebar-action-secondary-buttons">
                  <button 
                    className="sidebar-action-button secondary"
                    onClick={resetForm}
                  >
                    {translate('reset_form')}
                  </button>
                  
                  <button 
                className={`sidebar-action-button secondary ${newItem.noExpiryDate ? 'active-toggle' : ''}`}
                onClick={() => {
                  setNewItem(prev => ({
                    ...prev,
                    noExpiryDate: !prev.noExpiryDate
                  }));
                  // You can optionally use the separate state if you need more control
                  // setExpiryToggleActive(!expiryToggleActive);
                }}
              >
                {translate('toggle_expiry')}
              </button>
                </div>
              </div>
              <div className="required-fields-summary">
                <div className="summary-info">
                  <span>{translate('required_fields')}:</span>
                  <span className="itemadd-summary-info-value">
                    {Object.entries({
                      [translate('store')]: !!selectedStore,
                      [translate('product_name')]: !!newItem.name,
                      [translate('brand')]: !!newItem.brand,
                      [translate('category')]: !!(newItem.category || newItem.categoryKey),
                      [translate('quantity')]: !!newItem.quantity,
                      [translate('selling_price')]: !!newItem.price,
                      [translate('cost_price')]: !!newItem.costPrice,
                      [translate('low_stock_threshold')]: !!newItem.lowStockThreshold,
                      [translate('expiry')]: newItem.noExpiryDate || (!!newItem.expiryDateFrom && !!newItem.expiryDateTo)
                    }).filter(([_, isValid]) => !isValid).map(([field]) => field).join(', ') || translate('all_completed')}
                  </span>
                </div>
              </div>
              
              <hr className="sidebar-divider" />
              
              <div className="numpad-section">
                <h3 className="numpad-title">{translate('numeric_keypad')}</h3>
                <div className="active-input-display">
                  <span>{translate('active_field')}: </span>
                  <span className="active-field-name">{activeInput ? translate(activeInput) : translate('none')}</span>
                </div>
                
                <div className="numpad">
                  <div className="numpad-row">
                    <button className="numpad-button" onClick={() => handleNumpadInput('7')}>7</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('8')}>8</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('9')}>9</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('clear')}>⌫</button>
                  </div>
                  <div className="numpad-row">
                    <button className="numpad-button" onClick={() => handleNumpadInput('4')}>4</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('5')}>5</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('6')}>6</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('+1')}>+1</button>
                  </div>
                  <div className="numpad-row">
                    <button className="numpad-button" onClick={() => handleNumpadInput('1')}>1</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('2')}>2</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('3')}>3</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('-1')}>-1</button>
                  </div>
                  <div className="numpad-row">
                    <button className="numpad-button" onClick={() => handleNumpadInput('.')}>.</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('0')}>0</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('00')}>00</button>
                    <button className="numpad-button" onClick={() => handleNumpadInput('OK')}>{translate('enter')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationDialog
          message={editingId 
            ? translate('confirm_update_item')
            : translate('confirm_add_item')}
          onConfirm={handleConfirm}
          onCancel={() => setShowConfirmation(false)}
          confirmText={translate('confirm')}
          cancelText={translate('cancel')}
        />
      )}
    </div>
  );
};

export default ItemAdd;