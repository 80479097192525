import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import PricingPlan from './PricingPlan';
import './Auth.css';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [brandName, setBrandName] = useState('');
  const [status, setStatus] = useState('');
  const [stores, setStores] = useState([]);
  const [numberOfStores, setNumberOfStores] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [registrationStep, setRegistrationStep] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState('');
  const navigate = useNavigate();
  const { register } = useAuth();
  const pricingRef = useRef(null);


  const handlePasswordBlur = () => {
    setPasswordTouched(true);
  };

  const handleStoreInfoChange = (index, field, value) => {
    const updatedStores = [...stores];
    if (!updatedStores[index]) {
      updatedStores[index] = {};
    }
    updatedStores[index][field] = value;
    setStores(updatedStores);
  };

  const handleNumberOfStoresChange = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value) || value < 1) {
      setNumberOfStores(1);
    } else {
      setNumberOfStores(value);
      if (value < stores.length) {
        setStores(stores.slice(0, value));
      }
    }
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const validateStep1 = () => {
    if (password.length < 8) {
      setError('Password should be at least 8 characters');
      return false;
    }
    
    if (!firstName || !lastName) {
      setError('First Name and Last Name are required');
      return false;
    }

    if (!email) {
      setError('Email is required');
      return false;
    }

    if (!brandName) {
      setError('Brand Name is required');
      return false;
    }

    if (!status) {
      setError('Status is required');
      return false;
    }

    return true;
  };

  const validateStep2 = () => {
    let isValid = true;
    
    // Check if all store information is filled
    stores.forEach((store, index) => {
      if (!store.country || !store.state || !store.city || !store.address || 
          !store.size || !store.employees || !store.phone) {
        isValid = false;
        setError(`Please fill all required information for Store ${index + 1}`);
      }
    });
    
    return isValid;
  };

  const goToNextStep = () => {
    if (registrationStep === 1) {
      if (validateStep1()) {
        setError('');
        setRegistrationStep(2);
      }
    } else if (registrationStep === 2) {
      if (validateStep2()) {
        setError('');
        setRegistrationStep(3);
      }
    }
  };

  const goToPreviousStep = () => {
    setError('');
    setRegistrationStep(registrationStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    
    if (!selectedPlan) {
      setError('Please select a pricing plan');
      return;
    }
  
    try {
      setError('');
      setLoading(true);
      
      await register(email, password, { 
        brandName, 
        status, 
        stores,
        firstName,
        lastName,
        middleName,
        customerPlan: selectedPlan
      });
      
      navigate('/');
    } catch (error) {
      setError(error.message || 'Failed to create account');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (registrationStep === 3) {
      // Reset scroll position immediately
      if (document.querySelector('.auth-card')) {
        document.querySelector('.auth-card').scrollBottom = 0;
      }
      
      // Use setTimeout to ensure the DOM has updated
      setTimeout(() => {
        // Scroll window to top to ensure proper positioning
        window.scrollTo({
          top: 1000,
          behavior: 'auto'
        });
        
        // Ensure the auth card is positioned correctly
        const authCard = document.querySelector('.auth-card');
        if (authCard) {
          authCard.scrollBottom = 0;
        }
      }, 10);
    }
  }, [registrationStep]);
  
  return (
    <div className="auth-container animated-bg">
      <div className={`auth-card ${registrationStep === 3 ? 'auth-card-wide' : ''}`}>
        <h2 className="h2">Create Account</h2>
        
        {/* Step indicators */}
        <div className="step-indicators">
          <div className={`step ${registrationStep >= 1 ? 'completed' : ''} ${registrationStep === 1 ? 'active' : ''}`}>1</div>
          <div className={`step ${registrationStep >= 2 ? 'completed' : ''} ${registrationStep === 2 ? 'active' : ''}`}>2</div>
          <div className={`step ${registrationStep >= 3 ? 'completed' : ''} ${registrationStep === 3 ? 'active' : ''}`}>3</div>
        </div>
        
        <hr />
        
        {registrationStep === 1 && (
          <form onSubmit={(e) => { e.preventDefault(); goToNextStep(); }}>
            {error && <div className="error-message">{error}</div>}
            
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                className="auth-input"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={loading}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div className="password-container">
                <input
                  className="auth-input"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={handlePasswordBlur}
                  required
                  disabled={loading}
                  minLength="8"
                />
                <button
                  type="button"
                  className="password-toggle-text"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label={showPassword ? "Hide password" : "Show password"}
                >
                  <p>{showPassword ? "Hide" : "Show"}</p>
                </button>
              </div>
              {passwordTouched && password.length < 8 && (
                <p className="password-hint invalid">Password should be at least 8 characters</p>
              )}
            </div>

            {/* Personal Information - Two column layout */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  className="auth-input"
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  disabled={loading}
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  className="auth-input"
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  disabled={loading}
                />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="middleName">Middle Name (Optional)</label>
              <input
                className="auth-input"
                type="text"
                id="middleName"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                disabled={loading}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="brandName">Brand Name</label>
              <input
                className="auth-input"
                type="text"
                id="brandName"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
                required
                disabled={loading}
              />
            </div>

            <div className="form-group">
              <label htmlFor="status">Business Type</label>
              <select
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
                disabled={loading}
              >
                <option value="">Select Type</option>
                <option value="Store Network">Store Network</option>
              </select>
            </div>

            {status === 'Store Network' && (
              <div className="form-group">
                <label htmlFor="numberOfStores">Number of Stores</label>
                <input
                  className="auth-input"
                  type="number"
                  id="numberOfStores"
                  value={numberOfStores}
                  onChange={handleNumberOfStoresChange}
                  min="1"
                  max="50"
                  required
                  disabled={loading}
                />
              </div>
            )}

            <button type="submit" disabled={loading} className="auth-button">
              Continue
            </button>

            <p className="auth-link">
              Already have an account? <Link to="/login">Sign In</Link>
            </p>
          </form>
        )}

        {registrationStep === 2 && (
          <form onSubmit={(e) => { e.preventDefault(); goToNextStep(); }}>
            {error && <div className="error-message">{error}</div>}
            
            {(status === 'Store' ? [0] : [...Array(numberOfStores).keys()]).map((_, index) => (
              <div key={index} className="store-info-auth">
                <h3>{status === 'Store' ? 'Store Information' : `Store ${index + 1} Information`}</h3>
                
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor={`country-${index}`}>Country</label>
                    <input
                      className="auth-input"
                      type="text"
                      id={`country-${index}`}
                      value={stores[index]?.country || ''}
                      onChange={(e) => handleStoreInfoChange(index, 'country', e.target.value)}
                      required
                      disabled={loading}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor={`state-${index}`}>State</label>
                    <input
                      className="auth-input"
                      type="text"
                      id={`state-${index}`}
                      value={stores[index]?.state || ''}
                      onChange={(e) => handleStoreInfoChange(index, 'state', e.target.value)}
                      required
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor={`city-${index}`}>City</label>
                    <input
                      className="auth-input"
                      type="text"
                      id={`city-${index}`}
                      value={stores[index]?.city || ''}
                      onChange={(e) => handleStoreInfoChange(index, 'city', e.target.value)}
                      required
                      disabled={loading}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor={`address-${index}`}>Address</label>
                    <input
                      className="auth-input"
                      type="text"
                      id={`address-${index}`}
                      value={stores[index]?.address || ''}
                      onChange={(e) => handleStoreInfoChange(index, 'address', e.target.value)}
                      required
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor={`size-${index}`}>Size (sq m)</label>
                    <input
                      className="auth-input"
                      type="number"
                      id={`size-${index}`}
                      value={stores[index]?.size || ''}
                      onChange={(e) => handleStoreInfoChange(index, 'size', e.target.value)}
                      min="1"
                      required
                      disabled={loading}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor={`employees-${index}`}>Number of Employees</label>
                    <input
                      className="auth-input"
                      type="number"
                      id={`employees-${index}`}
                      value={stores[index]?.employees || ''}
                      onChange={(e) => handleStoreInfoChange(index, 'employees', e.target.value)}
                      min="1"
                      required
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor={`phone-${index}`}>Contact Phone</label>
                    <input
                      className="auth-input"
                      type="tel"
                      id={`phone-${index}`}
                      value={stores[index]?.phone || ''}
                      onChange={(e) => handleStoreInfoChange(index, 'phone', e.target.value)}
                      required
                      disabled={loading}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor={`contactEmail-${index}`}>Contact Email (Optional)</label>
                    <input
                      className="auth-input"
                      type="email"
                      id={`contactEmail-${index}`}
                      value={stores[index]?.contactEmail || ''}
                      onChange={(e) => handleStoreInfoChange(index, 'contactEmail', e.target.value)}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            ))}

            <div className="form-buttons">
              <button type="button" onClick={goToPreviousStep} className="auth-button secondary">
                Back
              </button>
              <button type="submit" disabled={loading} className="auth-button">
                Continue
              </button>
            </div>
          </form>
        )}

{registrationStep === 3 && (
  <form onSubmit={handleSubmit} className="plan-selection-form">
    {error && <div className="error-message">{error}</div>}
    
    <div className="pricing-section" ref={pricingRef}>
      <PricingPlan onSelectPlan={handleSelectPlan} selectedPlan={selectedPlan} />
      
      <div className="form-buttons">
        <button type="button" onClick={goToPreviousStep} className="auth-button secondary">
          Back
        </button>
        <button 
          type="submit" 
          disabled={loading || !selectedPlan} 
          className="auth-button"
        >
          {loading ? 'Processing...' : 'Complete Registration'}
        </button>
      </div>
    </div>
  </form>
)}
      </div>
    </div>
  );
}

export default Register;