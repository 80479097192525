import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import './AddStore.css';

const AddStore = () => {
  const [storeInfo, setStoreInfo] = useState({
    country: '',
    state: '',
    city: '',
    address: '',
    size: '',
    employees: '',
    phone: '',
    contactEmail: ''
  });
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleChange = (e) => {
    setStoreInfo({ ...storeInfo, [e.target.name]: e.target.value });
  };

  const validateStoreInfo = () => {
    const requiredFields = ['country', 'state', 'city', 'address', 'size', 'employees', 'phone'];
    return requiredFields.every(field => storeInfo[field].trim() !== '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSaveStatus('saving');

    if (!validateStoreInfo()) {
      setError('Please fill in all required fields');
      setSaveStatus('error');
      return;
    }

    try {
      const response = await axios.post('/stores/add-store', storeInfo);

      if (response.data.success) {
        setSaveStatus('success');
        setTimeout(() => {
          navigate('/store/profile');
        }, 2000);
      } else {
        throw new Error(response.data.error || 'Failed to add store');
      }
    } catch (error) {
      console.error('Error adding store:', error);
      setError(error.response?.data?.error || 'Error adding store');
      setSaveStatus('error');
    }
  };

  if (!user) {
    navigate('/login');
    return null;
  }

  return (
    <div className="addstore-add-container">
      <div className="addstore-container">
        <h2 className="addstore-title">Add New Store</h2>
        {saveStatus && (
          <div className={`save-status ${saveStatus}`}>
            {saveStatus === 'saving' && 'Saving changes...'}
            {saveStatus === 'success' && 'Store added successfully!'}
            {saveStatus === 'error' && 'Error adding store. Please try again.'}
          </div>
        )}
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit} className="addstore-form">
          <div className="addstore-form-group">
            <input
              className="addstore-form-group-input"
              type="text"
              id="country"
              name="country"
              value={storeInfo.country}
              onChange={handleChange}
              placeholder="Country"
              required
            />
          </div>
          <div className="addstore-form-group">
            <input
              className="addstore-form-group-input"
              type="text"
              id="state"
              name="state"
              value={storeInfo.state}
              onChange={handleChange}
              placeholder="State"
              required
            />
          </div>
          <div className="addstore-form-group">
            <input
              className="addstore-form-group-input"
              type="text"
              id="city"
              name="city"
              value={storeInfo.city}
              onChange={handleChange}
              placeholder="City"
              required
            />
          </div>
          <div className="addstore-form-group">
            <input
              className="addstore-form-group-input"
              type="text"
              id="address"
              name="address"
              value={storeInfo.address}
              onChange={handleChange}
              placeholder="Address"
              required
            />
          </div>
          <div className="addstore-form-group">
            <input
              className="addstore-form-group-input"
              type="number"
              id="size"
              name="size"
              value={storeInfo.size}
              onChange={handleChange}
              placeholder="Size (sq m)"
              required
            />
          </div>
          <div className="addstore-form-group">
            <input
              className="addstore-form-group-input"
              type="number"
              id="employees"
              name="employees"
              value={storeInfo.employees}
              onChange={handleChange}
              placeholder="Number of Employees"
              required
            />
          </div>
          <div className="addstore-form-group">
            <input
              className="addstore-form-group-input"
              type="tel"
              id="phone"
              name="phone"
              value={storeInfo.phone}
              onChange={handleChange}
              placeholder="Contact Phone"
              required
            />
          </div>
          <div className="addstore-form-group">
            <input
              className="addstore-form-group-input"
              type="email"
              id="contactEmail"
              name="contactEmail"
              value={storeInfo.contactEmail}
              onChange={handleChange}
              placeholder="Contact Email (optional)"
            />
          </div>

          <button 
            type="submit" 
            className="addstore-submit-btn" 
            disabled={saveStatus === 'saving'}
          >
            {saveStatus === 'saving' ? 'Adding Store...' : 'Add Store'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddStore;
