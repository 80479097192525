import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import './Notifications.css';
import ConfirmationDialog from './ConfirmationDialog'

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [brandName, setBrandName] = useState('');
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPostponeAlert, setShowPostponeAlert] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    fetchStoreInfo();
  }, [user, navigate]);

  useEffect(() => {
    if (modifiedBrandName) {
      const fetchNotifications = async () => {
        try {
          const response = await axios.get(`/notifications/all/${modifiedBrandName}`);
          setNotifications(response.data);
        } catch (error) {
          console.error('Error fetching notifications:', error);
          setError('Failed to fetch notifications');
        }
      };

      fetchNotifications();
      const interval = setInterval(fetchNotifications, 30000); // Poll every 30 seconds
      return () => clearInterval(interval);
    }
  }, [modifiedBrandName]);

  const fetchStoreInfo = async () => {
    try {
      const response = await axios.get('/notifications/store-info');
      setBrandName(response.data.brandName);
      setModifiedBrandName(response.data.modifiedBrandName);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching store info:', error);
      setError('Failed to fetch store information');
      setLoading(false);
    }
  };

  const handlePostpone = async (notification) => {
    try {
      await axios.post(
        `/notifications/postpone/${modifiedBrandName}/${notification.id}`
      );
      setNotifications(prevNotifications => 
        prevNotifications.filter(n => n.id !== notification.id)
      );
      setShowPostponeAlert(true);
    } catch (error) {
      console.error('Error postponing notification:', error);
      setError('Failed to postpone notification');
    }
  };

  const handleDismiss = (notification) => {
    setSelectedNotification(notification);
    setShowConfirmation(true);
  };

  const confirmDismiss = async () => {
    setShowConfirmation(false);
    if (selectedNotification) {
      try {
        await axios.post(
          `/notifications/dismiss/${modifiedBrandName}/${selectedNotification.id}`
        );
        setNotifications(prevNotifications => 
          prevNotifications.filter(n => n.id !== selectedNotification.id)
        );
      } catch (error) {
        console.error('Error dismissing notification:', error);
        setError('Failed to dismiss notification');
      }
    }
    setSelectedNotification(null);
  };

 

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="notifications-page-container-main">
      <div className="notifications-page-container">
        <h2>Notifications ({notifications.length})</h2>
        {notifications.length === 0 ? (
          <p>No notifications</p>
        ) : (  
          <ul className="notifications-page-list">
            {notifications.map((notification) => (
              <li 
                key={notification.id} 
                className={`notification-page-item ${
                  notification.status ? notification.status.toLowerCase().replace(/\s+/g, '-') : ''
                } ${notification.title === 'Welcome' ? 'welcome-notification' : ''}`}
              >
                <h3>{notification.title}</h3>
                {notification.title === 'Welcome' ? (
                  <div className="welcome-content">
                    <p>{notification.message}</p>
                    <p className="welcome-date">
                      Date: {notification.date ? new Date(notification.date).toLocaleString() : 'N/A'}
                    </p>
                  </div>
                ) : (
                  <>
                    <p>Store Address: {notification.store}</p>
                    <p>Item: {notification.item}</p>
                    <p>Current Quantity: {notification.quantity}</p>
                    <p>Status: {notification.status}</p>
                    <p>Date: {notification.date ? new Date(notification.date).toLocaleString() : 'N/A'}</p>
                  </>
                )}
                <button onClick={() => handleDismiss(notification)} className="dismiss-btn">
                  Dismiss
                </button>
                {notification.title !== 'Welcome' && (
                  <button onClick={() => handlePostpone(notification)} className="postpone-btn">
                    Postpone
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
        
        {showConfirmation && (
          <ConfirmationDialog
          message="Are you sure you want to dismiss?"
          onConfirm={confirmDismiss}
          onCancel={() => setShowConfirmation(false)}
          confirmText="Confirm"
          cancelText="Cancel"
        />
        )}

        {showPostponeAlert && (
          <div className="confirmation-overlay">
          <div className="alert-dialog">
            <div className="alert-content">
              <p>Notification will arrive in 24 hours!</p>
              <button onClick={() => setShowPostponeAlert(false)} className="ok-btn">
                OK
              </button>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
