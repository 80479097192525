import React from 'react';
import { X, ChevronRight, Search } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import './MobileMenuFinances.css';

const MobileMenuFinances = ({ isOpen, onClose }) => {
  const location = useLocation();

  const menuItems = [
    { title: 'Accounting', path: '/store/finances/accounting' },
    { title: 'Budgeting', path: '/store/finances/budgeting' },
    { title: 'Credit Management', path: '/store/finances/credit-management' },
    { title: 'Payments', path: '/store/finances/payments' },
    { title: 'Financial Reports', path: '/store/finances/reports' },
    { title: 'Back to Inventory', path: '/store/dashboard' },
];

const isActive = (path) => {
  return location.pathname === path ? 'active' : '';
};

  return (
    <div className={`mobile-menu-overlay ${isOpen ? 'open' : ''}`}>
      <div className={`mobile-menu-container ${isOpen ? 'open' : ''}`}>
        <div className="mobile-menu-header">
          <h2></h2>
          <button className="mobile-menu-close" onClick={onClose}>
            <X size={24} />
          </button>
        </div>
        <div className="mobile-menu-search-container-n">
          <div className="mobile-menu-search-n">
            <Search size={20} />
            <input type="text" placeholder="Search" />
          </div>
        </div>
        <nav className="mobile-nav">
          <ul>
            {menuItems.map((item) => (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className={`mobile-menu-item ${isActive(item.path)} ${item.title === 'Back to Inventory' ? 'back-to-inventory-item' : ''}`}
                  onClick={onClose}
                >
                  <span>{item.title}</span>
                  <ChevronRight size={20} />
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileMenuFinances;