import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, BarChart, Bar } from 'recharts';
import axios from 'axios';
import LoadingAnimation from './LoadingPages/LoadingAnimation';
import { useDaxHover } from '../contexts/DaxHoverContext';
import DaxHoverOverlay from './DaxHoverOverlay';
import { translations } from './Data/data'; // Import translations
import PatternAnalysis from './PatternAnalysis';
import './Dashboard.css';

// Define category keys for consistent use across components
const categoryKeys = [
  'all_categories', 'raw_materials', 'groceries', 'beverages', 
  'household_products', 'personal_care_items', 'snacks', 'frozen_foods', 
  'health_and_wellness_products', 'electronics', 'pet_supplies', 'office_supplies'
];

// Helper function to create a mapping between translated category names and keys
const createCategoryMappings = (language) => {
  const categoryToKeyMap = {};
  const keyToCategoryMap = {};
  
  categoryKeys.forEach(key => {
    const translatedCategory = translations[language]?.[key] || translations['en'][key];
    categoryToKeyMap[translatedCategory] = key;
    keyToCategoryMap[key] = translatedCategory;
 });
  
  return {
    categoryToKeyMap,   // Maps from translated name to key
    keyToCategoryMap    // Maps from key to translated name
 };
};

// Function to get a category key from a category name in any language
const getCategoryKeyFromName = (categoryName) => {
  if (!categoryName) return null;
  
  // Get mappings for all supported languages
  const allMappings = Object.keys(translations).map(lang => 
    createCategoryMappings(lang).categoryToKeyMap
 );
  
  // Try to find the category key in any language
  for (const mapping of allMappings) {
    if (mapping[categoryName]) {
      return mapping[categoryName];
 }
 }
  
  // Fallback for category names that don't match any translation
  return null;
};

const Dashboard = () => {
  const [language, setLanguage] = useState('en');
  
  const [items, setItems] = useState([]);
  const [brandName, setBrandName] = useState('');
  const [stores, setStores] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPatternAnalysisOpen, setIsPatternAnalysisOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  
  // State for category mappings
  const [categoryMappings, setCategoryMappings] = useState({
    categoryToKeyMap: {},
    keyToCategoryMap: {}
 });
  
  // State for filters
  const [selectedStore, setSelectedStore] = useState('all');
  const [selectedItem, setSelectedItem] = useState('all');
  const [selectedBrand, setSelectedBrand] = useState('all');
  const [selectedCategoryKey, setSelectedCategoryKey] = useState('all_categories');
  
  // Date range filter states
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilterActive, setDateFilterActive] = useState(false);
  
  // Chart type selection state
  const [chartType, setChartType] = useState('Line Chart');
  
  const [filteredData, setFilteredData] = useState([]);
  const [originalReportsData, setOriginalReportsData] = useState([]); // Store original data for date filtering
  const [uniqueItems, setUniqueItems] = useState([]);
  const [uniqueBrands, setUniqueBrands] = useState([]);
  const [uniqueCategoryKeys, setUniqueCategoryKeys] = useState(['all_categories']);
  const [allReports, setAllReports] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  
  // Stats for displaying totals
  const [totalItemsAdded, setTotalItemsAdded] = useState(0);
  const [totalItemsSold, setTotalItemsSold] = useState(0);
  const [totalAddedValue, setTotalAddedValue] = useState(0);
  const [totalSoldValue, setTotalSoldValue] = useState(0);
  
  // Flag to prevent refetching data when expanded view is toggled
  const [dataLoaded, setDataLoaded] = useState(false);

  const { isDaxEnabled, hoveredSection, setHoveredSection, setIsDaxEnabled } = useDaxHover();
  const navigate = useNavigate();
  const { user } = useAuth();

  // Translation helper function
  const translate = useCallback((key) => {
    return translations[language]?.[key] || translations['en'][key];
 }, [language]);

  // Update category mappings when language changes
  useEffect(() => {
    setCategoryMappings(createCategoryMappings(language));
 }, [language]);

  // Set default dates when component mounts
  useEffect(() => {
    // Set default end date to today
    const today = new Date();
    const endDateStr = today.toISOString().split('T')[0];
    setEndDate(endDateStr);

    // Set default start date to 30 days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const startDateStr = thirtyDaysAgo.toISOString().split('T')[0];
    setStartDate(startDateStr);
 }, []);

  const handleDaxClick = useCallback((content) => {
    navigate('/store/dax/new', {
      state: { 
        initialMessage: {
          content,
          title: content.includes(translate('inventory_summary_document')) ? translate('inventory_summary') : translate('inventory_analytics_overview'),
          type: "Document"
 }
 }
 });
    setIsDaxEnabled(false);
 }, [navigate, translate, setIsDaxEnabled]);

  // Check if user is authenticated
  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
 }
    
    // Only fetch store info if it hasn't been fetched yet
    if (!brandName) {
      fetchStoreInfo();
 }
 }, [user, navigate, brandName]);

  // Fetch data once when store info is available
  useEffect(() => {
    if (brandName && stores.length > 0 && !dataLoaded) {
      let isMounted = true;
      
      const fetchData = async () => {
        try {
          await Promise.all([
            fetchItems(),
            fetchAllReports()
 ]);
          
          if (isMounted) {
            setLoading(false);
            setDataLoaded(true); // Mark data as loaded to prevent refetching
 }
 } catch (error) {
          if (isMounted) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch dashboard data');
            setLoading(false);
 }
 }
 };
      
      fetchData();
      
      return () => {
        isMounted = false;
 };
 }
 }, [brandName, stores, dataLoaded]);

  // Extract unique values from items and reports
  useEffect(() => {
    if ((items.length > 0 || allReports.length > 0) && !isFiltering) {
      extractUniqueValues();
 }
 }, [items, allReports, isFiltering]);

  // Process reports data when loaded or filters change
  useEffect(() => {
    if (allReports.length > 0 && !isFiltering) {
      processReportsData();
 }
 }, [allReports, selectedStore, selectedItem, selectedBrand, selectedCategoryKey, startDate, endDate, dateFilterActive, isFiltering]);

  const fetchStoreInfo = async () => {
    try {
      const response = await axios.get('/dashboard/store-info');
      if (response.data && response.data.brandName) {
        setBrandName(response.data.brandName);
        setModifiedBrandName(response.data.modifiedBrandName);
        setStores(response.data.stores);
 } else if (response.data && response.data.success && response.data.data) {
        // Alternative response format
        setBrandName(response.data.data.brandName);
        setModifiedBrandName(response.data.data.modifiedBrandName);
        setStores(response.data.data.stores);
 }
 } catch (error) {
      setError('Failed to fetch store information');
      setLoading(false);
 }
 };

  const fetchItems = async () => {
    try {
      const response = await axios.get('/dashboard/items', {
        params: {
          modifiedBrandName,
          stores: JSON.stringify(stores)
 }
 });
      
      // Process items to add category keys
      const processedItems = response.data.map(item => {
        return {
          ...item,
          categoryKey: getCategoryKeyFromName(item.category)
 };
 });
      
      setItems(processedItems);
 } catch (error) {
      console.error('Error fetching items:', error);
 }
 };
  
  // Fetch all reports - our primary data source
  const fetchAllReports = async () => {
    try {
      setIsFiltering(true);
      // Fetch reports with a large page size to get as many as possible
      const response = await axios.get(`/reports/reports/${modifiedBrandName}`, {
        params: { pageSize: 1000 }
 });
      
      if (response.data && response.data.reports) {
        // Process and standardize the reports data
        const processedReports = response.data.reports.map(report => {
          // Ensure timestamp is consistently a Date object
          let timestamp = new Date();
          if (typeof report.timestamp === 'string') {
            timestamp = new Date(report.timestamp);
 } else if (report.timestamp && report.timestamp._seconds) {
            timestamp = new Date(report.timestamp._seconds * 1000);
 } else if (report.timestamp && report.timestamp.toDate) {
            timestamp = report.timestamp.toDate();
 }
          
          // Add categoryKey to report
          const categoryKey = getCategoryKeyFromName(report.category);
          
          // Process items array if it exists
          let processedItems = [];
          if (report.items && Array.isArray(report.items)) {
            processedItems = report.items.map(item => ({
              ...item,
              categoryKey: getCategoryKeyFromName(item.category)
 }));
 }
          
          return {
            ...report,
            timestamp,
            categoryKey,
            items: processedItems.length > 0 ? processedItems : report.items,
            // Standardize quantity field - ensure it's a number
            quantity: parseInt(report.quantity) || 0
 };
 });
        
        setAllReports(processedReports);
        setOriginalReportsData(processedReports); // Store the original data for date filtering
 }
 } catch (error) {
      console.error('Error fetching reports:', error);
 } finally {
      setIsFiltering(false);
 }
 };
  
  // Extract unique values function - memoized to prevent unnecessary recalculations
  const extractUniqueValues = useCallback(() => {
    // Build sets to store unique values
    const itemsSet = new Set();
    const brandsSet = new Set();
    const categoryKeysSet = new Set(['all_categories']);
    
    // Extract values from inventory items
    items.forEach(item => {
      if (item.itemName) itemsSet.add(item.itemName);
      if (item.brand) brandsSet.add(item.brand);
      
      // Get the category key for language-independent filtering
      if (item.category) {
        const categoryKey = getCategoryKeyFromName(item.category);
        if (categoryKey) categoryKeysSet.add(categoryKey);
 }
 });
    
    // Extract values from reports
    allReports.forEach(report => {
      // Direct values
      if (report.itemName) itemsSet.add(report.itemName);
      if (report.brand) brandsSet.add(report.brand);
      
      // Get category key for report
      if (report.category) {
        const categoryKey = getCategoryKeyFromName(report.category);
        if (categoryKey) categoryKeysSet.add(categoryKey);
 }
      
      // Check items array in sale reports
      if (report.items && Array.isArray(report.items)) {
        report.items.forEach(item => {
          if (item.itemName) itemsSet.add(item.itemName);
          if (item.brand) brandsSet.add(item.brand);
          
          // Get category key for item in report
          if (item.category) {
            const categoryKey = getCategoryKeyFromName(item.category);
            if (categoryKey) categoryKeysSet.add(categoryKey);
 }
 });
 }
 });
    
    // Convert sets to sorted arrays
    setUniqueItems(Array.from(itemsSet).filter(Boolean).sort());
    setUniqueBrands(Array.from(brandsSet).filter(Boolean).sort());
    setUniqueCategoryKeys(Array.from(categoryKeysSet));
 }, [items, allReports]);
  
  // Process reports data based on filters - memoized to prevent unnecessary recalculations
  const processReportsData = useCallback(() => {
    setIsFiltering(true);
    
    try {
      // Start with all reports
      let reportsToProcess = [...allReports];
      
      // Apply date filter if active
      if (dateFilterActive && startDate && endDate) {
        const startDateObj = new Date(startDate);
        startDateObj.setHours(0, 0, 0, 0); // Start of day
        
        const endDateObj = new Date(endDate);
        endDateObj.setHours(23, 59, 59, 999); // End of day
        
        reportsToProcess = reportsToProcess.filter(report => {
          const reportDate = new Date(report.timestamp);
          return reportDate >= startDateObj && reportDate <= endDateObj;
 });
 }
      
      // Filter reports based on selected criteria
      const filteredReports = reportsToProcess.filter(report => {
        // Apply all filters using same approach
        
        // 1. Store filter
        if (selectedStore !== 'all' && report.store !== selectedStore) {
          return false;
 }
        
        // 2. Item name filter
        if (selectedItem !== 'all') {
          // For direct reports like Added
          if (report.itemName && report.itemName === selectedItem) {
            // Match found at top level, continue with other filters
 }
          // For reports with items array like Sale
          else if (report.items && Array.isArray(report.items)) {
            const hasMatchingItem = report.items.some(item => item.itemName === selectedItem);
            if (!hasMatchingItem) {
              return false; // No matching item found
 }
 }
          // No match found
          else if (!report.itemName || report.itemName !== selectedItem) {
            return false;
 }
 }
        
        // 3. Brand filter
        if (selectedBrand !== 'all') {
          // For direct reports like Added
          if (report.brand && report.brand === selectedBrand) {
            // Match found at top level, continue with other filters
 }
          // For reports with items array like Sale
          else if (report.items && Array.isArray(report.items)) {
            const hasMatchingBrand = report.items.some(item => item.brand === selectedBrand);
            if (!hasMatchingBrand) {
              return false; // No matching brand found
 }
 }
          // No match found
          else if (!report.brand || report.brand !== selectedBrand) {
            return false;
 }
 }
        
        // 4. Category filter - using language-independent keys
        if (selectedCategoryKey !== 'all_categories') {
          // For direct reports
          if (report.categoryKey && report.categoryKey === selectedCategoryKey) {
            // Match found at top level, continue with other filters
 }
          // For reports with items array
          else if (report.items && Array.isArray(report.items)) {
            const hasMatchingCategory = report.items.some(item => item.categoryKey === selectedCategoryKey);
            if (!hasMatchingCategory) {
              return false; // No matching category found
 }
 }
          // No match found
          else {
            return false;
 }
 }
        
        // All filters passed, include this report
        return true;
 });
      
      // Aggregate data by date
      const dateGroups = {};
      let totalAdded = 0;
      let totalSold = 0;
      let addedValue = 0;
      let soldValue = 0;
      
      filteredReports.forEach(report => {
        // Format date as YYYY-MM-DD
        const date = report.timestamp.toISOString().split('T')[0];
        
        // Initialize date group if not exists
        if (!dateGroups[date]) {
          dateGroups[date] = { date, added: 0, sale: 0 };
 }
        
        // Handle different report types
        if (report.action === 'Added') {
          const quantity = parseInt(report.quantity) || 0;
          dateGroups[date].added += quantity;
          totalAdded += quantity;
          
          // Calculate value if price is available
          const price = parseFloat(report.price) || 0;
          addedValue += quantity * price;
 } 
        else if (report.action === 'Sale') {
          let saleQuantity = 0;
          let saleValue = 0;
          
          // For reports with items array
          if (report.items && Array.isArray(report.items)) {
            // Apply filters to items array to determine which items to count
            let itemsToCount = [...report.items]; // Start with all items
            
            // Apply item filter
            if (selectedItem !== 'all') {
              itemsToCount = itemsToCount.filter(item => item.itemName === selectedItem);
 }
            
            // Apply brand filter
            if (selectedBrand !== 'all') {
              itemsToCount = itemsToCount.filter(item => item.brand === selectedBrand);
 }
            
            // Apply category filter using category keys
            if (selectedCategoryKey !== 'all_categories') {
              itemsToCount = itemsToCount.filter(item => item.categoryKey === selectedCategoryKey);
 }
            
            // Calculate totals from filtered items
            saleQuantity = itemsToCount.reduce((sum, item) => {
              const qty = parseInt(item.quantity) || 0;
              const price = parseFloat(item.unitPrice) || 0;
              saleValue += qty * price;
              return sum + qty;
 }, 0);
 } 
          // For reports with direct quantity - already filtered above
          else {
            saleQuantity = parseInt(report.quantity) || 0;
            const price = parseFloat(report.price) || parseFloat(report.unitPrice) || 0;
            saleValue = saleQuantity * price;
 }
          
          dateGroups[date].sale += saleQuantity;
          totalSold += saleQuantity;
          soldValue += saleValue;
 }
 });
      
      // Convert to array and sort by date
      const chartData = Object.values(dateGroups).sort((a, b) => 
        new Date(a.date) - new Date(b.date)
 );
      
      // Update state with processed data
      setGraphData(chartData);
      setFilteredData(chartData);
      setTotalItemsAdded(totalAdded);
      setTotalItemsSold(totalSold);
      setTotalAddedValue(addedValue);
      setTotalSoldValue(soldValue);
 } catch (error) {
      console.error('Error processing reports data:', error);
 } finally {
      setIsFiltering(false);
 }
 }, [allReports, selectedStore, selectedItem, selectedBrand, selectedCategoryKey, startDate, endDate, dateFilterActive]);

  const handleStoreChange = (e) => {
    setSelectedStore(e.target.value);
 };

  const handleItemChange = (e) => {
    setSelectedItem(e.target.value);
 };

  const handleBrandChange = (e) => {
    setSelectedBrand(e.target.value);
 };

  const handleCategoryChange = (e) => {
    setSelectedCategoryKey(e.target.value);
 };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setDateFilterActive(true);
 };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setDateFilterActive(true);
 };
 
  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
 };

 const handlePatternAnalysisOpen = () => {
  setIsPatternAnalysisOpen(true);
};

const handlePatternAnalysisClose = () => {
  setIsPatternAnalysisOpen(false);
};

  const resetFilters = () => {
    setSelectedStore('all');
    setSelectedItem('all');
    setSelectedBrand('all');
    setSelectedCategoryKey('all_categories');
    
    // Reset date filter
    const today = new Date();
    const endDateStr = today.toISOString().split('T')[0];
    
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const startDateStr = thirtyDaysAgo.toISOString().split('T')[0];
    
    setStartDate(startDateStr);
    setEndDate(endDateStr);
    setDateFilterActive(false);
 };

  const currentDateFilter = () => {
    const today = new Date();
    const endDateStr = today.toISOString().split('T')[0];
    
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const startDateStr = thirtyDaysAgo.toISOString().split('T')[0];
    
    setStartDate(startDateStr);
    setEndDate(endDateStr);
    setDateFilterActive(false);

 };

  // Memoize summary data to prevent recalculations
  const formatSummaryData = useMemo(() => {
    return (data) => `
${translate('inventory_summary_document')}:

${translate('total_items_in_inventory')}: ${data.totalItems}
${translate('low_stock_items_colon')}: ${data.lowStockItems}
${translate('expired_items_colon')}: ${data.expiredItems}
${translate('total_inventory_value_colon')}: $${data.totalValue.toFixed(2)}

${translate('summary_overview')}
`.trim();
 }, [translate]);

  // Memoize trend analysis to prevent recalculations
  const analyzeTrend = useMemo(() => {
    return (data) => {
      if (data.length < 2) return translate('insufficient_data');
    
      const firstPoint = data[0];
      const lastPoint = data[data.length - 1];
      const addedTrend = lastPoint.added - firstPoint.added;
      const saleTrend = lastPoint.sale - firstPoint.sale;
    
      let trendAnalysis = '';
    
      if (addedTrend > 0) {
        trendAnalysis += translate('added_upward_trend') + ' ';
 } else if (addedTrend < 0) {
        trendAnalysis += translate('added_downward_trend') + ' ';
 } else {
        trendAnalysis += translate('added_stable_trend') + ' ';
 }
    
      if (saleTrend > 0) {
        trendAnalysis += translate('sale_upward_trend') + ' ';
 } else if (saleTrend < 0) {
        trendAnalysis += translate('sale_downward_trend') + ' ';
 } else {
        trendAnalysis += translate('sale_stable_trend') + ' ';
 }
    
      return trendAnalysis;
 };
 }, [translate]);

  // Memoize analytics data format to prevent recalculations
  const formatAnalyticsData = useMemo(() => {
    return (data) => {
      if (!data || data.length === 0) return translate('no_analytics_data');
    
      const dataPoints = data.map(point => {
        return `${translate('date')}: ${point.date}
${translate('items_added')}: ${point.added || 0}
${translate('items_sold')}: ${point.sale || 0}`;
 }).join('\n\n');
    
      const totalAdded = data.reduce((sum, point) => sum + (point.added || 0), 0);
      const totalSold = data.reduce((sum, point) => sum + (point.sale || 0), 0);
      const averageAdded = totalAdded / data.length;
      const averageSold = totalSold / data.length;
    
      return `
${translate('inventory_analytics_overview')}:

${translate('detailed_activity_data')}:
${dataPoints}

${translate('summary_statistics')}:
- ${translate('total_items_added')}: ${totalAdded}
- ${translate('total_items_sold')}: ${totalSold}
- ${translate('average_items_added_per_day')}: ${averageAdded.toFixed(2)}
- ${translate('average_items_sold_per_day')}: ${averageSold.toFixed(2)}

${translate('trend_analysis')}:
${analyzeTrend(data)}
`.trim();
 };
 }, [translate, analyzeTrend]);

  // Memoized components
  const CustomTooltip = useMemo(() => {
    return ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="label">{`${translate('date')}: ${new Date(label).toLocaleDateString()}`}</p>
            <p className="info added">{`${translate('items_added')}: ${payload[0].value || 0}`}</p>
            <p className="info sale">{`${translate('items_sold')}: ${payload[1].value || 0}`}</p>
          </div>
 );
 }
      return null;
 };
 }, [translate]);

  // Memoized Analytics Chart Component
  const AnalyticsContent = useMemo(() => {
    return ({ data = filteredData }) => (
      <div className="analytics-chart-container">
        <ResponsiveContainer width="100%" height={300}>
          {chartType === 'Line Chart' ? (
            <LineChart data={data}>
              <defs>
                <linearGradient id="colorAdded" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#008a47" stopOpacity={0.1}/>
                  <stop offset="95%" stopColor="#008a47" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="colorSale" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#046ed1" stopOpacity={0.1}/>
                  <stop offset="95%" stopColor="#046ed1" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
              <XAxis 
                dataKey="date" 
                stroke="#333"
                tick={{ fill: '#333', fontSize: 12 }}
                tickFormatter={(tick) => new Date(tick).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
              />
              <YAxis 
                stroke="#333"
                tick={{ fill: '#333', fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend 
                verticalAlign="top" 
                height={36}
                iconType="circle"
              />
              <Area 
                type="monotone" 
                dataKey="added" 
                stroke="#008a47" 
                fillOpacity={1}
                fill="url(#colorAdded)" 
                name={translate('added')}
              />
              <Area 
                type="monotone" 
                dataKey="sale" 
                stroke="#046ed1" 
                fillOpacity={1}
                fill="url(#colorSale)" 
                name={translate('sold')}
              />
              <Line 
                type="monotone" 
                dataKey="added" 
                stroke="#008a47" 
                strokeWidth={3}
                dot={{ r: 4, strokeWidth: 2, fill: '#fff' }}
                activeDot={{ r: 6, strokeWidth: 2, fill: '#fff' }}
                name={translate('added')}
              />
              <Line 
                type="monotone" 
                dataKey="sale" 
                stroke="#046ed1" 
                strokeWidth={3}
                dot={{ r: 4, strokeWidth: 2, fill: '#fff' }}
                activeDot={{ r: 6, strokeWidth: 2, fill: '#fff' }}
                name={translate('sold')}
              />
            </LineChart>
          ) : (
            <BarChart data={data} barSize={40}>
              <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
              <XAxis 
                dataKey="date" 
                stroke="#333"
                tick={{ fill: '#333', fontSize: 12 }}
                tickFormatter={(tick) => new Date(tick).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
              />
              <YAxis 
                stroke="#333"
                tick={{ fill: '#333', fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend 
                verticalAlign="top" 
                height={36}
                iconType="circle"
              />
              <Bar 
                dataKey="added" 
                fill="#008a47" 
                name={translate('added')}
                radius={[4, 4, 0, 0]}
              />
              <Bar 
                dataKey="sale" 
                fill="#046ed1" 
                name={translate('sold')}
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
    );
  }, [filteredData, translate, CustomTooltip, chartType]);
  // Memoized Filter Components
  const DateRangeFilter = useMemo(() => {
    return () => (
      <div className="date-range-filter">
        <div className="date-filter-header">
          <h4>{translate('date_range')}</h4>
        </div>
        <div className="date-inputs">
          <div className="date-input-group">
            <label htmlFor="start-date">{translate('from')}:</label>
            <input
              type="date"
              id="start-date"
              value={startDate}
              onChange={handleStartDateChange}
              className="date-input"
            />
          </div>
          <div className="date-input-group">
            <label htmlFor="end-date">{translate('to')}:</label>
            <input
              type="date"
              id="end-date"
              value={endDate}
              onChange={handleEndDateChange}
              className="date-input"
            />
          </div>
          <button 
            className="apply-date-filter-btn"
            onClick={currentDateFilter}
            disabled={isFiltering || !startDate || !endDate}
          >
            {translate('reset')}
          </button>
        </div>
      </div>
 );
 }, [startDate, endDate, isFiltering, translate, handleStartDateChange, handleEndDateChange, currentDateFilter]);

  // Memoized Filter Controls
  const FilterControls = useMemo(() => {
    return () => (
      <div className="filter-controls">
        {/* Chart Type Selector
        <div className="chart-type-selector">
          <label htmlFor="chart-type">{translate('chart_type')}:</label>
          <select
            id="chart-type"
            value={chartType}
            onChange={handleChartTypeChange}
            className="filter-select"
          >
            <option value="Line Chart">Line Chart</option>
            <option value="Bar Chart">Bar Chart</option>
          </select>
        </div> */}
        
        {/* Date Range Filter */}
        <DateRangeFilter />
        
        <div className="filter-grid">
          <div className="filter-group">
            <label htmlFor="store-filter">{translate('store')}:</label>
            <select 
              id="store-filter" 
              value={selectedStore} 
              onChange={handleStoreChange}
              className="filter-select"
              disabled={isFiltering}
            >
              <option value="all">{translate('all_stores')}</option>
              {stores.map((store, index) => (
                <option key={index} value={store}>{store}</option>
 ))}
            </select>
          </div>
          
          <div className="filter-group">
            <label htmlFor="item-filter">{translate('item')}:</label>
            <select 
              id="item-filter" 
              value={selectedItem} 
              onChange={handleItemChange}
              className="filter-select"
              disabled={isFiltering}
            >
              <option value="all">{translate('all_items')}</option>
              {uniqueItems.map((item, index) => (
                <option key={index} value={item}>{item}</option>
 ))}
            </select>
          </div>
        
          <div className="filter-group">
            <label htmlFor="brand-filter">{translate('brand')}:</label>
            <select 
              id="brand-filter" 
              value={selectedBrand} 
              onChange={handleBrandChange}
              className="filter-select"
              disabled={isFiltering}
            >
              <option value="all">{translate('all_brands')}</option>
              {uniqueBrands.map((brand, index) => (
                <option key={index} value={brand}>{brand}</option>
 ))}
            </select>
          </div>
          
          <div className="filter-group">
            <label htmlFor="category-filter">{translate('category')}:</label>
            <select 
              id="category-filter" 
              value={selectedCategoryKey} 
              onChange={handleCategoryChange}
              className="filter-select"
              disabled={isFiltering}
            >
              {uniqueCategoryKeys.map(categoryKey => (
                <option key={categoryKey} value={categoryKey}>
                  {translate(categoryKey)}
                </option>
 ))}
            </select>
          </div>
        </div>
        
        <div className="active-filters">
          {selectedStore !== 'all' && (
            <span className="filter-tag">
              {translate('store')}: {selectedStore}
            </span>
 )}
          {selectedItem !== 'all' && (
            <span className="filter-tag">
              {translate('item')}: {selectedItem}
            </span>
 )}
          {selectedBrand !== 'all' && (
            <span className="filter-tag">
              {translate('brand')}: {selectedBrand}
            </span>
 )}
          {selectedCategoryKey !== 'all_categories' && (
            <span className="filter-tag">
              {translate('category')}: {translate(selectedCategoryKey)}
            </span>
 )}
        </div>

        <button 
          onClick={resetFilters}
          className="reset-filters-btn"
          disabled={isFiltering}
        >
          {translate('reset_filters')}
        </button>
      </div>
 );
 }, [
    translate, 
    selectedStore, 
    selectedItem, 
    selectedBrand, 
    selectedCategoryKey, 
    stores, 
    uniqueItems, 
    uniqueBrands, 
    uniqueCategoryKeys, 
    isFiltering, 
    handleStoreChange, 
    handleItemChange, 
    handleBrandChange, 
    handleCategoryChange,
    resetFilters,
    DateRangeFilter,
    chartType,
    handleChartTypeChange
 ]);

  // Memoized Analytics Stats Component
  const AnalyticsStats = useMemo(() => {
    return () => (
      <div className="analytics-stats">
        <div className="stats-grid">
          <div className="stat-card">
            <div className="stat-title">{translate('total_items_added')}</div>
            <div className="stat-value">{totalItemsAdded.toLocaleString()}</div>
          </div>
          
          <div className="stat-card">
            <div className="stat-title">{translate('total_items_sold')}</div>
            <div className="stat-value">{totalItemsSold.toLocaleString()}</div>
          </div>
          
          <div className="stat-card">
            <div className="stat-title">{translate('total_added_value')}</div>
            <div className="stat-value">
            <span className="currency-symbol">{translate('currency_symbol')}</span>
              {totalAddedValue.toFixed(2)}</div>
          </div>
          
          <div className="stat-card">
            <div className="stat-title">{translate('total_sold_value')}</div>
            <div className="stat-value">
            <span className="currency-symbol">{translate('currency_symbol')}</span>
              {totalSoldValue.toFixed(2)}</div>
          </div>
        </div>
      </div>
 );
 }, [translate, totalItemsAdded, totalItemsSold, totalAddedValue, totalSoldValue]);

  // Calculate inventory stats
  const totalItems = items.length;
  const lowStockItems = items.filter(item => item.quantity < item.lowStockThreshold).length;
  const expiredItems = items.filter(item => {
    const expirationDate = item.expiryDateTo ? new Date(item.expiryDateTo) : null;
    return expirationDate && expirationDate < new Date() && !item.noExpiryDate;
 }).length;
  const totalValue = items.reduce((sum, item) => sum + (item.quantity * (item.price || 0)), 0);

  // Handle closing the expanded analytics modal properly
  const handleCloseExpandedView = () => {
    setIsExpanded(false);
 };

  if (loading) {
    return (
      <LoadingAnimation 
        onLoadComplete={() => {
          setLoading(false);
 }} 
      />
 );
 }

  if (error) {
    return <div className="error">{error}</div>;
 }

  return (
    <div className="dashboard-container loaded">
      <main>
        <h1 className="dashboard-title"></h1>
        
        <section 
          className="inventory-summary relative"
          onMouseEnter={() => isDaxEnabled && setHoveredSection('summary')}
          onMouseLeave={() => setHoveredSection(null)}
        >
          <h3 className="h3-dashboard">{translate('inventory_summary')}</h3>
          <hr/>
          <div className="summary-grid">
            <div className="summary-item">
              <span className="summary-label">{translate('total_items')}:</span>
              <span className="summary-value">{totalItems}</span>
            </div>
            <div className="summary-item">
              <span className="summary-label">{translate('low_stock_items')}:</span>
              <span className="summary-value">{lowStockItems}</span>
            </div>
            <div className="summary-item">
              <span className="summary-label">{translate('expired_items')}:</span>
              <span className="summary-value">{expiredItems}</span>
            </div>
            <div className="summary-item">
              <span className="summary-label">{translate('total_inventory_value')}:</span>
              <span className="summary-value">
                <span className="currency-symbol">{translate('currency_symbol')}</span>
                {totalValue.toFixed(2)}
              </span>
            </div>
          </div>

          {isDaxEnabled && hoveredSection === 'summary' && (
            <DaxHoverOverlay 
              onClick={() => handleDaxClick(formatSummaryData({
                totalItems,
                lowStockItems,
                expiredItems,
                totalValue
 }))}
            />
 )}
        </section>

        <section 
          className="inventory-analytics relative"
          onMouseEnter={() => isDaxEnabled && setHoveredSection('analytics')}
          onMouseLeave={() => setHoveredSection(null)}
        >
          <div className="analytics-header">
            <h3 className="h3-dashboard">{translate('inventory_analytics')}</h3>
            <div className="analytics-controls">
              <p 
                onClick={() => setIsExpanded(true)} 
                className="more-analytics-btn"
              >
                {translate('analytics')} 
              </p>
            </div>
          </div>
          <hr/>
          <AnalyticsContent />

          {isDaxEnabled && hoveredSection === 'analytics' && (
            <DaxHoverOverlay 
              onClick={() => handleDaxClick(formatAnalyticsData(filteredData))}
            />
 )}
        </section>
      </main>
      
      {isExpanded && (
        <div className="expanded-analytics-overlay">
          <div className="expanded-analytics-content">
            <div className="expanded-header">
              <h3 className='h3-dashboard'>{translate('detailed_inventory_analytics')}</h3>
              <div className="expanded-header-actions">
              <div className="chart-selector-wrapper">
                <select
                  id="chart-type-expanded"
                  value={chartType}
                  onChange={handleChartTypeChange}
                  className="chart-type-select"
                >
                  <option value="Line Chart">{translate('line_chart')}</option>
                  <option value="Bar Chart">{translate('bar_chart')}</option>
                </select>
              </div>
              
              {/* Add Pattern Analysis Button */}
              <button 
                onClick={handlePatternAnalysisOpen} 
                className="pattern-analysis-btn"
              >
                {translate('pattern_analysis')}
              </button>

              <div className="back-button-container">
                <button onClick={handleCloseExpandedView} className="back-button">
                  {translate('close')}
                </button>
              </div>
            </div>
            </div>
            <hr/>
            
            {/* Chart */}
            <div className="expanded-chart">
              <AnalyticsContent />
            </div>

             {/* Filter controls */}
             <FilterControls />
            
            {/* Analytics Statistics */}
            <AnalyticsStats />
          </div>
        </div>
 )}

{isPatternAnalysisOpen && (
  <PatternAnalysis
    isOpen={isPatternAnalysisOpen}
    onClose={handlePatternAnalysisClose}
    allReports={allReports}
    translate={translate}
    selectedStore={selectedStore}
    selectedItem={selectedItem}
    selectedBrand={selectedBrand}
    selectedCategoryKey={selectedCategoryKey}
    startDate={startDate}
    endDate={endDate}
    stores={stores}
    uniqueItems={uniqueItems}
    uniqueBrands={uniqueBrands}
    uniqueCategoryKeys={uniqueCategoryKeys}
  />
)}

    </div>
 );
};

export default Dashboard;