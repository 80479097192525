import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import './Profile.css';

const BillingPlans = ({ onBack }) => {
  const { user, updatePlan } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(user?.customerPlan || 'free');
  const [currentPlan, setCurrentPlan] = useState(user?.customerPlan || 'free');
  const [billingCycle, setBillingCycle] = useState('monthly');
  const confirmButtonRef = useRef(null);

  const plans = [
    {
      id: 'pro',
      name: 'Pro',
      price: billingCycle === 'monthly' ? '$49.99' : '$479.90',
      originalPrice: billingCycle === 'monthly' ? '$49.99' : '$599.88',
      discount: billingCycle === 'monthly' ? '' : '(Save up to 20%)',
      features: [
        { title: 'Target:', desc: 'Growing small businesses' },
        { title: 'Inventory Management:', desc: 'Up to 1,000 products' },
        { title: 'Order Management:', desc: '500 orders/month' },
        { title: 'Financial Management:', desc: 'Full accounting and budgeting, no credit tools' },
        { title: 'DaxAI Assistant:', desc: '500 queries/day' },
        { title: 'Inventory Analytics:', desc: 'Advanced filters, exportable data' },
        { title: 'Users:', desc: 'Up to 3 users' },
        { title: 'Support:', desc: '24 / 7' }
      ],
      isPopular: true
    },
    {
      id: 'business',
      name: 'Business',
      price: billingCycle === 'monthly' ? '$249.99' : '$2,399.90',
      originalPrice: billingCycle === 'monthly' ? '$249.99' : '$2,999.88',
      discount: billingCycle === 'monthly' ? '' : '(Save up to 20%)',
      features: [
        { title: 'Target:', desc: 'Medium-to-large businesses with high-volume operations' },
        { title: 'Inventory Management:', desc: '10,000 products' },
        { title: 'Order Management:', desc: '5,000 orders/month' },
        { title: 'Financial Management:', desc: 'Full suite (accounting, budgeting, credit management)' },
        { title: 'DaxAI Assistant:', desc: '5000 queries/day' },
        { title: 'Inventory Analytics:', desc: 'Predictive analytics, automated alerts' },
        { title: 'Users:', desc: 'Up to 15 users' },
        { title: 'Support:', desc: '24 / 7' }
      ],
      isPopular: false
    },
    {
      id: 'enterprise',
      name: 'Enterprise',
      price: 'Custom',
      features: [
        { title: 'Target:', desc: 'Large enterprises with custom needs' },
        { title: 'Inventory Management:', desc: 'Unlimited' },
        { title: 'Order Management:', desc: 'Unlimited' },
        { title: 'Financial Management:', desc: 'Full suite + custom integrations (ERP, CRM)' },
        { title: 'DaxAI Assistant:', desc: 'Unlimited queries' },
        { title: 'Inventory Analytics:', desc: 'Fully customizable dashboards, ML-driven forecasting' },
        { title: 'Users:', desc: 'Unlimited' },
        { title: 'Support:', desc: '24 / 7' }
      ],
      isPopular: false
    }
  ];

  const freePlan = {
    id: 'free',
    name: 'Free',
    price: '$0',
    features: [
      { title: 'Target:', desc: 'Individual users, startups testing the system' },
      { title: 'Inventory Management:', desc: 'Up to 50 products' },
      { title: 'Order Management:', desc: '50 orders/month' },
      { title: 'Financial Management:', desc: 'Basic reports only (no accounting or credit tools)' },
      { title: 'DaxAI Assistant:', desc: 'Limited access (50 queries/day)' },
      { title: 'Inventory Analytics:', desc: 'Basic charts only' },
      { title: 'Users:', desc: '1 user' },
      { title: 'Support:', desc: '24 / 7' }
    ],
    isPopular: false
  };

  // This is an improved scrolling function that works more reliably
  const scrollToConfirmButton = () => {
    if (confirmButtonRef.current) {
      const yOffset = -20; // Add some offset for better visibility
      const element = confirmButtonRef.current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    // Check if the selected plan is different from current plan
    if (selectedPlan !== currentPlan && confirmButtonRef.current) {
      // Scroll to the confirm button when component mounts or selected plan changes
      const scrollToButton = () => {
        const yOffset = -20; // Add some offset for better visibility
        const element = confirmButtonRef.current;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        
        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });
      };
      
      // Small delay to ensure DOM is updated
      const timeoutId = setTimeout(scrollToButton, 300);
      
      // Clean up timeout on unmount
      return () => clearTimeout(timeoutId);
    }
  }, [selectedPlan, currentPlan]);

  const handleSelectPlan = (planId) => {
    setSelectedPlan(planId);
    if (planId !== currentPlan) {
      // Add a small delay to ensure the DOM is updated before scrolling
      setTimeout(scrollToConfirmButton, 100);
    }
  };

  const toggleBillingCycle = () => {
    setBillingCycle(billingCycle === 'monthly' ? 'annual' : 'monthly');
  };

  const handleChangePlan = async () => {
    if (selectedPlan === currentPlan) {
      setError('You are already on this plan');
      setTimeout(() => setError(''), 3000);
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      // Use the updatePlan function from AuthContext
      const result = await updatePlan(selectedPlan);
      
      if (result) {
        setCurrentPlan(selectedPlan);
        setSuccess('Your plan has been updated successfully!');
        setTimeout(() => setSuccess(''), 3000);
      } else {
        setError('Failed to update plan. Please try again.');
      }
    } catch (error) {
      console.error('Error updating plan:', error);
      setError(error.message || 'Failed to update plan. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div >
      <div className="billing-plans-header">
        <h2 className="billing-plans-title">Billing & Plans</h2>
        <div className="back-button-container">
          <button onClick={onBack} className="back-button">
            <span className="back-arrow">←</span> Back
          </button>
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}

      <div className="billing-cycle-toggle">
        <span className={`cycle-option ${billingCycle === 'monthly' ? 'active' : ''}`}>Monthly</span>
        <label className="toggle-switch">
          <input 
            type="checkbox" 
            checked={billingCycle === 'annual'} 
            onChange={toggleBillingCycle} 
          />
          <span className="toggle-slider round"></span>
        </label>
        <span className={`cycle-option ${billingCycle === 'annual' ? 'active' : ''}`}>Annual {billingCycle === 'annual' && <span className="discount-badge">Save up to 20%</span>}</span>
      </div>

      {/* Main Plans Grid */}
      <div className="pricing-plans">
        {plans.map((plan) => (
          <div 
            key={plan.id} 
            className={`pricing-plan ${plan.id} ${selectedPlan === plan.id ? 'selected' : ''}`}
            onClick={() => handleSelectPlan(plan.id)}
          >
            {plan.isPopular && <div className="popular-badge">Most popular</div>}
            {currentPlan === plan.id && <div className="current-plan-tag">Current</div>}
            <div className={`plan-header ${plan.id}`}>
              <h3 className="plan-name">{plan.name}</h3>
              <div className="price">{plan.price}</div>
              {plan.discount && <div className="discount">{plan.discount}</div>}
            </div>
            <div className="plan-features">
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index}>
                    <strong>{feature.title}</strong> {feature.desc}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {/* Free Plan Section */}
      <div className="free-plan-section">
        <div 
          className={`pricing-plan free ${selectedPlan === 'free' ? 'selected' : ''}`}
          onClick={() => handleSelectPlan('free')}
        >
          {currentPlan === 'free' && <div className="current-plan-tag">Current</div>}
          <div className="plan-header free">
            <h3 className="plan-name">{freePlan.name}</h3>
            <div className="price">{freePlan.price}</div>
          </div>
          <div className="plan-features">
            <ul>
              {freePlan.features.map((feature, index) => (
                <li key={index}>
                  <strong>{feature.title}</strong> {feature.desc}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="billing-action-buttons" ref={confirmButtonRef}>
        <button 
          className="cancel-button"
          onClick={onBack}
        >
          Cancel
        </button>
        <button 
          className={`confirm-button ${selectedPlan === currentPlan ? 'disabled' : ''}`}
          onClick={handleChangePlan}
          disabled={loading || selectedPlan === currentPlan}
        >
          {loading ? 'Processing...' : 'Confirm'}
        </button>
      </div>
    </div>
  );
};

export default BillingPlans;