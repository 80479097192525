
export const translations = {
  en: {
    products: 'Products',
    market_research: 'Market Research',
    equipment: 'Equipment',
    learning: 'Learning',
    community: 'Community',
    partners: 'Partners',
    about: 'About',
    login: 'Login',
    contact_us: 'Contact us',
    ai_innovations: 'AI innovations at your company',
    discover_text: 'Discover how the latest in generative AI capabilities in our solutions portfolio can help you revolutionize your business model strategy.',
    learn_more: 'Learn more',
    choose_region: 'Choose your Region',
    search_country: 'Search for a country',
    inventory_management: 'Inventory Management',
    supply_chain_management: 'Supply Chain Management',
    financial_management: 'Financial Management',
    human_capital_management: 'Human Capital Management',
    crm_and_customer_experience: 'CRM and Customer Experience',
    view_products_az: 'View Products A-Z',
    by_industry: 'By Industry',
    by_category: 'By Category',
    automotive: 'Automotive',
    banking: 'Banking',
    consumer_products: 'Consumer Products',
    healthcare: 'Healthcare',
    retail: 'Retail',
    view_all_industries: 'View All Industries',
    business_technology_platform: 'Business Technology Platform',
    application_development_and_automation: 'Application Development and Automation',
    data_and_analytics: 'Data and Analytics',
    extended_planning_and_analysis: 'Extended Planning and Analysis',
    integration: 'Integration',

    // Checkout translations
  checkout_title: 'Transaction Checkout',
  return_processing: 'Return Processing',
  total_amount: 'Total Amount',
  items: 'Items',
  complete_transaction: 'Complete Transaction',
  process_return: 'Process Return',
  switch_to_return: 'Switch to Return',
  switch_to_checkout: 'Switch to Checkout',
  cancel: 'Cancel',
  enter_barcode: 'Enter Barcode',
  enter_transaction_code: 'Enter Transaction Code',
  search: 'Search',
  add: 'Add',
  search_transaction: 'Search Transaction',
  selected_item: 'Selected Item',
  available: 'Available',
  transaction_code: 'Transaction Code',
  transaction_date: 'Transaction Date',
  inventory: 'Inventory',
  no_items_added: 'No items added',
  remove: 'Remove',
  price: 'Price',
  quantity: 'Quantity',
  barcode: 'Barcode',
  product: 'Product',
  brand: 'Brand',
  weight: 'Weight',
  volume: 'Volume',
  add_to_cart: 'Add to Cart',
  no_items_found: 'No items found',
  search_inventory: 'Search inventory',
  all_categories: 'All Categories',
  all_stores: 'All Stores',
  all_brands: 'All Brands',
  sort_by: 'Sort By',
  alphabetical: 'Alphabetical',
  date_added: 'Date Added',
  expiry_date: 'Expiry Date',
  ascending: 'Ascending',
  descending: 'Descending',
  close: 'Close',
  currency_symbol: '$',

   // Additional inventory page translations
   showing: 'Showing',
   of: 'of',
   items: 'items',
   only_items_in_stock: 'Only Items In Stock',
   no_expiry: 'N/A',
   inventory_management: 'Inventory Management',
   low_stock: 'Low Stock',
   out_of_stock: 'Out of Stock',
   in_stock: 'In Stock',
  
  // Category translations
  category: 'Category',
  groceries: 'Groceries',
  beverages: 'Beverages',
  household_products: 'Household Products',
  personal_care_items: 'Personal Care Items',
  snacks: 'Snacks',
  frozen_foods: 'Frozen Foods',
  health_and_wellness_products: 'Health and Wellness Products',
  electronics: 'Electronics',
  pet_supplies: 'Pet Supplies',
  office_supplies: 'Office Supplies',
  raw_materials: 'Raw Materials',
  
  // Error messages
  error_no_item_selected: 'No item selected',
  error_invalid_price: 'Please enter a valid price',
  error_invalid_quantity: 'Please enter a valid quantity',
  error_cart_empty: 'Cart is empty',
  error_no_transaction: 'No transaction found to return',
  error_invalid_transaction_code: 'Please enter a valid 12-digit transaction code',
  error_barcode_required: 'Please enter a barcode',
  
  // Success messages
  success_return_processed: 'Return processed successfully',
  success_transaction_completed: 'Transaction completed successfully',
  // Dashboard translations
  dashboard_title: 'Dashboard',
  inventory_summary: 'Inventory Summary',
  total_items: 'Total Items',
  low_stock_items: 'Low Stock Items',
  expired_items: 'Expired Items',
  total_inventory_value: 'Total Inventory Value',
  inventory_analytics: 'Inventory Analytics',
  analytics: 'Analytics',
  detailed_inventory_analytics: 'Inventory Analytics',
  back_to_dashboard: 'Back to Dashboard',
  date: 'Date',
  items_added: 'Items Added',
  items_sold: 'Items Sold',
  sold: 'Sold',
  added: 'Added',
  
  // Inventory summary document
  inventory_summary_document: 'Inventory Summary Document',
  total_items_in_inventory: 'Total Items in Inventory',
  low_stock_items_colon: 'Low Stock Items',
  expired_items_colon: 'Expired Items',
  total_inventory_value_colon: 'Total Inventory Value',
  summary_overview: 'This summary provides a comprehensive overview of your current inventory status.',
  
  // Inventory analytics document
  inventory_analytics_overview: 'Inventory Analytics Overview',
  detailed_activity_data: 'Detailed Activity Data',
  summary_statistics: 'Summary Statistics',
  total_items_added: 'Total Items Added',
  total_items_sold: 'Total Items Sold',
  average_items_added_per_day: 'Average Items Added per Day',
  average_items_sold_per_day: 'Average Items Sold per Day',
  trend_analysis: 'Trend Analysis',
  
  // Trend analysis texts
  added_upward_trend: 'Items being added are showing an upward trend.',
  added_downward_trend: 'Items being added are showing a downward trend.',
  added_stable_trend: 'Items being added are relatively stable.',
  removed_upward_trend: 'Items being removed are showing an upward trend.',
  removed_downward_trend: 'Items being removed are showing a downward trend.',
  removed_stable_trend: 'Items being removed are relatively stable.',
  insufficient_data: 'Insufficient data for trend analysis.',
  no_analytics_data: 'No analytics data available.',
    
  // Sidebar navigation
  dashboard: 'Dashboard',
  inventory: 'Inventory',
  add: 'Add',
  checkout: 'Checkout',
  take_out: 'Take Out',
  orders: 'Orders',
  reports: 'Reports',
  security: 'Security',
  profile: 'Profile',
  finances: 'Finances',
  view_analytics: 'View Analytics',
  

  // User info
  admin: 'Admin',
  email_copied: 'Email Copied!',

  // How Daxtop Works section
  how_daxtop_works: 'How Daxtop works',
  become_goto_store: 'Become the go-to store.',
  grow_revenue_text: 'Grow your revenue, keep staff happy and provide a seamless multichannel experience.',
  learn_more_button: 'Learn more',
  download_install: 'Download and install',
  configure: 'Configure',
  increase_store_efficiency: 'Increase Store Efficiency',
  
  // Download and install content
  daxtop_is_desktop_app: 'Daxtop is a desktop app for Mac, Windows, and Linux. When you install Daxtop on your computer, it creates virtual environments named Daxtop Store and Daxtop Inventory.',
  
  // Configure content
  configure_daxtop_store: 'Configure your Daxtop Store to work with your preferred store management tools. Setup takes just a minute and provides enhanced POS system.',
  
  // Increase efficiency content
  automated_workflows: 'With automated workflows, Daxtop cuts down on routine tasks and gives you better access to real-time store data, so you can focus on what really matters—running your business.',


  // User info
  admin: 'Admin',
  email_copied: 'Email Copied!',

   // DaxAI page titles and main elements
   recent_chats: 'Recent Chats',
   start_new_chat: 'Start new chat',
   ask_anything: 'Ask anything....',
   verification_notice: 'DAX Agent can make mistakes. Please verify responses.',
   share: 'Share',
   rename: 'Rename',
   save: 'Save',
   delete: 'Delete',
   new_chat: 'New Chat',
   
   // Thinking and generating states
   thinking_deeply: 'Thinking deeply...',
   analyzing_inventory_data: 'Analyzing inventory data...',
   processing_analytics: 'Processing analytics...',
   analyzing_document: 'Analyzing document...',
   
   // Document and analysis related
   document_preview: 'Document Preview',
   inventory_summary: 'Inventory Summary',
   inventory_analytics_overview: 'Inventory Analytics Overview',
   explain_in_armenian: 'Please explain this document in Armenian',
   document_content: 'Document Content',
   user_question: 'User Question',
   
   // Error messages
   error_chat_creation: 'Failed to create chat',
   error_chat_update: 'Failed to update chat',
   error_chat_delete: 'Failed to delete chat',
   error_chat_rename: 'Failed to rename chat',
   error_user_data: 'Failed to load user data',
   error_generic: "I'm sorry, I encountered an error. Please try again.",
   error_rename: 'Failed to rename chat. Please try again.',
   error_load_chat: 'Failed to load chats',
   error_save_chat: 'Failed to save chats',
   // Chat sidebar elements
   add_data: 'Add Data',
   inventory: 'Inventory',
   orders: 'Orders',
   reports: 'Reports',


   // Page titles
  edit_item: 'Edit Item',
  add_new_item: 'Add New Item',
  speed_inventory: 'Speed Inventory',
  add_quickly_barcode: 'Add items quickly with barcode scanning',
  
  // Form fields
  store: 'Store',
  select_store: 'Select Store',
  barcode_upc: 'Barcode/UPC (Scan or Enter)',
  scan_enter_barcode: 'Scan or enter barcode',
  product_name: 'Product Name',
  name_of_product: 'Name of the product',
  brand: 'Brand',
  name_of_brand: 'Name of the Brand',
  category: 'Category',
  select_category: 'Select Category',
  quantity: 'Quantity',
  selling_price: 'Selling Price',
  selling_price_per_unit: 'Selling Price per unit',
  cost_price: 'Cost Price',
  cost_price_per_unit: 'Cost Price per unit',
  low_stock_threshold: 'Low Stock Threshold',
  sku: 'SKU',
  sku_full: 'SKU (Stock Keeping Unit)',
  weight_unit: 'Weight Unit',
  select_weight_unit: 'Select Weight Unit',
  weight: 'Weight',
  weight_not_applicable: 'Weight not applicable',
  weight_of_unit: 'Weight of one unit in',
  volume_unit: 'Volume Unit',
  select_volume_unit: 'Select Volume Unit',
  volume: 'Volume',
  volume_not_applicable: 'Volume not applicable',
  volume_of_unit: 'Volume of one unit in',
  expiry_date_from: 'Expiry Date (From)',
  expiry_date_to: 'Expiry Date (To)',
  no_expiry_date: 'No Expiry Date',
  description: 'Description',
  description_optional: 'Description (optional)',
  
  // Buttons
  register_new_item: 'Register New Item',
  quick_mode: 'Quick Mode',
  on: 'ON',
  off: 'OFF',
  update_item: 'Update Item',
  add_item: 'Add Item',
  cancel_edit: 'Cancel Edit',
  reset_form: 'Reset Form',
  toggle_expiry: 'Toggle Expiry',
  save_current_template: 'Save Current As Template',
  load: 'Load',
  delete: 'Delete',
  
  // Templates section
  item_templates: 'Item Templates',
  no_templates: 'No saved templates. Create some by saving the current form.',
  
  // Numpad and sidebar
  numeric_keypad: 'Numeric Keypad',
  active_field: 'Active field',
  none: 'None',
  required_fields: 'Required fields',
  all_completed: 'All completed',
  
  // Success and error messages
  template_found: 'Template found! Details filled automatically.',
  item_updated_success: 'Item "{0}" updated successfully',
  item_added_success: 'Item "{0}" added successfully',
  item_deleted_success: 'Item "{0}" deleted successfully',
  template_saved_success: 'Template "{0}" saved successfully',
  no_previous_item: 'No previous item to duplicate',
  failed_save_item: 'Failed to save item',
  failed_delete_item: 'Failed to delete item',
  failed_fetch_items: 'Failed to fetch items',
  
  // Confirmation dialogs
  confirm_update_item: 'Are you sure you want to update this item?',
  confirm_add_item: 'Are you sure you want to add this item to inventory?',
  confirm: 'Confirm',
  cancel: 'Cancel',
  enter_barcode_duplicate: 'Enter barcode for the duplicate item (or leave empty):',
  enter_template_name: 'Enter a name for this template:',
  
  // Weight units
  kilogram: 'Kilogram (kg)',
  gram: 'Gram (g)',
  ounce: 'Ounce (oz)',
  pound: 'Pound (lb)',
  na: 'N/A',
  
  // Volume units
  liter: 'Liter (L)',
  milliliter: 'Milliliter (mL)',
  fluid_ounce: 'Fluid Ounce (fl oz)',
  cubic_meter: 'Cubic Meter (m³)',

  // ItemRegister page titles
  barcode_registry: 'Barcode Registry',
  registry_subtitle: 'Register products once, use them multiple times in inventory',
  register_new_barcode: 'Register New Barcode',
  registered_items: 'Registered Items',
  registered_barcodes: 'Registered Barcodes',
  register_barcode: 'Register Barcode',
  // Form fields
  barcode_upc: 'Barcode/UPC',
  scan_enter_barcode: 'Scan or enter barcode',
  search: 'Search',
  product_name: 'Product Name',
  name_of_product: 'Name of the product',
  brand: 'Brand',
  brand_name: 'Brand name',
  category: 'Category',
  select_category: 'Select Category',
  sku: 'SKU',
  sku_optional: 'Stock Keeping Unit (optional)',
  unit_pack_type: 'Unit/Pack Type',
  individual_unit: 'Individual Unit',
  pack: 'Pack',
  units_per_pack: 'Units per Pack',
  units_in_pack: 'Number of units in each pack',
  cost_price: 'Cost Price',
  cost_price_per_unit: 'Cost price per unit',
  weight_unit: 'Weight Unit',
  weight: 'Weight',
  weight_of_unit: 'Weight of one unit',
  volume_unit: 'Volume Unit',
  volume: 'Volume',
  volume_of_unit: 'Volume of one unit',
  shelf_life: 'Shelf Life',
  period: 'Period',
  no_expiration_date: 'No Expiration Date',
  
  // Expiration units
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  years: 'Years',
  na: 'N/A',
  
  // Buttons and actions
  update_item_registry: 'Update Item Registry',
  add_to_registry: 'Add To Registry',
  cancel_edit: 'Cancel Edit',
  edit: 'Edit',
  delete: 'Delete',
  quantity_to_take_out: 'Quantity',
  
  // Table headers
  scan_or_enter_barcode : 'Barcode',
  expiry: 'Expiry',
  description_placeholder: 'Description',
  sku_placeholder: 'SKU',
  barcode: 'Barcode',
  product: 'Product',
  shelf_life: 'Shelf Life',
  unit_pack: 'Unit/Pack',
  actions: 'Actions',
  
  // Messages
  no_items_found: 'No registered items found. Add items to your registry.',
  item_updated_registry: 'Item "{0}" updated in registry',
  item_added_registry: 'Item "{0}" added to registry',
  item_removed_registry: 'Item "{0}" removed from registry',
  template_item_found: 'Template item found. Fields filled automatically.',
  fill_required_fields: 'Please fill in all required fields',
  enter_barcode: 'Please enter a barcode',
  error_searching_barcode: 'Error searching barcode',
  failed_save_registry: 'Failed to save item to registry',
  failed_delete_registry: 'Failed to delete item from registry',
  failed_fetch_registered: 'Failed to fetch registered items',
  
  // Weight units (already defined in ItemAdd)
  kilogram: 'Kilogram (kg)',
  gram: 'Gram (g)',
  ounce: 'Ounce (oz)',
  pound: 'Pound (lb)',
  
  // Volume units (already defined in ItemAdd)
  liter: 'Liter (L)',
  milliliter: 'Milliliter (mL)',
  fluid_ounce: 'Fluid Ounce (fl oz)',
  cubic_meter: 'Cubic Meter (m³)',
  
  // Confirmation dialogs
  confirm_update_registry: 'Are you sure you want to update this item in the registry?',
  confirm_add_registry: 'Are you sure you want to add this item to the registry?',
  confirm: 'Confirm',
  cancel: 'Cancel',
  
  // Display values
  no_expiry: 'No Expiry',
  pack_units: 'Pack ({0} units)',
  unit: 'Unit',
  search_registry: 'Search registry...',

  filter_by: 'Filter by',
  reset_filters: 'Reset Filters',
  reset: 'Reset',
  item: 'Item',
  all_items: 'All Items',
  specific_item: 'Specific Item',
  brand: 'Brand',
  category: 'Category',
  store: 'Store',
  select_value: 'Select value',
  timeframe: 'Timeframe',
  from: 'From',
  to: 'To',
  apply: 'Apply',
  years: 'Years',
  months: 'Months',
  weeks: 'Weeks',
  days: 'Days',
  hours: 'Hours',
  detailed_inventory_analytics: 'Inventory Analytics',
  more: 'More',

  pattern_analysis: 'Pattern Analysis',
  average_day: 'Average Day',
  average: 'average',
  total: 'total',
  average_all_time: 'All Time (Average)',
  current_week: 'Current Week',
  custom_week: 'Custom Week',
  week_range: 'Week Range',
  calculating_patterns: 'Calculating patterns...',
  no_pattern_data_available: 'No pattern data available',
  pattern_insights: 'Pattern Insights',
  peak_times: 'Peak Times',
  peak_added_time: 'Peak Added Time',
  peak_sold_time: 'Peak Sold Time',
  pattern_summary_prefix: 'Analysis for',
  pattern_summary_shows: 'shows that',
  pattern_activity_detected: 'there are clear patterns of activity throughout the day.',
  pattern_no_significant_activity: 'there is no significant activity pattern detected.',
  insufficient_data_for_insights: 'Insufficient data to generate meaningful insights.',
  line_chart: 'Line Chart',
  bar_chart: 'Bar Chart',
  back: 'Back',
  occurrences: 'occurrences',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
},

zh: {
    products: '产品',
    market_research: '市场研究',
    equipment: '设备',
    learning: '学习',
    community: '社区',
    partners: '合作伙伴',
    about: '关于',
    login: '登录',
    contact_us: '联系我们',
    ai_innovations: 'YourCompany的AI创新',
    discover_text: '了解我们解决方案组合中最新的生成式AI功能如何帮助您革新业务模型策略。',
    learn_more: '了解更多',
    choose_region: '选择您的地区',
    search_country: '搜索国家',
    inventory_management: '库存管理',
    supply_chain_management: '供应链管理',
    financial_management: '财务管理',
    human_capital_management: '人力资本管理',
    crm_and_customer_experience: 'CRM与客户体验',
    view_products_az: '查看产品 A-Z',
    by_industry: '按行业',
    by_category: '按类别',
    automotive: '汽车',
    banking: '银行',
    consumer_products: '消费品',
    healthcare: '医疗保健',
    retail: '零售',
    view_all_industries: '查看所有行业',
    business_technology_platform: '商业技术平台',
    application_development_and_automation: '应用开发与自动化',
    data_and_analytics: '数据与分析',
    extended_planning_and_analysis: '扩展规划与分析',
    integration: '集成',
},

es: {
    products: 'Productos',
    market_research: 'Investigación de Mercado',
    equipment: 'Equipamiento',
    learning: 'Aprendizaje',
    community: 'Comunidad',
    partners: 'Socios',
    about: 'Acerca de',
    login: 'Iniciar sesión',
    contact_us: 'Contáctenos',
    ai_innovations: 'Innovaciones de IA en YourCompany',
    discover_text: 'Descubra cómo las últimas capacidades de IA generativa en nuestra cartera de soluciones pueden ayudarlo a revolucionar su estrategia de modelo de negocio.',
    learn_more: 'Aprenda más',
    choose_region: 'Elija su región',
    search_country: 'Buscar un país',
    inventory_management: 'Gestión de inventario',
    supply_chain_management: 'Gestión de la cadena de suministro',
    financial_management: 'Gestión financiera',
    human_capital_management: 'Gestión del capital humano',
    crm_and_customer_experience: 'CRM y experiencia del cliente',
    view_products_az: 'Ver productos A-Z',
    by_industry: 'Por industria',
    by_category: 'Por categoría',
    automotive: 'Automotriz',
    banking: 'Bancario',
    consumer_products: 'Productos de consumo',
    healthcare: 'Salud',
    retail: 'Minorista',
    view_all_industries: 'Ver todas las industrias',
    business_technology_platform: 'Plataforma de tecnología empresarial',
    application_development_and_automation: 'Desarrollo y automatización de aplicaciones',
    data_and_analytics: 'Datos y análisis',
    extended_planning_and_analysis: 'Planificación y análisis extendidos',
    integration: 'Integración',
},

fr: {
    products: 'Produits',
    market_research: 'Étude de Marché',
    equipment: 'Équipement',
    learning: 'Apprentissage',
    community: 'Communauté',
    partners: 'Partenaires',
    about: 'À propos',
    login: 'Connexion',
    contact_us: 'Contactez-nous',
    ai_innovations: 'Innovations IA chez YourCompany',
    discover_text: 'Découvrez comment les dernières capacités d\'IA générative de notre portefeuille de solutions peuvent vous aider à révolutionner votre stratégie de modèle d\'entreprise.',
    learn_more: 'En savoir plus',
    choose_region: 'Choisissez votre région',
    search_country: 'Rechercher un pays',
    inventory_management: 'Gestion des stocks',
    supply_chain_management: 'Gestion de la chaîne d\'approvisionnement',
    financial_management: 'Gestion financière',
    human_capital_management: 'Gestion des ressources humaines',
    crm_and_customer_experience: 'CRM et expérience client',
    view_products_az: 'Voir les produits A-Z',
    by_industry: 'Par industrie',
    by_category: 'Par catégorie',
    automotive: 'Automobile',
    banking: 'Banque',
    consumer_products: 'Produits de consommation',
    healthcare: 'Santé',
    retail: 'Vente au détail',
    view_all_industries: 'Voir toutes les industries',
    business_technology_platform: 'Plateforme de technologie d\'entreprise',
    application_development_and_automation: 'Développement et automatisation d\'applications',
    data_and_analytics: 'Données et analyses',
    extended_planning_and_analysis: 'Planification et analyse étendues',
    integration: 'Intégration',
},

ar: {
    products: 'المنتجات',
    market_research: 'بحث السوق ',
    equipment: 'المعدات',
    learning: 'التعلم',
    community: 'المجتمع',
    partners: 'الشركاء',
    about: 'حول',
    login: 'تسجيل الدخول',
    contact_us: 'اتصل بنا',
    ai_innovations: 'ابتكارات الذكاء الاصطناعي في YourCompany',
    discover_text: 'اكتشف كيف يمكن لأحدث قدرات الذكاء الاصطناعي التوليدي في محفظة حلولنا أن تساعدك في ثورة استراتيجية نموذج أعمالك.',
    learn_more: 'تعرف على المزيد',
    choose_region: 'اختر منطقتك',
    search_country: 'البحث عن بلد',
    inventory_management: 'إدارة المخزون',
    supply_chain_management: 'إدارة سلسلة التوريد',
    financial_management: 'الإدارة المالية',
    human_capital_management: 'إدارة رأس المال البشري',
    crm_and_customer_experience: 'إدارة علاقات العملاء وتجربة العملاء',
    view_products_az: 'عرض المنتجات A-Z',
    by_industry: 'حسب الصناعة',
    by_category: 'حسب الفئة',
    automotive: 'سيارات',
    banking: 'البنوك',
    consumer_products: 'المنتجات الاستهلاكية',
    healthcare: 'الرعاية الصحية',
    retail: 'تجزئة',
    view_all_industries: 'عرض جميع الصناعات',
    business_technology_platform: 'منصة تكنولوجيا الأعمال',
    application_development_and_automation: 'تطوير التطبيقات والأتمتة',
    data_and_analytics: 'البيانات والتحليلات',
    extended_planning_and_analysis: 'التخطيط والتحليل الموسع',
    integration: 'التكامل',
},

hi: {
    products: 'उत्पाद',
    market_research: 'बाज़ार अनुसंधान',
    equipment: 'उपकरण',
    learning: 'सीखना',
    community: 'समुदाय',
    partners: 'साझेदार',
    about: 'के बारे में',
    login: 'लॉग इन करें',
    contact_us: 'संपर्क करें',
    ai_innovations: 'YourCompany में AI नवाचार',
    discover_text: 'जानें कि हमारे समाधान पोर्टफोलियो में नवीनतम जनरेटिव AI क्षमताएं आपके व्यवसाय मॉडल रणनीति को कैसे क्रांतिकारी बना सकती हैं।',
    learn_more: 'और जानें',
    choose_region: 'अपना क्षेत्र चुनें',
    search_country: 'देश खोजें',
    inventory_management: 'इन्वेंटरी प्रबंधन',
    supply_chain_management: 'सप्लाई चेन प्रबंधन',
    financial_management: 'वित्तीय प्रबंधन',
    human_capital_management: 'मानव पूंजी प्रबंधन',
    crm_and_customer_experience: 'CRM और ग्राहक अनुभव',
    view_products_az: 'उत्पाद देखें A-Z',
    by_industry: 'उद्योग द्वारा',
    by_category: 'श्रेणी द्वारा',
    automotive: 'ऑटोमोटिव',
    banking: 'बैंकिंग',
    consumer_products: 'उपभोक्ता उत्पाद',
    healthcare: 'स्वास्थ्य सेवा',
    retail: 'खुदरा',
    view_all_industries: 'सभी उद्योग देखें',
    business_technology_platform: 'व्यापार तकनीकी प्लेटफ़ॉर्म',
    application_development_and_automation: 'एप्लिकेशन विकास और स्वचालन',
    data_and_analytics: 'डेटा और विश्लेषण',
    extended_planning_and_analysis: 'विस्तारित योजना और विश्लेषण',
    integration: 'एकीकरण',
},

ru: {
    products: 'Продукты',
    market_research: 'Исследование Рынка',
    equipment: 'Оборудование',
    learning: 'Обучение',
    community: 'Сообщество',
    partners: 'Партнеры',
    about: 'О нас',
    login: 'Вход',
    contact_us: 'Свяжитесь с нами',
    ai_innovations: 'Инновации ИИ в вашей компании',
    discover_text: 'Узнайте, как новейшие возможности генеративного ИИ в нашем портфеле решений могут помочь вам революционизировать стратегию вашей бизнес-модели.',
    learn_more: 'Узнать больше',
    choose_region: 'Выберите свой регион',
    search_country: 'Поиск страны',
    inventory_management: 'Управление запасами',
    supply_chain_management: 'Управление цепочкой поставок',
    financial_management: 'Финансовое управление',
    human_capital_management: 'Управление человеческим капиталом',
    crm_and_customer_experience: 'CRM и клиентский опыт',
    view_products_az: 'Посмотреть продукты A-Z',
    by_industry: 'По отраслям',
    by_category: 'По категории',
    automotive: 'Автомобильный',
    banking: 'Банковский',
    consumer_products: 'Потребительские товары',
    healthcare: 'Здравоохранение',
    retail: 'Розничная торговля',
    view_all_industries: 'Посмотреть все отрасли',
    business_technology_platform: 'Платформа бизнес-технологий',
    application_development_and_automation: 'Разработка и автоматизация приложений',
    data_and_analytics: 'Данные и аналитика',
    extended_planning_and_analysis: 'Расширенное планирование и анализ',
    integration: 'Интеграция',
     // Checkout translations
  checkout_title: 'Оформление Транзакции',
  return_processing: 'Обработка Возврата',
  total_amount: 'Общая Сумма',
  items: 'Товары',
  complete_transaction: 'Завершить Транзакцию',
  process_return: 'Обработать Возврат',
  switch_to_return: 'Перейти к Возврату',
  switch_to_checkout: 'Перейти к Оплате',
  cancel: 'Отмена',
  enter_barcode: 'Введите Штрих-код',
  enter_transaction_code: 'Введите Код Транзакции',
  search: 'Поиск',
  add: 'Добавить',
  search_transaction: 'Поиск Транзакции',
  selected_item: 'Выбранный Товар',
  available: 'Доступно',
  transaction_code: 'Код Транзакции',
  transaction_date: 'Дата Транзакции',
  inventory: 'Инвентарь',
  no_items_added: 'Товары не добавлены',
  remove: 'Удалить',
  price: 'Цена',
  quantity: 'Количество',
  barcode: 'Штрих-код',
  product: 'Товар',
  brand: 'Бренд',
  weight: 'Вес',
  volume: 'Объем',
  add_to_cart: 'Добавить в Корзину',
  no_items_found: 'Товары не найдены',
  search_inventory: 'Поиск в инвентаре',
  all_categories: 'Все Категории',
  all_stores: 'Все Магазины',
  all_brands: 'Все Бренды',
  sort_by: 'Сортировать По',
  alphabetical: 'По Алфавиту',
  date_added: 'Дате Добавления',
  expiry_date: 'Сроку Годности',
  ascending: 'По Возрастанию',
  descending: 'По Убыванию',
  close: 'Закрыть',
  currency_symbol: '₽',

  // Additional inventory page translations
  showing: 'Показано',
  of: 'из',
  items: 'товаров',
  only_items_in_stock: 'Только Товары в Наличии',
  no_expiry: 'Н/Д',
  inventory_management: 'Управление Запасами',
  low_stock: 'Мало на Складе',
  out_of_stock: 'Нет в Наличии',
  in_stock: 'В Наличии',

  // Category translations
  category: 'Категория',
  groceries: 'Продукты',
  beverages: 'Напитки',
  household_products: 'Товары для Дома',
  personal_care_items: 'Средства Личной Гигиены',
  snacks: 'Закуски',
  frozen_foods: 'Замороженные Продукты',
  health_and_wellness_products: 'Товары для Здоровья',
  electronics: 'Электроника',
  pet_supplies: 'Товары для Животных',
  office_supplies: 'Офисные Принадлежности',
  raw_materials: 'Сырье',
  
  // Error messages
  error_no_item_selected: 'Товар не выбран',
  error_invalid_price: 'Пожалуйста, введите действительную цену',
  error_invalid_quantity: 'Пожалуйста, введите действительное количество',
  error_cart_empty: 'Корзина пуста',
  error_no_transaction: 'Транзакция для возврата не найдена',
  error_invalid_transaction_code: 'Пожалуйста, введите действительный 12-значный код транзакции',
  error_barcode_required: 'Пожалуйста, введите штрих-код',
  
  // Success messages
  success_return_processed: 'Возврат успешно обработан',
  success_transaction_completed: 'Транзакция успешно завершена',

  // Dashboard translations
  dashboard_title: 'Панель Управления',
  inventory_summary: 'Сводка Инвентаря',
  total_items: 'Всего Товаров',
  low_stock_items: 'Товары на Исходе',
  expired_items: 'Просроченные Товары',
  total_inventory_value: 'Общая Стоимость Инвентаря',
  inventory_analytics: 'Аналитика Инвентаря',
  analytics: 'Больше',
  detailed_inventory_analytics: 'Аналитика Инвентаря',
  back_to_dashboard: 'Вернуться',
  date: 'Дата',
  items_added: 'Добавлено Товаров',
  items_sold: 'Продано Товаров',
  sold: 'Продано',
  added: 'Добавлено',
  
  // Inventory summary document
  inventory_summary_document: 'Документ Сводки Инвентаря',
  total_items_in_inventory: 'Всего Товаров в Инвентаре',
  low_stock_items_colon: 'Товары на Исходе',
  expired_items_colon: 'Просроченные Товары',
  total_inventory_value_colon: 'Общая Стоимость Инвентаря',
  summary_overview: 'Эта сводка предоставляет комплексный обзор текущего состояния вашего инвентаря.',
  
  // Inventory analytics document
  inventory_analytics_overview: 'Обзор Аналитики Инвентаря',
  detailed_activity_data: 'Подробные Данные Активности',
  summary_statistics: 'Сводная Статистика',
  total_items_added: 'Всего Добавлено Товаров',
  total_items_sold: 'Всего Продано Товаров',
  average_items_added_per_day: 'Среднее Количество Добавленных Товаров в День',
  average_items_sold_per_day: 'Среднее Количество Проданных Товаров в День',
  trend_analysis: 'Анализ Тенденций',
  
  // Trend analysis texts
  added_upward_trend: 'Добавление товаров показывает восходящую тенденцию.',
  added_downward_trend: 'Добавление товаров показывает нисходящую тенденцию.',
  added_stable_trend: 'Добавление товаров относительно стабильно.',
  removed_upward_trend: 'Удаление товаров показывает восходящую тенденцию.',
  removed_downward_trend: 'Удаление товаров показывает нисходящую тенденцию.',
  removed_stable_trend: 'Удаление товаров относительно стабильно.',
  insufficient_data: 'Недостаточно данных для анализа тенденций.',
  no_analytics_data: 'Данные аналитики недоступны.',
  // Sidebar navigation
  dashboard: 'Панель',
  inventory: 'Инвентарь',
  add: 'Добавить',
  checkout: 'Оформление',
  take_out: 'Вынос',
  orders: 'Заказы',
  reports: 'Отчеты',
  security: 'Безопасность',
  profile: 'Профиль',
  finances: 'Финансы',
  view_analytics: 'Просмотр Аналитики',

   // How Daxtop Works section
   how_daxtop_works: 'Как работает Daxtop',
   become_goto_store: 'Станьте магазином первого выбора.',
   grow_revenue_text: 'Увеличивайте свой доход, поддерживайте персонал в хорошем настроении и обеспечивайте бесперебойный мультиканальный опыт.',
   learn_more_button: 'Узнать больше',
   download_install: 'Скачать и установить',
   configure: 'Настроить',
   increase_store_efficiency: 'Повысить эффективность магазина',
   
   // Download and install content
   daxtop_is_desktop_app: 'Daxtop - это настольное приложение для Mac, Windows и Linux. Когда вы устанавливаете Daxtop на свой компьютер, оно создает виртуальные среды под названием Daxtop Store и Daxtop Inventory.',
   
   // Configure content
   configure_daxtop_store: 'Настройте Daxtop Store для работы с предпочитаемыми инструментами управления магазином. Настройка занимает всего минуту и обеспечивает расширенную систему POS.',
   
   // Increase efficiency content
   automated_workflows: 'Благодаря автоматизированным рабочим процессам, Daxtop сокращает количество рутинных задач и предоставляет вам лучший доступ к данным магазина в реальном времени, поэтому вы можете сосредоточиться на том, что действительно важно — управлении вашим бизнесом.',
 
  
  // User info
  admin: 'Админ',
  email_copied: 'Почта Скопирована!',

  // DaxAI page titles and main elements
  recent_chats: 'Недавние Чаты',
  start_new_chat: 'Начать новый чат',
  ask_anything: 'Спросите что-нибудь....',
  verification_notice: 'DAX Агент может ошибаться. Пожалуйста, проверяйте ответы.',
  share: 'Поделиться',
  rename: 'Переименовать',
  save: 'Сохранить',
  new_chat: 'Новый Чат',
  
  // Thinking and generating states
  thinking_deeply: 'Глубоко размышляю...',
  analyzing_inventory_data: 'Анализирую данные инвентаря...',
  processing_analytics: 'Обрабатываю аналитику...',
  analyzing_document: 'Анализирую документ...',
  
  // Document and analysis related
  document_preview: 'Предпросмотр Документа',
  inventory_summary: 'Сводка Инвентаря',
  inventory_analytics_overview: 'Обзор Аналитики Инвентаря',
  explain_in_armenian: 'Пожалуйста, объясните это на армянском',
  document_content: 'Содержание Документа',
  user_question: 'Вопрос Пользователя',
  
  // Error messages
  error_chat_creation: 'Не удалось создать чат',
  error_chat_update: 'Не удалось обновить чат',
  error_chat_delete: 'Не удалось удалить чат',
  error_chat_rename: 'Не удалось переименовать чат',
  error_user_data: 'Не удалось загрузить данные пользователя',
  error_generic: "Извините, произошла ошибка. Пожалуйста, попробуйте еще раз.",
  error_rename: 'Не удалось переименовать чат. Пожалуйста, попробуйте еще раз.',
  error_load_chat: 'Не удалось загрузить чат',
  error_save_chat: 'Не удалось сохранить чат',
  // Chat sidebar elements
  add_data: 'Добавить Данные',
  inventory: 'Инвентарь',
  orders: 'Заказы',
  reports: 'Отчеты',

  // Page titles
  edit_item: 'Редактировать Товар',
  add_new_item: 'Добавить Новый Товар',
  speed_inventory: 'Быстрая Инвентаризация',
  add_quickly_barcode: 'Быстрое добавление товаров с помощью сканирования штрих-кода',
  
  // Form fields
  store: 'Магазин',
  select_store: 'Выберите Магазин',
  barcode_upc: 'Штрих-код/UPC (Сканируйте или Введите)',
  scan_enter_barcode: 'Сканируйте или введите штрих-код',
  product_name: 'Название Товара',
  name_of_product: 'Название товара',
  brand: 'Бренд',
  name_of_brand: 'Название Бренда',
  category: 'Категория',
  select_category: 'Выберите Категорию',
  quantity: 'Количество',
  selling_price: 'Цена Продажи',
  selling_price_per_unit: 'Цена продажи за единицу',
  cost_price: 'Закупочная Цена',
  cost_price_per_unit: 'Закупочная цена за единицу',
  low_stock_threshold: 'Порог Низкого Запаса',
  sku: 'Артикул',
  sku_full: 'Артикул (Учетная Единица Хранения)',
  weight_unit: 'Единица Измерения Веса',
  select_weight_unit: 'Выберите Единицу Веса',
  weight: 'Вес',
  weight_not_applicable: 'Вес не применим',
  weight_of_unit: 'Вес одной единицы в',
  volume_unit: 'Единица Измерения Объема',
  select_volume_unit: 'Выберите Единицу Объема',
  volume: 'Объем',
  volume_not_applicable: 'Объем не применим',
  volume_of_unit: 'Объем одной единицы в',
  expiry_date_from: 'Срок Годности (От)',
  expiry_date_to: 'Срок Годности (До)',
  no_expiry_date: 'Без Срока Годности',
  description: 'Описание',
  description_optional: 'Описание (необязательно)',
  
  // Buttons
  register_new_item: 'Зарегистрировать Новый Товар',
  quick_mode: 'Быстрый Режим',
  on: 'ВКЛ',
  off: 'ВЫКЛ',
  update_item: 'Обновить Товар',
  add_item: 'Добавить Товар',
  cancel_edit: 'Отменить Редактирование',
  reset_form: 'Сбросить Форму',
  toggle_expiry: 'Переключить Срок Годности',
  save_current_template: 'Сохранить Текущий Как Шаблон',
  load: 'Загрузить',
  
  // Templates section
  item_templates: 'Шаблоны Товаров',
  no_templates: 'Нет сохраненных шаблонов. Создайте их, сохранив текущую форму.',
  
  // Numpad and sidebar
  numeric_keypad: 'Цифровая Клавиатура',
  active_field: 'Активное поле',
  none: 'Нет',
  required_fields: 'Обязательные поля',
  all_completed: 'Все заполнено',
  
  // Success and error messages
  template_found: 'Шаблон найден! Детали заполнены автоматически.',
  item_updated_success: 'Товар "{0}" успешно обновлен',
  item_added_success: 'Товар "{0}" успешно добавлен',
  item_deleted_success: 'Товар "{0}" успешно удален',
  template_saved_success: 'Шаблон "{0}" успешно сохранен',
  no_previous_item: 'Нет предыдущего товара для дублирования',
  failed_save_item: 'Не удалось сохранить товар',
  failed_delete_item: 'Не удалось удалить товар',
  failed_fetch_items: 'Не удалось загрузить товары',
  
  // Confirmation dialogs
  confirm_update_item: 'Вы уверены, что хотите обновить этот товар?',
  confirm_add_item: 'Вы уверены, что хотите добавить этот товар в инвентарь?',
  confirm: 'Подтвердить',
  cancel: 'Отмена',
  enter_barcode_duplicate: 'Введите штрих-код для дублируемого товара (или оставьте пустым):',
  enter_template_name: 'Введите имя для этого шаблона:',
  
  // Weight units
  kilogram: 'Килограмм (кг)',
  gram: 'Грамм (г)',
  ounce: 'Унция (унц)',
  pound: 'Фунт (фнт)',
  na: 'Н/Д',
  
  // Volume units
  liter: 'Литр (л)',
  milliliter: 'Миллилитр (мл)',
  fluid_ounce: 'Жидкая Унция (ж. унц)',
  cubic_meter: 'Кубический Метр (м³)',

  // ItemRegister page titles
  barcode_registry: 'Реестр Штрих-кодов',
  registry_subtitle: 'Зарегистрируйте товары один раз, используйте их многократно в инвентаре',
  register_new_barcode: 'Зарегистрировать Новый Штрих-код',
  registered_items: 'Зарегистрированные Товары',
  registered_barcodes: 'Зарегистрированные Штрих-коды',
  register_barcode: 'Зарегистрировать Штрих-код',
  // Form fields
  barcode_upc: 'Штрих-код/UPC',
  scan_enter_barcode: 'Сканируйте или введите штрих-код',
  search: 'Поиск',
  product_name: 'Название Товара',
  name_of_product: 'Название товара',
  brand: 'Бренд',
  brand_name: 'Название бренда',
  category: 'Категория',
  select_category: 'Выберите Категорию',
  sku: 'Артикул',
  sku_optional: 'Артикул (необязательно)',
  unit_pack_type: 'Тип Единицы/Упаковки',
  individual_unit: 'Отдельная Единица',
  pack: 'Упаковка',
  units_per_pack: 'Единиц в Упаковке',
  units_in_pack: 'Количество единиц в каждой упаковке',
  cost_price: 'Закупочная Цена',
  cost_price_per_unit: 'Закупочная цена за единицу',
  weight_unit: 'Единица Измерения Веса',
  weight: 'Вес',
  weight_of_unit: 'Вес одной единицы',
  volume_unit: 'Единица Измерения Объема',
  volume: 'Объем',
  volume_of_unit: 'Объем одной единицы',
  shelf_life: 'Срок Годности',
  period: 'Период',
  no_expiration_date: 'Без Срока Годности',
  
  // Expiration units
  days: 'Дней',
  weeks: 'Недель',
  months: 'Месяцев',
  years: 'Лет',
  na: 'Н/Д',
  
  // Buttons and actions
  update_item_registry: 'Обновить Товар в Реестре',
  add_to_registry: 'Добавить в Реестр',
  cancel_edit: 'Отменить Редактирование',
  edit: 'Редактировать',
  delete: 'Удалить',
  quantity_to_take_out: 'Количество',
  
  // Table headers

  scan_or_enter_barcode : 'Штрих-код',
  expiry: 'Срок',
  description_placeholder: 'Описание',
  sku_placeholder: 'Артикул',
  barcode: 'Штрих-код',
  product: 'Товар',
  shelf_life: 'Срок Годности',
  unit_pack: 'Ед./Упак.',
  actions: 'Действия',
  
  // Messages
  no_items_found: 'Зарегистрированных товаров не найдено. Добавьте товары в реестр.',
  item_updated_registry: 'Товар "{0}" обновлен в реестре',
  item_added_registry: 'Товар "{0}" добавлен в реестр',
  item_removed_registry: 'Товар "{0}" удален из реестра',
  template_item_found: 'Найден шаблон товара. Поля заполнены автоматически.',
  fill_required_fields: 'Пожалуйста, заполните все обязательные поля',
  enter_barcode: 'Пожалуйста, введите штрих-код',
  error_searching_barcode: 'Ошибка при поиске штрих-кода',
  failed_save_registry: 'Не удалось сохранить товар в реестре',
  failed_delete_registry: 'Не удалось удалить товар из реестра',
  failed_fetch_registered: 'Не удалось загрузить зарегистрированные товары',
  
  // Confirmation dialogs
  confirm_update_registry: 'Вы уверены, что хотите обновить этот товар в реестре?',
  confirm_add_registry: 'Вы уверены, что хотите добавить этот товар в реестр?',
  confirm: 'Подтвердить',
  cancel: 'Отмена',
  
  // Display values
  no_expiry: 'Без Срока Годности',
  pack_units: 'Упаковка ({0} ед.)',
  unit: 'Единица',
  search_registry: 'Поиск в реестре...',

  filter_by: 'Фильтровать по',
  reset_filters: 'Сбросить', 
  reset: 'Сбросить фильтры',
  item: 'Товар',
  all_items: 'Все товары',
  specific_item: 'Конкретный товар',
  brand: 'Бренд',
  category: 'Категория',
  store: 'Магазин',
  select_value: 'Выберите значение',
  timeframe: 'Временной период',
  from: 'С',
  to: 'По',
  apply: 'Применить',
  years: 'Годы',
  months: 'Месяцы',
  weeks: 'Недели',
  days: 'Дни',
  hours: 'Часы',
  detailed_inventory_analytics: 'Детальная аналитика запасов',
  analytics: 'Аналитика',

  pattern_analysis: 'Анализ Закономерностей',
  average_day: 'Средний День',
  average: 'среднее',
  total: 'всего',
  average_all_time: 'Все Время (Среднее)',
  current_week: 'Текущая Неделя',
  custom_week: 'Выбрать Неделю',
  week_range: 'Диапазон Недели',
  calculating_patterns: 'Вычисление закономерностей...',
  no_pattern_data_available: 'Данные о закономерностях недоступны',
  pattern_insights: 'Статистические Выводы',
  peak_times: 'Пиковые Часы',
  peak_added_time: 'Пиковое Время Поступлений',
  peak_sold_time: 'Пиковое Время Продаж',
  pattern_summary_prefix: 'Анализ за',
  pattern_summary_shows: 'показывает, что',
  pattern_activity_detected: 'наблюдаются четкие закономерности активности в течение дня.',
  pattern_no_significant_activity: 'значительных закономерностей активности не обнаружено.',
  insufficient_data_for_insights: 'Недостаточно данных для формирования значимых выводов.',
  line_chart: 'Линейный График',
  bar_chart: 'Столбчатая Диаграмма',
  back: 'Назад',
  occurrences: 'случаев',
  monday: 'Понедельник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четверг',
  friday: 'Пятница',
  saturday: 'Суббота',
  sunday: 'Воскресенье',

},

pt: {
    products: 'Produtos',
    market_research: 'Pesquisa de Mercado',
    equipment: 'Equipamento',
    learning: 'Aprendizado',
    community: 'Comunidade',
    partners: 'Parceiros',
    about: 'Sobre',
    login: 'Entrar',
    contact_us: 'Contate-nos',
    ai_innovations: 'Inovações de IA na YourCompany',
    discover_text: 'Descubra como as mais recentes capacidades de IA generativa em nosso portfólio de soluções podem ajudá-lo a revolucionar sua estratégia de modelo de negócios.',
    learn_more: 'Saiba mais',
    choose_region: 'Escolha sua região',
    search_country: 'Pesquisar um país',
    inventory_management: 'Gestão de Inventário',
    supply_chain_management: 'Gestão da Cadeia de Suprimentos',
    financial_management: 'Gestão Financeira',
    human_capital_management: 'Gestão de Capital Humano',
    crm_and_customer_experience: 'CRM e Experiência do Cliente',
    view_products_az: 'Ver Produtos A-Z',
    by_industry: 'Por Indústria',
    by_category: 'Por Categoria',
    automotive: 'Automotivo',
    banking: 'Bancário',
    consumer_products: 'Produtos de Consumo',
    healthcare: 'Saúde',
    retail: 'Varejo',
    view_all_industries: 'Ver Todas as Indústrias',
    business_technology_platform: 'Plataforma de Tecnologia Empresarial',
    application_development_and_automation: 'Desenvolvimento e Automação de Aplicativos',
    data_and_analytics: 'Dados e Análise',
    extended_planning_and_analysis: 'Planejamento e Análise Avançados',
    integration: 'Integração',
},

bn: {
    products: 'পণ্য',
    market_research: 'বাজার গবেষণা',
    equipment: 'সরঞ্জাম',
    learning: 'শেখা',
    community: 'সম্প্রদায়',
    partners: 'পার্টনার',
    about: 'সম্পর্কে',
    login: 'লগ ইন করুন',
    contact_us: 'যোগাযোগ করুন',
    ai_innovations: 'YourCompany-এ AI উদ্ভাবন',
    discover_text: 'জানুন কীভাবে আমাদের সমাধান পোর্টফোলিওতে সর্বশেষ জেনারেটিভ AI ক্ষমতাগুলি আপনার ব্যবসার মডেল কৌশলকে বিপ্লবী করতে সহায়তা করতে পারে।',
    learn_more: 'আরও জানুন',
    choose_region: 'আপনার অঞ্চল নির্বাচন করুন',
    search_country: 'একটি দেশ খুঁজুন',
    inventory_management: 'মজুদ ব্যবস্থাপনা',
    supply_chain_management: 'সরবরাহ শৃঙ্খলা ব্যবস্থাপনা',
    financial_management: 'আর্থিক ব্যবস্থাপনা',
    human_capital_management: 'মানবসম্পদ ব্যবস্থাপনা',
    crm_and_customer_experience: 'CRM এবং গ্রাহক অভিজ্ঞতা',
    view_products_az: 'পণ্য দেখুন A-Z',
    by_industry: 'শিল্প দ্বারা',
    by_category: 'শ্রেণী অনুযায়ী',
    automotive: 'অটোমোটিভ',
    banking: 'ব্যাংকিং',
    consumer_products: 'ভোক্তাদের পণ্য',
    healthcare: 'স্বাস্থ্যসেবা',
    retail: 'খুচরা',
    view_all_industries: 'সব শিল্প দেখুন',
    business_technology_platform: 'ব্যবসা প্রযুক্তির প্ল্যাটফর্ম',
    application_development_and_automation: 'অ্যাপ্লিকেশন উন্নয়ন এবং স্বয়ংক্রিয়তা',
    data_and_analytics: 'ডেটা এবং বিশ্লেষণ',
    extended_planning_and_analysis: 'বিস্তৃত পরিকল্পনা এবং বিশ্লেষণ',
    integration: 'একীভূতকরণ',
},

ja: {
    products: '製品',
    market_research: '市場調査',
    equipment: '機器',
    learning: '学習',
    community: 'コミュニティ',
    partners: 'パートナー',
    about: '私たちについて',
    login: 'ログイン',
    contact_us: 'お問い合わせ',
    ai_innovations: 'YourCompanyのAI革新',
    discover_text: '私たちのソリューションポートフォリオにおける最新の生成AI機能が、どのようにあなたのビジネスモデル戦略を革命的に変えることができるかを発見してください。',
    learn_more: '詳細を学ぶ',
    choose_region: '地域を選択',
    search_country: '国を検索',
    inventory_management: '在庫管理',
    supply_chain_management: 'サプライチェーン管理',
    financial_management: '財務管理',
    human_capital_management: '人的資本管理',
    crm_and_customer_experience: 'CRMと顧客体験',
    view_products_az: '製品をA-Zで表示',
    by_industry: '業界別',
    by_category: 'カテゴリー別',
    automotive: '自動車',
    banking: '銀行',
    consumer_products: '消費財',
    healthcare: 'ヘルスケア',
    retail: '小売',
    view_all_industries: 'すべての業界を表示',
    business_technology_platform: 'ビジネステクノロジープラットフォーム',
    application_development_and_automation: 'アプリケーション開発と自動化',
    data_and_analytics: 'データと分析',
    extended_planning_and_analysis: '拡張計画と分析',
    integration: '統合',
},

pa: {
    products: 'ਉਤਪਾਦ',
    market_research: 'ਮਾਰਕੀਟ ਰਿਸਰਚ',
    equipment: 'ਉਪਕਰਣ',
    learning: 'ਸਿੱਖਣਾ',
    community: 'ਕਮਿਊਨਿਟੀ',
    partners: 'ਭਾਗੀਦਾਰ',
    about: 'ਬਾਰੇ',
    login: 'ਲਾਗਿਨ ਕਰੋ',
    contact_us: 'ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ',
    ai_innovations: 'YourCompany ਵਿੱਚ AI ਨਵੀਨਤਾਵਾਂ',
    discover_text: 'ਜਾਣੋ ਕਿ ਸਾਡੇ ਹੱਲਾਂ ਦੇ ਪੋਰਟਫੋਲੀਓ ਵਿੱਚ ਨਵੀਨਤਮ ਜਨਰੇਟਿਵ AI ਸਮਰੱਥਾਵਾਂ ਤੁਹਾਡੇ ਕਾਰੋਬਾਰ ਮਾਡਲ ਦੀ ਰਣਨੀਤੀ ਨੂੰ ਕਿਵੇਂ ਬਦਲ ਸਕਦੀਆਂ ਹਨ।',
    learn_more: 'ਹੋਰ ਜਾਣੋ',
    choose_region: 'ਆਪਣਾ ਖੇਤਰ ਚੁਣੋ',
    search_country: 'ਇੱਕ ਦੇਸ਼ ਖੋਜੋ',
    inventory_management: 'ਇਨਵੈਂਟਰੀ ਪ੍ਰਬੰਧਨ',
    supply_chain_management: 'ਸਪਲਾਈ ਚੇਨ ਪ੍ਰਬੰਧਨ',
    financial_management: 'ਆਰਥਿਕ ਪ੍ਰਬੰਧਨ',
    human_capital_management: 'ਮਨੁੱਖੀ ਪੂੰਜੀ ਪ੍ਰਬੰਧਨ',
    crm_and_customer_experience: 'CRM ਅਤੇ ਗ੍ਰਾਹਕ ਅਨੁਭਵ',
    view_products_az: 'ਉਤਪਾਦਾਂ ਨੂੰ A-Z ਵੇਖੋ',
    by_industry: 'ਉਦਯੋਗ ਦੁਆਰਾ',
    by_category: 'ਸ਼੍ਰੇਣੀ ਦੁਆਰਾ',
    automotive: 'ਗੱਡੀਆਂ',
    banking: 'ਬੈਂਕਿੰਗ',
    consumer_products: 'ਉਪਭੋਗਤਾ ਉਤਪਾਦ',
    healthcare: 'ਸਿਹਤ ਸੇਵਾ',
    retail: 'ਹਿੱਸੇਦਾਰੀ',
    view_all_industries: 'ਸਭ ਉਦਯੋਗ ਵੇਖੋ',
    business_technology_platform: 'ਕਾਰੋਬਾਰ ਟੈਕਨੋਲੋਜੀ ਪਲੇਟਫਾਰਮ',
    application_development_and_automation: 'ਐਪਲੀਕੇਸ਼ਨ ਵਿਕਾਸ ਅਤੇ ਆਟੋਮੇਸ਼ਨ',
    data_and_analytics: 'ਡੇਟਾ ਅਤੇ ਵਿਸ਼ਲੇਸ਼ਣ',
    extended_planning_and_analysis: 'ਵਿਸ਼ਤਾਰਿਤ ਯੋਜਨਾ ਅਤੇ ਵਿਸ਼ਲੇਸ਼ਣ',
    integration: 'ਇਕਤੀ',
},
hy: {
  products: 'Լուծումներ',
  market_research: 'Շուկայի Հետազոտություն',
  equipment: 'Սարքավորումներ',
  learning: 'Դասընթացներ',
  community: 'Համայնք',
  partners: 'Գործընկերներ',
  about: 'Մեր Մասին',
  login: 'Մուտք Գործել',
  contact_us: 'Հետադարձ Կապ',
  ai_innovations: 'Քո ընկերությունում ԱԲ նորարարություններ',
  discover_text: 'Որպեսզի իմանաք, թե մեր լուծումների պորտֆոլիոյում վերջին արտադրողական AI կարողությունները ինչպես կարող են օգնել ձեզ հեղափոխել ձեր բիզնես մոդելի ռազմավարությունը:',
  learn_more: 'Իմանալ Ավելին',
  choose_region: 'Ընտրեք Ձեր Տարածաշրջանը',
  search_country: 'Փնտրել Երկիր',
  inventory_management: 'Պահեստի Կառավարում',
  supply_chain_management: 'Մատակարարման Շղթայի Կառավարում',
  financial_management: 'Ֆինանսական Կառավարում',
  human_capital_management: 'Մարդկային Կապիտալի Կառավարում',
  crm_and_customer_experience: 'CRM և Հաճախորդի Գոհունակություն',
  view_products_az: 'Դիտել Ապրանքները Ա-Ֆ',
  by_industry: 'Ըստ Ոլորտի',
  by_category: 'Ըստ Կատեգորիայի ',
  automotive: 'Ավտոմոբիլային',
  banking: 'Բանկային Գործը',
  consumer_products: 'Սպառողական Ապրանքներ',
  healthcare: 'Առողջապահություն',
  retail: 'Մանրածախ',
  view_all_industries: 'Դիտել Բոլոր Ոլորտները',
  business_technology_platform: 'Բիզնեսի Տեխնոլոգիական Պլատֆորմ',
  application_development_and_automation: 'Հավելվածների Մշակում և Ավտոմատացում',
  data_and_analytics: 'Տվյալներ և Վերլուծություն',
  extended_planning_and_analysis: 'Ընդլայնված Պլանավորում և Վերլուծություն',
  integration: 'Ինտեգրում',
   // Checkout translations
   checkout_title: 'Գործարքի Վճարում',
   return_processing: 'Վերադարձի Մշակում',
   total_amount: 'Ընդհանուր Գումար',
   items: 'Ապրանքներ',
   complete_transaction: 'Ավարտել Գործարքը',
   process_return: 'Մշակել Վերադարձը',
   switch_to_return: 'Անցնել Վերադարձին',
   switch_to_checkout: 'Անցնել Վճարմանը',
   cancel: 'Չեղարկել',
   enter_barcode: 'Մուտքագրեք շտրիխ-կոդը',
   enter_transaction_code: 'Մուտքագրեք Գործարքի Կոդը',
   search: 'Որոնել',
   add: 'Ավելացնել',
   search_transaction: 'Որոնել Գործարքը',
   selected_item: 'Ընտրված Ապրանք',
   available: 'Առկա է',
   transaction_code: 'Գործարքի Կոդ',
   transaction_date: 'Գործարքի Ամսաթիվ',
   inventory: 'Պահեստ',
   no_items_added: 'Ապրանքներ չեն ավելացվել',
   remove: 'Հեռացնել',
   price: 'Գին',
   quantity: 'Քանակ',
   barcode: 'Շտրիխ-կոդ',
   product: 'Ապրանք',
   brand: 'Ապրանքանիշ',
   weight: 'Քաշ',
   volume: 'Ծավալ',
   add_to_cart: 'Ավելացնել',
   no_items_found: 'Ապրանքներ չեն գտնվել',
   search_inventory: 'Որոնել պահեստում',
   all_categories: 'Բոլոր Կատեգորիաները',
   all_stores: 'Բոլոր Խանութները',
   all_brands: 'Բոլոր Ապրանքանիշները',
   sort_by: 'Դասավորել Ըստ',
   alphabetical: 'Այբբենական',
   date_added: 'Ավելացման Ամսաթվի',
   expiry_date: 'Պիտանելիության Ժամկետի',
   ascending: 'Աճման Կարգով',
   descending: 'Նվազման Կարգով',
   close: 'Փակել',
   currency_symbol: '֏',

   // Additional inventory page translations
   showing: 'Ցուցադրվում է',
   of: '-ից',
   items: 'Ապրանքները',
   only_items_in_stock: 'Միայն Առկա Ապրանքները',
   no_expiry: 'Կ/Չ',
   inventory_management: 'Պահեստի Կառավարում',
   low_stock: 'Քիչ Պաշար',
   out_of_stock: 'Պաշարում Չկա',
   in_stock: 'Առկա է',
   
   // Category translations
   category: 'Կատեգորիա',
   groceries: 'Մթերք',
   beverages: 'Ըմպելիքներ',
   household_products: 'Տնային Ապրանքներ',
   personal_care_items: 'Անձնական Խնամքի Պարագաներ',
   snacks: 'Նախուտեստներ',
   frozen_foods: 'Սառեցված Սնունդ',
   health_and_wellness_products: 'Առողջության և Բարեկեցության Ապրանքներ',
   electronics: 'Էլեկտրոնիկա',
   pet_supplies: 'Կենդանիների Պարագաներ',
   office_supplies: 'Գրասենյակային Պարագաներ',
   raw_materials: 'Հումք',
   
   // Error messages
   error_no_item_selected: 'Ոչ մի ապրանք ընտրված չէ',
   error_invalid_price: 'Խնդրում ենք մուտքագրել վավեր գին',
   error_invalid_quantity: 'Խնդրում ենք մուտքագրել վավեր քանակ',
   error_cart_empty: 'Զամբյուղը դատարկ է',
   error_no_transaction: 'Վերադարձի համար գործարք չի գտնվել',
   error_invalid_transaction_code: 'Խնդրում ենք մուտքագրել վավեր 12-նիշ գործարքի կոդ',
   error_barcode_required: 'Խնդրում ենք մուտքագրել շտրիխ-կոդը',
   
   // Success messages
   success_return_processed: 'Վերադարձը հաջողությամբ մշակվել է',
   success_transaction_completed: 'Գործարքը հաջողությամբ ավարտվել է',
    // Dashboard translations
  inventory_summary: 'Պահեստի ամփոփում',
  total_items: 'Ընդհանուր ապրանքներ',
  low_stock_items: 'Քիչ քանակությամբ ապրանքներ',
  expired_items: 'Ժամկետանց ապրանքներ',
  total_inventory_value: 'Պահեստի Ընդհանուր Արժեքը',
  inventory_analytics: 'Պահեստի Վերլուծություն',
  analytics: 'Ավելին',
  detailed_inventory_analytics: 'Պահեստի Վերլուծություն',
  back_to_dashboard: 'Վերադառնալ',
  date: 'Ամսաթիվ',
  items_added: 'Ավելացված Ապրանքներ',
  items_sold: 'Վաճառված Ապրանքներ',
  sold: 'Վաճառված',
  added: 'Ավելացված',
  
  // Inventory summary document
  inventory_summary_document: 'Պահեստի Ամփոփ Փաստաթուղթ',
  total_items_in_inventory: 'Ընդհանուր Ապրանքներ Պահեստում',
  low_stock_items_colon: 'Պակաս Պաշարով Ապրանքներ',
  expired_items_colon: 'Ժամկետանց Ապրանքներ',
  total_inventory_value_colon: 'Պահեստի Ընդհանուր Արժեքը',
  summary_overview: 'Այս ամփոփումը տրամադրում է ձեր ընթացիկ պահեստի վիճակի համապարփակ ակնարկ։',
  
  // Inventory analytics document
  inventory_analytics_overview: 'Պահեստի Վերլուծության Ակնարկ',
  detailed_activity_data: 'Մանրամասն Գործունեության Տվյալներ',
  summary_statistics: 'Ամփոփ Վիճակագրություն',
  total_items_added: 'Ընդհանուր Ավելացված Ապրանքներ',
  total_items_sold: 'Ընդհանուր Վաճառված Ապրանքներ',
  average_items_added_per_day: 'Միջին Օրական Ավելացված Ապրանքներ',
  average_items_sold_per_day: 'Միջին Օրական Վաճառված Ապրանքներ',
  trend_analysis: 'Միտումների Վերլուծություն',
  
  // Trend analysis texts
  added_upward_trend: 'Ավելացվող ապրանքները ցույց են տալիս աճող միտում։',
  added_downward_trend: 'Ավելացվող ապրանքները ցույց են տալիս նվազող միտում։',
  added_stable_trend: 'Ավելացվող ապրանքները հարաբերականորեն կայուն են։',
  removed_upward_trend: 'Հեռացվող ապրանքները ցույց են տալիս աճող միտում։',
  removed_downward_trend: 'Հեռացվող ապրանքները ցույց են տալիս նվազող միտում։',
  removed_stable_trend: 'Հեռացվող ապրանքները հարաբերականորեն կայուն են։',
  insufficient_data: 'Անբավարար տվյալներ միտումների վերլուծության համար։',
  no_analytics_data: 'Վերլուծության տվյալները հասանելի չեն։',
  
  // Sidebar navigation
  dashboard: 'Վահանակ',
  inventory: 'Պահեստ',
  add: 'Ավելացնել',
  checkout: 'Վաճառել',
  take_out: 'Դուրս հանել',
  orders: 'Պատվերներ',
  reports: 'Հաշվետվություններ',
  security: 'Անվտանգություն',
  profile: 'Պրոֆիլ',
  finances: 'Ֆինանսներ',
  view_analytics: 'Դիտել Վերլուծությունը',
  
  // User info
  admin: 'Ադմին',
  email_copied: 'Էլ․ փոստը Պատճենված է!',

  
  // How Daxtop Works section
  how_daxtop_works: 'Ինչպես է աշխատում Daxtop-ը',
  become_goto_store: 'Դարձրեք ձեր գործունեությունը առավել արդյունավետ ',
  grow_revenue_text: 'Ավելացրեք ձեր եկամուտը, պահեք անձնակազմին գոհ և ապահովեք անխափան ծառայություն ։',
  learn_more_button: 'Իմանալ ավելին',
  download_install: 'Ներբեռնել և տեղադրել',
  configure: 'Կարգավորել',
  increase_store_efficiency: 'Բարձրացնել խանութի արդյունավետությունը',
  
  // Download and install content
  daxtop_is_desktop_app: 'Daxtop-ը հավելված է Mac, Windows և Linux համակարգերի համար։ Երբ ներբեռնում եք Daxtop-ը ձեր համակարգչում, այն ստեղծում է վիրտուալ միջավայրեր՝ Daxtop Store և Daxtop Inventory անուններով։',
  
  // Configure content
  configure_daxtop_store: 'Կարգավորեք ձեր Daxtop Խանութը ձեր նախընտրած կառավարման գործիքների հետ աշխատելու համար։ Կարգավորումը տևում է ընդամենը մեկ րոպե և ապահովում է ընդլայնված POS համակարգ։',
  
  // Increase efficiency content
  automated_workflows: 'Ավտոմատացված աշխատանքային գործընթացների շնորհիվ Daxtop-ը կրճատում է առաջադրանքները և ձեզ տալիս է իրական ժամանակում խանութի տվյալների ավելի մեծ հասանելիություն, որպեսզի կարողանաք կենտրոնանալ այն բանի վրա, ինչն իսկապես կարևոր է՝ ձեր բիզնեսի կառավարման համար։',

 

  // DaxAI page titles and main elements
  recent_chats: 'Վերջին Զրույցներ',
  start_new_chat: 'Սկսել նոր զրույց',
  ask_anything: 'Հարցրեք ցանկացած բան....',
  verification_notice: 'DAX Գործակալը կարող է սխալվել։ Խնդրում ենք ստուգել պատասխանները։',
  share: 'Կիսվել',
  rename: 'Վերանվանել',
  save: 'Պահպանել',
  new_chat: 'Նոր Զրույց',
  
  // Thinking and generating states
  thinking_deeply: 'Մտածում եմ...',
  analyzing_inventory_data: 'Վերլուծում եմ պահեստի տվյալները...',
  processing_analytics: 'Մշակում եմ վերլուծությունը...',
  analyzing_document: 'Վերլուծում եմ փաստաթուղթը...',
  
  // Document and analysis related
  document_preview: 'Փաստաթղթի Նախադիտում',
  inventory_summary: 'Պահեստի Ամփոփում',
  inventory_analytics_overview: 'Պահեստի Վերլուծության Ակնարկ',
  explain_in_armenian: 'Խնդրում եմ բացատրեք հայերենով',
  document_content: 'Փաստաթղթի Բովանդակություն',
  user_question: 'Օգտատիրոջ Հարց',
  
  // Error messages
  error_chat_creation: 'Չհաջողվեց ստեղծել զրույց',
  error_chat_update: 'Չհաջողվեց թարմացնել զրույցը',
  error_chat_delete: 'Չհաջողվեց ջնջել զրույցը',
  error_chat_rename: 'Չհաջողվեց վերանվանել զրույցը',
  error_user_data: 'Չհաջողվեց բեռնել օգտատիրոջ տվյալները',
  error_generic: 'Կներեք, ես հանդիպեցի սխալի։ Խնդրում եմ փորձեք կրկին։',
  error_rename: 'Չհաջողվեց վերանվանել զրույցը։ Խնդրում եմ փորձեք կրկին։',
  error_load_chat: 'Չհաջողվեց բեռնել զրույցը',
  error_save_chat: 'Չհաջողվեց պահպանել զրույցը',
  // Chat sidebar elements
  add_data: 'Ավելացնել Տվյալներ',
  inventory: 'Պահեստ',
  orders: 'Պատվերներ',
  reports: 'Հաշվետվություններ',

   // Page titles
   edit_item: 'Խմբագրել Ապրանքը',
   add_new_item: 'Ավելացնել Նոր Ապրանք',
   speed_inventory: 'Արագ Գույքագրում',
   add_quickly_barcode: 'Ավելացրեք ապրանքներ արագ՝ շտրիխ կոդի սկանավորմամբ',
   
   // Form fields
   store: 'Խանութ',
   select_store: 'Ընտրեք Խանութը',
   barcode_upc: 'Շտրիխ-կոդ/UPC (Սկանավորեք կամ Մուտքագրեք)',
   scan_enter_barcode: 'Սկանավորեք կամ մուտքագրեք շտրիխ-կոդը',
   product_name: 'Ապրանքի Անվանում',
   name_of_product: 'Ապրանքի անվանումը',
   brand: 'Ապրանքանիշ',
   name_of_brand: 'Ապրանքանիշի անվանումը',
   category: 'Կատեգորիա',
   select_category: 'Ընտրեք Կատեգորիան',
   quantity: 'Քանակ',
   selling_price: 'Վաճառքի Գին',
   selling_price_per_unit: 'Վաճառքի գինը մեկ միավորի համար',
   cost_price: 'Ինքնարժեք',
   cost_price_per_unit: 'Ինքնարժեքը մեկ միավորի համար',
   low_stock_threshold: 'Ցածր Պաշարի Շեմ',
   sku: 'Ապրանքի Կոդ',
   sku_full: 'Ապրանքի Կոդ (Պահեստային Հաշվառման Միավոր)',
   weight_unit: 'Քաշի Չափման Միավոր',
   select_weight_unit: 'Ընտրեք Քաշի Միավորը',
   weight: 'Քաշ',
   weight_not_applicable: 'Քաշը կիրառելի չէ',
   weight_of_unit: 'Մեկ միավորի քաշը',
   volume_unit: 'Ծավալի Չափման Միավոր',
   select_volume_unit: 'Ընտրեք Ծավալի Միավորը',
   volume: 'Ծավալ',
   volume_not_applicable: 'Ծավալը կիրառելի չէ',
   volume_of_unit: 'Մեկ միավորի ծավալը',
   expiry_date_from: 'Պիտանելիության Ժամկետ (Սկսած)',
   expiry_date_to: 'Պիտանելիության Ժամկետ (Մինչև)',
   no_expiry_date: 'Առանց Պիտանելիության Ժամկետի',
   description: 'Նկարագրություն',
   description_optional: 'Նկարագրություն (ոչ պարտադիր)',
   
   // Buttons
   register_new_item: 'Գրանցել Նոր Ապրանք',
   quick_mode: 'Արագ Ռեժիմ',
   on: 'ՄԻԱՑ',
   off: 'ԱՆՋ',
   update_item: 'Թարմացնել Ապրանքը',
   add_item: 'Ավելացնել Ապրանքը',
   cancel_edit: 'Չեղարկել Խմբագրումը',
   reset_form: 'Մաքրել Դաշտերը',
   
   toggle_expiry: 'Պիտանելիության Ժամկետ',
   save_current_template: 'Պահպանել Ընթացիկը Որպես Ձևանմուշ',
   load: 'Բեռնել',
   
   // Templates section
   item_templates: 'Ապրանքի Ձևանմուշներ',
   no_templates: 'Պահպանված ձևանմուշներ չկան։ Ստեղծեք դրանք՝ պահպանելով ընթացիկ ձևը։',
   
   // Numpad and sidebar
   numeric_keypad: 'Թվային Ստեղնաշար',
   active_field: 'Ակտիվ դաշտ',
   none: 'Չկա',
   required_fields: 'Պարտադիր դաշտեր',
   all_completed: 'Բոլորը լրացված են',
   
   // Success and error messages
   template_found: 'Ձևանմուշը գտնվել է! Մանրամասները լրացվել են ավտոմատ կերպով։',
   item_updated_success: 'Ապրանքը "{0}" հաջողությամբ թարմացվել է',
   item_added_success: 'Ապրանքը "{0}" հաջողությամբ ավելացվել է',
   item_deleted_success: 'Ապրանքը "{0}" հաջողությամբ ջնջվել է',
   template_saved_success: 'Ձևանմուշը "{0}" հաջողությամբ պահպանվել է',
   no_previous_item: 'Չկա նախորդ ապրանք կրկնօրինակման համար',
   failed_save_item: 'Չհաջողվեց պահպանել ապրանքը',
   failed_delete_item: 'Չհաջողվեց ջնջել ապրանքը',
   failed_fetch_items: 'Չհաջողվեց բեռնել ապրանքները',
   
   // Confirmation dialogs
   confirm_update_item: 'Դուք վստա՞հ եք, որ ցանկանում եք թարմացնել այս ապրանքը։',
   confirm_add_item: 'Դուք վստա՞հ եք, որ ցանկանում եք ավելացնել այս ապրանքը պահեստում։',
   confirm: 'Հաստատել',
   cancel: 'Չեղարկել',
   enter_barcode_duplicate: 'Մուտքագրեք շտրիխ կոդ կրկնօրինակվող ապրանքի համար (կամ թողեք դատարկ):',
   enter_template_name: 'Մուտքագրեք անուն այս ձևանմուշի համար:',
   
   // Weight units
   kilogram: 'Կիլոգրամ (կգ)',
   gram: 'Գրամ (գ)',
   ounce: 'Ունցիա (ունց)',
   pound: 'Ֆունտ (ֆնտ)',
   na: 'Չ/Հ',
   
   // Volume units
   liter: 'Լիտր (լ)',
   milliliter: 'Միլիլիտր (մլ)',
   fluid_ounce: 'Հեղուկ Ունցիա (հղ. ունց)',
   cubic_meter: 'Խորանարդ Մետր (մ³)',

    // ItemRegister page titles
  barcode_registry: 'Շտրիխ-կոդերի Գրանցամատյան',
  registry_subtitle: 'Գրանցեք ապրանքները մեկ անգամ, օգտագործեք դրանք բազմակի անգամ պահեստում',
  register_new_barcode: 'Գրանցել Նոր Շտրիխ-Կոդ',
  registered_items: 'Գրանցված Ապրանքներ',
  registered_barcodes: 'Գրանցված Շտրիխ-կոդեր',
  register_barcode: 'Գրանցել Շտրիխ-Կոդ',
  // Form fields
  barcode_upc: 'Շտրիխ Կոդ/UPC',
  scan_enter_barcode: 'Սկանավորեք կամ մուտքագրեք շտրիխ կոդը',
  search: 'Որոնել',
  product_name: 'Ապրանքի Անվանում',
  name_of_product: 'Ապրանքի անվանումը',
  brand: 'Ապրանքանիշ',
  brand_name: 'Ապրանքանիշի անվանումը',
  category: 'Կատեգորիա',
  select_category: 'Ընտրեք Կատեգորիան',
  sku: 'Ապրանքի Կոդ',
  sku_optional: 'Ապրանքի Կոդ (ոչ պարտադիր)',
  unit_pack_type: 'Միավոր/Փաթեթ Տեսակ',
  individual_unit: 'Առանձին Միավոր',
  pack: 'Փաթեթ',
  units_per_pack: 'Միավորներ Փաթեթում',
  units_in_pack: 'Միավորների քանակը յուրաքանչյուր փաթեթում',
  cost_price: 'Ինքնարժեք',
  cost_price_per_unit: 'Ինքնարժեքը մեկ միավորի համար',
  weight_unit: 'Քաշի Չափման Միավոր',
  weight: 'Քաշ',
  weight_of_unit: 'Մեկ միավորի քաշը',
  volume_unit: 'Ծավալի Չափման Միավոր',
  volume: 'Ծավալ',
  volume_of_unit: 'Մեկ միավորի ծավալը',
  shelf_life: 'Պիտանելիության Ժամկետ',
  period: 'Ժամանակահատված',
  no_expiration_date: 'Առանց Պիտանելիության Ժամկետի',
  
  // Expiration units
  days: 'Օր',
  weeks: 'Շաբաթ',
  months: 'Ամիս',
  years: 'Տարի',
  na: 'Չ/Հ',
  
  // Buttons and actions
  update_item_registry: 'Թարմացնել Ապրանքը Գրանցամատյանում',
  add_to_registry: 'Ավելացնել Գրանցամատյանում',
  cancel_edit: 'Չեղարկել Խմբագրումը',
  edit: ' Փոխել',
  delete: 'Ջնջել',
  quantity_to_take_out: 'Քանակը',
  
  // Table headers
  scan_or_enter_barcode : 'Շտրիխ Կոդ',
  expiry: 'Ժամկետ',
  description_placeholder: 'Նկարագրություն',
  sku_placeholder: 'Արտիկուլ',
  barcode: 'Շտրիխ Կոդ',
  product: 'Ապրանք',
  shelf_life: 'Պիտանելիության Ժամկետ',
  unit_pack: 'Միավոր/Փաթեթ',
  actions: 'Գործողություններ',
  
  // Messages
  no_items_found: 'Գրանցված ապրանքներ չեն գտնվել։ Ավելացրեք ապրանքներ գրանցամատյանում։',
  item_updated_registry: 'Ապրանքը "{0}" թարմացվել է գրանցամատյանում',
  item_added_registry: 'Ապրանքը "{0}" ավելացվել է գրանցամատյանում',
  item_removed_registry: 'Ապրանքը "{0}" հեռացվել է գրանցամատյանից',
  template_item_found: 'Ապրանքի ձևանմուշը գտնվել է։ Դաշտերը լրացվել են ավտոմատ կերպով։',
  fill_required_fields: 'Խնդրում ենք լրացնել բոլոր պարտադիր դաշտերը',
  enter_barcode: 'Խնդրում ենք մուտքագրել շտրիխ կոդը',
  error_searching_barcode: 'Շտրիխ կոդի որոնման սխալ',
  failed_save_registry: 'Չհաջողվեց պահպանել ապրանքը գրանցամատյանում',
  failed_delete_registry: 'Չհաջողվեց ջնջել ապրանքը գրանցամատյանից',
  failed_fetch_registered: 'Չհաջողվեց բեռնել գրանցված ապրանքները',
  
  // Confirmation dialogs
  confirm_update_registry: 'Դուք վստա՞հ եք, որ ցանկանում եք թարմացնել այս ապրանքը գրանցամատյանում։',
  confirm_add_registry: 'Դուք վստա՞հ եք, որ ցանկանում եք ավելացնել այս ապրանքը գրանցամատյանում։',
  confirm: 'Հաստատել',
  cancel: 'Չեղարկել',
  
  // Display values
  no_expiry: 'Առանց Ժամկետի',
  pack_units: 'Փաթեթ ({0} միավոր)',
  unit: 'Միավոր',
  search_registry: 'Որոնել գրանցամատյանում...',

  filter_by: 'Ըստ',
  reset_filters: 'Անջատել ֆիլտրերը',
  reset: 'Վերականգնել',
  item: 'Ապրանքի',
  all_items: 'Բոլոր ապրանքները',
  specific_item: 'Ապրանքի',
  brand: 'Բրենդի',
  category: 'Կատեգորիայի',
  store: 'Խանութի',
  select_value: 'Ընտրեք',
  timeframe: 'Ժամանակահատված',
  from: 'Սկսած',
  to: 'Մինչև',
  apply: 'Կիրառել',
  years: 'Տարիներ',
  months: 'Ամիսներ',
  weeks: 'Շաբաթներ',
  days: 'Օրեր',
  hours: 'Ժամեր',
  detailed_inventory_analytics: 'Պահեստի վերլուծություն',
  analytics: 'Վերլուծություն',
  search_registry: 'Որոնել գրանցամատյանում...',


  pattern_analysis: 'Օրինաչափություններ',
  average_day: 'Միջին Օր',
  average: 'միջին',
  total: 'ընդհանուր',
  average_all_time: 'Ամբողջ Ժամանակը (Միջին)',
  current_week: 'Ընթացիկ Շաբաթ',
  custom_week: 'Ընտրովի Շաբաթ',
  week_range: 'Շաբաթվա Միջակայք',
  calculating_patterns: 'Հաշվարկվում են օրինաչափությունները...',
  no_pattern_data_available: 'Օրինաչափությունների տվյալները հասանելի չեն',
  pattern_insights: 'Օրինաչափությունների Նշումներ',
  peak_times: 'Պիկային Ժամեր',
  peak_added_time: 'Ավելացման Պիկային Ժամ',
  peak_sold_time: 'Վաճառքի Պիկային Ժամ',
  pattern_summary_prefix: 'Վերլուծություն',
  pattern_summary_shows: 'ցույց է տալիս, որ',
  pattern_activity_detected: 'օրվա ընթացքում կան ակտիվության հստակ օրինաչափություններ:',
  pattern_no_significant_activity: 'նշանակալի ակտիվության օրինաչափություններ չեն հայտնաբերվել:',
  insufficient_data_for_insights: 'Բավարար տվյալներ չկան իմաստալից վերլուծության համար:',
  line_chart: 'Գծային Գրաֆիկ',
  bar_chart: 'Սյունակային Գրաֆիկ',
  back: 'Հետ',
  occurrences: 'դեպքեր',
  monday: 'Երկուշաբթի',
  tuesday: 'Երեքշաբթի',
  wednesday: 'Չորեքշաբթի',
  thursday: 'Հինգշաբթի',
  friday: 'Ուրբաթ',
  saturday: 'Շաբաթ',
  sunday: 'Կիրակի',
  
},
  };
  
  export const regions = [
    {
      name: 'Africa',
      countries: [
        { name: 'Algeria', code: 'ar' },
        { name: 'Angola', code: 'pt' },
        { name: 'Benin', code: 'fr' },
        { name: 'Botswana', code: 'en' },
        { name: 'Burkina Faso', code: 'fr' },
        { name: 'Burundi', code: 'rn' },
        { name: 'Cabo Verde', code: 'pt' },
        { name: 'Cameroon', code: 'fr' },
        { name: 'Central African Republic', code: 'fr' },
        { name: 'Chad', code: 'fr' },
        { name: 'Comoros', code: 'ar' },
        { name: 'Congo, Republic of the', code: 'fr' },
        { name: 'Congo, Democratic Republic of the', code: 'fr' },
        { name: "Côte d'Ivoire", code: 'fr' },
        { name: 'Djibouti', code: 'fr' },
        { name: 'Egypt', code: 'ar' },
        { name: 'Equatorial Guinea', code: 'es' },
        { name: 'Eritrea', code: 'ti' },
        { name: 'Eswatini', code: 'ss' },
        { name: 'Ethiopia', code: 'am' },
        { name: 'Gabon', code: 'fr' },
        { name: 'Gambia', code: 'en' },
        { name: 'Ghana', code: 'en' },
        { name: 'Guinea', code: 'fr' },
        { name: 'Guinea-Bissau', code: 'pt' },
        { name: 'Kenya', code: 'sw' },
        { name: 'Lesotho', code: 'st' },
        { name: 'Liberia', code: 'en' },
        { name: 'Libya', code: 'ar' },
        { name: 'Madagascar', code: 'mg' },
        { name: 'Malawi', code: 'en' },
        { name: 'Mali', code: 'fr' },
        { name: 'Mauritania', code: 'ar' },
        { name: 'Mauritius', code: 'en' },
        { name: 'Morocco', code: 'ar' },
        { name: 'Mozambique', code: 'pt' },
        { name: 'Namibia', code: 'en' },
        { name: 'Niger', code: 'fr' },
        { name: 'Nigeria', code: 'en' },
        { name: 'Rwanda', code: 'rw' },
        { name: 'Senegal', code: 'fr' },
        { name: 'Sierra Leone', code: 'en' },
        { name: 'Somalia', code: 'so' },
        { name: 'South Africa', code: 'en' },
        { name: 'South Sudan', code: 'en' },
        { name: 'Sudan', code: 'ar' },
        { name: 'Tanzania', code: 'sw' },
        { name: 'Togo', code: 'fr' },
        { name: 'Tunisia', code: 'ar' },
        { name: 'Uganda', code: 'en' },
        { name: 'Zambia', code: 'en' },
        { name: 'Zimbabwe', code: 'en' },
      ],
    },
    {
      name: 'Asia',
      countries: [
        { name: 'Afghanistan', code: 'ps' },
        { name: 'Armenia', code: 'hy' },
        { name: 'Azerbaijan', code: 'az' },
        { name: 'Bahrain', code: 'ar' },
        { name: 'Bangladesh', code: 'bn' },
        { name: 'Bhutan', code: 'dz' },
        { name: 'Brunei', code: 'ms' },
        { name: 'Cambodia', code: 'km' },
        { name: 'China', code: 'zh' },
        { name: 'Cyprus', code: 'el' },
        { name: 'Georgia', code: 'ka' },
        { name: 'India', code: 'hi' },
        { name: 'Indonesia', code: 'id' },
        { name: 'Iran', code: 'fa' },
        { name: 'Iraq', code: 'ar' },
        { name: 'Israel', code: 'he' },
        { name: 'Japan', code: 'ja' },
        { name: 'Jordan', code: 'ar' },
        { name: 'Kazakhstan', code: 'kk' },
        { name: 'Kuwait', code: 'ar' },
        { name: 'Kyrgyzstan', code: 'ky' },
        { name: 'Laos', code: 'lo' },
        { name: 'Lebanon', code: 'ar' },
        { name: 'Malaysia', code: 'ms' },
        { name: 'Maldives', code: 'dv' },
        { name: 'Mongolia', code: 'mn' },
        { name: 'Myanmar', code: 'my' },
        { name: 'Nepal', code: 'ne' },
        { name: 'North Korea', code: 'ko' },
        { name: 'Oman', code: 'ar' },
        { name: 'Pakistan', code: 'ur' },
        { name: 'Palestine', code: 'ar' },
        { name: 'Philippines', code: 'tl' },
        { name: 'Qatar', code: 'ar' },
        { name: 'Saudi Arabia', code: 'ar' },
        { name: 'Singapore', code: 'en' },
        { name: 'Sri Lanka', code: 'si' },
        { name: 'Syria', code: 'ar' },
        { name: 'Tajikistan', code: 'tg' },
        { name: 'Thailand', code: 'th' },
        { name: 'Timor-Leste', code: 'tet' },
        { name: 'Turkmenistan', code: 'tk' },
        { name: 'United Arab Emirates', code: 'ar' },
        { name: 'Uzbekistan', code: 'uz' },
        { name: 'Vietnam', code: 'vi' },
        { name: 'Yemen', code: 'ar' },
      ],
    },
    {
      name: 'Europe',
      countries: [
        { name: 'Albania', code: 'sq' },
        { name: 'Andorra', code: 'ca' },
        { name: 'Austria', code: 'de' },
        { name: 'Belarus', code: 'be' },
        { name: 'Belgium', code: 'fr' },
        { name: 'Bosnia and Herzegovina', code: 'bs' },
        { name: 'Bulgaria', code: 'bg' },
        { name: 'Croatia', code: 'hr' },
        { name: 'Czech Republic', code: 'cs' },
        { name: 'Denmark', code: 'da' },
        { name: 'Estonia', code: 'et' },
        { name: 'Finland', code: 'fi' },
        { name: 'France', code: 'fr' },
        { name: 'Germany', code: 'de' },
        { name: 'Greece', code: 'el' },
        { name: 'Hungary', code: 'hu' },
        { name: 'Iceland', code: 'is' },
        { name: 'Ireland', code: 'en' },
        { name: 'Italy', code: 'it' },
        { name: 'Latvia', code: 'lv' },
        { name: 'Liechtenstein', code: 'de' },
        { name: 'Lithuania', code: 'lt' },
        { name: 'Luxembourg', code: 'fr' },
        { name: 'Malta', code: 'mt' },
        { name: 'Moldova', code: 'ro' },
        { name: 'Monaco', code: 'fr' },
        { name: 'Montenegro', code: 'sr' },
        { name: 'Netherlands', code: 'nl' },
        { name: 'North Macedonia', code: 'mk' },
        { name: 'Norway', code: 'no' },
        { name: 'Poland', code: 'pl' },
        { name: 'Portugal', code: 'pt' },
        { name: 'Romania', code: 'ro' },
        { name: 'Russia', code: 'ru' },
        { name: 'San Marino', code: 'it' },
        { name: 'Serbia', code: 'sr' },
        { name: 'Slovakia', code: 'sk' },
        { name: 'Slovenia', code: 'si' },
        { name: 'Spain', code: 'es' },
        { name: 'Sweden', code: 'sv' },
        { name: 'Switzerland', code: 'de' },
        { name: 'Ukraine', code: 'uk' },
        { name: 'United Kingdom', code: 'en' },
        { name: 'Vatican City', code: 'it' },
      ],
    },
    {
      name: 'North America',
      countries: [
        { name: 'Antigua and Barbuda', code: 'en' },
        { name: 'Bahamas', code: 'en' },
        { name: 'Barbados', code: 'en' },
        { name: 'Belize', code: 'en' },
        { name: 'Canada', code: 'en' },
        { name: 'Costa Rica', code: 'es' },
        { name: 'Cuba', code: 'es' },
        { name: 'Dominica', code: 'en' },
        { name: 'Dominican Republic', code: 'es' },
        { name: 'El Salvador', code: 'es' },
        { name: 'Grenada', code: 'en' },
        { name: 'Guatemala', code: 'es' },
        { name: 'Haiti', code: 'fr' },
        { name: 'Honduras', code: 'es' },
        { name: 'Jamaica', code: 'en' },
        { name: 'Mexico', code: 'es' },
        { name: 'Nicaragua', code: 'es' },
        { name: 'Panama', code: 'es' },
        { name: 'Saint Kitts and Nevis', code: 'en' },
        { name: 'Saint Lucia', code: 'en' },
        { name: 'Saint Vincent and the Grenadines', code: 'en' },
        { name: 'United States', code: 'en' },
      ],
    },
    {
      name: 'Oceania',
      countries: [
        { name: 'Australia', code: 'en' },
        { name: 'Fiji', code: 'en' },
        { name: 'French Polynesia', code: 'fr' },
        { name: 'Kiribati', code: 'en' },
        { name: 'Marshall Islands', code: 'mh' },
        { name: 'Micronesia', code: 'en' },
        { name: 'Nauru', code: 'na' },
        { name: 'New Caledonia', code: 'fr' },
        { name: 'New Zealand', code: 'en' },
        { name: 'Papua New Guinea', code: 'en' },
        { name: 'Samoa', code: 'sm' },
        { name: 'Solomon Islands', code: 'en' },
        { name: 'Tonga', code: 'to' },
        { name: 'Tuvalu', code: 'tvl' },
        { name: 'Vanuatu', code: 'bi' },
      ],
    },
    {
      name: 'South America',
      countries: [
        { name: 'Argentina', code: 'es' },
        { name: 'Bolivia', code: 'es' },
        { name: 'Brazil', code: 'pt' },
        { name: 'Chile', code: 'es' },
        { name: 'Colombia', code: 'es' },
        { name: 'Ecuador', code: 'es' },
        { name: 'French Guiana', code: 'fr' },
        { name: 'Guyana', code: 'en' },
        { name: 'Paraguay', code: 'es' },
        { name: 'Peru', code: 'es' },
        { name: 'Suriname', code: 'nl' },
        { name: 'Uruguay', code: 'es' },
        { name: 'Venezuela', code: 'es' },
      ],
    },
  ];
  
  

  // Update this object with correct ISO country codes
  export const countryToISOCode = {
    'Afghanistan': 'af',
    'Albania': 'al',
    'Algeria': 'dz',
    'Andorra': 'ad',
    'Angola': 'ao',
    'Antigua and Barbuda': 'ag',
    'Argentina': 'ar',
    'Armenia': 'am',
    'Australia': 'au',
    'Austria': 'at',
    'Azerbaijan': 'az',
    'Bahamas': 'bs',
    'Bahrain': 'bh',
    'Bangladesh': 'bd',
    'Barbados': 'bb',
    'Belarus': 'by',
    'Belgium': 'be',
    'Belize': 'bz',
    'Benin': 'bj',
    'Bhutan': 'bt',
    'Bolivia': 'bo',
    'Bosnia and Herzegovina': 'ba',
    'Botswana': 'bw',
    'Brazil': 'br',
    'Brunei': 'bn',
    'Bulgaria': 'bg',
    'Burkina Faso': 'bf',
    'Burundi': 'bi',
    'Cabo Verde': 'cv',
    'Cambodia': 'kh',
    'Cameroon': 'cm',
    'Canada': 'ca',
    'Central African Republic': 'cf',
    'Chad': 'td',
    'Chile': 'cl',
    'China': 'cn',
    'Colombia': 'co',
    'Comoros': 'km',
    'Congo, Democratic Republic of the': 'cd',
    'Congo, Republic of the': 'cg',
    'Costa Rica': 'cr',
    "Côte d'Ivoire": 'ci',
    'Croatia': 'hr',
    'Cuba': 'cu',
    'Cyprus': 'cy',
    'Czech Republic': 'cz',
    'Denmark': 'dk',
    'Djibouti': 'dj',
    'Dominica': 'dm',
    'Dominican Republic': 'do',
    'Ecuador': 'ec',
    'Egypt': 'eg',
    'El Salvador': 'sv',
    'Equatorial Guinea': 'gq',
    'Eritrea': 'er',
    'Estonia': 'ee',
    'Eswatini': 'sz',
    'Ethiopia': 'et',
    'Fiji': 'fj',
    'Finland': 'fi',
    'France': 'fr',
    'French Guiana': 'gf',
    'French Polynesia': 'pf',
    'Gabon': 'ga',
    'Gambia': 'gm',
    'Georgia': 'ge',
    'Germany': 'de',
    'Ghana': 'gh',
    'Greece': 'gr',
    'Grenada': 'gd',
    'Guatemala': 'gt',
    'Guinea': 'gn',
    'Guinea-Bissau': 'gw',
    'Guyana': 'gy',
    'Haiti': 'ht',
    'Honduras': 'hn',
    'Hungary': 'hu',
    'Iceland': 'is',
    'India': 'in',
    'Indonesia': 'id',
    'Iran': 'ir',
    'Iraq': 'iq',
    'Ireland': 'ie',
    'Israel': 'il',
    'Italy': 'it',
    'Jamaica': 'jm',
    'Japan': 'jp',
    'Jordan': 'jo',
    'Kazakhstan': 'kz',
    'Kenya': 'ke',
    'Kiribati': 'ki',
    'Korea, North': 'kp',
    'Korea, South': 'kr',
    'Kuwait': 'kw',
    'Kyrgyzstan': 'kg',
    'Laos': 'la',
    'Latvia': 'lv',
    'Lebanon': 'lb',
    'Lesotho': 'ls',
    'Liberia': 'lr',
    'Libya': 'ly',
    'Liechtenstein': 'li',
    'Lithuania': 'lt',
    'Luxembourg': 'lu',
    'Madagascar': 'mg',
    'Malawi': 'mw',
    'Malaysia': 'my',
    'Maldives': 'mv',
    'Mali': 'ml',
    'Malta': 'mt',
    'Marshall Islands': 'mh',
    'Mauritania': 'mr',
    'Mauritius': 'mu',
    'Mexico': 'mx',
    'Micronesia': 'fm',
    'Moldova': 'md',
    'Monaco': 'mc',
    'Mongolia': 'mn',
    'Montenegro': 'me',
    'Morocco': 'ma',
    'Mozambique': 'mz',
    'Myanmar': 'mm',
    'Namibia': 'na',
    'Nauru': 'nr',
    'Nepal': 'np',
    'Netherlands': 'nl',
    'New Caledonia' : 'nc',
    'New Zealand': 'nz',
    'Nicaragua': 'ni',
    'Niger': 'ne',
    'Nigeria': 'ng',
    'North Korea': 'kp',
    'North Macedonia': 'mk',
    'Norway': 'no',
    'Oman': 'om',
    'Pakistan': 'pk',
    'Palau': 'pw',
    'Palestine': 'ps',
    'Panama': 'pa',
    'Papua New Guinea': 'pg',
    'Paraguay': 'py',
    'Peru': 'pe',
    'Philippines': 'ph',
    'Poland': 'pl',
    'Portugal': 'pt',
    'Qatar': 'qa',
    'Romania': 'ro',
    'Russia': 'ru',
    'Rwanda': 'rw',
    'Saint Kitts and Nevis': 'kn',
    'Saint Lucia': 'lc',
    'Saint Vincent and the Grenadines': 'vc',
    'Samoa': 'ws',
    'San Marino': 'sm',
    'Sao Tome and Principe': 'st',
    'Saudi Arabia': 'sa',
    'Senegal': 'sn',
    'Serbia': 'rs',
    'Seychelles': 'sc',
    'Sierra Leone': 'sl',
    'Singapore': 'sg',
    'Slovakia': 'sk',
    'Slovenia': 'si',
    'Solomon Islands': 'sb',
    'Somalia': 'so',
    'South Africa': 'za',
    'South Sudan': 'ss',
    'Spain': 'es',
    'Sri Lanka': 'lk',
    'Sudan': 'sd',
    'Suriname': 'sr',
    'Sweden': 'se',
    'Switzerland': 'ch',
    'Syria': 'sy',
    'Taiwan': 'tw',
    'Tajikistan': 'tj',
    'Tanzania': 'tz',
    'Thailand': 'th',
    'Timor-Leste': 'tl',
    'Togo': 'tg',
    'Tonga': 'to',
    'Trinidad and Tobago': 'tt',
    'Tunisia': 'tn',
    'Turkey': 'tr',
    'Turkmenistan': 'tm',
    'Tuvalu': 'tv',
    'Uganda': 'ug',
    'Ukraine': 'ua',
    'United Arab Emirates': 'ae',
    'United Kingdom': 'gb',
    'United States': 'us',
    'Uruguay': 'uy',
    'Uzbekistan': 'uz',
    'Vanuatu': 'vu',
    'Vatican City': 'va',
    'Venezuela': 've',
    'Vietnam': 'vn',
    'Yemen': 'ye',
    'Zambia': 'zm',
    'Zimbabwe': 'zw',
};

  
  