import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;
const TOKEN_KEY = 'auth_token';
const USER_DATA_KEY = 'user_data';
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(() => {
    const savedUserData = localStorage.getItem(USER_DATA_KEY);
    return savedUserData ? JSON.parse(savedUserData) : null;
  });
  const [loading, setLoading] = useState(true);

  // Set up axios defaults and interceptors
  useEffect(() => {
    // Set default base URL
    axios.defaults.baseURL = API_URL;
    
    // Request interceptor
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          const errorMessage = error.response?.data?.error?.toLowerCase() || '';
          if (errorMessage.includes('token') || errorMessage.includes('auth')) {
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(USER_DATA_KEY);
            setUser(null);
          }
        }
        return Promise.reject(error);
      }
    );
  }, []);

  const verifyToken = async (skipLoading = false) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      setUser(null);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get('/auth/verify-token');
      const userData = response.data.user;

      if (!userData || !userData.role) {
        throw new Error('Invalid user data');
      }

      localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
      setUser(userData);
    } catch (error) {
      console.error('Token verification failed:', error);
      if (error.response?.status === 401) {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(USER_DATA_KEY);
        setUser(null);
      }
    } finally {
      if (!skipLoading) setLoading(false);
    }
  };

  const saveUserData = (userData, token) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
    setUser(userData);
  };

  const updateUserData = (newData) => {
    if (!user) return;
    
    const updatedUser = { ...user, ...newData };
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(updatedUser));
    setUser(updatedUser);
  };

  const login = async (email, password) => {
    try {
      const response = await axios.post('/auth/login', { email, password });
      const { token, user: userData } = response.data;
      saveUserData(userData, token);
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Login failed');
    }
  };

  const adminLogin = async (email, password) => {
    try {
      const response = await axios.post('/auth/admin/login', { email, password });
      const { token, user: userData } = response.data;
      saveUserData(userData, token);
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Admin login failed');
    }
  };

  const brokerLogin = async (email, password) => {
    try {
      const response = await axios.post('/auth/broker/login', { email, password });
      const { token, user: userData } = response.data;
      saveUserData(userData, token);
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Broker login failed');
    }
  };

  const register = async (email, password, additionalData = {}) => {
    try {
      const response = await axios.post('/auth/register', {
        email,
        password,
        ...additionalData // Make sure all additionalData is spread here
      });
      
      const { token, user: userData } = response.data;
      saveUserData(userData, token);
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Registration failed');
    }
  };
  
  const adminRegister = async (email, password, adminKey) => {
    try {
      const response = await axios.post('/auth/admin/register', { 
        email, 
        password,
        adminKey
      });
      const { token, user: userData } = response.data;
      saveUserData(userData, token);
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Admin registration failed');
    }
  };

  const brokerRegister = async (brokerData) => {
    try {
      const response = await axios.post('/auth/broker/register', brokerData);
      const { token, user: userData } = response.data;
      saveUserData(userData, token);
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Broker registration failed');
    }
  };

  const updatePlan = async (newPlan) => {
    try {
      const response = await axios.post('/profile/update-plan', { newPlan });
      if (response.data.success) {
        updateUserData({ customerPlan: newPlan });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error updating plan:', error);
      throw new Error(error.response?.data?.error || 'Failed to update plan');
    }
  };

  const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_DATA_KEY);
    setUser(null);
  };

  useEffect(() => {
    verifyToken();

    const verificationInterval = setInterval(() => {
      if (localStorage.getItem(TOKEN_KEY)) {
        verifyToken(true);
      }
    }, 5 * 60 * 1000);

    const handleFocus = () => {
      if (localStorage.getItem(TOKEN_KEY)) {
        verifyToken(true);
      }
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      clearInterval(verificationInterval);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  const value = {
    user,
    login,
    adminLogin,
    brokerLogin,
    register,
    adminRegister,
    brokerRegister,
    updatePlan,
    updateUserData,
    logout,
    loading,
    isAdmin: user?.role === 'admin',
    isBroker: user?.role === 'broker'
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}