import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import './Reports.css';
import { SimpleLoad } from './LoadingPages/LoadingAnimation';

const Reports = ({ onBack }) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalReports, setTotalReports] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [lastVisibleId, setLastVisibleId] = useState(null);

  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    fetchStoreInfo();
  }, [user, navigate]);

  useEffect(() => {
    if (modifiedBrandName) {
      fetchReports();
    }
  }, [modifiedBrandName, pageSize, currentPage]);

  const fetchStoreInfo = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/reports/store-info');
      if (response.data && response.data.modifiedBrandName) {
        setModifiedBrandName(response.data.modifiedBrandName);
      } else {
        throw new Error('Invalid store data');
      }
    } catch (error) {
      console.error('Store info fetch error:', error);
      setError(error.response?.data?.error || 'Failed to access store information');
    }
  };

  const fetchReports = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/reports/reports/${modifiedBrandName}`, {
        params: {
          page: currentPage,
          pageSize,
          startAfter: lastVisibleId
        }
      });

      if (response.data) {
        setReports(response.data.reports);
        setTotalReports(response.data.totalReports);
        setLastVisibleId(response.data.lastVisible);
      }
    } catch (error) {
      console.error('Reports fetch error:', error);
      setError(error.response?.data?.error || 'Failed to load reports');
    } finally {
      setLoading(false);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
    setLastVisibleId(null);
  };

  const handleNextPage = () => {
    setCurrentPage(prev => prev + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handlePageNumberChange = (e) => {
    setPageNumber(e.target.value);
  };

  const handlePageNumberSubmit = () => {
    const page = parseInt(pageNumber, 10);
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const downloadPDF = async () => {
    try {
      const response = await axios.get(`/reports/export/${modifiedBrandName}`);
      const formattedData = response.data;

      const doc = new jsPDF();
      doc.autoTable({
        columns: [
          { header: 'Action', dataKey: 'action' },
          { header: 'Item', dataKey: 'item' },
          { header: 'Brand', dataKey: 'brand' },
          { header: 'Quantity', dataKey: 'quantity' },
          { header: 'Store', dataKey: 'store' },
          { header: 'Date', dataKey: 'date' }
        ],
        body: formattedData,
        margin: { top: 10 },
        theme: 'grid',
        headStyles: { fillColor: [66, 66, 66] }
      });

      doc.save("history_report.pdf");
    } catch (error) {
      console.error('PDF download error:', error);
      setError('Failed to download report');
    }
  };

  const totalPages = Math.ceil(totalReports / pageSize);



  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="reports-container-inner">
      <div className="privacy-header">
        <h2>History</h2>
        <div className="back-button-container">
          <button onClick={onBack} className="back-button">
            <span className="back-arrow">←</span> Back
          </button>
        </div>
      </div>
      <hr className="reports-container-hr"/>

      <div className="reports-controls">
        <div className="page-size-control">
          <label htmlFor="pageSize">Show:</label>
          <select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <button className="download-btn" onClick={downloadPDF}>Download PDF</button>
      </div>

      <div className="reports-table-container">
        <table className="reports-table">
          <thead>
            <tr>
              <th>Action</th>
              <th>Item</th>
              <th>Brand</th>
              <th>Quantity</th>
              <th>Store</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, index) => (
              <tr key={index}>
                <td>{report.action}</td>
                <td>{report.itemName || '-'}</td>
                <td>{report.brand || '-'}</td>
                <td>{report.quantity || '-'}</td>
                <td>{report.store || '-'}</td>
                <td>{new Date(report.timestamp).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="reports-pagination">
        <button 
          className="reports-pagination-button" 
          onClick={handlePreviousPage} 
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="reports-pagination-span">
          {`${currentPage} of ${totalPages}`}
        </span>
        <button 
          className="reports-pagination-button" 
          onClick={handleNextPage} 
          disabled={currentPage === totalPages}
        >
          Next
        </button>
        <input
          className="reports-pagination-input"
          type="number"
          value={pageNumber}
          onChange={handlePageNumberChange}
          min={1}
          max={totalPages}
          onBlur={handlePageNumberSubmit}
        />
        <button 
          className="reports-pagination-button" 
          onClick={handlePageNumberSubmit}
        >
          Go
        </button>
      </div>
    </div>
  );
};

export default Reports;