import React, { useState } from 'react';
import { X, Search, ChevronRight, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const MobileMenu = ({ isOpen, onClose, translate, handleLogin }) => {
  const [menuStack, setMenuStack] = useState([{ title: 'main', items: ['products', 'equipment', 'learning', 'community', 'partners', 'about'] }]);
  const navigate = useNavigate();

  const productSubmenus = {
    products: [
      { title: translate('by_category'), key: 'by_category' },
      { title: translate('by_industry'), key: 'by_industry' },
      { title: translate('business_technology_platform'), key: 'business_technology_platform' },
    ],
    by_category: [
      { title: translate('inventory_management'), key: 'inventory_management' },
      { title: translate('supply_chain_management') },
      { title: translate('financial_management') },
      { title: translate('human_capital_management') },
      { title: translate('crm_and_customer_experience') },
      { title: translate('view_products_az') },
    ],
    by_industry: [
      { title: translate('automotive') },
      { title: translate('banking') },
      { title: translate('consumer_products') },
      { title: translate('healthcare') },
      { title: translate('retail') },
      { title: translate('view_all_industries') },
    ],
    business_technology_platform: [
      { title: translate('application_development_and_automation') },
      { title: translate('data_and_analytics') },
      { title: translate('extended_planning_and_analysis') },
      { title: translate('integration') },
    ],
  };

  const handleMarketResearchClick = (e) => {
    e.preventDefault();
    window.location.href = 'https://market.daxtop.com/search';
    onClose();
  };

  const navigateToSubmenu = (key) => {
    if (key === 'products') {
      setMenuStack([...menuStack, { title: key, items: productSubmenus[key] }]);
    } else if (productSubmenus[key]) {
      setMenuStack([...menuStack, { title: key, items: productSubmenus[key] }]);
    } else if (key === 'inventory_management') {
      handleLogin();
      onClose();
    } else if (key === 'equipment') {
      handleMarketResearchClick(new Event('click'));
    }
  };

  const navigateBack = () => {
    if (menuStack.length > 1) {
      setMenuStack(menuStack.slice(0, -1));
    }
  };

  const handleItemClick = (item) => {
    if (typeof item === 'string') {
      navigateToSubmenu(item);
    } else if (item.key) {
      navigateToSubmenu(item.key);
    }
  };

  const currentMenu = menuStack[menuStack.length - 1];

  if (!isOpen) return null;

  return (
    <div className={`mobile-menu-container-l ${isOpen ? 'open' : ''}`}>
      <div className="mobile-menu-header-l">
        <div className="mobile-menu-search-l">
          <Search size={20} />
          <input type="text" placeholder={translate('search')} />
        </div>
        <button className="mobile-menu-close-l" onClick={onClose}>
          <X size={24} />
        </button>
      </div>
      <nav className="mobile-nav-l">
        {menuStack.length > 1 && (
          <div className="mobile-menu-back-l" onClick={navigateBack}>
            <ArrowLeft size={24} className='arrow-design' />
            <span>{translate('back')}</span>
          </div>
        )}
        <ul>
          {currentMenu.items.map((item, index) => (
            <li key={index} onClick={() => handleItemClick(item)}>
              <span>{typeof item === 'string' ? translate(item) : item.title}</span>
              {(item === 'products' || (typeof item === 'object' && productSubmenus[item.key])) && <ChevronRight size={20} />}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default MobileMenu;