import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { API_URL } from '../contexts/AuthContext';
import {CalendarCheck, Users, SquareCheckBig, DollarSign, SquareCheck} from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './BrokerDashboard.css';

function BrokerDashboard() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [brokerData, setBrokerData] = useState(null);
  const [stats, setStats] = useState({
    pendingLeads: 0,
    activePartners: 0,
    completedDeals: 0,
    revenue: 0,
    notifications: 0,
    clientCount: 0
  });
  const [recentActivities, setRecentActivities] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [performanceData, setPerformanceData] = useState({
    dailyRevenue: 0,
    weeklyRevenue: 0,
    monthlyRevenue: 0,
    yearlyRevenue: 0
  });
  const [revenueChart, setRevenueChart] = useState([]);
  const [showAllClients, setShowAllClients] = useState(false);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Show loading animation
        setLoading(true);

        // Fetch broker dashboard data
        const dashboardResponse = await axios.get(`${API_URL}/broker/dashboard`);
        setStats(dashboardResponse.data.stats || {
          pendingLeads: 8,
          activePartners: 12,
          completedDeals: 24,
          revenue: 45650,
          notifications: 3,
          clientCount: 15
        });

        // Fetch performance metrics
        setPerformanceData({
          dailyRevenue: 1200,
          weeklyRevenue: 8500,
          monthlyRevenue: 32000,
          yearlyRevenue: 240000
        });

       

        // Fetch broker profile from universalBroker collection
        const profileResponse = await axios.get(`${API_URL}/broker/profile`);
        if (profileResponse.data && profileResponse.data.profile) {
          setBrokerData(profileResponse.data.profile);
        }

        // Fetch recent activities (placeholder for now)
        setRecentActivities([
          { 
            id: 1, 
            type: 'lead', 
            action: 'New lead assigned', 
            entity: 'Stellar Retail Solutions', 
            date: new Date(2025, 2, 5),
            status: 'pending'
          },
          { 
            id: 2, 
            type: 'partner', 
            action: 'Partner application approved', 
            entity: 'ABC Investments', 
            date: new Date(2025, 2, 4),
            status: 'completed' 
          },
          { 
            id: 3, 
            type: 'deal', 
            action: 'Deal completed', 
            entity: 'Maven Coffee Chain', 
            date: new Date(2025, 2, 3),
            status: 'completed'
          },
          { 
            id: 4, 
            type: 'finance', 
            action: 'Commission received', 
            entity: '$2,400.00', 
            date: new Date(2025, 2, 2),
            status: 'completed'
          },
          { 
            id: 5, 
            type: 'client', 
            action: 'New client onboarded', 
            entity: 'Urban Threads Boutique', 
            date: new Date(2025, 2, 1),
            status: 'completed'
          }
        ]);
      } catch (error) {
        console.error('Error fetching broker data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchClients = async () => {
      try {
        setClientsLoading(true);
        const response = await axios.get(`${API_URL}/broker/clients`);
        
        // Sample data if API doesn't return any clients
        const sampleClients = [
          {
            id: 1,
            brandName: 'Urban Threads Boutique',
            storeType: 'Clothing',
            email: 'contact@urbanthreads.com',
            phone: '(555) 123-4567',
            status: 'Active',
            stores: 3,
            createdAt: new Date(2025, 1, 15)
          },
          {
            id: 2,
            brandName: 'Maven Coffee Chain',
            storeType: 'Cafe',
            email: 'info@mavencoffee.com',
            phone: '(555) 987-6543',
            status: 'Active',
            stores: 5,
            createdAt: new Date(2025, 0, 28)
          },
          {
            id: 3,
            brandName: 'Tech Outpost',
            storeType: 'Electronics',
            email: 'sales@techoutpost.com',
            phone: '(555) 456-7890',
            status: 'Pending',
            stores: 1,
            createdAt: new Date(2025, 2, 4)
          },
          {
            id: 4,
            brandName: 'Fresh Market Grocery',
            storeType: 'Grocery',
            email: 'orders@freshmarket.com',
            phone: '(555) 234-5678',
            status: 'Active',
            stores: 2,
            createdAt: new Date(2025, 1, 20)
          },
          {
            id: 5,
            brandName: 'Fitness Gear Pro',
            storeType: 'Sports Equipment',
            email: 'support@fitnessgear.com',
            phone: '(555) 345-6789',
            status: 'Active',
            stores: 1,
            createdAt: new Date(2025, 0, 10)
          }
        ];
        
        setClients(response.data?.clients || sampleClients);
      } catch (error) {
        console.error('Error fetching clients:', error);
        // Fallback to sample clients if there's an error
        const sampleClients = [
          {
            id: 1,
            brandName: 'Urban Threads Boutique',
            storeType: 'Clothing',
            email: 'contact@urbanthreads.com',
            phone: '(555) 123-4567',
            status: 'Active',
            stores: 3,
            createdAt: new Date(2025, 1, 15)
          }
        ];
        setClients(sampleClients);
      } finally {
        setClientsLoading(false);
      }
    };

    fetchDashboardData();
    fetchClients();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };

  const formatTimeAgo = (date) => {
    if (!date) return 'N/A';
    try {
      const now = new Date();
      const diffInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
      
      if (diffInDays === 0) return 'Today';
      if (diffInDays === 1) return 'Yesterday';
      if (diffInDays < 7) return `${diffInDays} days ago`;
      return formatDate(date);
    } catch (error) {
      console.error('Error calculating time ago:', error);
      return 'N/A';
    }
  };

  const formatCurrency = (amount) => {
    try {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount || 0);
    } catch (error) {
      console.error('Error formatting currency:', error);
      return '$0.00';
    }
  };

  // Calculate percentage change
  const calculateChange = (current, previous) => {
    if (!previous) return { value: 0, isPositive: true };
    try {
      const change = ((current - previous) / previous) * 100;
      return {
        value: Math.abs(change).toFixed(1),
        isPositive: change >= 0
      };
    } catch (error) {
      console.error('Error calculating change:', error);
      return { value: 0, isPositive: true };
    }
  };

  // Generate referral link
  const generateReferralLink = () => {
    try {
      const baseUrl = window.location.origin;
      return `${baseUrl}/register/${user?.brokerID || (brokerData && brokerData.brokerID) || 'BRO123'}`;
    } catch (error) {
      console.error('Error generating referral link:', error);
      return window.location.origin + '/register/BRO123';
    }
  };

  const copyReferralLink = () => {
    try {
      const link = generateReferralLink();
      navigator.clipboard.writeText(link)
        .then(() => {
          // Use a toast notification instead of alert in a real application
          alert('Referral link copied to clipboard!');
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
          alert('Failed to copy to clipboard. Please try again.');
        });
    } catch (error) {
      console.error('Error in copyReferralLink:', error);
      alert('Something went wrong. Please try again.');
    }
  };

  // Toggle show all clients
  const toggleShowAllClients = () => {
    setShowAllClients(!showAllClients);
  };

  // Get first letter safely
  const getFirstLetter = (str) => {
    if (!str || typeof str !== 'string') return 'B';
    return str.charAt(0).toUpperCase();
  };

  // Safe toLowerCase
  const safeToLowerCase = (str) => {
    if (!str || typeof str !== 'string') return '';
    return str.toLowerCase();
  };

  const RevenueChart = () => {
    // Sample data from the original code
    const revenueData = [
      { month: 'Jan', revenue: 14200 },
      { month: 'Feb', revenue: 18500 },
      { month: 'Mar', revenue: 22000 },
      { month: 'Apr', revenue: 25400 },
      { month: 'May', revenue: 32000 },
      { month: 'Jun', revenue: 28500 }
    ];
  
    // Format currency for the tooltip and axis
    const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value);
    };
  
    // Custom tooltip to match the design
    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
        return (
          <div style={{
            backgroundColor: 'white',
            padding: '8px 12px',
            border: '1px solid #e2e8f0',
            borderRadius: '6px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            fontSize: '13px'
          }}>
            <p style={{ margin: 0, fontWeight: 600 }}>{formatCurrency(payload[0].value)}</p>
          </div>
        );
      }
      return null;
    };
    
    return (
      <div className="chart-card w-full">
        <div className="card-header flex justify-between items-center p-5 border-b border-gray-100">
          <h2 className="text-lg font-semibold text-gray-800">Revenue Trend</h2>
          <div className="card-actions">
            <select className="bg-white border border-gray-200 rounded-md py-1.5 px-3 text-sm text-gray-600">
              <option>Last 6 Months</option>
              <option>This Year</option>
              <option>Last Year</option>
            </select>
          </div>
        </div>
        
        <div className="p-5" style={{ height: '280px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={revenueData}
              margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
            >
              <CartesianGrid vertical={false} strokeDasharray="3 3" stroke="#edf2f7" />
              <XAxis 
                dataKey="month" 
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#718096', fontSize: 13 }}
                dy={10}
              />
              <YAxis 
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#718096', fontSize: 13 }}
                tickFormatter={formatCurrency}
                width={80}
              />
              <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(25, 51, 99, 0.05)' }} />
              <Bar 
                dataKey="revenue" 
                fill="url(#colorGradient)" 
                radius={[4, 4, 0, 0]}
                barSize={40}
              />
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#204179" />
                  <stop offset="100%" stopColor="#1091cc" />
                </linearGradient>
              </defs>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  const getClientFullName = (client) => {
    // If client has fullName property, use it
    if (client.fullName) {
      return client.fullName;
    }
    
    // Otherwise construct from individual name parts
    const first = client.firstName || '';
    const middle = client.middleName ? ` ${client.middleName} ` : ' ';
    const last = client.lastName || '';
    
    // If we have any name parts, return them joined
    if (first || last) {
      return `${first}${middle}${last}`.trim();
    }
    
    // Default fallback to email or brandName
    return client.brandName || client.email || 'Unknown';
  };

  return (
    <div className="broker-dashboard">
      
        <>
          <div className="dashboard-header">
            <div className="welcome-section">
              <h1>Welcome back, {brokerData?.fullName || user?.name || 'Partner'}</h1>
              <p className="dashboard-subtitle">Here's your business overview for {new Date().toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}</p>
            </div>
            <div className="header-actions">
              <button className="header-action-button">
                <i className="action-icon schedule-icon"></i>
                Schedule Demo
              </button>
              <button className="header-action-button primary">
                <i className="action-icon add-client-icon"></i>
                Add New Client
              </button>
            </div>
          </div>

        

          <div className="metrics-section">
            <div className="metrics-grid">
              <div className="metric-card">
                <div className="metric-icon"><Users size={26} /></div>
                <div className="metric-content">
                  <div className="metric-value">{stats.clientCount || clients.length || 0}</div>
                  <div className="metric-label">Total Clients</div>
                </div>
              </div>
              
              <div className="metric-card">
                <div className="metric-icon"> <CalendarCheck size={26}/> </div>
                <div className="metric-content">
                  <div className="metric-value">0</div>
                  <div className="metric-label">Upcoming Meetings</div>
                </div>
              </div>
              
              <div className="metric-card">
                <div className="metric-icon"><SquareCheck size={26} /></div>
                <div className="metric-content">
                  <div className="metric-value">{stats.completedDeals || 0}</div>
                  <div className="metric-label">Completed Deals</div>
                </div>
              </div>
              
              <div className="metric-card">
                <div className="metric-icon"> <DollarSign size={26} /></div>
                <div className="metric-content">
                  <div className="metric-value">{formatCurrency(stats.revenue)}</div>
                  <div className="metric-label">Total Revenue</div>
                </div>
              </div>
            </div>
          </div>

          <div className="dashboard-content">
            <div className="dashboard-column left">
              {/* Revenue Chart Card */}
              <div className="chart-card">
               
                <div className="chart-container">
                  {/* In a real application, you would use a charting library like Chart.js, Recharts, etc. */}
                   
                      <RevenueChart />
                </div>
              </div>
              
              {/* Referral Link Card */}
              <div className="referral-card">
                <div className="card-header">
                  <h2>Your Referral Program</h2>
                  <div className="card-subtitle">Generate more business by referring POS clients</div>
                </div>
                <div className="referral-link-container">
                  <div className="referral-stats">
                    <div className="referral-stat">
                      <div className="referral-stat-value">{clients.length || 0}</div>
                      <div className="referral-stat-label">Total Referrals</div>
                    </div>
                    <div className="referral-stat">
                      <div className="referral-stat-value">3</div>
                      <div className="referral-stat-label">This Month</div>
                    </div>
                    <div className="referral-stat">
                      <div className="referral-stat-value">{formatCurrency(8540)}</div>
                      <div className="referral-stat-label">Commissions Earned</div>
                    </div>
                  </div>
                  <div className="referral-link-input-container">
                    <input 
                      type="text" 
                      readOnly 
                      value={generateReferralLink()} 
                      className="referral-link-input"
                    />
                    <button 
                      onClick={copyReferralLink}
                      className="copy-link-button"
                    >
                      <i className="copy-icon"></i>
                      Copy Link
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="dashboard-column right">
              {/* Broker Profile Card */}
              <div className="broker-profile-card">
                <div className="broker-profile-header">
                  <div className="broker-profile-avatar">
                    {getFirstLetter(brokerData?.fullName || user?.name)}
                  </div>
                  <div className="broker-profile-info">
                    <h3 className="broker-profile-name">{brokerData?.fullName || user?.name || 'Your Name'}</h3>
                    <p className="broker-profile-title">Senior Partner</p>
                    <p className="broker-profile-id">ID: {user?.brokerID || (brokerData && brokerData.brokerID) || 'BR-00123'}</p>
                    <div className="broker-profile-status">
                      <span className="status-indicator active"></span>Active Account
                    </div>
                  </div>
                  <Link to="/broker/profile" className="broker-profile-edit-button">
                    <i className="edit-icon"></i>
                  </Link>
                </div>
                <div className="broker-profile-details">
                  <div className="broker-profile-detail">
                    <i className="detail-icon email-icon"></i>
                    <div className="detail-content">
                      <div className="detail-label">Email</div>
                      <div className="detail-value">{user?.email || 'broker@example.com'}</div>
                    </div>
                  </div>
                  <div className="broker-profile-detail">
                    <i className="detail-icon phone-icon"></i>
                    <div className="detail-content">
                      <div className="detail-label">Phone</div>
                      <div className="detail-value">{brokerData?.primaryPhoneNumber || '(555) 123-4567'}</div>
                    </div>
                  </div>
                  <div className="broker-profile-detail">
                    <i className="detail-icon calendar-icon"></i>
                    <div className="detail-content">
                      <div className="detail-label">Member Since</div>
                      <div className="detail-value">{formatDate(user?.createdAt || '2024-06-15')}</div>
                    </div>
                  </div>
                  <div className="broker-profile-detail">
                    <i className="detail-icon location-icon"></i>
                    <div className="detail-content">
                      <div className="detail-label">Location</div>
                      <div className="detail-value">{brokerData?.location || 'San Francisco, CA'}</div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Recent Activities Card */}
              <div className="activities-card">
                <div className="card-header">
                  <h2>Recent Activities</h2>
                  <Link to="/broker/activities" className="view-all-link">View all</Link>
                </div>
                <div className="activities-list">
                  {recentActivities.length > 0 ? (
                    recentActivities.map(activity => (
                      <div key={activity.id} className={`activity-item ${activity.status || ''}`}>
                        <div className={`activity-icon ${activity.type || ''}-activity-icon`}></div>
                        <div className="activity-content">
                          <div className="activity-header">
                            <div className="activity-title">{activity.action || 'Activity'}</div>
                            <div className="activity-time">{formatTimeAgo(activity.date)}</div>
                          </div>
                          <div className="activity-entity">{activity.entity || ''}</div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="empty-state">
                      <p>No recent activities</p>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Recent Clients Card */}
              <div className="clients-card">
                <div className="card-header">
                  <h2>Recent Clients</h2>
                  <Link to="/broker/clients" className="view-all-link">View all</Link>
                </div>
                
                <div className="clients-list">
                  {clientsLoading ? (
                    <div className="loading-indicator">
                      <span>Loading clients...</span>
                    </div>
                  ) : clients.length > 0 ? (
                    (showAllClients ? clients : clients.slice(0, 3)).map((client, index) => (
                      <div key={index} className="client-item">
                        <div className="client-avatar">
                          {getFirstLetter(client.brandName)}
                        </div>
                        <div className="client-content">
                          <div className="client-name">{getClientFullName(client) || 'Unnamed Client'}</div>
                          <div className="client-details">
                            <span className="client-type">{client.storeType || 'Retail'}</span>
                            <span className="client-separator">•</span>
                            <span className="client-stores">{client.stores || 1} {(client.stores || 1) === 1 ? 'Store' : 'Stores'}</span>
                          </div>
                          <div className="client-contact">
                            <span className="client-email">{client.email || 'No email'}</span>
                            <span className="client-separator">•</span>
                            <span className="client-phone">{client.phone || 'No phone'}</span>
                          </div>
                        </div>
                        <div className={`client-status ${safeToLowerCase(client.status || 'pending')}`}>
                          {client.status || 'Pending'}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="empty-state">
                      <div className="empty-icon clients-empty-icon"></div>
                      <h3>No clients yet</h3>
                      <p>Start sharing your referral link with potential clients to grow your network.</p>
                      <button 
                        className="primary-button" 
                        onClick={copyReferralLink}
                      >
                        Copy Referral Link
                      </button>
                    </div>
                  )}
                  
                  {clients.length > 3 && (
                    <button 
                      className="see-all-link" 
                      onClick={toggleShowAllClients}
                    >
                      {showAllClients ? 'Show less' : 'Show all clients'}
                      <i className={`arrow-icon ${showAllClients ? 'up' : 'down'}`}></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      
    </div>
  );
}

export default BrokerDashboard;