import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';
import ProfilePrivacy from './ProfilePrivacy';
import Reports from './AccountHistory';
import BillingPlans from './BillingPlans';

const ProfileSettings = ({ storeInfo, onBack }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [error, setError] = useState('');
  
  const handleSectionClick = (section) => {
    setActiveSection(section);
  };
  
  const renderContent = () => {
    switch (activeSection) {
      case 'privacy':
        return <ProfilePrivacy storeInfo={storeInfo} onBack={() => setActiveSection(null)} />;
      case 'history':
        return <Reports onBack={() => setActiveSection(null)} />;
      case 'billing':
        return <BillingPlans onBack={() => setActiveSection(null)} />;
      default:
        return (
          <>
            <div className="privacy-header">
              <h2>Profile Settings</h2>
              <div className="back-button-container">
                <button onClick={onBack} className="back-button">
                  <span className="back-arrow">←</span> Back
                </button>
              </div>
            </div>

            {error && <div className="error-message">{error}</div>}
            
            <div className="settings-list">
              <div className="settings-item" onClick={() => handleSectionClick('privacy')}>
                <span>Account Privacy</span>
                <span className="settings-arrow">›</span>
              </div>
              
              <div className="settings-item" onClick={() => handleSectionClick('billing')}>
                <span>Billing & Plans</span>
                <span className="settings-arrow">›</span>
              </div>
              
              <div className="settings-item">
                <span>Supplier Search and Permits</span>
                <span className="settings-arrow">›</span>
              </div>

              <div className="settings-item" onClick={() => handleSectionClick('history')}>
                <span>Activity History</span>
                <span className="settings-arrow">›</span>
              </div>
              
              <div className="settings-item">
                <span>Language & Date</span>
                <span className="settings-arrow">›</span>
              </div>
              
              <div className="settings-item">
                <span>Setting 4</span>
                <span className="settings-arrow">›</span>
              </div>
              
              <div className="settings-item">
                <span>Setting 5</span>
                <span className="settings-arrow">›</span>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="profile-container">
      {renderContent()}
    </div>
  );
};

export default ProfileSettings;