import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { ArrowUpDown, SquarePen  } from 'lucide-react';
import ConfirmationDialog from './ConfirmationDialog';
import { translations } from './Data/data'; // Import translations
import './ItemRegister.css';

// Convert arrays to translation keys
const categoryKeys = [
  'groceries', 'beverages', 'household_products', 'personal_care_items', 
  'snacks', 'frozen_foods', 'health_and_wellness_products', 'electronics', 
  'pet_supplies', 'office_supplies', 'raw_materials'
];

const weightUnitKeys = [
  'kilogram', 'gram', 'ounce', 'pound', 'na'
];

const volumeUnitKeys = [
  'liter', 'milliliter', 'fluid_ounce', 'cubic_meter', 'na'
];

const expirationPeriodUnitKeys = [
  'days', 'weeks', 'months', 'years', 'na'
];

// Helper function to create a mapping between translated values and keys
const createKeysMapping = (keys, language) => {
  const toKeyMap = {};
  const toValueMap = {};
  
  keys.forEach(key => {
    const translatedValue = translations[language]?.[key] || translations['en'][key];
    toKeyMap[translatedValue] = key;
    toValueMap[key] = translatedValue;
  });
  
  return {
    toKeyMap,   // Maps from translated value to key
    toValueMap  // Maps from key to translated value
  };
};

// Function to get a key from a value in any language
const getKeyFromValue = (value, keys) => {
  // Get mappings for all supported languages
  const allMappings = Object.keys(translations).map(lang => 
    createKeysMapping(keys, lang).toKeyMap
  );
  
  // Try to find the key in any language
  for (const mapping of allMappings) {
    if (mapping[value]) {
      return mapping[value];
    }
  }
  
  // Return original value as fallback
  return value;
};

const ItemRegister = () => {
  const [language, setLanguage] = useState('en');
  
  // Create mappings for different selectable values
  const [categoryMappings, setCategoryMappings] = useState({
    toKeyMap: {},
    toValueMap: {}
  });
  
  const [weightUnitMappings, setWeightUnitMappings] = useState({
    toKeyMap: {},
    toValueMap: {}
  });
  
  const [volumeUnitMappings, setVolumeUnitMappings] = useState({
    toKeyMap: {},
    toValueMap: {}
  });
  
  const [expirationUnitMappings, setExpirationUnitMappings] = useState({
    toKeyMap: {},
    toValueMap: {}
  });
  
  // Translation helper function
  const translate = (key) => {
    return translations[language]?.[key] || translations['en'][key] || key;
  };
  
  // Replace formatting strings with a helper function 
  const formatMessage = (message, ...args) => {
    return message.replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] !== 'undefined' ? args[number] : match;
    });
  };

  // Update mappings when language changes
  useEffect(() => {
    setCategoryMappings(createKeysMapping(categoryKeys, language));
    setWeightUnitMappings(createKeysMapping(weightUnitKeys, language));
    setVolumeUnitMappings(createKeysMapping(volumeUnitKeys, language));
    setExpirationUnitMappings(createKeysMapping(expirationPeriodUnitKeys, language));
  }, [language]);

  // Refs for input focus management
  const barcodeInputRef = useRef(null);
  const nameInputRef = useRef(null);

  // State variables
  const [registeredItems, setRegisteredItems] = useState([]);
  const [filter, setFilter] = useState('');
  const [itemData, setItemData] = useState({
    barcode: '',
    name: '',
    brand: '',
    category: '',
    categoryKey: '',
    unitOfMeasureForWeight: 'na',
    weightUnitKey: 'na',
    unitOfMeasureForVolume: 'na',
    volumeUnitKey: 'na',
    isUnitOrPack: 'unit', // 'unit' or 'pack'
    unitsInPack: '1',
    weight: '',
    volume: '',
    costPrice: '',
    sku: '',
    expirationPeriod: '', // The numeric part of the expiration
    expirationUnit: 'days', // Days, Weeks, Months, Years, N/A
    expirationUnitKey: 'days',
    noExpiryDate: false
  });
  
  const [editingId, setEditingId] = useState(null);
  const [brandName, setBrandName] = useState('');
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [modifiedBrandName, setModifiedBrandName] = useState('');

  // For filtering
  const [filteredItems, setFilteredItems] = useState([]);
  
  // For barcode input handling
  const [barcodeInputActive, setBarcodeInputActive] = useState(false);
  
  // For Registered Items modal
  const [showRegisteredItemsModal, setShowRegisteredItemsModal] = useState(false);
  const [registeredItemsSearchTerm, setRegisteredItemsSearchTerm] = useState('');
  const [registeredItemsFilterCategory, setRegisteredItemsFilterCategory] = useState('all_categories');
  const [registeredItemsFilterBrand, setRegisteredItemsFilterBrand] = useState('all_brands');
  const [registeredItemsSortBy, setRegisteredItemsSortBy] = useState('alphabetical');
  const [registeredItemsSortOrder, setRegisteredItemsSortOrder] = useState('ascending');
  const [filteredRegisteredItems, setFilteredRegisteredItems] = useState([]);
  const [brands, setBrands] = useState(['all_brands']);
  
  const navigate = useNavigate();
  const { user } = useAuth();

  // Initial data fetching
  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    fetchStoreInfo();
  }, [user, navigate]);

  useEffect(() => {
    if (brandName && selectedStore) {
      fetchRegisteredItems();
    }
  }, [brandName, selectedStore]);

  // Filter items based on search term
  useEffect(() => {
    filterItems();
  }, [registeredItems, filter]);

  // Focus barcode input on mount
  useEffect(() => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  }, []);

  // Handle barcode input blur - check for existing template item
  useEffect(() => {
    if (!barcodeInputActive && itemData.barcode) {
      checkForTemplateItem(itemData.barcode);
    }
  }, [barcodeInputActive, itemData.barcode]);

  // Filter and sort registered items when filters change
  useEffect(() => {
    filterAndSortRegisteredItems();
  }, [registeredItems, registeredItemsSearchTerm, registeredItemsFilterCategory, registeredItemsFilterBrand, registeredItemsSortBy, registeredItemsSortOrder]);

  const filterAndSortRegisteredItems = () => {
    if (!registeredItems.length) return;

    let filtered = registeredItems.filter(item => {
      // Search in both name and barcode fields
      const searchInName = item.name.toLowerCase().includes(registeredItemsSearchTerm.toLowerCase());
      const searchInBarcode = item.barcode && item.barcode.includes(registeredItemsSearchTerm);
      
      return (searchInName || searchInBarcode) &&
        (registeredItemsFilterCategory === 'all_categories' || item.categoryKey === registeredItemsFilterCategory) &&
        (registeredItemsFilterBrand === 'all_brands' || item.brand === registeredItemsFilterBrand);
    });

    filtered.sort((a, b) => {
      let comparison = 0;

      switch (registeredItemsSortBy) {
        case 'alphabetical':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'brand':
          comparison = a.brand.localeCompare(b.brand);
          break;
        case 'category':
          comparison = a.category.localeCompare(b.category);
          break;
        case 'barcode':
          comparison = a.barcode.localeCompare(b.barcode);
          break;
        default:
          comparison = 0;
      }
      
      return registeredItemsSortOrder === 'ascending' ? comparison : -comparison;
    });

    setFilteredRegisteredItems(filtered);
  };

  const toggleSortOrder = () => {
    setRegisteredItemsSortOrder(prevOrder => prevOrder === 'ascending' ? 'descending' : 'ascending');
  };

  const fetchStoreInfo = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await axios.get('/items/store-info');

      if (response.data.success && response.data.data) {
        const { brandName, modifiedBrandName, stores } = response.data.data;
        setBrandName(brandName);
        setModifiedBrandName(modifiedBrandName);
        setStores(stores);
        if (stores.length > 0) {
          setSelectedStore(stores[0]);
        }
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Store info fetch error:', error);
      setError(error.response?.data?.error || error.message || translate('failed_fetch_store'));
    } finally {
      setLoading(false);
    }
  };

  const fetchRegisteredItems = async () => {
    try {
      setError('');
      const response = await axios.get('/items/template-items', {
        params: { 
          modifiedBrandName,
          selectedStore 
        }
      });
      
      // Process the items to add language-independent keys
      const processedItems = response.data.map(item => {
        // Add keys for each translatable field
        const categoryKey = getKeyFromValue(item.category, categoryKeys);
        const weightUnitKey = getKeyFromValue(item.unitOfMeasureForWeight, weightUnitKeys);
        const volumeUnitKey = getKeyFromValue(item.unitOfMeasureForVolume, volumeUnitKeys);
        const expirationUnitKey = getKeyFromValue(item.expirationUnit, expirationPeriodUnitKeys);
        
        return {
          ...item,
          categoryKey,
          weightUnitKey,
          volumeUnitKey,
          expirationUnitKey
        };
      });
      
      setRegisteredItems(processedItems);
      setFilteredItems(processedItems);
      setFilteredRegisteredItems(processedItems);
      
      // Update brands list for filtering
      const uniqueBrands = ['all_brands', ...new Set(processedItems.map(item => item.brand).filter(Boolean))];
      setBrands(uniqueBrands);
    } catch (error) {
      console.error('Error fetching registered items:', error);
      setError(translate('failed_fetch_registered'));
    }
  };

  const checkForTemplateItem = async (barcode) => {
    if (!barcode || !modifiedBrandName || !selectedStore) return;
    
    try {
      const response = await axios.get('/items/template-item-by-barcode', {
        params: {
          modifiedBrandName,
          selectedStore,
          barcode
        }
      });
      
      if (response.data && response.data.success && response.data.item) {
        // We found a template item, populate the form
        const templateItem = response.data.item;
        
        // Find keys for all translatable fields
        const categoryKey = getKeyFromValue(templateItem.category, categoryKeys);
        const weightUnitKey = getKeyFromValue(templateItem.unitOfMeasureForWeight, weightUnitKeys);
        const volumeUnitKey = getKeyFromValue(templateItem.unitOfMeasureForVolume, volumeUnitKeys);
        const expirationUnitKey = getKeyFromValue(templateItem.expirationUnit, expirationPeriodUnitKeys);
        
        setItemData({
          ...templateItem,
          isUnitOrPack: templateItem.isUnitOrPack || 'unit',
          unitsInPack: templateItem.unitsInPack || '1',
          // Store the keys for language-independent handling
          categoryKey,
          weightUnitKey,
          volumeUnitKey,
          expirationUnitKey
        });
        
        setEditingId(templateItem.id);
        setSuccess(translate('template_item_found'));
      }
    } catch (error) {
      // Silently handle the 404 error when item is not found
      // No console errors or user notifications
      if (error.response && error.response.status === 404) {
        // Do nothing, this is expected behavior
        return;
      }
      
      // Only log truly unexpected errors
      if (error.response && error.response.status !== 404) {
        console.log('Unexpected error checking for template item:', error.message);
      }
    }
  };

  const filterItems = () => {
    if (!registeredItems.length) return;

    if (!filter.trim()) {
      setFilteredItems(registeredItems);
      return;
    }
    
    const searchTerm = filter.toLowerCase();
    const filtered = registeredItems.filter(item => 
      item.name?.toLowerCase().includes(searchTerm) || 
      item.brand?.toLowerCase().includes(searchTerm) || 
      item.barcode?.toLowerCase().includes(searchTerm)
    );
    
    setFilteredItems(filtered);
  };

  const handleBarcodeSearch = async () => {
    if (!itemData.barcode.trim()) {
      setError(translate('enter_barcode'));
      return;
    }

    try {
      await checkForTemplateItem(itemData.barcode);
    } catch (error) {
      console.error('Error searching barcode:', error);
      setError(translate('error_searching_barcode'));
    }
  };

  const handleConfirm = async () => {
    setShowConfirmation(false);
    if (confirmAction) {
      await confirmAction();
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    
    setConfirmAction(() => saveItem);
    setShowConfirmation(true);
  };

  const saveItem = async () => {
    try {
      setSuccess('');
      
      // Validate required fields
      if (!itemData.barcode || !itemData.name || !itemData.brand || !itemData.categoryKey) {
        setError(translate('fill_required_fields'));
        return;
      }
      
      // Prepare data for saving - translate keys to current language values for backend
      const translatedCategoryValue = categoryMappings.toValueMap[itemData.categoryKey] || itemData.category;
      const translatedWeightUnitValue = weightUnitMappings.toValueMap[itemData.weightUnitKey] || itemData.unitOfMeasureForWeight;
      const translatedVolumeUnitValue = volumeUnitMappings.toValueMap[itemData.volumeUnitKey] || itemData.unitOfMeasureForVolume;
      const translatedExpirationUnitValue = expirationUnitMappings.toValueMap[itemData.expirationUnitKey] || itemData.expirationUnit;
      
      const itemToSave = {
        ...itemData,
        category: translatedCategoryValue,
        unitOfMeasureForWeight: translatedWeightUnitValue,
        unitOfMeasureForVolume: translatedVolumeUnitValue,
        expirationUnit: translatedExpirationUnitValue,
        costPrice: parseFloat(itemData.costPrice) || 0,
        unitsInPack: itemData.isUnitOrPack === 'pack' ? parseInt(itemData.unitsInPack) || 1 : 1,
        // Include keys for future editing
        categoryKey: itemData.categoryKey,
        weightUnitKey: itemData.weightUnitKey,
        volumeUnitKey: itemData.volumeUnitKey,
        expirationUnitKey: itemData.expirationUnitKey
      };

      let response;
      
      if (editingId) {
        response = await axios.put(`/items/update-template-item/${editingId}`, {
          modifiedBrandName,
          selectedStore,
          itemData: itemToSave
        });
        setSuccess(formatMessage(translate('item_updated_registry'), itemData.name));
      } else {
        response = await axios.post('/items/register-template-item', {
          modifiedBrandName,
          selectedStore,
          itemData: itemToSave
        });
        setSuccess(formatMessage(translate('item_added_registry'), itemData.name));
      }
      
      resetForm();
      fetchRegisteredItems();
      
      // Focus on barcode input after adding
      if (barcodeInputRef.current) {
        barcodeInputRef.current.focus();
      }
      
      // Clear success message after 2 seconds
      setTimeout(() => {
        setSuccess('');
      }, 2000);
    } catch (error) {
      console.error('Error saving registered item:', error);
      setError(translate('failed_save_registry'));
    }
  };

  const handleDelete = (item) => {
    setConfirmAction(() => async () => {
      try {
        await axios.delete(`/items/delete-template-item/${item.id}`, {
          data: {
            modifiedBrandName,
            selectedStore
          }
        });
        setSuccess(formatMessage(translate('item_removed_registry'), item.name));
        fetchRegisteredItems();
        
        // Clear success message after 2 seconds
        setTimeout(() => {
          setSuccess('');
        }, 2000);
      } catch (error) {
        console.error('Error deleting registered item:', error);
        setError(translate('failed_delete_registry'));
      }
    });
    setShowConfirmation(true);
  };

  const resetForm = () => {
    setItemData({
      barcode: '',
      name: '',
      brand: '',
      category: '',
      categoryKey: '',
      unitOfMeasureForWeight: translate('na'),
      weightUnitKey: 'na',
      unitOfMeasureForVolume: translate('na'),
      volumeUnitKey: 'na',
      isUnitOrPack: 'unit',
      unitsInPack: '1',
      weight: '',
      volume: '',
      costPrice: '',
      sku: '',
      expirationPeriod: '',
      expirationUnit: translate('days'),
      expirationUnitKey: 'days',
      noExpiryDate: false
    });
    setEditingId(null);
    
    // Focus on barcode input
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      setItemData(prevData => ({ ...prevData, [name]: checked }));
      return;
    }
    
    // Handle select dropdowns with keys
    if (name === 'category') {
      const selectedKey = value; // The value is already the key in our select options
      const translatedValue = categoryMappings.toValueMap[selectedKey] || value;
      
      setItemData(prevData => ({ 
        ...prevData, 
        categoryKey: selectedKey,
        category: translatedValue
      }));
    } 
    else if (name === 'unitOfMeasureForWeight') {
      const selectedKey = value;
      const translatedValue = weightUnitMappings.toValueMap[selectedKey] || value;
      
      setItemData(prevData => ({ 
        ...prevData, 
        weightUnitKey: selectedKey,
        unitOfMeasureForWeight: translatedValue
      }));
    }
    else if (name === 'unitOfMeasureForVolume') {
      const selectedKey = value;
      const translatedValue = volumeUnitMappings.toValueMap[selectedKey] || value;
      
      setItemData(prevData => ({ 
        ...prevData, 
        volumeUnitKey: selectedKey,
        unitOfMeasureForVolume: translatedValue
      }));
    }
    else if (name === 'expirationUnit') {
      const selectedKey = value;
      const translatedValue = expirationUnitMappings.toValueMap[selectedKey] || value;
      
      setItemData(prevData => ({ 
        ...prevData, 
        expirationUnitKey: selectedKey,
        expirationUnit: translatedValue
      }));
    }
    else {
      // Handle regular inputs
      setItemData(prevData => ({ ...prevData, [name]: value }));
    }
  };

  const isFormValid = () => {
    return itemData.barcode && 
           itemData.name && 
           itemData.brand && 
           itemData.categoryKey && 
           (itemData.noExpiryDate || (itemData.expirationPeriod && itemData.expirationUnitKey !== 'na'));
  };

  // Handle key events for quick navigation
  const handleKeyDown = (e, nextFieldRef) => {
    if (e.key === 'Enter' && nextFieldRef && nextFieldRef.current) {
      e.preventDefault();
      nextFieldRef.current.focus();
    }
  };

  // Open registered items modal
  const handleOpenRegisteredItems = () => {
    setShowRegisteredItemsModal(true);
    setRegisteredItemsSearchTerm('');
    setRegisteredItemsFilterCategory('all_categories');
    setRegisteredItemsFilterBrand('all_brands');
    setFilteredRegisteredItems(registeredItems);
  };

  // Close registered items modal
  const handleCloseRegisteredItems = () => {
    setShowRegisteredItemsModal(false);
  };

  // Edit item from modal
  const handleEditFromModal = (item) => {
    setItemData({
      ...item,
      isUnitOrPack: item.isUnitOrPack || 'unit',
      unitsInPack: item.unitsInPack || '1'
    });
    setEditingId(item.id);
    setShowRegisteredItemsModal(false);
    // Scroll to form
    document.querySelector('.registry-form-section').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="item-register-container">
      {showRegisteredItemsModal && (
        <div className="registry-inventory-modal-overlay">
          <div className="registry-inventory-modal">
            <div className="registry-inventory-modal-header">
              <h2>{translate('registered_barcodes')}</h2>
              <button className="close-modal-btn" onClick={handleCloseRegisteredItems}>×</button>
            </div>
            
            <div className="registry-inventory-modal-content">
              <div className="registry-inventory-controls">
                <div className="registry-inventory-search-filters">
                  <input
                    type="text"
                    className="registry-inventory-search-input"
                    placeholder={translate('search_registry')}
                    value={registeredItemsSearchTerm}
                    onChange={(e) => setRegisteredItemsSearchTerm(e.target.value)}
                  />
                  
                  <select 
                    value={registeredItemsFilterCategory} 
                    onChange={(e) => setRegisteredItemsFilterCategory(e.target.value)}
                    className="filter-dropdown"
                  >
                    <option value="all_categories">{translate('all_categories')}</option>
                    {categoryKeys.map(category => (
                      <option key={category} value={category}>{translate(category)}</option>
                    ))}
                  </select>
                </div>
                
                <div className="registry-inventory-filters-sort-container">
                  <div className="registry-inventory-filters-section">
                    <div className="filter-group">
                      <select 
                        value={registeredItemsFilterBrand} 
                        onChange={(e) => setRegisteredItemsFilterBrand(e.target.value)}
                        className="filter-dropdown"
                      >
                        {brands.map(brand => (
                          <option key={brand} value={brand}>{brand === 'all_brands' ? translate('all_brands') : brand}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  
                  <div className="registry-inventory-sort-section">
                    <div className="sort-controls">
                      <select 
                        value={registeredItemsSortBy} 
                        onChange={(e) => setRegisteredItemsSortBy(e.target.value)}
                        className="sort-dropdown"
                      >
                        <option value="alphabetical">{translate('alphabetical')}</option>
                        <option value="brand">{translate('brand')}</option>
                        <option value="category">{translate('category')}</option>
                        <option value="barcode">{translate('barcode')}</option>
                      </select>
                      
                      <button 
                        className="registry-sort-order-button" 
                        onClick={toggleSortOrder}
                      >
                        <ArrowUpDown size={20} />
                        {translate(registeredItemsSortOrder)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="registry-inventory-table-container">
                <table className="registry-inventory-table">
                  <thead>
                    <tr>
                      <th>{translate('barcode')}</th>
                      <th>{translate('product')}</th>
                      <th>{translate('brand')}</th>
                      <th>{translate('category')}</th>
                      <th>{translate('shelf_life')}</th>
                      <th>{translate('unit_pack')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredRegisteredItems.map((item) => (
                     <tr key={item.id}>
                     <td>{item.barcode}</td>
                     <td>{item.name}</td>
                     <td>{item.brand}</td>
                     <td>{translate(item.categoryKey) || item.category}</td>
                     <td>{item.noExpiryDate 
                         ? translate('no_expiry') 
                         : `${item.expirationPeriod} ${translate(item.expirationUnitKey) || item.expirationUnit}`}
                     </td>
                     <td>{item.isUnitOrPack === 'pack' 
                         ? formatMessage(translate('pack_units'), item.unitsInPack) 
                         : translate('unit')}
                     </td>
                     <td>
                       <div className="actions-cell">
                         <button 
                           className="registry-btn-edit" 
                           onClick={() => handleEditFromModal(item)}
                           aria-label={translate('edit')}
                         >
                           {translate('edit')}
                         </button>
                         <button
                           className="registry-btn-delete"
                           onClick={() => {
                             handleDelete(item);
                             handleCloseRegisteredItems();
                           }}
                           aria-label={translate('delete')}
                         >
                           {translate('delete')}
                         </button>
                       </div>
                     </td>
                   </tr>
                    ))}
                    {filteredRegisteredItems.length === 0 && (
                      <tr>
                        <td colSpan="7" style={{textAlign: 'center', padding: '20px'}}>
                          {translate('no_items_found')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`registry-container ${showConfirmation ? 'confirmation-active' : ''}`}>
        <div className="registry-header">
          <h1 className="registry-title">{translate('barcode_registry')}</h1>
          <p className="registry-subtitle">{translate('registry_subtitle')}</p>
        </div>

        <div className="registry-main">
          {/* Input form section */}
          <div className="registry-form-section">
            <div className="section-title-container">
              <h2 className="section-title">{translate('register_new_barcode')}</h2>
              <button 
                className="registered-items-button" 
                onClick={handleOpenRegisteredItems}
              >
                {translate('registered_barcodes')}
              </button>
            </div>

            <hr className='registry-header-hr'/>
            
            {error && <div className="registry-error">{error}</div>}
            {success && <div className="registry-success">{success}</div>}
            
            <form className="registry-form" onSubmit={handleSubmit}>
              <div className="registry-form-row">
                <div className="registry-form-group">
                  <label>{translate('barcode_upc')} <span className="required">*</span></label>
                  <div className="barcode-input-group">
                    <input
                      ref={barcodeInputRef}
                      type="text"
                      name="barcode"
                      value={itemData.barcode}
                      onChange={handleInputChange}
                      onKeyDown={(e) => handleKeyDown(e, nameInputRef)}
                      placeholder={translate('scan_enter_barcode')}
                      required
                      onFocus={() => setBarcodeInputActive(true)}
                      onBlur={() => setBarcodeInputActive(false)}
                    />
                    <button 
                      type="button" 
                      className="barcode-search-button"
                      onClick={handleBarcodeSearch}
                    >
                      {translate('search')}
                    </button>
                  </div>
                </div>
              </div>
              
              <div className="registry-form-row">
                <div className="registry-form-group">
                  <label>{translate('product_name')} <span className="required">*</span></label>
                  <input
                    ref={nameInputRef}
                    type="text"
                    name="name"
                    value={itemData.name}
                    onChange={handleInputChange}
                    placeholder={translate('name_of_product')}
                    required
                  />
                </div>
                
                <div className="registry-form-group">
                  <label>{translate('brand')} <span className="required">*</span></label>
                  <input
                    type="text"
                    name="brand"
                    value={itemData.brand}
                    onChange={handleInputChange}
                    placeholder={translate('brand_name')}
                    required
                  />
                </div>
              </div>
              
              <div className="registry-form-row">
                <div className="registry-form-group">
                  <label>{translate('category')} <span className="required">*</span></label>
                  <select
                    name="category"
                    value={itemData.categoryKey}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">{translate('select_category')}</option>
                    {categoryKeys.map(category => (
                      <option key={category} value={category}>{translate(category)}</option>
                    ))}
                  </select>
                </div>
                
                <div className="registry-form-group">
                  <label>{translate('sku')}</label>
                  <input
                    type="text"
                    name="sku"
                    value={itemData.sku}
                    onChange={handleInputChange}
                    placeholder={translate('sku_optional')}
                  />
                </div>
              </div>
              
              <div className="registry-form-row">
                <div className="registry-form-group">
                  <label>{translate('unit_pack_type')}</label>
                  <div className="unit-pack-selector">
                    <label className="radio-label">
                      <input
                        type="radio"
                        name="isUnitOrPack"
                        value="unit"
                        checked={itemData.isUnitOrPack === 'unit'}
                        onChange={handleInputChange}
                      />
                      {translate('individual_unit')}
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        name="isUnitOrPack"
                        value="pack"
                        checked={itemData.isUnitOrPack === 'pack'}
                        onChange={handleInputChange}
                      />
                      {translate('pack')}
                    </label>
                  </div>
                </div>
                
                {itemData.isUnitOrPack === 'pack' && (
                  <div className="registry-form-group">
                    <label>{translate('units_per_pack')}</label>
                    <input
                      type="number"
                      name="unitsInPack"
                      value={itemData.unitsInPack}
                      onChange={handleInputChange}
                      placeholder={translate('units_in_pack')}
                      min="1"
                    />
                  </div>
                )}
              </div>
              
              <div className="registry-form-row">
                <div className="registry-form-group">
                  <label>{translate('cost_price')}</label>
                  <input
                    type="number"
                    name="costPrice"
                    value={itemData.costPrice}
                    onChange={handleInputChange}
                    placeholder={translate('cost_price_per_unit')}
                    step="0.01"
                  />
                </div>
                
                <div className="registry-form-group">
                  <label>{translate('weight_unit')}</label>
                  <select
                    name="unitOfMeasureForWeight"
                    value={itemData.weightUnitKey}
                    onChange={handleInputChange}
                  >
                    {weightUnitKeys.map(unit => (
                      <option key={unit} value={unit}>{translate(unit)}</option>
                    ))}
                  </select>
                </div>
                
                <div className="registry-form-group">
                  <label>{translate('weight')}</label>
                  <input
                    type="text"
                    name="weight"
                    value={itemData.weight}
                    onChange={handleInputChange}
                    placeholder={translate('weight_of_unit')}
                    disabled={itemData.weightUnitKey === 'na'}
                  />
                </div>
              </div>
              
              <div className="registry-form-row">
                <div className="registry-form-group">
                  <label>{translate('volume_unit')}</label>
                  <select
                    name="unitOfMeasureForVolume"
                    value={itemData.volumeUnitKey}
                    onChange={handleInputChange}
                  >
                    {volumeUnitKeys.map(unit => (
                      <option key={unit} value={unit}>{translate(unit)}</option>
                    ))}
                  </select>
                </div>
                
                <div className="registry-form-group">
                  <label>{translate('volume')}</label>
                  <input
                    type="text"
                    name="volume"
                    value={itemData.volume}
                    onChange={handleInputChange}
                    placeholder={translate('volume_of_unit')}
                    disabled={itemData.volumeUnitKey === 'na'}
                  />
                </div>
              </div>
              
              <div className="registry-form-row">
                <div className="registry-form-group expiration-group">
                  <label>{translate('shelf_life')} <span className="required">*</span></label>
                  <div className="expiration-inputs">
                    <input
                      type="number"
                      name="expirationPeriod"
                      value={itemData.expirationPeriod}
                      onChange={handleInputChange}
                      placeholder={translate('period')}
                      min="1"
                      disabled={itemData.noExpiryDate}
                      required={!itemData.noExpiryDate}
                    />
                    <select
                      name="expirationUnit"
                      value={itemData.expirationUnitKey}
                      onChange={handleInputChange}
                      disabled={itemData.noExpiryDate}
                      required={!itemData.noExpiryDate}
                    >
                      {expirationPeriodUnitKeys.map(unit => (
                        <option key={unit} value={unit}>{translate(unit)}</option>
                      ))}
                    </select>
                  </div>
                </div>
                
                <div className="registry-form-group checkbox-container">
                  <label>
                    <input
                      type="checkbox"
                      name="noExpiryDate"
                      checked={itemData.noExpiryDate}
                      onChange={handleInputChange}
                    />
                    {translate('no_expiration_date')}
                  </label>
                </div>
              </div>
              
              <div className="registry-form-actions">
                <button 
                  type="submit" 
                  className="registry-submit-btn" 
                  disabled={!isFormValid()}
                >
                  {editingId ? translate('update_item_registry') : translate('add_to_registry')}
                </button>
                
                {editingId && (
                  <button 
                    type="button" 
                    className="registry-cancel-btn" 
                    onClick={resetForm}
                  >
                    {translate('cancel_edit')}
                  </button>
                )}
              </div>
            </form>
          </div>
          
      
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationDialog
          message={editingId 
            ? translate('confirm_update_registry')
            : translate('confirm_add_registry')}
          onConfirm={handleConfirm}
          onCancel={() => setShowConfirmation(false)}
          confirmText={translate('confirm')}
          cancelText={translate('cancel')}
        />
      )}
    </div>
  );
};

export default ItemRegister;