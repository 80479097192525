import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, X, User, Search, ChevronRight, ChevronDown, ChevronUp } from 'lucide-react';
import axios from 'axios';
import logoBlue from '../assets/Daxtop-Short-logo.svg';
import image from '../assets/image-office.webp';
import retailImage from '../assets/imageArmenian2.svg'; // Import the retail image
import daxtopWorkflow from '../assets/imageArmenian1.svg'; // Import the How Daxtop Works image
import globe from '../assets/glob.png';
import './LandingPage.css';
import MobileMenu from './MobileMenu';
import { translations, regions, countryToISOCode } from './Data/data';

const APP_NAME = "DAXTOP PORTAL";

const LandingPage = () => {
  // State declarations
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [showContactUs, setShowContactUs] = useState(false);
  const [showRegionSelector, setShowRegionSelector] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [language, setLanguage] = useState('en');
  const [userCountry, setUserCountry] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [activeTab, setActiveTab] = useState('retail'); // Default to retail tab
  const [expandedSection, setExpandedSection] = useState('download'); // For the How Daxtop Works accordion

  const navigate = useNavigate();
  const contactUsRef = useRef(null);
  const regionSelectorRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  // Auto-detect user's location and set region
  useEffect(() => {
    const getUserLocation = async () => {
      try {
        const response = await axios.get('/landing/user-location');
        const { country_name, continent_code } = response.data;

        // Find user's region based on continent code
        const userRegion = regions.find(r => r.continentCode === continent_code);
        const country = regions
 .flatMap(r => r.countries)
 .find(c => c.name === country_name);

        if (userRegion) {
          setSelectedRegion(userRegion);
 }

        if (country) {
          setUserCountry(country);
          setLanguage(country.code);
 } else {
          setLanguage('en');
 }
 } catch (error) {
        console.error('Error fetching user location:', error);
      // Default to Asia region and Armenian language
      const defaultRegion = regions.find(r => r.continentCode === 'AS');
      setSelectedRegion(defaultRegion);
      setLanguage('en');
      // Set default country to Armenia
      const armeniaCountry = regions.flatMap(r => r.countries).find(c => c.name === 'Armenia');
      setUserCountry(armeniaCountry);
 }
 };

    getUserLocation();
 }, []);

  // Enhanced region selection with grouping and sorting
  const getFilteredAndSortedRegions = useCallback(() => {
    const filteredCountries = regions.flatMap(region => 
      region.countries.filter(country =>
        country.name.toLowerCase().includes(searchQuery.toLowerCase())
 )
 );

    // Group countries by region
    const groupedCountries = filteredCountries.reduce((acc, country) => {
      const region = regions.find(r => 
        r.countries.some(c => c.name === country.name)
 );
      
      if (!acc[region.name]) {
        acc[region.name] = {
          ...region,
          countries: []
 };
 }
      
      acc[region.name].countries.push(country);
      return acc;
 }, {});

    // Convert back to array and sort regions
    return Object.values(groupedCountries)
 .filter(region => region.countries.length > 0)
 .sort((a, b) => {
        // Selected region always comes first
        if (a.name === selectedRegion?.name) return -1;
        if (b.name === selectedRegion?.name) return 1;
        return a.name.localeCompare(b.name);
 });
 }, [searchQuery, selectedRegion]);

  const handleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
 };

  const handleLogin = () => {
    navigate('/login');
 };

  const handleCountrySelect = (country, region) => {
    setUserCountry(country);
    setLanguage(country.code);
    setSelectedRegion(region);
    setShowRegionSelector(false);
    setSearchQuery('');
 };

 const handleSearch = (e) => {
  setSearchQuery(e.target.value);
  // Ensure the input retains focus after value changes
  if (searchInputRef.current) {
    searchInputRef.current.focus();
  }
};

// Fix 2: Update the useEffect that handles keyPress events
useEffect(() => {
  const handleKeyPress = (e) => {
    if (showRegionSelector && e.key.length === 1 && e.key.match(/[a-z]/i)) {
      // Remove this line that was causing the issue:
      // setSearchQuery(prevQuery => prevQuery);
      
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }
  };
  
  document.addEventListener('keydown', handleKeyPress);
  return () => {
    document.removeEventListener('keydown', handleKeyPress);
  };
}, [showRegionSelector]);

  const handleSubmit = async () => {
    try {
      const response = await axios.post('/landing/verify-registration', {
        code: inputValue
 });

      if (response.data.success) {
        navigate(`/register`);
 }
 } catch (error) {
      console.error('Verification error:', error);
      setErrorMessage('Wrong Registration Code');
 }
 };

  const translate = (key) => {
    return translations[language]?.[key] || translations['en'][key];
 };

  const getCountryFlag = (countryName) => {
    const isoCode = countryToISOCode[countryName] || countryName.toLowerCase();
    return `https://flagcdn.com/w160/${isoCode}.png`;
 };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setErrorMessage('');
 };

  const handleBlur = () => {
    setErrorMessage('');
 };

 const handleRegionSelectorToggle = () => {
  setShowRegionSelector(!showRegionSelector);
  if (!showRegionSelector) {
    setSearchQuery('');
    // Add a slight delay to ensure the input has rendered
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 50);
  }
};

  const handleClickOutside = useCallback((event) => {
    if (contactUsRef.current && !contactUsRef.current.contains(event.target)) {
      setShowContactUs(false);
 }
    if (regionSelectorRef.current && !regionSelectorRef.current.contains(event.target)) {
      setShowRegionSelector(false);
 }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
 }
 }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
 };
 }, [handleClickOutside]);


  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
 };




  const toggleContactUs = () => {
    setShowContactUs(prev => !prev);
    setShowRegionSelector(false);
 };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1200);
      if (window.innerWidth > 1200) {
        setIsMobileMenuOpen(false);
 }
 };
    
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
 }, []);

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Toggle How Daxtop Works accordion sections
  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  // Region selector component
  const RegionSelector = () => {
    const filteredRegions = getFilteredAndSortedRegions();

    return (
      <div className="region-selector-overlay">
        <div className="region-selector" ref={regionSelectorRef}>
          <div className="region-selector-header">
            <h3>{translate('choose_region')}</h3>
          </div>

          <div className="search-container">
              <Search size={18} className="search-icon" />
              <input
                ref={searchInputRef}
                type="text"
                placeholder={translate('search_country')}
                value={searchQuery}
                onChange={handleSearch}
                className="country-search"
                autoFocus={showRegionSelector}
                onBlur={(e) => {
                  // Prevent losing focus when clicking inside the input
                  if (e.relatedTarget !== searchInputRef.current) {
                    // Small delay to allow other click events to process first
                    setTimeout(() => {
                      if (showRegionSelector && searchInputRef.current) {
                        searchInputRef.current.focus();
                      }
                    }, 0);
                  }
                }}
              />
            </div>

          <div className="region-container">
            {filteredRegions.map((region) => (
              <div 
                key={region.name} 
                className={`region ${region.name === selectedRegion?.name ? 'selected' : ''}`}
              >
                <h4>{region.name}</h4>
                <ul>
                  {region.countries
 .sort((a, b) => a.name.localeCompare(b.name))
 .map((country) => (
                      <li 
                        key={country.name} 
                        onClick={() => handleCountrySelect(country, region)}
                        className={country.name === userCountry?.name ? 'selected' : ''}
                      >
                        <img 
                          src={getCountryFlag(country.name)}
                          alt={`${country.name} flag`}
                          className="country-flag"
                        />
                        <span>{country.name}</span>
                        {country.name === userCountry?.name && (
                          <span className="selected-indicator">✓</span>
 )}
                      </li>
 ))
                  }
                </ul>
              </div>
 ))}
          </div>
        </div>
      </div>
 );
 };

  return (
    <div className="landing-page">
      <header className="landing-header">
        <div className={`header-content ${isMobileView ? 'header-content-mobile' : 'header-content-desktop'}`}>
          {/* Logo and Navigation */}
          <div className="header-left">
            <div className="logo-daxtop">
              <img src={logoBlue} alt="DAXTOP Logo" />
            </div>
            {!isMobileView && (
              <nav className="landing-nav">
                {/* Navigation menu items */}
                <ul>
                  <li 
                    onMouseEnter={() => handleDropdown('products')} 
                    onMouseLeave={() => handleDropdown(null)}
                    className={activeDropdown === 'products' ? 'active' : ''}
                  >
                    <span>{translate('products')}</span>
                    {activeDropdown === 'products' && (
                       <div className="dropdown-content" ref={dropdownRef}>
                        <div className="dropdown-column">
                          <h3>{translate('by_category')}</h3>
                          <hr className='hr-design' />
                          <ul>
                            <li onClick={handleLogin}>{translate('inventory_management')}</li>
                            <li>{translate('supply_chain_management')}</li>
                            <li>{translate('financial_management')}</li>
                            <li>{translate('human_capital_management')}</li>
                            <li>{translate('crm_and_customer_experience')}</li>
                            <li>{translate('view_products_az')}</li>
                          </ul>
                        </div>
                        <div className="dropdown-column">
                          <h3>{translate('by_industry')}</h3>
                          <hr className='hr-design' />
                          <ul>
                            <li>{translate('automotive')}</li>
                            <li>{translate('banking')}</li>
                            <li>{translate('consumer_products')}</li>
                            <li>{translate('healthcare')}</li>
                            <li>{translate('retail')}</li>
                            <li>{translate('view_all_industries')}</li>
                          </ul>
                        </div>
                        <div className="dropdown-column">
                          <h3>{translate('business_technology_platform')}</h3>
                          <hr className='hr-design' />
                          <ul>
                            <li>{translate('application_development_and_automation')}</li>
                            <li>{translate('data_and_analytics')}</li>
                            <li>{translate('extended_planning_and_analysis')}</li>
                            <li>{translate('integration')}</li>
                          </ul>
                        </div>
                      </div>
 )}
                  </li>
                  <li><a href="/equipment">{translate('equipment')}</a></li>         
                  <li><a href="/learning">{translate('learning')}</a></li>
                  <li><a href="/community">{translate('community')}</a></li>
                  <li><a href="/partners">{translate('partners')}</a></li>
                  <li><a href="/about">{translate('about')}</a></li>
                </ul>
              </nav>
 )}
          </div>

          {/* Header Right Section */}
          <div className="header-right">
            {!isMobileView && (
              <button className="login-button" onClick={handleLogin}>
                {translate('login')}
              </button>
 )}
            {/* Country selector */}
            <div className="country-icon" onClick={handleRegionSelectorToggle}>
              {userCountry ? (
                <img
                  src={getCountryFlag(userCountry.name)}
                  alt={`${userCountry.name} flag`}
                  className="country-flag-circle"
                />
 ) : (
                <img src={globe} alt="Globe" className="country-flag-circle" />
 )}
            </div>
            {/* Mobile menu toggle */}
            {isMobileView && (
              <div className="mobile-header-right">
                <User size={23} color="#0070f2" onClick={handleLogin} />
                <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                  <Menu size={23} color="#0070f2" />
                  <span className="sr-only">Menu</span>
                </button>
              </div>
 )}
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      <MobileMenu 
        isOpen={isMobileMenuOpen} 
        onClose={toggleMobileMenu} 
        translate={translate}
        handleLogin={handleLogin}
      />

      {/* Region Selector */}
      {showRegionSelector && <RegionSelector />}

      {/* Main Content */}
      <main className="landing-main">
        {/* Category Tab Navigation */}

        {/* Hero Section */}
        <div className="hero-section">
          <div className="hero-image">
            <img src={image} alt="AI Innovation" />
          </div>
          <div className="hero-content">
            <h1>{translate('ai_innovations')}</h1>
            <p>{translate('discover_text')}</p>
            <button className="cta-button">{translate('learn_more')}</button>
          </div>
        </div>

     

        {/* How Daxtop Works Section */}
        {activeTab === 'retail' && (
            <div className="retail-panel">
              <div className="retail-content">
                <h2>{translate('become_goto_store')}</h2>
                <p>{translate('grow_revenue_text')}</p>
                <button className="retail-learn-more">{translate('learn_more_button')}</button>
              </div>
              <div className="retail-image">
                <img src={daxtopWorkflow} alt="Retail POS System" />
              </div>
            </div>
        )}

        {/* How Daxtop Works Section */}
        {activeTab === 'retail' && (
          <div className="how-daxtop-works">
            <h2 className="daxtop-works-title">{translate('how_daxtop_works')}</h2>
            
            <div className="daxtop-works-content">
              <div className="daxtop-works-image">
                <img src={retailImage} alt="Daxtop System Configuration" />
              </div>
              <div className="daxtop-works-accordion">
                {/* Download and install section */}
                <div className="accordion-section">
                  <div 
                    className="accordion-header" 
                    onClick={() => toggleSection('download')}
                  >
                    <h3>{translate('download_install')}</h3>
                    {expandedSection === 'download' ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </div>
                  
                  {expandedSection === 'download' && (
                    <div className="accordion-content">
                      <p>
                        {translate('daxtop_is_desktop_app')}
                      </p>
                    </div>
                  )}
                </div>
                
                {/* Configure section */}
                <div className="accordion-section">
                  <div 
                    className="accordion-header" 
                    onClick={() => toggleSection('configure')}
                  >
                    <h3>{translate('configure')}</h3>
                    {expandedSection === 'configure' ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </div>
                  
                  {expandedSection === 'configure' && (
                    <div className="accordion-content">
                      <p>
                        {translate('configure_daxtop_store')}
                      </p>
                    </div>
                  )}
                </div>
                
                {/* Increase efficiency section */}
                <div className="accordion-section">
                  <div 
                    className="accordion-header" 
                    onClick={() => toggleSection('productivity')}
                  >
                    <h3>{translate('increase_store_efficiency')}</h3>
                    {expandedSection === 'productivity' ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </div>
                  
                  {expandedSection === 'productivity' && (
                    <div className="accordion-content">
                      <p>
                        {translate('automated_workflows')}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>

      {/* Contact Us Button and Popup */}
      <div className="contact-us-button" onClick={toggleContactUs}>
        {translate('contact_us')}
      </div>

      {showContactUs && (
        <div className="contact-us-popup" ref={contactUsRef}>
          {/* Contact popup content */}
        </div>
      )}
    </div>
  );
};

export default LandingPage;