import React, { useState } from 'react';
import './StoreSecPlan.css';

const StoreSecPlan = () => {
  const [activeTab, setActiveTab] = useState('security');
  const [cameras, setCameras] = useState(Array.from({ length: 7 }, (_, i) => i + 1));
  const [floors, setFloors] = useState([1]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const addNewCamera = () => {
    setCameras([...cameras, cameras.length + 1]);
  };

  const addNewFloor = () => {
    setFloors([...floors, floors.length + 1]);
  };

  const getOrdinal = (n) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  return (
    <div className="storesecplan-container">
      <div className="storesecplan-tabs">
        <button 
          className={`tab-button ${activeTab === 'security' ? 'active' : ''}`}
          onClick={() => handleTabChange('security')}
        >
          SECURITY
        </button>
        <button 
          className={`tab-button ${activeTab === 'floorplan' ? 'active' : ''}`}
          onClick={() => handleTabChange('floorplan')}
        >
          FLOOR PLAN
        </button>
      </div>

      <div className="storesecplan-content">
        {activeTab === 'security' && (
          <div className="security-container">
            <div className="cameras-grid">
              {cameras.map((num) => (
                <div key={num} className="camera-box">
                  <div className="camera-label">CAMERA {num}</div>
                </div>
              ))}
              <div className="camera-box add-new" onClick={addNewCamera}>
                <div className="add-icon">+</div>
                <div className="camera-label">ADD NEW CAMERA</div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'floorplan' && (
          <div className="floorplan-container">
            <button className="upload-floor-plan-btn" >
              UPLOAD
            </button>
            <div className="floors-grid">
              {floors.map((num) => (
                <div key={num} className="floor-box">
                  <div className="floor-label">{getOrdinal(num)} Floor</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreSecPlan;