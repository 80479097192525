import React, { useState } from 'react';
import './PricingPlan.css';

const PricingPlan = ({ onSelectPlan, selectedPlan }) => {
  return (
    <div className="pricing-container">
      <h2 className="pricing-heading">Choose a Plan</h2>
      <div className="pricing-plans">
        {/* Free Plan */}
        <div 
          className={`pricing-plan ${selectedPlan === 'free' ? 'selected' : ''}`}
          onClick={() => onSelectPlan('free')}
        >
          <div className="plan-header free">
            <h3>Free Trial</h3>
            <div className="price"> $ 0 </div>
          </div>
          <div className="plan-features">
            <ul>
              <li>
                <strong>Best for:</strong> Individual users, startups testing the system
              </li>
              <li>
                <strong>Inventory Management:</strong> Up to 50 products
              </li>
              <li>
                <strong>Order Management:</strong> 50 orders/month
              </li>
              <li>
                <strong>Financial Management:</strong> Basic reports only (no accounting or credit tools)
              </li>
              <li>
                <strong>DaxAI Assistant:</strong> Limited access (50 queries/day)
              </li>
              <li>
                <strong>Inventory Analytics:</strong> Basic charts only
              </li>
              <li>
                <strong>Users:</strong> 1 user
              </li>
              <li>
                <strong>Duration:</strong> 3 months 
              </li>
            </ul>
          </div>
        </div>

        {/* Pro Plan */}
        <div 
          className={`pricing-plan ${selectedPlan === 'pro' ? 'selected' : ''}`}
          onClick={() => onSelectPlan('pro')}
        >
          <div className="plan-header pro">
            <h3>Pro</h3>
            <div className="popular-badge">Most Popular</div>
            <div className="price">$ 29.99</div>
          </div>
          <div className="plan-features">
            <ul>
              <li>
                <strong>Best for:</strong> Growing small businesses
              </li>
              <li>
                <strong>Inventory Management:</strong> Up to 1,000 products
              </li>
              <li>
                <strong>Order Management:</strong> 500 orders/month
              </li>
              <li>
                <strong>Financial Management:</strong> Full accounting and budgeting, no credit tools
              </li>
              <li>
                <strong>DaxAI Assistant:</strong> 500 queries/day
              </li>
              <li>
                <strong>Inventory Analytics:</strong> Advanced filters, exportable data
              </li>
              <li>
                <strong>Users:</strong> Up to 3 users
              </li>
              <li>
                <strong>Support:</strong> 24 / 7
              </li>
            </ul>
          </div>
        </div>

        {/* Business Plan */}
        <div 
          className={`pricing-plan ${selectedPlan === 'business' ? 'selected' : ''}`}
          onClick={() => onSelectPlan('business')}
        >
          <div className="plan-header business">
            <h3>Business</h3>
            <div className="price">$ 249.99</div>
          </div>
          <div className="plan-features">
            <ul>
              <li>
                <strong>Best for:</strong> Medium-to-large businesses 
              </li>
              <li>
                <strong>Inventory Management:</strong> 10,000 products
              </li>
              <li>
                <strong>Order Management:</strong> 5,000 orders/month
              </li>
              <li>
                <strong>Financial Management:</strong> Full suite (accounting, budgeting, credit management)
              </li>
              <li>
                <strong>DaxAI Assistant:</strong> 5000 queries/day
              </li>
              <li>
                <strong>Inventory Analytics:</strong> Predictive analytics, automated alerts
              </li>
              <li>
                <strong>Users:</strong> Up to 15 users
              </li>
              <li>
                <strong>Support:</strong> 24 / 7
              </li>
            </ul>
          </div>
        </div>

        {/* Enterprise Plan */}
        <div 
          className={`pricing-plan ${selectedPlan === 'enterprise' ? 'selected' : ''}`}
          onClick={() => onSelectPlan('enterprise')}
        >
          <div className="plan-header enterprise">
            <h3>Enterprise</h3>
            <div className="price">Custom</div>
          </div>
          <div className="plan-features">
            <ul>
              <li>
                <strong>Best for:</strong> Large enterprises with custom needs
              </li>
              <li>
                <strong>Inventory Management:</strong> Unlimited
              </li>
              <li>
                <strong>Order Management:</strong> Unlimited
              </li>
              <li>
                <strong>Financial Management:</strong> Full suite + custom integrations (ERP, CRM)
              </li>
              <li>
                <strong>DaxAI Assistant:</strong> Unlimited queries
              </li>
              <li>
                <strong>Inventory Analytics:</strong> Fully customizable dashboards, ML-driven forecasting
              </li>
              <li>
                <strong>Users:</strong> Unlimited
              </li>
              <li>
                <strong>Support:</strong> 24 / 7
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlan;