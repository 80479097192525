// PatternAnalysis.js
import React, { useState, useEffect, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, BarChart, Bar } from 'recharts';
import WeekSelection from './WeekSelection';
import './PatternAnalysis.css';

const daysOfWeek = ['Average Day', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const PatternAnalysis = ({ 
  isOpen, 
  onClose, 
  allReports, 
  translate, 
  selectedStore: initialStore, 
  selectedItem: initialItem, 
  selectedBrand: initialBrand, 
  selectedCategoryKey: initialCategoryKey,
  startDate,
  endDate,
  stores = [],
  uniqueItems = [],
  uniqueBrands = [],
  uniqueCategoryKeys = ['all_categories']
}) => {
  // Local filter state that is independent from Dashboard filters
  const [localSelectedStore, setLocalSelectedStore] = useState(initialStore);
  const [localSelectedItem, setLocalSelectedItem] = useState(initialItem);
  const [localSelectedBrand, setLocalSelectedBrand] = useState(initialBrand);
  const [localSelectedCategoryKey, setLocalSelectedCategoryKey] = useState(initialCategoryKey);
  
  // Initialize local filters when the component opens
  useEffect(() => {
    if (isOpen) {
      setLocalSelectedStore(initialStore);
      setLocalSelectedItem(initialItem);
      setLocalSelectedBrand(initialBrand);
      setLocalSelectedCategoryKey(initialCategoryKey);
    }
  }, [isOpen, initialStore, initialItem, initialBrand, initialCategoryKey]);

  const [selectedDay, setSelectedDay] = useState('Average Day');
  const [chartType, setChartType] = useState('Line Chart');
  const [patternData, setPatternData] = useState([]);
  const [isCalculating, setIsCalculating] = useState(false);
  const [yAxisDomain, setYAxisDomain] = useState({ added: 0, sale: 0 });
  const [weekType, setWeekType] = useState('average');
  const [weekDateRange, setWeekDateRange] = useState(null);

  // Local filter change handlers
  const handleStoreChange = (e) => {
    setLocalSelectedStore(e.target.value);
  };

  const handleItemChange = (e) => {
    setLocalSelectedItem(e.target.value);
  };

  const handleBrandChange = (e) => {
    setLocalSelectedBrand(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setLocalSelectedCategoryKey(e.target.value);
  };

  const handleResetFilters = () => {
    setLocalSelectedStore('all');
    setLocalSelectedItem('all');
    setLocalSelectedBrand('all');
    setLocalSelectedCategoryKey('all_categories');
  };

  // Process reports to get hourly averages by day of week
  useEffect(() => {
    if (!isOpen || !allReports || allReports.length === 0) return;

    setIsCalculating(true);
    
    try {
      // Filter reports based on criteria
      const filteredReports = allReports.filter(report => {
        // Apply store filter
        if (localSelectedStore !== 'all' && report.store !== localSelectedStore) {
          return false;
        }
        
        // Apply item filter
        if (localSelectedItem !== 'all') {
          if (report.itemName && report.itemName === localSelectedItem) {
            // Match found at top level
          } else if (report.items && Array.isArray(report.items)) {
            if (!report.items.some(item => item.itemName === localSelectedItem)) {
              return false;
            }
          } else if (!report.itemName || report.itemName !== localSelectedItem) {
            return false;
          }
        }
        
        // Apply brand filter
        if (localSelectedBrand !== 'all') {
          if (report.brand && report.brand === localSelectedBrand) {
            // Match found at top level
          } else if (report.items && Array.isArray(report.items)) {
            if (!report.items.some(item => item.brand === localSelectedBrand)) {
              return false;
            }
          } else if (!report.brand || report.brand !== localSelectedBrand) {
            return false;
          }
        }
        
        // Apply category filter
        if (localSelectedCategoryKey !== 'all_categories') {
          if (report.categoryKey && report.categoryKey === localSelectedCategoryKey) {
            // Match found at top level
          } else if (report.items && Array.isArray(report.items)) {
            if (!report.items.some(item => item.categoryKey === localSelectedCategoryKey)) {
              return false;
            }
          } else {
            return false;
          }
        }
        
        // Apply the regular date filter if provided and we're not using week filtering
        if (weekType === 'average' && startDate && endDate) {
          const reportDate = new Date(report.timestamp);
          const start = new Date(startDate);
          start.setHours(0, 0, 0, 0);
          const end = new Date(endDate);
          end.setHours(23, 59, 59, 999);
          
          if (reportDate < start || reportDate > end) {
            return false;
          }
        }
        
        // Apply week-based filtering
        if (weekType !== 'average' && weekDateRange) {
          const reportDate = new Date(report.timestamp);
          
          if (reportDate < weekDateRange.start || reportDate > weekDateRange.end) {
            return false;
          }
        }
        
        return true;
      });

      // Group reports by day of week and hour
      const reportsByDayAndHour = {};
      
      // Initialize data structure
      daysOfWeek.forEach(day => {
        reportsByDayAndHour[day] = {};
        for (let hour = 0; hour < 24; hour++) {
          reportsByDayAndHour[day][hour] = { 
            added: { total: 0, count: 0 },
            sale: { total: 0, count: 0 }
          };
        }
      });
      
      // Process each report
      filteredReports.forEach(report => {
        const date = new Date(report.timestamp);
        const dayIndex = date.getDay() === 0 ? 6 : date.getDay() - 1; // Convert 0-6 (Sun-Sat) to Mon-Sun (1-7)
        const day = daysOfWeek[dayIndex + 1]; // +1 because we added 'Average Day' at index 0
        const hour = date.getHours();
        
        // Process data for specific day
        if (report.action === 'Added') {
          const quantity = parseInt(report.quantity) || 0;
          reportsByDayAndHour[day][hour].added.total += quantity;
          reportsByDayAndHour[day][hour].added.count += 1;
          
          // Also add to "Average Day"
          reportsByDayAndHour['Average Day'][hour].added.total += quantity;
          reportsByDayAndHour['Average Day'][hour].added.count += 1;
        } else if (report.action === 'Sale') {
          // Handle both direct quantity and items array
          let saleQuantity = 0;
          
          if (report.items && Array.isArray(report.items)) {
            // Filter items based on selected criteria
            let itemsToCount = [...report.items];
            
            if (localSelectedItem !== 'all') {
              itemsToCount = itemsToCount.filter(item => item.itemName === localSelectedItem);
            }
            
            if (localSelectedBrand !== 'all') {
              itemsToCount = itemsToCount.filter(item => item.brand === localSelectedBrand);
            }
            
            if (localSelectedCategoryKey !== 'all_categories') {
              itemsToCount = itemsToCount.filter(item => item.categoryKey === localSelectedCategoryKey);
            }
            
            saleQuantity = itemsToCount.reduce((sum, item) => sum + (parseInt(item.quantity) || 0), 0);
          } else {
            saleQuantity = parseInt(report.quantity) || 0;
          }
          
          reportsByDayAndHour[day][hour].sale.total += saleQuantity;
          reportsByDayAndHour[day][hour].sale.count += 1;
          
          // Also add to "Average Day"
          reportsByDayAndHour['Average Day'][hour].sale.total += saleQuantity;
          reportsByDayAndHour['Average Day'][hour].sale.count += 1;
        }
      });
      
      // First calculate the Average Day data to set the Y-axis domain
      calculateDayData(reportsByDayAndHour, 'Average Day');
      
      // Then if a different day is selected, calculate that day's data
      if (selectedDay !== 'Average Day') {
        calculateDayData(reportsByDayAndHour, selectedDay);
      }
    } catch (error) {
      console.error('Error calculating pattern data:', error);
    } finally {
      setIsCalculating(false);
    }
  }, [
    isOpen, 
    allReports, 
    selectedDay, 
    localSelectedStore, 
    localSelectedItem, 
    localSelectedBrand, 
    localSelectedCategoryKey, 
    startDate, 
    endDate, 
    weekType, 
    weekDateRange
  ]);

  const calculateDayData = useCallback((reportsByDayAndHour, day) => {
    const dayData = [];
    
    for (let hour = 0; hour < 24; hour++) {
      const hourData = reportsByDayAndHour[day][hour];
      const formattedHour = hour === 0 ? '12:00 AM' : 
                            hour < 12 ? `${hour}:00 AM` : 
                            hour === 12 ? '12:00 PM' : 
                            `${hour - 12}:00 PM`;
      
      // For Average Day, calculate average values
      // For specific days, use total values directly
      if (day === 'Average Day') {
        // Calculate averages
        const addedAvg = hourData.added.count > 0 ? 
                        hourData.added.total / hourData.added.count : 0;
        const saleAvg = hourData.sale.count > 0 ? 
                      hourData.sale.total / hourData.sale.count : 0;
        
        dayData.push({
          hour: formattedHour,
          hourNum: hour,
          added: addedAvg,
          sale: saleAvg,
          addedCount: hourData.added.count,
          saleCount: hourData.sale.count
        });
        
        // Set Y-axis domain based on Average Day data
        // Find max values for added and sale
        const maxAdded = Math.max(...dayData.map(d => d.added));
        const maxSale = Math.max(...dayData.map(d => d.sale));
        
        // Get the overall maximum value
        const overallMax = Math.max(maxAdded, maxSale);
        
        // Round up to the nearest integer and add 10% extra space
        const roundedMax = Math.ceil(overallMax);
        const finalMax = roundedMax + Math.ceil(roundedMax * 0.1);
        
        // Set y-axis domain with rounded value and extra space
        setYAxisDomain({
          added: finalMax,
          sale: finalMax
        });
      } else {
        // For specific days, use totals directly
        dayData.push({
          hour: formattedHour,
          hourNum: hour,
          added: hourData.added.total,
          sale: hourData.sale.total,
          addedCount: hourData.added.count,
          saleCount: hourData.sale.count
        });
      }
    }
    
    setPatternData(dayData);
  }, []);

  const handleDayChange = (e) => {
    setSelectedDay(e.target.value);
  };

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };
  
  const handleWeekChange = (type, dateRange) => {
    setWeekType(type);
    setWeekDateRange(dateRange);
    
    // Reset to Average Day when changing week type
    if (selectedDay !== 'Average Day') {
      setSelectedDay('Average Day');
    }
  };

  // Custom tooltip for the charts
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const currentData = patternData.find(d => d.hour === label);
      return (
        <div className="pattern-tooltip">
          <p className="pattern-tooltip-label">{`${label}`}</p>
          <p className="pattern-tooltip-value added">
            {selectedDay === 'Average Day' 
              ? `${translate('added')}: ${payload[0].value.toFixed(2)} ${translate('average')} (${currentData?.addedCount || 0} ${translate('occurrences')})`
              : `${translate('added')}: ${payload[0].value.toFixed(0)} ${translate('total')} (${currentData?.addedCount || 0} ${translate('occurrences')})`
            }
          </p>
          <p className="pattern-tooltip-value sale">
            {selectedDay === 'Average Day'
              ? `${translate('sold')}: ${payload[1].value.toFixed(2)} ${translate('average')} (${currentData?.saleCount || 0} ${translate('occurrences')})`
              : `${translate('sold')}: ${payload[1].value.toFixed(0)} ${translate('total')} (${currentData?.saleCount || 0} ${translate('occurrences')})`
            }
          </p>
        </div>
      );
    }
    return null;
  };

  if (!isOpen) return null;

  return (
    <div className="pattern-analysis-overlay">
      <div className="pattern-analysis-content">
        <div className="pattern-header">
          <h3 className="h3-dashboard">{translate('pattern_analysis')}</h3>
        
          <div className="pattern-header-controls">
            
            <div className="pattern-control-row">
              <WeekSelection 
                translate={translate} 
                onWeekChange={handleWeekChange} 
              />
              <div className="day-select-wrapper">
                <select 
                  value={selectedDay} 
                  onChange={handleDayChange}
                  className="day-select"
                >
                  {daysOfWeek.map(day => (
                    <option key={day} value={day}>
                      {day === 'Average Day' 
                        ? translate('average_day') 
                        : translate(day.toLowerCase()) || day}
                    </option>
                  ))}
                </select>
              </div>
              <div className="chart-type-wrapper">
                <select
                  value={chartType}
                  onChange={handleChartTypeChange}
                  className="chart-type-select"
                >
                  <option value="Line Chart">{translate('line_chart')}</option>
                  <option value="Bar Chart">{translate('bar_chart')}</option>
                </select>
              </div>
              <button onClick={onClose} className="back-button">
                {translate('back')}
              </button>
            </div>
          </div>
          
        </div>
        <hr />

        

        <div className="pattern-chart">
          {isCalculating ? (
            <div className="calculating-indicator">
              <div className="loading-spinner"></div>
              <span>{translate('calculating_patterns')}</span>
            </div>
          ) : patternData.length > 0 ? (
            <ResponsiveContainer width="100%" height={400}>
              {chartType === 'Line Chart' ? (
                <LineChart data={patternData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                  <XAxis 
                    dataKey="hour" 
                    stroke="#333"
                    tick={{ fill: '#333', fontSize: 12 }}
                  />
                  <YAxis 
                    stroke="#333"
                    tick={{ fill: '#333', fontSize: 12 }}
                    domain={[0, yAxisDomain.added > yAxisDomain.sale ? yAxisDomain.added : yAxisDomain.sale]}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend 
                    verticalAlign="top" 
                    height={36}
                    iconType="circle"
                  />
                  <Line 
                    type="monotone" 
                    dataKey="added" 
                    stroke="#008a47" 
                    strokeWidth={3}
                    dot={{ r: 4, strokeWidth: 2, fill: '#fff' }}
                    activeDot={{ r: 6, strokeWidth: 2, fill: '#fff' }}
                    name={translate('added')}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="sale" 
                    stroke="#046ed1" 
                    strokeWidth={3}
                    dot={{ r: 4, strokeWidth: 2, fill: '#fff' }}
                    activeDot={{ r: 6, strokeWidth: 2, fill: '#fff' }}
                    name={translate('sold')}
                  />
                </LineChart>
              ) : (
                <BarChart data={patternData} barSize={30}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                  <XAxis 
                    dataKey="hour" 
                    stroke="#333"
                    tick={{ fill: '#333', fontSize: 12 }}
                  />
                  <YAxis 
                    stroke="#333"
                    tick={{ fill: '#333', fontSize: 12 }}
                    domain={[0, yAxisDomain.added > yAxisDomain.sale ? yAxisDomain.added : yAxisDomain.sale]}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend 
                    verticalAlign="top" 
                    height={36}
                    iconType="circle"
                  />
                  <Bar 
                    dataKey="added" 
                    fill="#008a47" 
                    name={translate('added')}
                    radius={[4, 4, 0, 0]}
                  />
                  <Bar 
                    dataKey="sale" 
                    fill="#046ed1" 
                    name={translate('sold')}
                    radius={[4, 4, 0, 0]}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          ) : (
            <div className="no-data-message">
              {translate('no_pattern_data_available')}
            </div>
          )}
        </div>


        {/* Filter Controls */}
        <div className="filter-controls pattern-filter-controls">
          <div className="filter-grid">
            <div className="filter-group">
              <label htmlFor="pattern-store-filter">{translate('store')}:</label>
              <select 
                id="pattern-store-filter" 
                value={localSelectedStore} 
                onChange={handleStoreChange}
                className="filter-select"
                disabled={isCalculating}
              >
                <option value="all">{translate('all_stores')}</option>
                {stores.map((store, index) => (
                  <option key={index} value={store}>{store}</option>
                ))}
              </select>
            </div>
            
            <div className="filter-group">
              <label htmlFor="pattern-item-filter">{translate('item')}:</label>
              <select 
                id="pattern-item-filter" 
                value={localSelectedItem} 
                onChange={handleItemChange}
                className="filter-select"
                disabled={isCalculating}
              >
                <option value="all">{translate('all_items')}</option>
                {uniqueItems.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>
          
            <div className="filter-group">
              <label htmlFor="pattern-brand-filter">{translate('brand')}:</label>
              <select 
                id="pattern-brand-filter" 
                value={localSelectedBrand} 
                onChange={handleBrandChange}
                className="filter-select"
                disabled={isCalculating}
              >
                <option value="all">{translate('all_brands')}</option>
                {uniqueBrands.map((brand, index) => (
                  <option key={index} value={brand}>{brand}</option>
                ))}
              </select>
            </div>
            
            <div className="filter-group">
              <label htmlFor="pattern-category-filter">{translate('category')}:</label>
              <select 
                id="pattern-category-filter" 
                value={localSelectedCategoryKey} 
                onChange={handleCategoryChange}
                className="filter-select"
                disabled={isCalculating}
              >
                {uniqueCategoryKeys.map(categoryKey => (
                  <option key={categoryKey} value={categoryKey}>
                    {translate(categoryKey)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="active-filters">
            {localSelectedStore !== 'all' && (
              <span className="filter-tag">
                {translate('store')}: {localSelectedStore}
              </span>
            )}
            {localSelectedItem !== 'all' && (
              <span className="filter-tag">
                {translate('item')}: {localSelectedItem}
              </span>
            )}
            {localSelectedBrand !== 'all' && (
              <span className="filter-tag">
                {translate('brand')}: {localSelectedBrand}
              </span>
            )}
            {localSelectedCategoryKey !== 'all_categories' && (
              <span className="filter-tag">
                {translate('category')}: {translate(localSelectedCategoryKey)}
              </span>
            )}
          </div>

          <button 
            onClick={handleResetFilters}
            className="reset-filters-btn"
            disabled={isCalculating}
          >
            {translate('reset_filters')}
          </button>
        </div>

        <div className="pattern-insights">
          <h4>{translate('pattern_insights')}</h4>
          {patternData.length > 0 ? (
            <>
              <div className="peak-times">
                <h5>{translate('peak_times')}</h5>
                <div className="peak-grid">
                  <div className="peak-item">
                    <div className="peak-label">{translate('peak_added_time')}:</div>
                    <div className="peak-value">
                      {patternData.reduce((max, current) => 
                        current.added > max.added ? current : max, patternData[0]).hour}
                      {selectedDay === 'Average Day' 
                        ? ` (${patternData.reduce((max, current) => current.added > max.added ? current : max, patternData[0]).added.toFixed(2)} ${translate('average')})`
                        : ` (${patternData.reduce((max, current) => current.added > max.added ? current : max, patternData[0]).added.toFixed(0)} ${translate('total')})`
                      }
                    </div>
                  </div>
                  <div className="peak-item">
                    <div className="peak-label">{translate('peak_sold_time')}:</div>
                    <div className="peak-value">
                      {patternData.reduce((max, current) => 
                        current.sale > max.sale ? current : max, patternData[0]).hour}
                      {selectedDay === 'Average Day' 
                        ? ` (${patternData.reduce((max, current) => current.sale > max.sale ? current : max, patternData[0]).sale.toFixed(2)} ${translate('average')})`
                        : ` (${patternData.reduce((max, current) => current.sale > max.sale ? current : max, patternData[0]).sale.toFixed(0)} ${translate('total')})`
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="pattern-summary">
                <p>
                  {translate('pattern_summary_prefix')} {' '}
                  {selectedDay === 'Average Day' 
                    ? translate('average_day') 
                    : translate(selectedDay.toLowerCase()) || selectedDay} {' '}
                  {translate('pattern_summary_shows')} 
                  {patternData.some(d => d.added > 0 || d.sale > 0) 
                    ? ` ${translate('pattern_activity_detected')}`
                    : ` ${translate('pattern_no_significant_activity')}`
                  }
                </p>
              </div>
            </>
          ) : (
            <p>{translate('insufficient_data_for_insights')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatternAnalysis;