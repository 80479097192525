import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import LoadingAnimation from './LoadingPages/LoadingAnimation';
import './Profile.css';

const CountdownTimer = ({ expirationTime, onExpire }) => {
    const [timeLeft, setTimeLeft] = useState('');
    const [isLessThanHour, setIsLessThanHour] = useState(false);
  
    useEffect(() => {
      if (!expirationTime) return;
  
      const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const expiration = new Date(expirationTime).getTime();
        const difference = expiration - now;
  
        if (difference <= 0) {
          setTimeLeft('');
          onExpire?.();
          return;
        }
  
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        
        setIsLessThanHour(hours < 1);
  
        setTimeLeft(`Remaining ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
      };
  
      calculateTimeLeft();
      const timer = setInterval(calculateTimeLeft, 1000);
  
      return () => clearInterval(timer);
    }, [expirationTime, onExpire]);
  
    return timeLeft ? (
      <span style={{ color: isLessThanHour ? '#c20505' : '#666666' }}>{timeLeft}</span>
    ) : null;
};

const ProfilePrivacy = ({ storeInfo, onBack }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [privacyStatus, setPrivacyStatus] = useState(storeInfo?.privacyStatus || 'Private');
  const [temporaryAccessCode, setTemporaryAccessCode] = useState(storeInfo?.temporaryAccessCode || '');
  const [expirationTime, setExpirationTime] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  const [isAccessCodeHovered, setIsAccessCodeHovered] = useState(false);
  const [isTempCodeHovered, setIsTempCodeHovered] = useState(false);
  const [isAccessCodeCopied, setIsAccessCodeCopied] = useState(false);
  const [isTempCodeCopied, setIsTempCodeCopied] = useState(false);

  useEffect(() => {
    if (storeInfo?.temporaryCodeExpiration?._seconds) {
      const expDate = new Date(storeInfo.temporaryCodeExpiration._seconds * 1000);
      if (expDate > new Date()) {
        setExpirationTime(expDate);
        setTemporaryAccessCode(storeInfo.temporaryAccessCode);
      }
    }
  }, [storeInfo]);

  const handlePrivacyStatusChange = async (e) => {
    const newStatus = e.target.value;
    setPrivacyStatus(newStatus);
    try {
      await axios.put('/profile/update-privacy', {
        modifiedBrandName: storeInfo.modifiedBrandName,
        privacyStatus: newStatus
      });
    } catch (error) {
      setError('Failed to update privacy status');
      console.error('Error updating privacy status:', error);
    }
  };

  const generateTemporaryCode = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/profile/generate-temporary-code', {
        modifiedBrandName: storeInfo.modifiedBrandName
      });
      setTemporaryAccessCode(response.data.temporaryAccessCode);
      setExpirationTime(response.data.expirationTime);
      setSaveStatus('success');
      setTimeout(() => setSaveStatus(null), 2000);
    } catch (error) {
      setError('Failed to generate temporary code');
      setSaveStatus('error');
      setTimeout(() => setSaveStatus(null), 2000);
    } finally {
      setLoading(false);
    }
  };

  const handleExpiration = () => {
    setTemporaryAccessCode('');
    setExpirationTime(null);
  };

  const getInputValue = (originalValue, isHovered, isCopied) => {
    if (isCopied) return "Copied";
    if (isHovered) return "Click to copy";
    return originalValue;
  };

  const getInputStyle = (isHovered, isCopied) => {
    return {
      cursor: 'pointer',
      backgroundColor: isHovered  ? '#dbdbdb' : 'white'
    };
  };

  const handleAccessCodeCopy = () => {
    if (storeInfo.accessCode) {
      navigator.clipboard.writeText(storeInfo.accessCode);
      setIsAccessCodeHovered(false);
      setIsAccessCodeCopied(true);
      setTimeout(() => {
        setIsAccessCodeCopied(false);
      }, 1500);
    }
  };

  const handleTempCodeCopy = () => {
    if (temporaryAccessCode) {
      navigator.clipboard.writeText(temporaryAccessCode);
      setIsTempCodeHovered(false);
      setIsTempCodeCopied(true);
      setTimeout(() => {
        setIsTempCodeCopied(false);
      }, 1500);
    }
  };

  const handleMouseEnter = (setHoverState, isCopied) => {
    if (!isCopied) {
      setHoverState(true);
    }
  };

  const handleMouseLeave = (setHoverState) => {
    setHoverState(false);
  };

  return (
    <div>
      <div className="privacy-header">
        <h2>Account Privacy</h2>
        <div className="back-button-container">
          <button onClick={onBack} className="back-button">
            <span className="back-arrow">←</span> Back
          </button>
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}
      
      {saveStatus && (
        <div className={`save-status ${saveStatus}`}>
          {saveStatus === 'success' && 'Temporary code generated successfully!'}
          {saveStatus === 'error' && 'Error generating temporary code'}
        </div>
      )}

      <div className="privacy-form">
        <div className="form-group">
          <label>Privacy Status:</label>
          <select
            value={privacyStatus}
            onChange={handlePrivacyStatusChange}
            className="privacy-select"
          >
            <option value="Private">Private</option>
            <option value="Public">Public</option>
            <option value="Partners">Partners</option>
            <option value="Upon Request">Upon Request</option>
          </select>
        </div>

        <div className="form-group">
          <label>Access Code:</label>
          <input
            type="text"
            value={getInputValue(storeInfo.accessCode || '', isAccessCodeHovered, isAccessCodeCopied)}
            className="privacy-input"
            onMouseEnter={() => handleMouseEnter(setIsAccessCodeHovered, isAccessCodeCopied)}
            onMouseLeave={() => handleMouseLeave(setIsAccessCodeHovered)}
            onClick={handleAccessCodeCopy}
            readOnly
            style={getInputStyle(isAccessCodeHovered, isAccessCodeCopied)}
          />
        </div>

        <div className="form-group">
          <label>Temporary Access Code:</label>
          <div className="temporary-code-container">
            <div className="input-wrapper">
              <input
                type="text"
                value={getInputValue(temporaryAccessCode || '', isTempCodeHovered, isTempCodeCopied)}
                className="privacy-input with-timer"
                onMouseEnter={() => handleMouseEnter(setIsTempCodeHovered, isTempCodeCopied)}
                onMouseLeave={() => handleMouseLeave(setIsTempCodeHovered)}
                onClick={handleTempCodeCopy}
                readOnly
                placeholder="No active temporary code"
                style={getInputStyle(isTempCodeHovered && temporaryAccessCode, isTempCodeCopied)}
              />
              {expirationTime && (
                <span className="timer-display">
                  <CountdownTimer 
                    expirationTime={expirationTime} 
                    onExpire={handleExpiration}
                  />
                </span>
              )}
              <button
                onClick={generateTemporaryCode}
                className="generate-button-p"
                disabled={loading}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePrivacy;