import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import ProfileSettings from './ProfileSettings';
import './Profile.css';
import ConfirmationDialog from './ConfirmationDialog';

const Profile = () => {
  const [storeInfo, setStoreInfo] = useState(null);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingStore, setEditingStore] = useState(null);
  const [expandedStore, setExpandedStore] = useState(null);
  const [hoveredStore, setHoveredStore] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [storeToDelete, setStoreToDelete] = useState(null);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const navigate = useNavigate();
  const { user, logout } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    fetchStoreInfo();
  }, [user, navigate]);

  const fetchStoreInfo = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/profile/store-info');

      if (response.data.success && response.data.data) {
        const { storeInfo, stores } = response.data.data;
        setStoreInfo(storeInfo);
        setStores(stores);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Error fetching store information');
      console.error('Store info fetch error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditStore = (store) => {
    setEditingStore({ ...store });
    setExpandedStore(store.id);
  };

  const handleSaveChanges = async () => {
    setSaveStatus('saving');
    try {
      await axios.put('/profile/update-store', {
        storeId: editingStore.id,
        modifiedBrandName: storeInfo.modifiedBrandName,
        storeData: editingStore,
        isNetworkStore: storeInfo.status === 'Store Network'
      });

      if (storeInfo.status === 'Store') {
        setStoreInfo({ ...storeInfo, ...editingStore });
        setStores([{ ...storeInfo, ...editingStore }]);
      } else {
        setStores(stores.map(store => 
          store.id === editingStore.id ? editingStore : store
        ));
      }
      
      setSaveStatus('success');
      setTimeout(() => {
        setSaveStatus(null);
        setEditingStore(null);
      }, 2000);
    } catch (error) {
      setSaveStatus('error');
      setError(error.response?.data?.error || 'Error saving changes');
      setTimeout(() => setSaveStatus(null), 2000);
    }
  };

  const confirmDeleteStore = async () => {
    setShowConfirmation(false);
    setLoading(true);
    try {
      const response = await axios.delete('/profile/delete-store', {
        params: {
          storeId: storeToDelete,
          modifiedBrandName: storeInfo.modifiedBrandName,
          mainStoreId: storeInfo.id
        }
      });

      const { remainingStores } = response.data;
      setStores(remainingStores);

      if (remainingStores.length === 1) {
        const lastStore = remainingStores[0];
        setStoreInfo({ ...storeInfo, ...lastStore, status: 'Store' });
      }
    } catch (error) {
      setError('Error deleting store');
      console.error('Error deleting store:', error);
    } finally {
      setStoreToDelete(null);
      setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingStore(null);
  };

  const handleInputChange = (e) => {
    setEditingStore({ ...editingStore, [e.target.name]: e.target.value });
  };

  const handleDeleteStore = (storeId) => {
    setStoreToDelete(storeId);
    setShowConfirmation(true);
  };

  const cancelDeleteStore = () => {
    setShowConfirmation(false);
    setStoreToDelete(null);
  };

  const handleLogout = async () => {
    try {
      await axios.post('/profile/logout');
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
    }
  };



  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!storeInfo || !stores.length) {
    return;
  }

  return (
    <div className="profile-container-main">
      {showPrivacy ? (
        <ProfileSettings 
          storeInfo={storeInfo}
          onBack={() => setShowPrivacy(false)}
        />
      ) : (
        <div className="profile-container">
          <div className="profile-header">
            <h2 className="profile-title">Profile</h2>
            <button 
              onClick={() => setShowPrivacy(true)}
              className="settings-button"
            >
              Settings
            </button>
          </div>

          <div className="profile-content">
            <div className="profile-info-section">
              <h3 className="section-title">Store Information</h3>
              <div className="status-badge">
                <span className="status-label">Status:</span>
                <span className="status-value">{storeInfo.status}</span>
              </div>

              {saveStatus && (
                <div className={`save-status ${saveStatus}`}>
                  {saveStatus === 'saving' && 'Saving changes...'}
                  {saveStatus === 'success' && 'Changes saved successfully!'}
                  {saveStatus === 'error' && 'Error saving changes. Please try again.'}
                </div>
              )}
            </div>

            <div className="stores-list">
              {stores.map(store => (
                <div 
                  key={store.id} 
                  className={`store-card ${expandedStore === store.id ? 'expanded' : ''}`}
                  onMouseEnter={() => setHoveredStore(store.id)}
                  onMouseLeave={() => setHoveredStore(null)}
                >
                  <div className="store-card-header">
                    <h4 className="store-address">{store.address}</h4>
                    {!editingStore && (
                      <button 
                        className={`expand-button ${hoveredStore === store.id ? 'visible' : ''}`}
                        onClick={() => setExpandedStore(expandedStore === store.id ? null : store.id)}
                      >
                        {expandedStore === store.id ? 'Less' : 'More'}
                      </button>
                    )}
                  </div>
                  
                  {expandedStore === store.id && (
                    <div className="store-details">
                      {editingStore && editingStore.id === store.id ? (
                        <div className="edit-form">
                          <div className="form-row">
                            <div className="form-group">
                              <label>Address</label>
                              <input 
                                name="address" 
                                value={editingStore.address} 
                                onChange={handleInputChange} 
                                placeholder="Address" 
                              />
                            </div>
                            <div className="form-group">
                              <label>Country</label>
                              <input 
                                name="country" 
                                value={editingStore.country} 
                                onChange={handleInputChange} 
                                placeholder="Country" 
                              />
                            </div>
                          </div>
                          
                          <div className="form-row">
                            <div className="form-group">
                              <label>State</label>
                              <input 
                                name="state" 
                                value={editingStore.state} 
                                onChange={handleInputChange} 
                                placeholder="State" 
                              />
                            </div>
                            <div className="form-group">
                              <label>City</label>
                              <input 
                                name="city" 
                                value={editingStore.city} 
                                onChange={handleInputChange} 
                                placeholder="City" 
                              />
                            </div>
                          </div>
                          
                          <div className="form-row">
                            <div className="form-group">
                              <label>Size (sq m)</label>
                              <input 
                                name="size" 
                                value={editingStore.size} 
                                onChange={handleInputChange} 
                                placeholder="Size (sq m)" 
                              />
                            </div>
                            <div className="form-group">
                              <label>Employees</label>
                              <input 
                                name="employees" 
                                value={editingStore.employees} 
                                onChange={handleInputChange} 
                                placeholder="Employees" 
                              />
                            </div>
                          </div>
                          
                          <div className="form-row">
                            <div className="form-group">
                              <label>Phone</label>
                              <input 
                                name="phone" 
                                value={editingStore.phone} 
                                onChange={handleInputChange} 
                                placeholder="Phone" 
                              />
                            </div>
                            <div className="form-group">
                              <label>Email</label>
                              <input 
                                name="contactEmail" 
                                value={editingStore.contactEmail} 
                                onChange={handleInputChange} 
                                placeholder="Email" 
                              />
                            </div>
                          </div>
                          
                          <div className="button-group">
                            <button onClick={handleSaveChanges} className="primary-button">
                              Save Changes
                            </button>
                            <button onClick={handleCancelEdit} className="secondary-button">
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="store-info-grid">
                          <div className="info-row">
                            <div className="info-item">
                              <span className="info-label">Address:</span>
                              <span className="info-value">{store.address}</span>
                            </div>
                            <div className="info-item">
                              <span className="info-label">Country:</span>
                              <span className="info-value">{store.country}</span>
                            </div>
                          </div>
                          
                          <div className="info-row">
                            <div className="info-item">
                              <span className="info-label">State:</span>
                              <span className="info-value">{store.state}</span>
                            </div>
                            <div className="info-item">
                              <span className="info-label">City:</span>
                              <span className="info-value">{store.city}</span>
                            </div>
                          </div>
                          
                          <div className="info-row">
                            <div className="info-item">
                              <span className="info-label">Size:</span>
                              <span className="info-value">{store.size} sq m</span>
                            </div>
                            <div className="info-item">
                              <span className="info-label">Employees:</span>
                              <span className="info-value">{store.employees}</span>
                            </div>
                          </div>
                          
                          <div className="info-row">
                            <div className="info-item">
                              <span className="info-label">Phone:</span>
                              <span className="info-value">{store.phone}</span>
                            </div>
                            <div className="info-item">
                              <span className="info-label">Email:</span>
                              <span className="info-value">{store.contactEmail || 'N/A'}</span>
                            </div>
                          </div>

                          <div className="button-group">
                            <button onClick={() => handleEditStore(store)} className="primary-button">
                              Edit
                            </button>
                            {storeInfo.status === 'Store Network' && stores.length > 1 && (
                              <button 
                                onClick={() => handleDeleteStore(store.id)}
                                className="danger-button"
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="profile-actions">
              {(storeInfo.status === 'Store Network' || stores.length === 1) && (
                <Link to="/store/add-store" className="add-store-button">
                  Add Store
                </Link>
              )}

              <button onClick={handleLogout} className="logout-button">
                Logout
              </button>
            </div>
          </div>

          {showConfirmation && (
            <ConfirmationDialog
              message="Are you sure you want to delete this store?"
              onConfirm={confirmDeleteStore}
              onCancel={cancelDeleteStore}
              confirmText="Confirm"
              cancelText="Cancel"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Profile;