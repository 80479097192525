import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MessengerCreateOrder.css';


const OrderConfirmationDialog = ({ onView, onConfirm, onClose }) => {
  return (
    <div className="confirmation-overlay-confirm-page">
      <div className="confirmation-dialog-confirm-page">
        <div className="confirmation-content-confirm-page">
          <p className="confirmation-message-confirm-page">Confirm Order Submission?</p>
          <div className="confirmation-buttons-confirm-page">
            <button onClick={onView} className="view-btn-confirm">
              View order
            </button>
            <button onClick={onConfirm} className="confirm-btn-confirm">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const ViewOrderModal = ({ items, onSubmit, onClose, onCancel }) => {
  return (
    <div className="messenger-create-order-backdrop" onClick={onClose}>
      <div className="messenger-create-order-content" onClick={e => e.stopPropagation()}>
        <div className="messenger-create-order-header">
          <h3>View Order</h3>
          <button className="messenger-close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="view-order-body">
          <table className="view-order-table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Volume</th>
                <th>Weight</th>
                <th>Barcode</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.volume || 'N/A'}</td>
                  <td>{item.weight || 'N/A'}</td>
                  <td>{item.barcode || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="messenger-order-actions">
          <button onClick={onCancel} className="messenger-cancel-button">
            Cancel
          </button>
          <button onClick={onSubmit} className="messenger-submit-button">
            Submit Order
          </button>
        </div>
      </div>
    </div>
  );
};



const MessengerCreateOrder = ({ onClose, onSubmit, currentUserData, currentChatUser }) => {
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState({
    barcode: '',
    category: '',
    name: '',
    unitOfMeasureForVolume: '',
    volume: '',
    unitOfMeasureForWeight: '',
    weight: '',
    quantity: ''
  });
  const [selectedStore, setSelectedStore] = useState('');
  const [expectedDelivery, setExpectedDelivery] = useState('');
  const [isDeliveryNA, setIsDeliveryNA] = useState(false);
  const [notifyBeforeValue, setNotifyBeforeValue] = useState('');
  const [notifyBeforeUnit, setNotifyBeforeUnit] = useState('days');
  const [stores, setStores] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showViewOrder, setShowViewOrder] = useState(false);



  const handleSubmitClick = (e) => {
    // Clear any existing validation messages
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.setCustomValidity('');
    });
    
    // Prevent form validation
    e?.preventDefault?.();
    setShowConfirmation(true);
  };

  const handleViewOrder = () => {
    setShowConfirmation(false);
    setShowViewOrder(true);
  };
  

  const handleConfirmOrder = async () => {
    setShowConfirmation(false);
    await handleSubmit();
  };

  const handleCloseViewOrder = () => {
    setShowViewOrder(false);
    onClose(); // Close the entire container
  };

  const handleBackToCreate = () => {
    setShowViewOrder(false); // Just go back to create view
  };


  const handleFinalSubmit = async () => {
    setShowViewOrder(false);
    await handleSubmit();
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = async () => {
    try {
      const response = await axios.get('/orders/store-info');
      setStores(response.data.stores || []);
    } catch (error) {
      console.error('Error fetching stores:', error);
    }
  };

  const categories = [
    'Groceries', 'Beverages', 'Household Products', 'Personal Care Items', 
    'Snacks', 'Frozen Foods', 'Health and Wellness Products', 'Electronics', 
    'Pet Supplies', 'Office Supplies', 'Raw Materials'
  ];

  const unitsOfMeasure = {
    weight: ['Kilogram (kg)', 'Gram (g)', 'Ounce (oz)', 'Pound (lb)', 'N/A'],
    volume: ['Liter (L)', 'Milliliter (mL)', 'Fluid Ounce (fl oz)', 'Cubic Meter (m³)', 'N/A']
  };

  const handleAddItem = () => {
    if (currentItem.name && currentItem.quantity) {
      setItems([...items, { ...currentItem, id: Date.now() }]);
      setCurrentItem({
        barcode: '',
        category: '',
        name: '',
        unitOfMeasureForVolume: '',
        volume: '',
        unitOfMeasureForWeight: '',
        weight: '',
        quantity: ''
      });
    }
  };

  const handleDeliveryNAToggle = () => {
    setIsDeliveryNA(!isDeliveryNA);
    if (!isDeliveryNA) {
      setExpectedDelivery('');
      setNotifyBeforeValue('');
      setNotifyBeforeUnit('days');
    }
  };

  const handleSubmit = async () => {
    if (items.length === 0 || !selectedStore) return;
      
    const orderData = {
      type: 'order',
      items: items.map(item => ({
        ...item,
        name: item.name.trim(),
        quantity: Number(item.quantity),
        volume: item.volume ? Number(item.volume) : null,
        weight: item.weight ? Number(item.weight) : null,
        category: item.category || null,
        barcode: item.barcode || null
      })),
      store: selectedStore,
      orderNumber: Math.floor(1000000000 + Math.random() * 9000000000).toString(),
      expectedDelivery: isDeliveryNA ? 'N/A' : expectedDelivery,
      notifyBefore: expectedDelivery && !isDeliveryNA && notifyBeforeValue ? {
        value: Number(notifyBeforeValue),
        unit: notifyBeforeUnit
      } : null,
      status: 'Pending',
      dateCreated: new Date()
    };
  
    await onSubmit(orderData);
    onClose();
  };


  if (showViewOrder) {
    return (
      <ViewOrderModal
      items={items}
      onSubmit={handleFinalSubmit}
      onClose={handleCloseViewOrder} // For × button and backdrop
      onCancel={handleBackToCreate} // New prop for Cancel button
    />
    );
  }

  return (
    <div className="messenger-create-order-backdrop" onClick={onClose}>
      <div className={`messenger-create-order-content ${items.length > 0 ? 'has-items' : ''}`} onClick={e => e.stopPropagation()}>
        <div className="messenger-create-order-header">
          <h3>Create New Order</h3>
          <button className="messenger-close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="messenger-create-order-body">
          {/* Items List Section - Shows up when items exist */}
          <div className="items-list-container">
            {items.map((item, index) => (
              <div key={item.id} className="item-card-messenger">
                <div>
                  <p>{item.name}</p>
                  <p>Qty: {item.quantity}</p>
                </div>
                <button
                  onClick={() => setItems(items.filter((_, i) => i !== index))}
                  className="remove-item-btn-messenger"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>

          {/* Form Section */}
          <div className="form-container">
            <div className="store-selection-messenger">
              <select
                value={selectedStore}
                onChange={(e) => setSelectedStore(e.target.value)}
                required
                className="store-select-messenger"
              >
                <option value="">Select Store</option>
                {stores.map(store => (
                  <option key={store} value={store}>{store}</option>
                ))}
              </select>
            </div>

            <div className="delivery-date-section-messenger">
              <label className="delivery-date-label">Expected Date of Delivery</label>
              <div className="date-input-container-messenger">
                <input
                  type="date"
                  value={expectedDelivery}
                  onChange={(e) => setExpectedDelivery(e.target.value)}
                  disabled={isDeliveryNA}
                  min={new Date().toISOString().split('T')[0]}
                  required={!isDeliveryNA}
                  className="date-input-messenger"
                />
                <div className="na-toggle-messenger">
                  <label className="na-label-messenger">
                    <input
                      type="checkbox"
                      checked={isDeliveryNA}
                      onChange={handleDeliveryNAToggle}
                      className="na-checkbox-messenger"
                    />
                    <span>N/A</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-row-messenger">
              <input
                type="text"
                value={currentItem.barcode}
                onChange={(e) => setCurrentItem({ ...currentItem, barcode: e.target.value })}
                placeholder="Barcode"
                className="messenger-input"
              />
              <select
                value={currentItem.category}
                onChange={(e) => setCurrentItem({ ...currentItem, category: e.target.value })}
                className="messenger-select"
              >
                <option value="">Select Category</option>
                {categories.map(cat => (
                  <option key={cat} value={cat}>{cat}</option>
                ))}
              </select>
            </div>

            <div className="form-row-messenger">
              <input
                type="text"
                value={currentItem.name}
                onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
                placeholder="Product Name"
                required
                className="messenger-input"
              />
              <input
                type="number"
                value={currentItem.quantity}
                onChange={(e) => setCurrentItem({ ...currentItem, quantity: e.target.value })}
                placeholder="Quantity"
                required
                className="messenger-input"
              />
            </div>

            <div className="form-row-messenger">
              <select
                value={currentItem.unitOfMeasureForVolume}
                onChange={(e) => setCurrentItem({ ...currentItem, unitOfMeasureForVolume: e.target.value })}
                className="messenger-select"
              >
                <option value="">Select Volume Unit</option>
                {unitsOfMeasure.volume.map(unit => (
                  <option key={unit} value={unit}>{unit}</option>
                ))}
              </select>
              <input
                type="number"
                value={currentItem.volume}
                onChange={(e) => setCurrentItem({ ...currentItem, volume: e.target.value })}
                placeholder="Volume"
                disabled={currentItem.unitOfMeasureForVolume === "N/A"}
                className="messenger-input"
              />
            </div>

            <div className="form-row-messenger">
              <select
                value={currentItem.unitOfMeasureForWeight}
                onChange={(e) => setCurrentItem({ ...currentItem, unitOfMeasureForWeight: e.target.value })}
                className="messenger-select"
              >
                <option value="">Select Weight Unit</option>
                {unitsOfMeasure.weight.map(unit => (
                  <option key={unit} value={unit}>{unit}</option>
                ))}
              </select>
              <input
                type="number"
                value={currentItem.weight}
                onChange={(e) => setCurrentItem({ ...currentItem, weight: e.target.value })}
                placeholder="Weight"
                disabled={currentItem.unitOfMeasureForWeight === "N/A"}
                className="messenger-input"
              />
            </div>

            <button type="button" onClick={handleAddItem} className="add-item-btn-messenger">
              Add Item
            </button>
          </div>
        </div>

        <div className="messenger-order-actions">
          <button onClick={onClose} className="messenger-cancel-button">
            Cancel
          </button>
          <button
              type="button" // Change from implicit 'submit' to explicit 'button'
              onClick={handleSubmitClick}
              className="messenger-submit-button"
              disabled={items.length === 0 || !selectedStore || (!isDeliveryNA && !expectedDelivery)}
            >
            Submit Order
          </button>
        </div>

        {showConfirmation && (
          <OrderConfirmationDialog
            onView={handleViewOrder}
            onConfirm={handleConfirmOrder}
            onClose={() => setShowConfirmation(false)}
          />
        )}
      </div>
    </div>
  );
};





export default MessengerCreateOrder;