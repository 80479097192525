import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { Search, X, Send, Check, AlertCircle, Paperclip, NotebookPen, BriefcaseBusiness } from 'lucide-react';
import './Messenger.css';
import MessengerCreateOrder from './MessengerCreateOrder';
import logoPDF from "../assets/logoPDF.svg"
import logoDOCX from "../assets/logoDOCX.svg"
import logoTXT from "../assets/logoTXT.svg"

const MessageType = {
    TEXT: 'text',
    IMAGE: 'image',
    VIDEO: 'video',
    DOCUMENT: 'document'
};

const ImageMessage = ({ url, fileName }) => {
    return (
      <div className="messenger-image-container">
        <img 
          src={url} 
          alt={fileName}
          className="messenger-image-message"
          onClick={() => window.open(url, '_blank')}
        />
      </div>
    );
  };

const formatFileSize = (bytes) => {
  if (!bytes) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
};

const DocumentMessage = ({ url, fileName, fileType, fileSize }) => {
  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Failed to download file. Please try again.');
    }
  };

  const getFileIcon = () => {
    switch(fileType) {
      case 'application/pdf':
        return <div><img src={logoPDF} alt="PDF" /></div>;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <div><img src={logoDOCX} alt="DOCX" /></div>;
      case 'text/plain':
        return <div><img src={logoTXT} alt="TXT" /></div>;
      default:
        return '📎';
    }
  };

  return (
    <div className="messenger-document-container" onClick={handleDownload}>
      <div className="document-icon">
        {getFileIcon()}
      </div>
      <div className="document-info">
        <div className="document-name">{fileName}</div>
        <div className="document-size">
          {formatFileSize(fileSize)}
        </div>
      </div>
    </div>
  );
};
const MessageStatus = {
    SENDING: 'sending',
    DELIVERED: 'delivered',
    NOT_DELIVERED: 'not_delivered',
    NOT_SEEN: 'not_seen',
    SEEN: 'seen'
};

// Message Status Indicator Component
const MessageStatusIndicator = ({ status, seen }) => {
    const getColor = () => {
        return seen ? '#075ede' : '#a8a8a8';
    };

    const DoubleCheck = ({ color }) => (
        <div className="double-check">
            <Check size={14} color={color} />
            <Check size={14} color={color} className="second-check" />
        </div>
    );

    const color = getColor();

    if (status === 'sending') {
        return <Check size={14} color={color} />;
    }
    if (status === 'not_delivered') {
        return <AlertCircle size={14} color="#700101" />;
    }
    
    return <DoubleCheck color={color} />;
};

const Messenger = () => {
    // State management
    const [users, setUsers] = useState([]);
    const [chats, setChats] = useState([]);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [searchEmail, setSearchEmail] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentUserData, setCurrentUserData] = useState(null);
    const [currentChatUser, setCurrentChatUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [initialized, setInitialized] = useState(false);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [visibleMessages, setVisibleMessages] = useState(new Set());
    const [lastMessageTimestamp, setLastMessageTimestamp] = useState(null);
    const [lastChatUpdateTime, setLastChatUpdateTime] = useState(null);
    const [showOrderForm, setShowOrderForm] = useState(false);
    const [viewOrderId, setViewOrderId] = useState(null);

    // Hooks
    const { chatId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();

    // Refs
    const observerRef = useRef(null);
    const messageCache = useRef(new Map());
    const currentChatRef = useRef(chatId);
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const pollingIntervalRef = useRef(null);
    const chatPollingIntervalRef = useRef(null);
    const isFirstLoadRef = useRef(true);



    // Scroll to bottom utility
    const scrollToBottom = useCallback(() => {
        if (messages.length > 0 && messagesContainerRef.current) {
            if (isFirstLoadRef.current) {
                messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
                isFirstLoadRef.current = false;
            } else {
                messagesContainerRef.current.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'end'
                });
            }
        }
    }, [messages]);

    // Add this helper function at the top level
const mergeMessages = (existingMessages, newMessages) => {
    const messageMap = new Map();
    
    // Add existing messages
    existingMessages.forEach(msg => {
        messageMap.set(msg.id, { ...msg });
    });
    
    // Merge new messages
    newMessages.forEach(newMsg => {
        const existingMsg = messageMap.get(newMsg.id);
        
        // Skip updates for temp messages
        if (existingMsg?.id?.startsWith('temp_')) {
            return;
        }
        
        // For existing messages, only update status
        if (existingMsg) {
            messageMap.set(newMsg.id, {
                ...existingMsg,
                status: newMsg.seen ? 'seen' : 
                        newMsg.delivered ? 'delivered' : 
                        existingMsg.status,
                seen: !!newMsg.seen,
                delivered: !!newMsg.delivered,
                seenAt: newMsg.seenAt || existingMsg.seenAt,
                deliveredAt: newMsg.deliveredAt || existingMsg.deliveredAt
            });
        } else {
            // Add new message
            messageMap.set(newMsg.id, {
                ...newMsg,
                status: newMsg.seen ? 'seen' : 
                        newMsg.delivered ? 'delivered' : 
                        'sending'
            });
        }
    });

    // Convert to array and sort by timestamp
    return Array.from(messageMap.values())
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
};



  // Update the setupPolling function to prevent duplicates
const setupPolling = useCallback(() => {
    if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
    }

    const pollMessages = async () => {
        if (!currentUserData?.modifiedBrandName || !chatId || chatId === 'new') return;

        try {
            const response = await axios.get(`/messenger/messages/${currentUserData.modifiedBrandName}/${chatId}`, {
                params: { lastMessageTimestamp }
            });

            if (Array.isArray(response.data) && response.data.length > 0) {
                setMessages(prev => {
                    // Create a Set of message IDs to track unique messages
                    const messageIds = new Set(prev.map(msg => msg.id));
                    const updatedMessages = [...prev];
                    
                    response.data.forEach(newMsg => {
                        const existingIndex = updatedMessages.findIndex(msg => 
                            // Check both real ID and temporary ID
                            msg.id === newMsg.id || 
                            (msg.id.startsWith('temp_') && msg.text === newMsg.text && 
                             msg.senderId === newMsg.senderId)
                        );
                        
                        if (existingIndex === -1 && !messageIds.has(newMsg.id)) {
                            // Only add if it's truly a new message
                            updatedMessages.push({
                                ...newMsg,
                                status: newMsg.seen ? 'seen' : 'delivered',
                                seen: !!newMsg.seen,
                                delivered: true
                            });
                            messageIds.add(newMsg.id);
                        } else if (existingIndex !== -1) {
                            // Update existing message status
                            updatedMessages[existingIndex] = {
                                ...updatedMessages[existingIndex],
                                status: newMsg.seen ? 'seen' : updatedMessages[existingIndex].status,
                                seen: !!newMsg.seen,
                                orderData: newMsg.orderData || updatedMessages[existingIndex].orderData // Add this line

                            };
                        }
                    });

                    return updatedMessages;
                });
            }
        } catch (error) {
            console.error('Error polling messages:', error);
        }
    };

    pollingIntervalRef.current = setInterval(pollMessages, 1000);
    pollMessages();

    return () => {
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
        }
    };
}, [chatId, currentUserData, lastMessageTimestamp]);
    

    // Chat list polling setup
    const setupChatListPolling = useCallback(() => {
        if (chatPollingIntervalRef.current) {
            clearInterval(chatPollingIntervalRef.current);
        }

        const pollChatList = async () => {
            if (!currentUserData?.modifiedBrandName) return;

            try {
                const response = await axios.get(`/messenger/chats/${currentUserData.modifiedBrandName}`);

                if (response.data && Array.isArray(response.data)) {
                    const validChats = response.data.filter(chat => 
                        chat && chat.otherUserData && chat.otherUserData.email
                    );

                    setChats(prevChats => {
                        const updatedChats = [...prevChats];
                        
                        validChats.forEach(newChat => {
                            const existingChatIndex = updatedChats.findIndex(chat => chat.id === newChat.id);
                            
                            if (existingChatIndex === -1) {
                                updatedChats.push(newChat);
                            } else {
                                updatedChats[existingChatIndex] = {
                                    ...updatedChats[existingChatIndex],
                                    ...newChat
                                };
                            }
                        });

                        return updatedChats.sort((a, b) => {
                            const timeA = a.lastMessageTime ? new Date(a.lastMessageTime).getTime() : 0;
                            const timeB = b.lastMessageTime ? new Date(b.lastMessageTime).getTime() : 0;
                            return timeB - timeA;
                        });
                    });

                    const latestChat = validChats[0];
                    if (latestChat?.lastMessageTime) {
                        setLastChatUpdateTime(new Date(latestChat.lastMessageTime).getTime());
                    }
                }
            } catch (error) {
                console.error('Error polling chat list:', error);
            }
        };

        chatPollingIntervalRef.current = setInterval(pollChatList, 1000);
        pollChatList();

        return () => {
            if (chatPollingIntervalRef.current) {
                clearInterval(chatPollingIntervalRef.current);
            }
        };
    }, [currentUserData, lastChatUpdateTime]);

    // Initialize messenger
    useEffect(() => {
        const init = async () => {
            await fetchCurrentUserData();
            setInitialized(true);
        };
        init();
    }, []);

    // Setup observers and fetch data
    useEffect(() => {
        if (!initialized) return;

        const loadChatData = async () => {
            if (chatId && chatId !== 'new') {
                if (currentChatRef.current !== chatId) {
                    setMessages([]);
                    currentChatRef.current = chatId;
                }
                
                if (!messageCache.current.has(chatId)) {
                    setLoadingMessages(true);
                    await Promise.all([
                        fetchMessages(),
                        fetchChats()
                    ]);
                    setLoadingMessages(false);
                } else {
                    setMessages(messageCache.current.get(chatId));
                    fetchMessages(true);
                }
            }
        };

        loadChatData();
        setupPolling();

        return () => {
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
            }
        };
    }, [chatId, initialized, setupPolling]);

    // Setup chat list polling
    useEffect(() => {
        if (currentUserData) {
            setupChatListPolling();
            setLoading(false);
        }

        return () => {
            if (chatPollingIntervalRef.current) {
                clearInterval(chatPollingIntervalRef.current);
            }
        };
    }, [currentUserData, setupChatListPolling]);

    // Message visibility observer
    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const messageId = entry.target.getAttribute('data-message-id');
                        if (messageId) {
                            setVisibleMessages(prev => new Set([...prev, messageId]));
                        }
                    }
                });
            },
            { threshold: 0.5 }
        );

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, []);

    // Mark messages as seen
    useEffect(() => {
        if (visibleMessages.size === 0 || !currentUserData || !chatId) return;

        const unseenMessages = messages.filter(msg => 
            visibleMessages.has(msg.id) && 
            msg.senderId !== currentUserData.userId && 
            !msg.seen
        );

        if (unseenMessages.length > 0) {
            const markMessagesAsSeen = async () => {
                try {
                    const response = await axios.post('/messenger/mark-seen', {
                        chatId,
                        userId: currentUserData.userId,
                        modifiedBrandName: currentUserData.modifiedBrandName,
                        messageIds: unseenMessages.map(msg => msg.id)
                    });

                    if (response.data.success) {
                        setMessages(prev => prev.map(msg => 
                            unseenMessages.some(unseenMsg => unseenMsg.id === msg.id)
                                ? { 
                                    ...msg, 
                                    status: 'seen',
                                    seen: true,
                                    seenAt: new Date()
                                }
                                : msg
                        ));
                    }
                } catch (error) {
                    console.error('Error marking messages as seen:', error);
                }
            };

            markMessagesAsSeen();
        }
    }, [visibleMessages, currentUserData, chatId, messages]);

    // API calls
    const fetchCurrentUserData = async () => {
        try {
            const response = await axios.get('/messenger/store-info');
            const userData = response.data;
            setCurrentUserData(userData);
            return userData;
        } catch (error) {
            console.error('Error fetching user data:', error);
            setError('Failed to fetch user data');
            return null;
        }
    };

    const fetchChats = async () => {
        if (!currentUserData?.modifiedBrandName) return;

        try {
            const response = await axios.get(`/messenger/chats/${currentUserData.modifiedBrandName}`);
            
            if (response.data && Array.isArray(response.data)) {
                const validChats = response.data.filter(chat => 
                    chat && chat.otherUserData && chat.otherUserData.email
                );
                
                setChats(validChats);

                if (chatId && chatId !== 'new') {
                    const currentChat = validChats.find(chat => chat.id === chatId);
                    if (currentChat?.otherUserData) {
                        setCurrentChatUser(currentChat.otherUserData);
                    }
                }
                setError('');
            }
        } catch (error) {
            console.error('Error fetching chats:', error);
            const errorMessage = error.response?.data?.error || 'Failed to fetch chats';
            setError(errorMessage);
            setChats([]);
        }
    };

    const fetchMessages = async (background = false) => {
        if (!currentUserData?.modifiedBrandName || !chatId) return;
        
        try {
            if (!background) {
                setLoadingMessages(true);
            }

            const response = await axios.get(
                `/messenger/messages/${currentUserData.modifiedBrandName}/${chatId}`
            );
            
            if (Array.isArray(response.data)) {
                const processedMessages = response.data.map(msg => ({
                    ...msg,
                    status: msg.seen ? 'seen' 
                            : msg.delivered ? 'not_seen'
                            : msg.status || 'sending',
                    seen: !!msg.seen,
                    timestamp: new Date(msg.timestamp)
                }));

                setMessages(prevMessages => {
                    const messageIds = new Set(processedMessages.map(msg => msg.id));
                    const uniqueMessages = [...processedMessages];
                    
                    prevMessages.forEach(msg => {
                        if (!messageIds.has(msg.id)) {
                            uniqueMessages.push(msg);
                        }
                    });
                    
                    return uniqueMessages.sort((a, b) => 
                        new Date(a.timestamp) - new Date(b.timestamp)
                    );
                });

                if (!background) {
                    messageCache.current.set(chatId, processedMessages);
                }
            }

            if (!background) {
                setLoadingMessages(false);
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
            if (!background) {
                setError('Failed to fetch messages');
                setLoadingMessages(false);
            }
        }
    };

    const searchUsers = async (searchTerm) => {
        try {
            const response = await axios.post('/messenger/search-users', {
                searchTerm
            });
            setUsers(response.data);
        } catch (error) {
            setError('Failed to search users');
            console.error('Error:', error);
        }
    };

    const createChat = async (recipientData) => {
        try {
            const response = await axios.post('/messenger/create-chat', {
                currentUserData,
                recipientData
            });

            navigate(`/store/messenger/${response.data.chatId}`);
            setSelectedUser(null);
            setUsers([]);
            setIsSearchOpen(false);
            setCurrentChatUser(recipientData);
        } catch (error) {
            setError('Failed to create chat');
            console.error('Error:', error);
        }
    };

    const sendMessage = async (e) => {
        e?.preventDefault();
        if (!message.trim() || !chatId || !currentUserData) return;
    
        const messageText = message.trim();
        setMessage('');
    
        // Create temporary message with 'sending' status
        const tempMessage = {
            id: `temp_${Date.now()}`,
            text: messageText,
            type: 'text',
            senderId: currentUserData.userId,
            senderEmail: currentUserData.email,
            status: 'sending',
            timestamp: new Date(),
            delivered: false,
            seen: false
        };
    
        // Add message to state immediately
        setMessages(prev => [...prev, tempMessage]);
    
        try {
            const response = await axios.post('/messenger/send-message', {
                chatId,
                messageData: {
                    text: messageText,
                    type: 'text',
                    senderId: currentUserData.userId,
                    senderEmail: currentUserData.email
                },
                currentUserData,
                recipientData: currentChatUser
            });
    
            if (response.data.success) {
                // Update only the status of the sent message
                setMessages(prev => prev.map(msg => 
                    msg.id === tempMessage.id 
                        ? {
                            ...msg,
                            id: response.data.messageId,
                            status: 'delivered',
                            delivered: true
                        } 
                        : msg
                ));
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prev => prev.map(msg => 
                msg.id === tempMessage.id 
                    ? { ...msg, status: 'not_delivered' } 
                    : msg
            ));
        }
    };
    
    
    
    



    const handleFileUpload = async (e) => {
        try {
            const file = e.target.files[0];
            if (!file) return;
    
            // Reset input
            e.target.value = '';
    
            // Check allowed file types
            const allowedTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'text/plain'
            ];
    
            if (!allowedTypes.includes(file.type)) {
                alert('File type not supported. Please upload an image, PDF, DOCX, or TXT file.');
                return;
            }
    
            // Size limit (10MB)
            if (file.size > 10 * 1024 * 1024) {
                alert('File size should not exceed 10MB');
                return;
            }
    
            // Create temporary message
            const tempId = `temp_${Date.now()}`;
            const tempMessage = {
                id: tempId,
                type: file.type.startsWith('image/') ? MessageType.IMAGE : MessageType.DOCUMENT,
                senderId: currentUserData.userId,
                senderEmail: currentUserData.email,
                fileName: file.name,
                fileType: file.type,
                status: 'sending',
                timestamp: new Date()
            };
    
            setMessages(prev => [...prev, tempMessage]);
    
            // Convert file to base64
            const reader = new FileReader();
            reader.onloadend = async () => {
                try {
                    const response = await axios.post('/messenger/upload-file', {
                        file: {
                            data: reader.result,
                            name: file.name,
                            type: file.type
                        },
                        chatId,
                        currentUserData: {
                            userId: currentUserData.userId,
                            email: currentUserData.email,
                            modifiedBrandName: currentUserData.modifiedBrandName
                        }
                    });
    
                    if (response.data.success) {
                        const messageData = {
                            type: file.type.startsWith('image/') ? MessageType.IMAGE : MessageType.DOCUMENT,
                            senderId: currentUserData.userId,
                            senderEmail: currentUserData.email,
                            fileUrl: response.data.fileUrl,
                            fileName: file.name,
                            fileType: file.type,
                            fileSize: response.data.fileSize
                        };
    
                        // Send message
                        const msgResponse = await axios.post('/messenger/send-message', {
                            chatId,
                            messageData,
                            currentUserData,
                            recipientData: currentChatUser
                        });
    
                        if (msgResponse.data.success) {
                            setMessages(prev => prev.map(msg => 
                                msg.id === tempId 
                                    ? {
                                        ...msg,
                                        id: msgResponse.data.messageId,
                                        fileUrl: response.data.fileUrl,
                                        status: 'delivered',
                                        delivered: true,
                                        timestamp: new Date(msgResponse.data.timestamp)
                                    }
                                    : msg
                            ));
                        }
                    }
                } catch (error) {
                    console.error('Error uploading file:', error);
                    setMessages(prev => prev.filter(msg => msg.id !== tempId));
                    alert('Failed to upload file. Please try again.');
                }
            };
    
            reader.readAsDataURL(file);
    
        } catch (error) {
            console.error('Error handling file:', error);
            alert('Failed to handle file. Please try again.');
        }
    };
    

    const handleOrderSubmit = async (orderData) => {
  try {
    const messageData = {
      type: 'order',
      orderData,
      senderId: currentUserData.userId,
      senderEmail: currentUserData.email,
      timestamp: new Date(),
      status: 'Pending'
    };

    const response = await axios.post('/messenger/send-message', {
      chatId,
      messageData,
      currentUserData,
      recipientData: currentChatUser
    });

    if (response.data.success) {
      setMessages(prev => [...prev, {
        ...messageData,
        id: response.data.messageId,
        status: 'delivered',
        delivered: true
      }]);
    }
  } catch (error) {
    console.error('Error sending order:', error);
    alert('Failed to send order. Please try again.');
  }
};

const handleOrderAction = async (messageId, newStatus) => {
    try {
        if (newStatus === 'Confirmed') {
            const message = messages.find(msg => msg.id === messageId);
            if (!message || !message.orderData) {
                throw new Error('Order data not found');
            }

            // Add default values for any potentially undefined fields
            const orderData = {
                items: message.orderData.items || [],
                store: message.orderData.store || currentChatUser.modifiedBrandName, // Use recipient's store name as default
                supplier: currentUserData.modifiedBrandName,
                expectedDelivery: message.orderData.expectedDelivery || new Date().toISOString(),
                notifyBefore: message.orderData.notifyBefore || 24, // Default 24 hours
                orderNumber: message.orderData.orderNumber,
                status: 'Confirmed',
                dateCreated: new Date().toISOString()
            };

            // Log the order data to verify all fields are present
            // console.log('Confirming order with data:', orderData);

            const response = await axios.post('/messenger/confirm-order', {
                messageId,
                chatId,
                orderData,
                currentUserData,
                recipientData: currentChatUser
            });

            if (response.data.success) {
                setMessages(prev => prev.map(msg => 
                    msg.id === messageId 
                        ? {
                            ...msg,
                            orderData: {
                                ...msg.orderData,
                                status: 'Confirmed'
                            }
                        }
                        : msg
                ));
            }
        } else {
            // Rejectlation logic remains the same
            const response = await axios.post('/messenger/update-order-status', {
                messageId,
                chatId,
                status: newStatus,
                currentUserData,
                recipientData: currentChatUser
            });

            if (response.data.success) {
                setMessages(prev => prev.map(msg => 
                    msg.id === messageId 
                        ? {
                            ...msg,
                            orderData: {
                                ...msg.orderData,
                                status: newStatus
                            }
                        }
                        : msg
                ));
            }
        }
    } catch (error) {
        console.error('Error updating order status:', error);
        alert('Failed to update order status. Please try again.');
    }
};

const OrderViewModal = ({ order, onClose, onConfirm, onReject, isReceiver }) => {
    if (!order) return null;

    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    const handleReject = () => {
        onReject();
        onClose();
    };

    return (
        <div className="order-modal-overlay">
            <div className="order-modal">
                <div className="modal-header">
                    <h2>ORDER #{order.orderData.orderNumber}</h2>
                    <button className="close-button" onClick={onClose}>×</button>
                </div>
                <div className="modal-content">
                    <table className="order-items-table">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th>Volume</th>
                                <th>Weight</th>
                                <th>Barcode</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.orderData.items.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.volume || 'N/A'}</td>
                                    <td>{item.weight || 'N/A'}</td>
                                    <td>{item.barcode || 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {isReceiver && order.orderData.status === 'Pending' && (
                    <div className="modal-actions">
                        <button 
                            className="messenger-confirm-button"
                            onClick={handleConfirm}
                        >
                            Confirm
                        </button>
                        <button 
                            className="messenger-reject-button"
                            onClick={handleReject}
                        >
                            Reject
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};





const renderMessage = (msg) => {
    switch (msg.type) {
        case MessageType.IMAGE:
            return (
                <div className="messenger-image-container">
                    <img 
                        src={msg.fileUrl} 
                        alt={msg.fileName || 'Image'}
                        className="messenger-image-message"
                        onClick={() => window.open(msg.fileUrl, '_blank')}
                    />
                </div>
            );
        case MessageType.DOCUMENT:
            return (
                <DocumentMessage 
                    url={msg.fileUrl}
                    fileName={msg.fileName}
                    fileType={msg.fileType}
                    fileSize={msg.fileSize}
                />
            );
        case 'order':
            // Safely access nested properties
            const orderData = msg.orderData || {};
            const items = orderData.items || [];
            const currentUserId = currentUserData?.userId;
            const isReceiver = msg.senderId !== currentUserId;
            
            // Default values for order properties
            const orderNumber = orderData.orderNumber || 'N/A';
            const status = orderData.status || 'Pending';
            const store = orderData.store || 'N/A';
            const supplier = orderData.supplier || 'N/A';
            const expectedDelivery = orderData.expectedDelivery || 'N/A';

            return (
                <div className="messenger-order-message" onClick={() => setViewOrderId(msg.id)}>
                    <div className="order-header">
                    <div className="order-header-left">
                            <span className="order-number">ORDER #{orderData.orderNumber}</span>
                            <span className={`order-status ${status.toLowerCase()}`}>
                                Status: {status}
                            </span>
                        </div>
                    
                    </div>
                    <div className="order-info">
                        <div className="info-row">
                            <span>Store: {orderData.store || 'N/A'}</span>
                        </div>
                        <div className="info-row">
                            <span>Expected Delivery: {orderData.expectedDelivery || 'N/A'}</span>
                        </div>
                    </div>
                    {/* Show detailed items list for expanded view */}
                    <div className="order-items-preview">
                        <div className="items-header">Order Items:</div>
                        <div className="items-list">
                            {items.slice(0, 2).map((item, index) => (
                                <div key={index} className="item-preview">
                                    • {item.name} - Qty: {item.quantity}
                                </div>
                            ))}
                            {items.length > 2 && (
                                <div className="more-items">
                                    +{items.length - 2} more items
                                </div>
                            )}
                        </div>
                    </div>
                   
                    {/* Action buttons for receivers of pending orders */}
                    {isReceiver && status === 'Pending' && (
                        <div className="order-actions">
                            <button 
                                className="messenger-view-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setViewOrderId(msg.id);
                                }}
                            >
                                Offer
                            </button>
                            <button 
                                className="messenger-confirm-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleOrderAction(msg.id, 'Confirmed');
                                }}
                            >
                               Confirm
                            </button>
                            <button 
                                className="messenger-reject-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleOrderAction(msg.id, 'Rejected');
                                }}
                            >
                                Reject
                            </button>
                        </div>
                    )}
                </div>
            );
        case MessageType.TEXT:
        default:
            return <div className="messenger-message-content">{msg.text}</div>;
    }
};
    
      

    const formatMessageTime = (timestamp) => {
        if (!timestamp) return '';
        
        const messageDate = new Date(timestamp);
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        
        if (messageDate.toDateString() === now.toDateString()) {
            return messageDate.toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit' 
            });
        }
        
        if (messageDate.toDateString() === yesterday.toDateString()) {
            return 'Yesterday';
        }
        
        if (messageDate.getFullYear() === now.getFullYear()) {
            return messageDate.toLocaleDateString([], { 
                month: 'short', 
                day: 'numeric' 
            });
        }
        
        return messageDate.toLocaleDateString([], { 
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

  

    if (error) {
        return (
            <div className="messenger-container">
                <div className="messenger-content">
                    <div className="error-container">
                        <div className="error-message">{error}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="messenger-container">
            <div className="messenger-content">
                {chatId === 'new' || isSearchOpen ? (
                    <div className="new-chat-container">
                        <div className="new-chat-header">
                            <h2>Create New Chat</h2>
                            <button onClick={() => setIsSearchOpen(false)} className="close-messenger-search-button">
                                <X size={20} />
                            </button>
                        </div>
                        <div className="messenger-search-user-container">
                            <div className="messenger-search-user-container-wrapper">
                                <Search className="messenger-search-icon" />
                                <input
                                    type="text"
                                    placeholder="Search users by email..."
                                    value={searchEmail}
                                    onChange={(e) => {
                                        setSearchEmail(e.target.value);
                                        searchUsers(e.target.value);
                                    }}
                                    className="messenger-search-user-input"
                                    autoFocus
                                />
                            </div>
                            {users.length > 0 && (
                                <div className="messenger-users-list">
                                    {users.map(user => (
                                        <div key={user.userId} className="messenger-user-item">
                                            <div className="messenger-user-avatar">
                                                {user.email.charAt(0).toUpperCase()}
                                            </div>
                                            <div className="messenger-user-details">
                                                <span className="messenger-user-email">{user.email}</span>
                                                <button onClick={() => createChat(user)} className="create-chat-btn">
                                                    Create Chat
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {searchEmail && users.length === 0 && (
                                <div className="no-results">
                                    No users found matching "{searchEmail}"
                                </div>
                            )}
                        </div>
                    </div>
                ) : chatId ? (
                    <div className="chat-window">
                        <div className="chat-header">
                            {currentChatUser && (
                                <div className="chat-recipient-info">
                                    <div className="chat-recipient-avatar">
                                        {currentChatUser.email.charAt(0).toUpperCase()}
                                    </div>
                                    <div className="chat-recipient-details">
                                        <div className="chat-recipient-email">
                                            {currentChatUser.email}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="messages-outer-container">
                            <div className="messages-container" ref={messagesContainerRef}>
                                <div className="messages-inner">
                                    {loadingMessages ? (
                                        <div className="messages-loading-container">
                                            <div className="messages-loading"></div>
                                        </div>
                                    ) : messages.length > 0 ? (
                                        messages.map(msg => (
                                            <div
                                                key={msg.id}
                                                className={`message-wrapper ${
                                                    msg.senderId === currentUserData?.userId ? 'sent' : 'received'
                                                }`}
                                                data-message-id={msg.id}
                                                ref={node => {
                                                    if (node && observerRef.current) {
                                                        observerRef.current.observe(node);
                                                    }
                                                }}
                                            >
                                                {msg.senderId !== currentUserData?.userId && (
                                                    <div className="messenger-message-avatar">
                                                        {msg.senderEmail?.charAt(0).toUpperCase()}
                                                    </div>
                                                )}
                                                <div className="message-bubble-container">
                                                <div className={`message ${msg.senderId === currentUserData?.userId ? 'sent' : 'received'}`}>
                                                    {renderMessage(msg)}
                                                    <div className="message-footer">
                                                        <div className="message-meta">
                                                        <span className="message-time">
                                                            {msg.timestamp ? new Date(msg.timestamp).toLocaleTimeString([], { 
                                                            hour: '2-digit', 
                                                            minute: '2-digit' 
                                                            }) : ''}
                                                        </span>
                                                        {msg.senderId === currentUserData?.userId && (
                                                            <div className="message-status">
                                                            <MessageStatusIndicator 
                                                                status={msg.status} 
                                                                seen={msg.seen} 
                                                            />
                                                            </div>
                                                        )}
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="no-messages"></div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div ref={messagesEndRef} />

                        <div className="messenger-message-input-wrapper">
                        <form onSubmit={sendMessage} className="messenger-message-input-form">
  <div className="messenger-message-input-container-wrapper">
    <>
      {showOrderForm && (
        <MessengerCreateOrder
          onClose={() => setShowOrderForm(false)}
          onSubmit={handleOrderSubmit}
        />
      )}
      
      <div className="messenger-paperclip-icon-wrapper">
        <input
          type="file"
          id="file-upload"
          accept="image/*,.pdf,.docx,.txt"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
        <label htmlFor="file-upload">
          <Paperclip className="messenger-paperclip-icon" size={22} />
        </label>
      </div>

      <div className="messenger-paperclip-icon-wrapper">
        <label
          type="button"
          onClick={() => setShowOrderForm(true)}
          className="order-button"
        >
          <BriefcaseBusiness className="messenger-ordering-icon" size={22} />
        </label>
      </div>

      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type a message..."
        className="messenger-message-input"
      />
      <button type="submit" className="messenger-send-button" disabled={!message.trim()}>
        <Send size={20} />
      </button>
    </>
  </div>
</form>
  </div>

                    {viewOrderId && (
                        <OrderViewModal
                            order={messages.find(msg => msg.id === viewOrderId)}
                            onClose={() => setViewOrderId(null)}
                            onConfirm={() => handleOrderAction(viewOrderId, 'Confirmed')}
                            onReject={() => handleOrderAction(viewOrderId, 'Rejected')}
                            isReceiver={messages.find(msg => msg.id === viewOrderId)?.senderId !== currentUserData?.userId}
                        />
                    )}
                </div>
                ) : (
                    <div className="chats-list-container">
                        <div className="chats-header">
                            <h2>Recent Chats</h2>
                            <button onClick={() => navigate('/store/messenger/new')} className="create-chat-button">
                                Create Chat
                            </button>
                        </div>
                        <div className="chats-list">
                            {chats.map(chat => (
                                <div
                                    key={chat.id}
                                    onClick={() => navigate(`/store/messenger/${chat.id}`)}
                                    className="chat-item"
                                >
                                    <div className="chat-avatar">
                                        {chat.otherUserData?.email.charAt(0).toUpperCase()}
                                    </div>
                                    <div className="chat-preview">
                                        <div className="chat-info">
                                            <span className="chat-participant">
                                                {chat.otherUserData?.email}
                                            </span>
                                            {chat.unreadMessageCount > 0 && (
                                                <span className="chat-unread-count">
                                                    {chat.unreadMessageCount > 99 ? '99+' : chat.unreadMessageCount}
                                                </span>
                                            )}
                                            <span className="chat-time">
                                                {formatMessageTime(chat.lastMessageTime)}
                                            </span>
                                        </div>
                                        {chat.lastMessage && (
                                            <div className="chat-last-message">
                                                {chat.lastMessage}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {chats.length === 0 && (
                                <div className="no-chats-message">
                                    No conversations yet
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Messenger;
