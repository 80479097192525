import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { ArrowUpDown } from 'lucide-react';
import './CheckOut.css';
import { translations } from './Data/data'; // Import the translations

// Category keys instead of hardcoded strings
const categoryKeys = [
  'all_categories', 'raw_materials', 'groceries', 'beverages', 
  'household_products', 'personal_care_items', 'snacks', 'frozen_foods', 
  'health_and_wellness_products', 'electronics', 'pet_supplies', 'office_supplies'
];

const CheckOut = () => {
  const [language, setLanguage] = useState('en');
  
  // State variables and hooks
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [barcode, setBarcode] = useState('');
  const [transactionCode, setTransactionCode] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('1');
  const [currentItem, setCurrentItem] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [total, setTotal] = useState(0);
  const [isReturnMode, setIsReturnMode] = useState(false);
  const [returnTransaction, setReturnTransaction] = useState(null);
  const [activeInput, setActiveInput] = useState(null); // Track which input is active for numpad
  const [editingQuantity, setEditingQuantity] = useState(null);

  // Inventory modal state
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [filteredInventoryItems, setFilteredInventoryItems] = useState([]);
  const [inventorySearchTerm, setInventorySearchTerm] = useState('');
  const [inventoryFilterCategory, setInventoryFilterCategory] = useState('all_categories');
  const [inventoryFilterStore, setInventoryFilterStore] = useState('all_stores');
  const [inventoryFilterBrand, setInventoryFilterBrand] = useState('all_brands');
  const [inventorySortBy, setSortBy] = useState('expiry_date');
  const [inventorySortOrder, setSortOrder] = useState('ascending');
  const [brands, setBrands] = useState(['all_brands']);

  const navigate = useNavigate();
  const { user } = useAuth();

  // Translation helper function
  const translate = (key) => {
    return translations[language]?.[key] || translations['en'][key];
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    fetchStoreInfo();
  }, [user, navigate]);

  // Calculate total whenever cart items change
  useEffect(() => {
    const sum = cartItems.reduce((acc, item) => {
      return acc + ((parseFloat(item.price) || parseFloat(item.unitPrice) || 0) * parseInt(item.quantity || 1));
    }, 0);
    setTotal(sum);
  }, [cartItems]);

  // Filter and sort inventory items when inventory filters change
  useEffect(() => {
    filterAndSortInventory();
  }, [inventoryItems, inventorySearchTerm, inventoryFilterCategory, inventoryFilterStore, inventoryFilterBrand, inventorySortBy, inventorySortOrder]);

  const fetchStoreInfo = async () => {
    setLoading(true);
    setError('');
    
    try {
      // Use the inventory endpoint
      const response = await axios.get('/inventory/store-info');

      if (response.data) {
        // Handle different possible response formats
        const brandName = response.data.brandName || (response.data.data && response.data.data.brandName);
        const modifiedBrandName = response.data.modifiedBrandName || (response.data.data && response.data.data.modifiedBrandName);
        const stores = response.data.stores || (response.data.data && response.data.data.stores) || [];
        
        setModifiedBrandName(modifiedBrandName);
        setStores(stores);
        if (stores.length > 0) {
          setSelectedStore(stores[0]);
        }
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Store info fetch error:', error);
      setError('Failed to fetch store information. Please refresh the page.');
    } finally {
      setLoading(false);
    }
  };

  const fetchInventory = async () => {
    try {
      const response = await axios.get('/inventory/inventory', {
        params: { modifiedBrandName }
      });

      if (response.data) {
        const processedItems = response.data.map(item => ({
          ...item,
          dateAdded: new Date(item.dateAdded),
          expiryDateTo: item.expiryDateTo ? new Date(item.expiryDateTo) : null
        }));
        setInventoryItems(processedItems);
        setFilteredInventoryItems(processedItems);
        updateBrands(processedItems);
      }
    } catch (error) {
      console.error('Error fetching inventory:', error);
      setError('Failed to fetch inventory');
    }
  };

  const updateBrands = (items) => {
    const uniqueBrands = ['all_brands', ...new Set(items.map(item => item.brand).filter(Boolean))];
    setBrands(uniqueBrands);
  };

  const filterAndSortInventory = () => {
    if (!inventoryItems.length) return;

    let filtered = inventoryItems.filter(item => {
      // Search in both name and barcode fields
      const searchInName = item.name.toLowerCase().includes(inventorySearchTerm.toLowerCase());
      const searchInBarcode = item.barcode && item.barcode.includes(inventorySearchTerm);
      
      return (searchInName || searchInBarcode) &&
        (inventoryFilterCategory === 'all_categories' || item.category === translate(inventoryFilterCategory)) &&
        (inventoryFilterStore === 'all_stores' || item.store === inventoryFilterStore) &&
        (inventoryFilterBrand === 'all_brands' || item.brand === inventoryFilterBrand);
    });

    filtered.sort((a, b) => {
      let comparison = 0;

      const compareDates = (dateA, dateB) => {
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;
        return new Date(dateA) - new Date(dateB);
      };

      switch (inventorySortBy) {
        case 'alphabetical':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'expiry_date':
          comparison = compareDates(a.expiryDateTo, b.expiryDateTo);
          break;
        case 'date_added':
          comparison = compareDates(a.dateAdded, b.dateAdded);
          break;
        case 'quantity':
          comparison = b.quantity - a.quantity;
          break;
        case 'price':
          comparison = b.price - a.price;
          break;
        default:
          comparison = 0;
      }
      return inventorySortOrder === 'ascending' ? comparison : -comparison;
    });

    setFilteredInventoryItems(filtered);
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'ascending' ? 'descending' : 'ascending');
  };

  // Open inventory modal and fetch inventory data
  const handleOpenInventory = () => {
    setShowInventoryModal(true);
    if (modifiedBrandName) {
      fetchInventory();
    }
  };

  // Close inventory modal
  const handleCloseInventory = () => {
    setShowInventoryModal(false);
  };

  // Add item from inventory to cart
  const addInventoryItemToCart = (item) => {
  // Don't add in return mode
  if (isReturnMode) return;

  // Ensure we have a valid quantity value from inventory
  const availableQuantity = item.quantity !== undefined && !isNaN(item.quantity) ? 
    item.quantity : Infinity;

  const cartItem = {
    id: item.id,
    name: item.name,
    barcode: item.barcode || '',
    brand: item.brand,
    category: item.category,
    quantity: 1, // Start with 1
    price: parseFloat(item.price) || 0,
    totalPrice: parseFloat(item.price) || 0,
    availableQuantity: availableQuantity // Store available quantity
  };
  
  // Add to cart
  setCartItems([...cartItems, cartItem]);
  
  // Close the inventory modal
  setShowInventoryModal(false);
};

  const searchItem = async () => {
    setError('');
    
    if (!barcode.trim()) {
      setError(translate('error_barcode_required'));
      return;
    }
    
    try {
      const response = await axios.get('/inventory/item-by-barcode', {
        params: { 
          modifiedBrandName, 
          selectedStore,
          barcode
        }
      });
      
      if (response.data && response.data.success) {
        if (response.data.isManualEntry) {
          // Handle manual entry
          setCurrentItem(response.data.item);
          setPrice('');
          setQuantity('1');
        } else {
          // Handle found item
          const item = response.data.item;
          setCurrentItem(item);
          setPrice(item.price); // Pre-fill price from inventory
          setQuantity('1');
        }
      } else {
        setError('Failed to find item. Please try again.');
      }
    } catch (error) {
      console.error('Error searching item:', error);
      setError('Failed to find item. Please try again.');
    }
  };
  

  const handleBarcodeScan = (e) => {
    if (e.key === 'Enter') {
      searchItem();
    }
  };

  const searchTransaction = async () => {
    setError('');
    setReturnTransaction(null);
    
    if (!transactionCode.trim() || transactionCode.length !== 12) {
      setError(translate('error_invalid_transaction_code'));
      return;
    }
    
    try {
      const response = await axios.get('/inventory/transaction-by-code', {
        params: { 
          modifiedBrandName, 
          selectedStore,
          transactionCode 
        }
      });
      
      if (response.data && response.data.transaction) {
        // Transaction validation is now handled by the backend
        setReturnTransaction(response.data.transaction);
        setCartItems(response.data.transaction.items || []);
        setTotal(response.data.transaction.totalPrice || 0);
      } else {
        setError('Transaction not found. Please check the code and try again.');
      }
    } catch (error) {
      console.error('Error searching transaction:', error);
      setError(error.response?.data?.error || 'Failed to find transaction. Please check the code and try again.');
    }
  };

  const addToCart = () => {
    if (!currentItem) {
      setError(translate('error_no_item_selected'));
      return;
    }
    
    if (!price || isNaN(parseFloat(price)) || parseFloat(price) <= 0) {
      setError(translate('error_invalid_price'));
      return;
    }
    
    if (!quantity || isNaN(parseInt(quantity)) || parseInt(quantity) <= 0) {
      setError(translate('error_invalid_quantity'));
      return;
    }
    
    // Create a cart item
    const itemName = currentItem.name || `Item (${currentItem.barcode})`;
    const cartItem = {
      id: currentItem.id,
      name: itemName,
      barcode: currentItem.barcode || '',
      brand: currentItem.brand,
      category: currentItem.category,
      quantity: parseInt(quantity),
      price: parseFloat(price),
      totalPrice: parseFloat(price) * parseInt(quantity),
      availableQuantity: currentItem.quantity || Infinity // Store available quantity
    };
    
    // Add to cart
    setCartItems([...cartItems, cartItem]);
    
    // Clear the form
    setCurrentItem(null);
    setBarcode('');
    setPrice('');
    setQuantity('1');
  };
  

  const removeFromCart = (index) => {
    const newCartItems = [...cartItems];
    newCartItems.splice(index, 1);
    setCartItems(newCartItems);
  };

  const updateItemQuantity = (index, newQuantity) => {
    if (newQuantity < 1) return; // Don't allow quantities less than 1
    
    const newCartItems = [...cartItems];
    newCartItems[index].quantity = newQuantity;
    newCartItems[index].totalPrice = newCartItems[index].price * newQuantity;
    setCartItems(newCartItems);
  };
  
  // Add a new function to handle direct input of quantity
  const handleQuantityInputChange = (index, value) => {
    // Always update the displayValue during editing
    const newCartItems = [...cartItems];
    newCartItems[index] = {
      ...newCartItems[index],
      displayValue: value
    };
    setCartItems(newCartItems);
    
    // Only update the actual quantity if it's a valid number
    const numValue = parseInt(value);
    if (!isNaN(numValue) && numValue > 0) {
      // Update the quantity but keep the displayValue for editing
      newCartItems[index].quantity = numValue;
      newCartItems[index].totalPrice = newCartItems[index].price * numValue;
    }
  };
  


  const toggleQuantityEdit = (index) => {
    setEditingQuantity(editingQuantity === index ? null : index);
  };

  const finalizeQuantityEdit = (index) => {
    const item = cartItems[index];
    let finalQuantity = 1; // Default to 1 if there are issues
    
    // First determine what the final quantity should be
    if (item.displayValue && item.displayValue !== '') {
      const parsedQuantity = parseInt(item.displayValue);
      if (!isNaN(parsedQuantity) && parsedQuantity > 0) {
        // We have a valid number
        finalQuantity = parsedQuantity;
        
        // Check against maximum available
        const maxAvailable = item.availableQuantity !== undefined ? item.availableQuantity : Infinity;
        if (finalQuantity > maxAvailable) {
          finalQuantity = maxAvailable;
          
          // Show a notification about maximum available
          if (maxAvailable !== Infinity) {
            setError(`${translate('maximum_available')}: ${maxAvailable}`);
            setTimeout(() => setError(''), 3000);
          }
        }
      }
    }
    
    // Now update the cart with the final quantity
    const newCartItems = [...cartItems];
    newCartItems[index] = {
      ...newCartItems[index],
      quantity: finalQuantity,
      totalPrice: newCartItems[index].price * finalQuantity
    };
    
    // Remove the temporary displayValue
    delete newCartItems[index].displayValue;
    
    // Update the cart
    setCartItems(newCartItems);
    
    // Exit edit mode
    setEditingQuantity(null);
  };
  

  // Handle pressing Enter key to confirm edit
  const handleQuantityKeyPress = (e, index) => {
    if (e.key === 'Enter') {
      finalizeQuantityEdit(index);
    }
  };


  const handleSellTransaction = async () => {
    if (cartItems.length === 0) {
      setError(translate('error_cart_empty'));
      return;
    }
    
    setError('');
    setSuccess('');
    
    try {
      const response = await axios.post('/inventory/process-sale', {
        modifiedBrandName,
        selectedStore,
        cartItems
      });
      
      if (response.data && response.data.success) {
        setSuccess(`${translate('success_transaction_completed')}. ${translate('transaction_code')}: ${response.data.transactionCode}`);
        // Clear the cart after successful transaction
        setCartItems([]);
        setTotal(0);
        
        // Show success message for 2 seconds
        setTimeout(() => {
          setSuccess('');
        }, 2000);
      } else {
        throw new Error(response.data.error || 'Failed to process transaction');
      }
    } catch (error) {
      console.error('Error processing transaction:', error);
      // Show more specific error messages to the user
      setError(
        error.response?.data?.error || 
        error.message || 
        'Failed to process transaction. Please try again.'
      );
      
      // Clear error after 5 seconds
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  const handleCancelAll = async () => {
    if (cartItems.length === 0) return;
    
    try {
      // All business logic now handled on the backend
      await axios.post('/inventory/cancel-cart', {
        modifiedBrandName,
        selectedStore,
        cartItems
      });
      
      // Clear the cart regardless of response
      setCartItems([]);
    } catch (error) {
      console.error('Error cancelling cart:', error);
    }
  };

  const handleReturnTransaction = async () => {
    if (!returnTransaction) {
      setError(translate('error_no_transaction'));
      return;
    }
    
    setError('');
    
    try {
      // All return logic now handled on the backend
      const response = await axios.post('/inventory/process-return', {
        modifiedBrandName,
        selectedStore,
        transactionCode: returnTransaction.transactionCode
      });
      
      if (response.data && response.data.success) {
        setSuccess(translate('success_return_processed'));
        setReturnTransaction(null);
        setCartItems([]);
        setTotal(0);
        setTransactionCode('');
        
        setTimeout(() => setSuccess(''), 2000);
        setIsReturnMode(false);
      } else {
        setError('Failed to process return.');
      }
    } catch (error) {
      console.error('Error processing return:', error);
      setError(error.response?.data?.error || 'Failed to process return. Please try again.');
    }
  };
  
  // Toggle between checkout and return modes
  const toggleReturnMode = () => {
    setIsReturnMode(!isReturnMode);
    setError('');
    setSuccess('');
    setCartItems([]);
    setTotal(0);
    setBarcode('');
    setTransactionCode('');
    setCurrentItem(null);
    setReturnTransaction(null);
  };

  // Handler for numpad buttons
  const handleNumpadInput = (value) => {
    if (!activeInput) return;

    if (value === 'clear') {
      // Remove last character (act like backspace) instead of clearing the whole field
      if (activeInput === 'barcode') setBarcode(prev => prev.slice(0, -1));
      else if (activeInput === 'transactionCode') setTransactionCode(prev => prev.slice(0, -1));
      else if (activeInput === 'price') setPrice(prev => prev.slice(0, -1));
      else if (activeInput === 'quantity') setQuantity(prev => prev.slice(0, -1) || '1'); // Keep at least '1'
    } else if (value === '+1') {
      // Increment by 1 (primarily for quantity)
      if (activeInput === 'quantity') {
        const currentVal = parseInt(quantity) || 0;
        setQuantity(String(currentVal + 1));
      }
    } else if (value === '-1') {
      // Decrement by 1 (primarily for quantity)
      if (activeInput === 'quantity') {
        const currentVal = parseInt(quantity) || 0;
        if (currentVal > 1) setQuantity(String(currentVal - 1));
      }
    } else if (value === '.') {
      // Add decimal point (for price only)
      if (activeInput === 'price' && !price.includes('.')) {
        setPrice(prev => prev + '.');
      }
    } else if (value === 'OK') {
      // Perform the appropriate action based on active input
      if (activeInput === 'barcode' || activeInput === 'transactionCode') {
        isReturnMode ? searchTransaction() : searchItem();
      } else if (activeInput === 'price' || activeInput === 'quantity') {
        addToCart();
      }
    } else {
      // Add the number to the active input
      if (activeInput === 'barcode') setBarcode(prev => prev + value);
      else if (activeInput === 'transactionCode') setTransactionCode(prev => prev + value);
      else if (activeInput === 'price') setPrice(prev => prev + value);
      else if (activeInput === 'quantity') setQuantity(prev => prev + value);
    }
  };

  // Set the active input for numpad focus
  const handleInputFocus = (inputName) => {
    setActiveInput(inputName);
  };

  return (
    <div className="checkout-container">
{showInventoryModal && (
  <div className="inventory-modal-overlay">
    <div className="inventory-modal">
      <div className="inventory-modal-header">
        <h2>{translate('inventory')}</h2>
        <button className="close-modal-btn" onClick={handleCloseInventory}>×</button>
      </div>
      
      <div className="inventory-modal-content">
        <div className="inventory-controls">
          <div className="inventory-search-filters">
            <input
              type="text"
              className="inventory-search-input"
              placeholder={translate('search_inventory')}
              value={inventorySearchTerm}
              onChange={(e) => setInventorySearchTerm(e.target.value)}
            />
            
            <select 
              value={inventoryFilterCategory} 
              onChange={(e) => setInventoryFilterCategory(e.target.value)}
              className="filter-dropdown"
            >
              {categoryKeys.map(category => (
                <option key={category} value={category}>{translate(category)}</option>
              ))}
            </select>
          </div>
          
          <div className="inventory-filters-sort-container">
            <div className="inventory-filters-section">
              <div className="filter-group">
                <select 
                  value={inventoryFilterStore} 
                  onChange={(e) => setInventoryFilterStore(e.target.value)}
                  className="filter-dropdown"
                >
                  <option value="all_stores">{translate('all_stores')}</option>
                  {stores.map(store => (
                    <option key={store} value={store}>{store}</option>
                  ))}
                </select>
              </div>
              
              <div className="filter-group">
                <select 
                  value={inventoryFilterBrand} 
                  onChange={(e) => setInventoryFilterBrand(e.target.value)}
                  className="filter-dropdown"
                >
                  {brands.map(brand => (
                    <option key={brand} value={brand}>{brand === 'all_brands' ? translate('all_brands') : brand}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="inventory-sort-section">
              <div className="sort-controls">
                <select 
                  value={inventorySortBy} 
                  onChange={(e) => setSortBy(e.target.value)}
                  className="sort-dropdown"
                >
                  <option value="alphabetical">{translate('alphabetical')}</option>
                  <option value="date_added">{translate('date_added')}</option>
                  <option value="expiry_date">{translate('expiry_date')}</option>
                  <option value="quantity">{translate('quantity')}</option>
                  <option value="price">{translate('price')}</option>
                </select>
                
                <button 
                  className="checkout-sort-order-button" 
                  onClick={toggleSortOrder}
                >
                  <ArrowUpDown size={20} />
                  {translate(inventorySortOrder)}
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <div className="inventory-table-container">
          <table className="inventory-table">
            <thead>
              <tr>
                <th>{translate('product')}</th>
                <th>{translate('brand')}</th>
                <th>{translate('barcode')}</th>
                <th>{translate('price')}</th>
                <th>{translate('quantity')}</th>
                <th>{translate('weight')}</th>
                <th>{translate('volume')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredInventoryItems.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.brand}</td>
                  <td>{item.barcode}</td>
                  <td>{translate('currency_symbol')}{parseFloat(item.price).toFixed(2)}</td>
                  <td>{item.quantity}</td>
                  <td>{item.weight} {item.unitOfMeasureForWeight !== "N/A" ? item.unitOfMeasureForWeight : ''}</td>
                  <td>{item.volume} {item.unitOfMeasureForVolume !== "N/A" ? item.unitOfMeasureForVolume : ''}</td>
                  <td>
                    <button 
                      className="add-to-cart-btn" 
                      onClick={() => addInventoryItemToCart(item)}
                      disabled={item.quantity <= 0 || isReturnMode}
                    >
                      {translate('add_to_cart')}
                    </button>
                  </td>
                </tr>
              ))}
              {filteredInventoryItems.length === 0 && (
                <tr>
                  <td colSpan="8" style={{textAlign: 'center', padding: '20px'}}>
                    {translate('no_items_found')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
)}

      <div className={`checkout-layout ${showInventoryModal ? 'blurred' : ''}`}>
        <div className="checkout-main">
          <div className="checkout-input-section">
            <div className="checkout-input-row">
              {!isReturnMode ? (
                <input
                  type="text"
                  className="barcode-input"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  onKeyDown={handleBarcodeScan}
                  placeholder={translate('enter_barcode')}
                  onFocus={() => handleInputFocus('barcode')}
                />
              ) : (
                <input
                  type="text"
                  className="barcode-input"
                  value={transactionCode}
                  onChange={(e) => setTransactionCode(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && searchTransaction()}
                  placeholder={translate('enter_transaction_code')}
                  maxLength={12}
                  onFocus={() => handleInputFocus('transactionCode')}
                />
              )}
              <select 
                className="store-select"
                value={selectedStore} 
                onChange={(e) => setSelectedStore(e.target.value)}
              >
                {stores.map(store => (
                  <option key={store} value={store}>{store}</option>
                ))}
              </select>
            </div>
            
            {!isReturnMode && (
              <div className="checkout-input-row">
                <input
                  type="text"
                  className="price-input"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder={translate('price')}
                  onFocus={() => handleInputFocus('price')}
                />
                <input
                  type="text"
                  className="quantity-input"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  placeholder={translate('quantity')}
                  onFocus={() => handleInputFocus('quantity')}
                />
                <button 
                  className="checkout-search-button" 
                  onClick={isReturnMode ? searchTransaction : searchItem}
                >
                  {translate('search')}
                </button>
                {!isReturnMode && (
                  <button 
                    className="checkout-add-button" 
                    onClick={addToCart}
                    disabled={!currentItem}
                  >
                    {translate('add')}
                  </button>
                )}
              </div>
            )}
            
            {isReturnMode && (
              <div className="checkout-input-row">
                <button 
                  className="checkout-search-button" 
                  onClick={searchTransaction}
                  style={{ width: '100%' }}
                >
                  {translate('search_transaction')}
                </button>
              </div>
            )}
            
            {success && <div className="checkout-success">{success}</div>}
            {!isReturnMode && currentItem && (
              <div className="current-item">
                <p>
                  <strong>{translate('selected_item')}:</strong> {currentItem.name || 'Manual Entry'} 
                  {currentItem.brand ? ` - ${currentItem.brand}` : ''}
                  {currentItem.barcode ? ` (${currentItem.barcode})` : ''}
                </p>
                {currentItem.quantity && (
                  <p><strong>{translate('available')}:</strong> {currentItem.quantity}</p>
                )}
              </div>
            )}
            {isReturnMode && returnTransaction && (
              <div className="current-item">
                <p><strong>{translate('transaction_code')}:</strong> {returnTransaction.transactionCode}</p>
                <p><strong>{translate('transaction_date')}:</strong> {new Date(returnTransaction.timestamp?.seconds ? returnTransaction.timestamp.seconds * 1000 : returnTransaction.timestamp).toLocaleString()}</p>
              </div>
            )}
          </div>

          <div className="checkout-cart-section">
            <div className="checkout-summary">
              <table className="cart-table">
                <thead>
                  <tr>
                    <th>{translate('items')}</th>
                    <th>{translate('barcode')}</th>
                    <th>{translate('price')}</th>
                    <th>{translate('quantity')}</th>
                    <th>
                      <button 
                        className="checkout-inventory-button" 
                        onClick={handleOpenInventory}
                      >
                        {translate('inventory')}
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.length === 0 ? (
                    <tr>
                      <td colSpan={5} className="no-items">{translate('no_items_added')}</td>
                    </tr>
                  ) : (
                    cartItems.map((item, index) => (
                      <tr key={index} className="cart-item-row">
                        <td>{item.itemName || item.name}</td>
                        <td>{item.barcode}</td>
                        <td>
                          <span className="price-display">
                            <span className="currency-symbol">{translate('currency_symbol')}</span>
                            {parseFloat(item.unitPrice || item.price).toFixed(2)}
                          </span>
                        </td>
                        <td>
                        <div className="quantity-cell">
                          {!isReturnMode ? (
                            <>
                              <button 
                                className="quantity-btn decrease" 
                                onClick={() => updateItemQuantity(index, item.quantity - 1)}
                                disabled={item.quantity <= 1}
                              >
                                -
                              </button>
                              
                              {editingQuantity === index ? (
                                <input
                                  type="text"
                                  className="quantity-input-small"
                                  value={item.displayValue !== undefined ? item.displayValue : item.quantity}
                                  onChange={(e) => handleQuantityInputChange(index, e.target.value)}
                                  onBlur={() => finalizeQuantityEdit(index)}
                                  onKeyPress={(e) => handleQuantityKeyPress(e, index)}
                                  autoFocus
                                  maxLength={5} /* Limit input to 5 characters */
                                />
                              ) : (
                                <div className="checkout-quantity-display" onClick={() => toggleQuantityEdit(index)}>
                                  <span className="checkout-quantity-display-input-number">
                                    {item.quantity}
                                  </span>
                                </div>
                              )}
                              
                              <button 
                                className="quantity-btn increase" 
                                onClick={() => updateItemQuantity(index, item.quantity + 1)}
                                disabled={item.quantity >= (item.availableQuantity || Infinity)}
                              >
                                +
                              </button>
                            </>
                          ) : (
                            <span className="checkout-quantity-display-readonly">{item.quantity}</span>
                          )}
                        </div>
                          </td>                     

                        <td >
                          {!isReturnMode && (
                            <button
                              className="remove-item"
                              onClick={() => removeFromCart(index)}
                              aria-label="Remove item"
                            >
                              <span aria-hidden="true">{translate('remove')}</span>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div className="checkout-sidebar">
          <div className="checkout-header">
            <h1 className="checkout-title">
              {isReturnMode ? translate('return_processing') : translate('checkout_title')}
            </h1>
 
          </div>
          
          <div className="sidebar-content">
            <div>
              <div className="total-display">
                <div className="total-label">{translate('total_amount')}</div>
                <div className="total-value">
                  <span className="total-currency-symbol">{translate('currency_symbol')}</span>
                  {total.toFixed(2)}
                </div>
              </div>
              <div className="transaction-summary">
                <div className="summary-info">
                  <span>{translate('items')}:</span>
                  <span className="summary-info-value">{cartItems.length}</span>
                </div>
              </div>
            </div>
            
            <hr className="sidebar-divider" />
            
            {/* Primary Actions */}
            <div className="checkout-actions">
              {!isReturnMode ? (
                <>
                  <button 
                    className="checkout-primary-button"
                    onClick={handleSellTransaction}
                    disabled={cartItems.length === 0}
                  >
                    {translate('complete_transaction')}
                  </button>
                  
                  <div className="checkout-secondary-buttons">
                    <button 
                      className="checkout-secondary-button"
                      onClick={toggleReturnMode}
                    >
                      {translate('switch_to_return')}
                    </button>
                    <button 
                      className="checkout-secondary-button danger"
                      onClick={handleCancelAll}
                      disabled={cartItems.length === 0}
                    >
                      {translate('cancel')}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <button 
                    className="checkout-primary-button"
                    onClick={handleReturnTransaction}
                    disabled={!returnTransaction}
                  >
                    {translate('process_return')}
                  </button>
                  
                  <div className="checkout-secondary-buttons">
                    <button 
                      className="checkout-secondary-button"
                      onClick={toggleReturnMode}
                    >
                      {translate('switch_to_checkout')}
                    </button>
                    <button 
                      className="checkout-secondary-button danger"
                      onClick={() => {
                        setReturnTransaction(null);
                        setCartItems([]);
                        setTransactionCode('');
                        setTotal(0);
                      }}
                      disabled={!returnTransaction}
                    >
                      {translate('cancel')}
                    </button>
                  </div>
                </>
              )}
            </div>
            
            
            <div className="numpad-section">
              <div className="numpad">
                <div className="numpad-row">
                  <button className="numpad-button" onClick={() => handleNumpadInput('7')}>7</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('8')}>8</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('9')}>9</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('clear')}>⌫</button>
                </div>
                <div className="numpad-row">
                  <button className="numpad-button" onClick={() => handleNumpadInput('4')}>4</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('5')}>5</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('6')}>6</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('+1')}>+1</button>
                </div>
                <div className="numpad-row">
                  <button className="numpad-button" onClick={() => handleNumpadInput('1')}>1</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('2')}>2</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('3')}>3</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('-1')}>-1</button>
                </div>
                <div className="numpad-row">
                  <button className="numpad-button" onClick={() => handleNumpadInput('.')}>.</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('0')}>0</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('00')}>00</button>
                  <button className="numpad-button" onClick={() => handleNumpadInput('OK')}>Enter</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>
              </div>
  );
};

export default CheckOut;