import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Bell, Mail, Menu, Bot, BotOff, BotMessageSquare } from 'lucide-react';
import { useDaxHover } from '../contexts/DaxHoverContext';
import MobileMenuNavBar from './MobileMenuNavBar';
import Sidebar from './Sidebar';
import axios from 'axios';
import logoInventory from '../assets/Daxtop-Store-logo.svg';
import logoMailbox from '../assets/Daxtop-Mailbox-logo.svg';
import './NavBar.css';
import { API_URL } from '../contexts/AuthContext';


function NavBar() {
  const [notificationCount, setNotificationCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [brandName, setBrandName] = useState('');
  const [modifiedBrandName, setModifiedBrandName] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [pageFullyLoaded, setPageFullyLoaded] = useState(false);
  const pollingIntervalRef = useRef(null);

  const { logout } = useAuth();
  const { user } = useAuth(); // Get user from AuthContext

  const location = useLocation();
  const navigate = useNavigate();
  const { isDaxEnabled, setIsDaxEnabled } = useDaxHover();

  // Check if current route is a finances route
  const isFinancesRoute = location.pathname.includes('/store/finances/');

  useEffect(() => {
    const loadingCheckInterval = setInterval(() => {
      const loadingContainer = document.querySelector('.loading-container');
      if (!loadingContainer && !pageFullyLoaded) {
        setPageFullyLoaded(true);
      }
    }, 100);

    return () => clearInterval(loadingCheckInterval);
  }, [pageFullyLoaded]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth >= 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchCounts = async () => {
      if (!user) return; // Don't fetch if no user

      try {
        
        const [notificationResponse, messageResponse] = await Promise.all([
          axios.get(`${API_URL}/pages/notifications/count`),
          axios.get(`${API_URL}/pages/messages/unread`)
        ]);
        
        
        setNotificationCount(notificationResponse.data.count || 0);
        setUnreadMessageCount(messageResponse.data.count || 0);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching counts:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
        setLoading(false);
      }
    };

    if (user) {
      // Initial fetch
      fetchCounts();

      // Set up polling
      pollingIntervalRef.current = setInterval(fetchCounts, 1000);

      return () => {
        if (pollingIntervalRef.current) {
          clearInterval(pollingIntervalRef.current);
        }
      };
    }
  }, [user]); // Depend on user instead of modifiedBrandName

  const isMailboxPath = location.pathname.startsWith('/messenger') ||
    location.pathname.startsWith('/inbox') ||
    location.pathname.startsWith('/sent') ||
    location.pathname.startsWith('/drafts') ||
    location.pathname.startsWith('/spam') ||
    location.pathname.startsWith('/settings');

  const isMessengerChat = location.pathname.match(/^\/messenger\/[^/]+$/);

  const isActive = (path) => {
    if (path === '/store/dax') {
      return location.pathname.startsWith('/store/dax') ? 'active' : '';
    }
    return location.pathname.startsWith(path) ? 'active' : '';
  };

  const handleDaxAIClick = () => {
    setIsDaxEnabled(!isDaxEnabled);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const navLinks = [
    { path: '/store/dax/new', icon: BotMessageSquare },
    { path: '/store/notifications', icon: Bell, count: notificationCount },
    { path: '/store/messenger', icon: Mail, label: 'Messenger', count: unreadMessageCount }
  ];

  // const showDaxButton = !location.pathname.startsWith('/store/dax') && !isMessengerChat;


  const showDaxButton = ![
    '/store/dax',
    '/store/notifications',
    '/store/inventory',
    '/store/messenger',
    '/store/inbox',
    '/store/sent',
    '/store/drafts',
    '/store/spam',
    '/store/settings',
    '/store/checkout',
    '/store/add-item',
    '/store/register-item',


  ].some(path => location.pathname.startsWith(path)) && !isMessengerChat;

  const hideSidebar = [
    '/store/dax',
    '/store/notifications',
    '/store/messenger',
    '/store/inbox',
    '/store/sent',
    '/store/drafts',
    '/store/spam',
    '/store/settings',
    '/store/checkout',
    '/store/add-item',
    '/store/register-item'


  ].some(path => location.pathname.startsWith(path));

  if (isFinancesRoute) {
    return null;
  }



  return (
    <div className="App">
      <nav className={`navbar ${isMobileView ? 'mobile-view' : ''}`}>
        <div className="navbar-container">
          <div className="navbar-left">
            <div className="navbar-logo">
              <Link to="/store/dashboard">
                <img 
                  src={isMailboxPath ? logoMailbox : logoInventory} 
                  alt="Logo"
                />
              </Link>
            </div>
          </div>

          <div className="navbar-right">
            {isMobileView ? (
              <ul className="navbar-links">
{navLinks.map((link) => (
  <li key={link.path}>
    <Link 
      to={link.path === '/store/dax' ? '/store/dax/new' : link.path} 
      className={isActive(link.path)}
    >
      <link.icon size={19} />
      {typeof link.count === 'number' && link.count > 0 && (
        <span className="notification-count">
          {link.count > 99 ? '99+' : link.count}
        </span>
      )}

                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <div>
                <ul className="navbar-links">

                  {navLinks.map((link) => (
                    <li key={link.path}>
                      <Link 
                        to={link.path === '/store/dax' ? '/store/dax/new' : link.path} 
                        className={isActive(link.path)}
                      >
                        <link.icon size={19} />
                        {link.count > 0 && (
                          <span className="notification-count">
                            {link.count > 99 ? '99+' : link.count}
                          </span>
                        )}
                      </Link>
                    </li>
                  ))}
                  <button 
                    className="mobile-menu-toggle" 
                    onClick={toggleMobileMenu}
                  >
                    <Menu size={20.5} />
                  </button>
                </ul>
              </div>
            )}
          </div>
        </div>

        <MobileMenuNavBar
          isOpen={isMobileMenuOpen}
          onClose={toggleMobileMenu}
          notificationCount={notificationCount}
        />

        {showDaxButton && (
          <div 
            className={`dax-ai-button ${isDaxEnabled ? 'enabled' : ''}`}
            onClick={handleDaxAIClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <p>
              {isDaxEnabled && isHovered 
                ? 'Click to Disable' 
                : (isDaxEnabled ? 'DaxAI Enabled ' : 'DaxAI')}
            </p>
            {isDaxEnabled && isHovered ? <BotOff size={22} /> : <Bot size={22} />}
          </div>
        )}
      </nav>

      {!hideSidebar && !isFinancesRoute && pageFullyLoaded && (
        <div className="main-content-sidebar">
          <Sidebar notificationCount={notificationCount} />
        </div>
      )}
    </div>
  );
}

export default NavBar;