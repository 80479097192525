import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { API_URL } from '../contexts/AuthContext';
import './BrokerDashboard.css';

function BrokerClients() {
  const { user } = useAuth();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [referralLink, setReferralLink] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}/broker/clients`);
        setClients(response.data.clients || []);
      } catch (error) {
        console.error('Error fetching clients:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();

    // Generate referral link
    const baseUrl = window.location.origin;
    setReferralLink(`${baseUrl}/register/${user?.brokerID}`);
  }, [user]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      // Handle both string dates and Date objects
      const date = typeof dateString === 'string' ? new Date(dateString) : dateString;
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        alert('Referral link copied to clipboard!');
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  // Helper function to display full name from client data
  const getClientFullName = (client) => {
    // If client has fullName property, use it
    if (client.fullName) {
      return client.fullName;
    }
    
    // Otherwise construct from individual name parts
    const first = client.firstName || '';
    const middle = client.middleName ? ` ${client.middleName} ` : ' ';
    const last = client.lastName || '';
    
    // If we have any name parts, return them joined
    if (first || last) {
      return `${first}${middle}${last}`.trim();
    }
    
    // Default fallback to email or brandName
    return client.brandName || client.email || 'Unknown';
  };

  return (
    <div className="clients-page">
      <div className="clients-header">
        <h1>Your Referred Clients</h1>
      </div>

      <div className="clients-card">
        <div className="card-header">
          <h2>Your Referral Link</h2>
        </div>
        <div className="referral-link-container">
          <p>Share this link with potential clients to earn commissions:</p>
          <div className="referral-link">
            <input 
              type="text" 
              readOnly 
              value={referralLink} 
              className="referral-link-input"
            />
            <button 
              onClick={copyReferralLink}
              className="copy-link-button"
            >
              Copy
            </button>
          </div>
        </div>
      </div>

      <div className="clients-card">
        <div className="card-header">
          <h2>All Clients</h2>
        </div>

        {clients.length > 0 ? (
          <div className="clients-table-container">
            <table className="clients-table">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Brand Name</th>
                  <th>Status</th>
                  <th>Stores</th>
                  <th>Joined Date</th>
                </tr>
              </thead>
              <tbody>
                {clients.map((client, index) => (
                  <tr key={index}>
                    <td>{getClientFullName(client)}</td>
                    <td>{client.email}</td>
                    <td>{client.brandName || 'N/A'}</td>
                    <td>
                      <span className="client-status active">Active</span>
                    </td>
                    <td>{client.stores || '1'}</td>
                    <td>{formatDate(client.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="empty-state">
            
            <h3>No clients yet</h3>
            <p>Start sharing your referral link with potential clients to grow your network and earn commissions.</p>
            <button className="empty-state-button" onClick={copyReferralLink}>
              Copy Referral Link
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default BrokerClients;