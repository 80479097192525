import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, Box, Edit, ShoppingCart, FileText, User, Bell, Bot, ShoppingBag, BookCheck, Cctv } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { translations } from './Data/data'; // Import translations
import axios from 'axios';
import './NavBar.css';

const Sidebar = ({ notificationCount }) => {
  const [language, setLanguage] = useState('en');
  
  const [showCopied, setShowCopied] = useState(false);
  const { user } = useAuth(); // Get user from AuthContext
  const location = useLocation();
  const navigate = useNavigate();

  // Translation helper function
  const translate = (key) => {
    return translations[language]?.[key] || translations['en'][key];
  };

  const handleCopyEmail = () => {
    if (user?.email) {
      navigator.clipboard.writeText(user.email);
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 1500);
    }
  };

  const isActive = (path) => {
    if (path === '/store/dax') {
      return location.pathname.startsWith('/store/dax') ? 'active' : '';
    }
    return location.pathname === path ? 'active' : '';
  };

  const navLinks = [
    { path: '/store/dashboard', icon: Home, label: translate('dashboard') },
    { path: '/store/inventory', icon: Box, label: translate('inventory') },
    { path: '/store/add-item', icon: Edit, label: translate('add') },
    { path: '/store/checkout', icon: BookCheck, label: translate('checkout') },
    // { path: '/store/takeout', icon: ShoppingCart, label: translate('take_out') },
    { path: '/store/orders', icon: ShoppingBag, label: translate('orders') },
    // { path: '/store/reports', icon: FileText, label: translate('reports') },
    { path: '/store/secplan', icon: Cctv, label: translate('security') },
    { path: '/store/profile', icon: User, label: translate('profile') },
  ];

  const handleFinancesClick = () => {
    const prefetchData = sessionStorage.getItem('financesPrefetchData');
    if (prefetchData) {
      const { timestamp } = JSON.parse(prefetchData);
      if (Date.now() - timestamp < 300000) { // 5 minutes
        navigate('/store/finances/accounting');
        return;
      }
    }
    navigate('/store/finances/accounting');
  };

  const handleProfileClick = () => {
    navigate('/store/profile');
  };

  const truncateEmail = (email) => {
    if (!email) return '';
    return email.length > 22 ? `${email.substring(0, 22)}...` : email;
  };
  
  return (
    <div className="sidebar-main">
      <ul className="sidebar-main-links">
        {navLinks.map((link) => (
          <li key={link.path}>
            <Link to={link.path} className={isActive(link.path)}>
              <link.icon size={20} />
              <span>{link.label}</span>
              {link.count > 0 && (
                <span className="notification-count">
                  {link.count > 99 ? '99+' : link.count}
                </span>
              )}
            </Link>
          </li>
        ))}
        <button className="finances-btn" onClick={handleFinancesClick}>
          {translate('finances')}
        </button>
      </ul>

      <div className='bottom-info-container'>
        <button className="store-analytics-btn" onClick={handleFinancesClick}>
          {translate('view_analytics')}
        </button>
        <div className="user-info-container" onClick={handleCopyEmail} style={{ cursor: 'pointer' }}>
          <div className="sidebar-user-email">{truncateEmail(user?.email)}</div>
          <div className="user-role">{translate('admin')}</div>
          {showCopied && <div className="copied-notification">{translate('email_copied')}</div>}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;