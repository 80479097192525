import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './BrokerAuth.css';

function BrokerLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { brokerLogin } = useAuth();
  const [showPassword, setShowPassword] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    try {
      setError('');
      setLoading(true);
      await brokerLogin(email, password);
      navigate('/broker/dashboard');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="BrokerAuth-auth-container">
      <div className="BrokerAuth-auth-card">
        <h2 className="BrokerAuth-h2">Broker Login</h2>
        <hr className="BrokerAuth-hr" />
        <form onSubmit={handleSubmit}>
          {error && <div className="BrokerAuth-error-message">{error}</div>}
          <div className="BrokerAuth-form-group">
            <label className="BrokerAuth-label">Email</label>
            <input
              className="BrokerAuth-input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={loading}
            />
          </div>
          <div className="BrokerAuth-form-group">
            <label className="BrokerAuth-label">Password</label>
            <input
              className="BrokerAuth-input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={loading}
              minLength="6"
            />
          </div>
          <button type="submit" disabled={loading} className="BrokerAuth-auth-button">
            {loading ? 'Loading...' : 'Login as Broker'}
          </button>
          <p className="BrokerAuth-auth-link">
            Not an broker? <Link to="/login">Regular Login</Link>
          </p>
          <p className="BrokerAuth-auth-link">
            Need an broker account? <Link to="/broker/register">Register as Broker</Link>
          </p>
        </form>
      </div>
    </div>
  );
}

function BrokerRegister() {
  const initialFormState = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    middleName: '',
    dateOfBirth: '',
    gender: '',
    nationality: '',
    countryOfResidence: '',
    stateProvince: '',
    cityMunicipality: '',
    permanentAddress: '',
    currentAddress: '',
    primaryPhoneNumber: '',
    secondaryPhoneNumber: '',
  };

  const [formData, setFormData] = useState(initialFormState);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);

  const navigate = useNavigate();
  const { brokerRegister } = useAuth();

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const validateStep1 = () => {
    if (!formData.firstName || !formData.lastName || !formData.dateOfBirth || 
        !formData.gender || !formData.nationality) {
      setError('Please fill all required fields');
      return false;
    }
    return true;
  };

  const validateStep2 = () => {
    if (!formData.countryOfResidence || !formData.stateProvince || 
        !formData.cityMunicipality || !formData.permanentAddress || 
        !formData.currentAddress || !formData.primaryPhoneNumber) {
      setError('Please fill all required fields');
      return false;
    }
    return true;
  };

  const validateStep3 = () => {
    if (!formData.email || !formData.password || !formData.confirmPassword ) {
      setError('Please fill all required fields');
      return false;
    }
    
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    
    if (formData.password.length < 6) {
      setError('Password must be at least 6 characters');
      return false;
    }
    
    return true;
  };

  const nextStep = () => {
    setError('');
    
    if (step === 1 && validateStep1()) {
      setStep(2);
    } else if (step === 2 && validateStep2()) {
      setStep(3);
    }
  };

  const prevStep = () => {
    setError('');
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    // Validate final step
    if (!validateStep3()) {
      return;
    }

    try {
      setError('');
      setLoading(true);
      
      // Exclude confirmPassword from the data sent to the server
      const { confirmPassword, ...registrationData } = formData;
      
      await brokerRegister(registrationData);
      navigate('/broker/dashboard');
    } catch (error) {
      console.error('Broker registration error:', error);
      setError(error.message || 'Failed to create broker account');
    } finally {
      setLoading(false);
    }
  };

  const renderStep1 = () => (
    <>
      <h3>Personal Information</h3>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">First Name*</label>
        <input
          className="BrokerAuth-input"
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
          disabled={loading}
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Last Name*</label>
        <input
          className="BrokerAuth-input"
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
          disabled={loading}
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Middle Name (Optional)</label>
        <input
          className="BrokerAuth-input"
          type="text"
          name="middleName"
          value={formData.middleName}
          onChange={handleChange}
          disabled={loading}
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Date of Birth*</label>
        <input
          className="BrokerAuth-input"
          type="date"
          name="dateOfBirth"
          value={formData.dateOfBirth}
          onChange={handleChange}
          required
          disabled={loading}
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Gender*</label>
        <select
          className="BrokerAuth-select"
          name="gender"
          value={formData.gender}
          onChange={handleChange}
          required
          disabled={loading}
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
          <option value="prefer-not-to-say">Prefer not to say</option>
        </select>
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Nationality*</label>
        <input
          className="BrokerAuth-input"
          type="text"
          name="nationality"
          value={formData.nationality}
          onChange={handleChange}
          required
          disabled={loading}
        />
      </div>
    </>
  );

  const renderStep2 = () => (
    <>
      <h3>Contact Information</h3>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Country of Residence*</label>
        <input
          className="BrokerAuth-input"
          type="text"
          name="countryOfResidence"
          value={formData.countryOfResidence}
          onChange={handleChange}
          required
          disabled={loading}
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">State/Province*</label>
        <input
          className="BrokerAuth-input"
          type="text"
          name="stateProvince"
          value={formData.stateProvince}
          onChange={handleChange}
          required
          disabled={loading}
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">City/Municipality*</label>
        <input
          className="BrokerAuth-input"
          type="text"
          name="cityMunicipality"
          value={formData.cityMunicipality}
          onChange={handleChange}
          required
          disabled={loading}
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Permanent Address*</label>
        <textarea
          className="BrokerAuth-input"
          name="permanentAddress"
          value={formData.permanentAddress}
          onChange={handleChange}
          required
          disabled={loading}
          rows="2"
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Current Address*</label>
        <textarea
          className="BrokerAuth-input"
          name="currentAddress"
          value={formData.currentAddress}
          onChange={handleChange}
          required
          disabled={loading}
          rows="2"
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Primary Phone Number*</label>
        <input
          className="BrokerAuth-input"
          type="tel"
          name="primaryPhoneNumber"
          value={formData.primaryPhoneNumber}
          onChange={handleChange}
          required
          disabled={loading}
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Secondary Phone Number (Optional)</label>
        <input
          className="BrokerAuth-input"
          type="tel"
          name="secondaryPhoneNumber"
          value={formData.secondaryPhoneNumber}
          onChange={handleChange}
          disabled={loading}
        />
      </div>
    </>
  );

  const renderStep3 = () => (
    <>
      <h3>Account Information</h3>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Email Address*</label>
        <input
          className="BrokerAuth-input"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          disabled={loading}
        />
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Password*</label>
        <div className="BrokerAuth-password-container">
          <input
            className="BrokerAuth-input"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            onBlur={handlePasswordBlur}
            required
            disabled={loading}
            minLength="6"
          />
          <button
            type="button"
            className="BrokerAuth-password-toggle-text"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? "Hide password" : "Show password"}
          >
            <p>{showPassword ? "Hide" : "Show"}</p>
          </button>
        </div>
        {passwordTouched && formData.password.length < 6 && (
          <p className="BrokerAuth-password-hint invalid">Password must be at least 6 characters</p>
        )}
      </div>
      <div className="BrokerAuth-form-group">
        <label className="BrokerAuth-label">Confirm Password*</label>
        <input
          className="BrokerAuth-input"
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
          disabled={loading}
          minLength="6"
        />
      </div>
    

    </>
  );

  return (
    <div className="BrokerAuth-auth-container">
      <div className="BrokerAuth-auth-card BrokerAuth-broker-registration-form">
        <h2 className="BrokerAuth-h2">Broker Registration</h2>
        <hr className="BrokerAuth-hr" />
        {error && <div className="BrokerAuth-error-message">{error}</div>}
        
        <div className="BrokerAuth-step-indicator">
          <div className={`BrokerAuth-step ${step === 1 ? 'active' : (step > 1 ? 'completed' : '')}`}>1</div>
          <div className="BrokerAuth-step-line"></div>
          <div className={`BrokerAuth-step ${step === 2 ? 'active' : (step > 2 ? 'completed' : '')}`}>2</div>
          <div className="BrokerAuth-step-line"></div>
          <div className={`BrokerAuth-step ${step === 3 ? 'active' : ''}`}>3</div>
        </div>
        
        <form onSubmit={handleSubmit}>
          {step === 1 && renderStep1()}
          {step === 2 && renderStep2()}
          {step === 3 && renderStep3()}

          <div className="BrokerAuth-form-navigation">
            {step > 1 && (
              <button 
                type="button" 
                onClick={prevStep} 
                disabled={loading}
                className="BrokerAuth-back-button"
              >
                Back
              </button>
            )}
            
            {step < 3 ? (
              <button 
                type="button" 
                onClick={nextStep} 
                disabled={loading}
                className="BrokerAuth-next-button"
              >
                Next
              </button>
            ) : (
              <button 
                type="submit" 
                disabled={loading}
                className="BrokerAuth-submit-button"
              >
                {loading ? 'Registering...' : 'Submit Registration'}
              </button>
            )}
          </div>
        </form>
        
        <p className="BrokerAuth-auth-link">
          Already have an account? <Link to="/broker/login">Broker Login</Link>
        </p>
      </div>
    </div>
  );
}

export { BrokerLogin, BrokerRegister };