import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import {
  Image as ImageIcon,
  Type,
  Grid,
  Upload,
  Move,
  Save,
  ArrowLeft,
  Crop,
  Plus,
  Minus,
  Trash2
} from 'lucide-react';
import './AdminCreateDesign.css';

function AdminCreateDesign() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);
  
  const [title, setTitle] = useState('Untitled Design');
  const [selectedTool, setSelectedTool] = useState('move');
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [zoom, setZoom] = useState(100);
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (id) {
      loadDesign();
    }
  }, [id]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.width = 800;
      canvas.height = 600;
      redrawCanvas();
    }
  }, []);

  useEffect(() => {
    redrawCanvas();
  }, [elements, zoom]);

  const loadDesign = async () => {
    try {
      const response = await api.get(`/designs/${id}`);
      const design = response.data;
      setTitle(design.title || 'Untitled Design');
      setElements(design.elements || []);
    } catch (error) {
      console.error('Error loading design:', error);
      setError('Failed to load design');
    }
  };

  const redrawCanvas = async () => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Clear canvas with white background
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw each element
    for (const element of elements) {
      try {
        if (element.type === 'image' && element.src) {
          const img = await loadImageWithCors(element.src);
          ctx.save();
          if (element.rotation) {
            ctx.translate(element.x + element.width/2, element.y + element.height/2);
            ctx.rotate(element.rotation * Math.PI / 180);
            ctx.drawImage(img, -element.width/2, -element.height/2, element.width, element.height);
          } else {
            ctx.drawImage(img, element.x, element.y, element.width, element.height);
          }
          ctx.restore();
        } else if (element.type === 'text') {
          ctx.save();
          ctx.font = `${element.fontSize}px ${element.fontFamily}`;
          ctx.fillStyle = element.color || '#000000';
          if (element.rotation) {
            ctx.translate(element.x, element.y);
            ctx.rotate(element.rotation * Math.PI / 180);
            ctx.fillText(element.content, 0, 0);
          } else {
            ctx.fillText(element.content, element.x, element.y);
          }
          ctx.restore();
        }
      } catch (error) {
        console.error('Error drawing element:', error);
      }
    }
  };
  
  
  const loadImageWithCors = async (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      
      img.onload = () => {
        resolve(img);
      };
      
      img.onerror = () => {
        // Retry without crossOrigin if first attempt fails
        const retryImg = new Image();
        retryImg.onload = () => {
          resolve(retryImg);
        };
        retryImg.onerror = () => {
          reject(new Error('Failed to load image'));
        };
        retryImg.src = src;
      };
      
      // Add cache buster to prevent caching issues
      img.src = `${src}?cb=${Date.now()}`;
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);
      setError('');

      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const response = await api.post('/designs/upload', {
            imageData: reader.result,
            fileName: file.name
          });

          // Test load the image with CORS before adding to elements
          await loadImageWithCors(response.data.url);

          const newElement = {
            type: 'image',
            src: response.data.url,
            x: 50,
            y: 50,
            width: 300,
            height: 300,
            rotation: 0
          };

          setElements(prev => [...prev, newElement]);
        } catch (error) {
          console.error('Error uploading image:', error);
          setError('Failed to upload image: ' + error.message);
        } finally {
          setIsUploading(false);
        }
      };

      reader.onerror = () => {
        setError('Failed to read image file');
        setIsUploading(false);
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error handling image upload:', error);
      setError('Failed to handle image upload: ' + error.message);
      setIsUploading(false);
    }
  };

  const handleAddText = () => {
    const newElement = {
      type: 'text',
      content: 'Double click to edit',
      x: 50,
      y: 50,
      fontSize: 24,
      fontFamily: 'Arial',
      color: '#000000',
      rotation: 0
    };
    setElements(prev => [...prev, newElement]);
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setError('');

      const canvas = canvasRef.current;
      if (!canvas) {
        throw new Error('Canvas not found');
      }

      // Draw everything on the canvas first
      const ctx = canvas.getContext('2d');
      
      // Clear with white background
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw all elements
      for (const element of elements) {
        if (element.type === 'image' && element.src) {
          try {
            const img = await loadImageWithCors(element.src);
            ctx.drawImage(img, element.x, element.y, element.width, element.height);
          } catch (error) {
            console.error('Error drawing image:', error);
          }
        } else if (element.type === 'text') {
          ctx.font = `${element.fontSize}px ${element.fontFamily}`;
          ctx.fillStyle = element.color || '#000000';
          ctx.fillText(element.content, element.x, element.y);
        }
      }

      // Create a high-quality preview
      const timestamp = Date.now();
      const previewData = canvas.toDataURL('image/png', 1.0);

      // Upload preview image
      const previewResponse = await api.post('/designs/upload', {
        imageData: previewData,
        fileName: `preview-${timestamp}.png`
      });

      // Prepare design data
      const designData = {
        title: title || 'Untitled Design',
        elements,
        canvasData: {
          width: canvas.width,
          height: canvas.height
        },
        previewUrl: previewResponse.data.url,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      // Save or update design
      if (id) {
        await api.put(`/designs/${id}`, designData);
      } else {
        const response = await api.post('/designs', designData);
        navigate(`/admin/create/${response.data.id}`);
      }

      setError('');
    } catch (error) {
      console.error('Error saving design:', error);
      setError('Failed to save design: ' + error.message);
    } finally {
      setIsSaving(false);
    }
  };



  const handleElementSelect = (element) => {
    setSelectedElement(element);
  };

  const handleElementDrag = (element, newX, newY) => {
    setElements(elements.map(el =>
      el === element
        ? { ...el, x: newX, y: newY }
        : el
    ));
  };

  return (
    <div className="design-editor">
      {error && <div className="error-message">{error}</div>}
      
      <div className="toolbar">
        <button 
          className="back-button"
          onClick={() => navigate('/admin/designs')}
        >
          <ArrowLeft size={20} />
          Back to Designs
        </button>

        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Untitled Design"
          className="title-input"
        />

        <div className="tool-buttons">
          <button
            className={`tool-button ${selectedTool === 'move' ? 'active' : ''}`}
            onClick={() => setSelectedTool('move')}
            title="Move Tool"
          >
            <Move size={20} />
          </button>
          <button
            className={`tool-button ${selectedTool === 'crop' ? 'active' : ''}`}
            onClick={() => setSelectedTool('crop')}
            title="Crop Tool"
          >
            <Crop size={20} />
          </button>
          <button
            className="tool-button"
            onClick={() => setZoom(prev => Math.min(prev + 10, 200))}
            title="Zoom In"
          >
            <Plus size={20} />
          </button>
          <button
            className="tool-button"
            onClick={() => setZoom(prev => Math.max(prev - 10, 50))}
            title="Zoom Out"
          >
            <Minus size={20} />
          </button>
          <span className="zoom-level">{zoom}%</span>
        </div>

        <button
          className="save-button"
          onClick={handleSave}
          disabled={isSaving}
        >
          <Save size={20} />
          {isSaving ? 'Saving...' : 'Save'}
        </button>
      </div>

      <div className="editor-container">
        <div className="sidebar left-sidebar">
          <div className="sidebar-section">
            <h3>Elements</h3>
            <button 
              className="sidebar-button" 
              onClick={handleAddText}
              title="Add Text Element"
            >
              <Type size={20} />
              Add Text
            </button>
            <label className="sidebar-button" title="Upload Image">
              <Upload size={20} />
              {isUploading ? 'Uploading...' : 'Upload Image'}
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
                disabled={isUploading}
              />
            </label>
            <button className="sidebar-button" title="Add Shape">
              <Grid size={20} />
              Shapes
            </button>
          </div>
        </div>

        <div className="canvas-container">
          {isUploading && (
            <div className="loading-overlay">
              <p>Uploading image...</p>
            </div>
          )}
          <canvas
            ref={canvasRef}
            className="design-canvas"
            style={{ 
              transform: `scale(${zoom / 100})`,
              transformOrigin: 'center center'
            }}
          />
        </div>

        <div className="sidebar right-sidebar">
          {selectedElement && (
            <div className="element-properties">
              <h3>Properties</h3>
              
              {selectedElement.type === 'text' && (
                <>
                  <div className="property-group">
                    <label>Text Content</label>
                    <input
                      type="text"
                      value={selectedElement.content}
                      onChange={(e) => {
                        setElements(elements.map(el =>
                          el === selectedElement
                            ? { ...el, content: e.target.value }
                            : el
                        ));
                      }}
                    />
                  </div>
                  
                  <div className="property-group">
                    <label>Font Size</label>
                    <input
                      type="number"
                      value={selectedElement.fontSize}
                      min="1"
                      max="200"
                      onChange={(e) => {
                        setElements(elements.map(el =>
                          el === selectedElement
                            ? { ...el, fontSize: parseInt(e.target.value) || 1 }
                            : el
                        ));
                      }}
                    />
                  </div>
                  
                  <div className="property-group">
                    <label>Color</label>
                    <input
                      type="color"
                      value={selectedElement.color}
                      onChange={(e) => {
                        setElements(elements.map(el =>
                          el === selectedElement
                            ? { ...el, color: e.target.value }
                            : el
                        ));
                      }}
                    />
                  </div>
                </>
              )}
              
              {selectedElement.type === 'image' && (
                <div className="property-group">
                  <label>Size</label>
                  <div className="size-inputs">
                    <input
                      type="number"
                      value={Math.round(selectedElement.width)}
                      min="1"
                      onChange={(e) => {
                        const newWidth = parseInt(e.target.value) || 1;
                        setElements(elements.map(el =>
                          el === selectedElement
                            ? { ...el, width: newWidth }
                            : el
                        ));
                      }}
                    />
                    <span>×</span>
                    <input
                      type="number"
                      value={Math.round(selectedElement.height)}
                      min="1"
                      onChange={(e) => {
                        const newHeight = parseInt(e.target.value) || 1;
                        setElements(elements.map(el =>
                          el === selectedElement
                            ? { ...el, height: newHeight }
                            : el
                        ));
                      }}
                    />
                  </div>
                </div>
              )}
              
              <div className="property-group">
                <label>Position</label>
                <div className="position-inputs">
                  <input
                    type="number"
                    value={Math.round(selectedElement.x)}
                    onChange={(e) => {
                      const newX = parseInt(e.target.value) || 0;
                      setElements(elements.map(el =>
                        el === selectedElement
                          ? { ...el, x: newX }
                          : el
                      ));
                    }}
                  />
                  <span>,</span>
                  <input
                    type="number"
                    value={Math.round(selectedElement.y)}
                    onChange={(e) => {
                      const newY = parseInt(e.target.value) || 0;
                      setElements(elements.map(el =>
                        el === selectedElement
                          ? { ...el, y: newY }
                          : el
                      ));
                    }}
                  />
                </div>
              </div>
              
              <div className="property-group">
                <label>Rotation (degrees)</label>
                <input
                  type="number"
                  value={selectedElement.rotation || 0}
                  min={-360}
                  max={360}
                  onChange={(e) => {
                    const newRotation = parseInt(e.target.value) || 0;
                    setElements(elements.map(el =>
                      el === selectedElement
                        ? { ...el, rotation: newRotation }
                        : el
                    ));
                  }}
                />
              </div>
              
              <button
                className="delete-button"
                onClick={() => {
                  setElements(elements.filter(el => el !== selectedElement));
                  setSelectedElement(null);
                }}
                title="Delete Selected Element"
              >
                <Trash2 size={20} />
                Delete Element
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminCreateDesign;